import React, { useCallback } from "react";
import { makeStyles, PersonaSize, Text, Icon } from "@fluentui/react";

import { useTranslation } from "react-i18next";
import { Mine } from "../../locales";
import { StatusType } from "./CardStatusText";
import BaseCard from "./Card";
import { RCUserPersona } from "../rc/RCPersona";
import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";
import { useCustomizeTime } from "../../hooks/useCustomizeTime";

const Importing = 1;
const ImportingFailed = 2;

function getBundleStatus(doc: CDS.Bundle): "importing" | "failed" | undefined {
  const documentStatus = doc.status;
  switch (documentStatus) {
    case Importing:
      return "importing";
    case ImportingFailed:
      return "failed";
    default:
      return undefined;
  }
}

const useStyles = makeStyles(theme => ({
  userImg: { width: rem(34) },
  coverTag: {
    backgroundColor: theme.palette.blackTranslucent40,
    position: "absolute",
    top: 0,
    left: 0,
    padding: rem(6, 12),
    minWidth: rem(50),
    borderRadius: rem(4, 0, 4, 0),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    " i": {
      color: theme.palette.white,
      fontSize: rem(12),
    },
    " span": {
      color: theme.palette.white,
      marginLeft: rem(8),
      lineHeight: rem(16),
      fontWeight: 600,
    },
  },
}));

const useCreatorStyles = makeStyles(theme => ({
  details: { paddingLeft: rem(8) },
  primaryText: { color: theme.palette.neutralPrimaryAlt, height: rem(16), lineHeight: rem(16) },
  secondaryText: {
    fontSize: rem(12),
    color: theme.palette.neutralPrimaryAlt,
    height: rem(14),
    lineHeight: rem(14),
  },
}));

export default function BundleCard({
  bundle,
  onTap,
  children,
  isDeleting,
  isJustRead,
  showCreator,
  rootRef,
}: React.PropsWithChildren<{
  isJustRead?: boolean;
  bundle?: CDS.Bundle;
  showCreator?: boolean;
  onTap?: (bundle: CDS.Bundle) => void;
  isDeleting?: boolean;
  rootRef?: React.LegacyRef<HTMLDivElement>;
}>) {
  const { t } = useTranslation();
  const onClick = useCallback(() => bundle && onTap && onTap(bundle), [bundle, onTap]);
  const status: StatusType = isDeleting
    ? "deleting"
    : (bundle && getBundleStatus(bundle)) ?? (isJustRead ? "justRead" : undefined);
  const displayTime = useCustomizeTime(bundle?.createAt);
  const styles = useStyles();
  const creatorStyles = useCreatorStyles();
  const notJustRead = status && !(status === "justRead");
  return (
    <BaseCard
      rootRef={rootRef}
      className="bundle-card"
      title={bundle?.name}
      description={bundle?.description}
      cover={bundle?.cover}
      status={status}
      onClick={onClick}
      category={showCreator ? bundle?.category?.name : undefined}
      tag={
        <div className={`${styles.coverTag}`}>
          <Icon iconName={IconName.Collection} />
          {bundle && (
            <Text variant="small">{t(Mine.content, { count: bundle.stats?.itemCount || 0 })}</Text>
          )}
        </div>
      }
      footer={
        <>
          {!isJustRead &&
            !notJustRead &&
            (showCreator ? (
              <RCUserPersona
                size={PersonaSize.size28}
                userId={bundle?.creatorId}
                secondaryText={displayTime}
                showSecondaryText
                styles={creatorStyles}
              />
            ) : (
              <div />
            ))}
          {children}
        </>
      }
    />
  );
}
