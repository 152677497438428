import React, { ReactElement } from "react";
import { Text, mergeStyles, useTheme, makeStyles, Shimmer } from "@fluentui/react";
import CardStatusText, { StatusType } from "./CardStatusText";

import Cardcss from "./Card.module.scss";
import FileTypeIcon from "../common/FileTypeIcon";
import { rem } from "../../lib/unit";
import ShimmerImage from "../common/ShimmerImage";

export interface TingCardProps {
  title?: string;
  cover?: string;
  category?: string;
  description?: string | false;
  status?: StatusType;
  footer?: ReactElement;
  fileExtension?: string;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  tag?: ReactElement;
  rootRef?: React.LegacyRef<HTMLDivElement>;
}

const useCardStyles = makeStyles(theme => ({
  root: [
    {
      boxShadow: theme?.effects.elevation4,
      color: theme.semanticColors.bodyText,
      backgroundColor: theme?.isInverted ? theme?.palette.neutralLighter : theme?.palette.white,
      cursor: "pointer",
      selectors: {
        ":hover": {
          boxShadow: theme?.effects.elevation8,
          backgroundColor: theme.palette.neutralLighterAlt,
        },
        ":hover::after": {
          content: "",
          border: "none",
        },
        ":active": { backgroundColor: theme.palette.neutralLight },
      },
    },
    Cardcss.Card,
  ],
  category: { color: theme.palette.themePrimary, fontWeight: 600 },
  statusMask: { backgroundColor: "rgba(96, 94, 92, 0.6)" },
  cover: [
    Cardcss.Cover,
    {
      backgroundColor: theme.palette.neutralLight,
      borderRadius: rem(4, 4, 0, 0),
      " img": {
        objectFit: "cover",
        borderRadius: rem(4, 4, 0, 0),
      },
    },
  ],
  footer: [Cardcss.Foot, { color: theme.palette?.neutralSecondary, position: "relative" }],
  fileIcon: {
    height: rem(32),
    width: rem(32),
    fontSize: rem(32),
    position: "absolute",
    bottom: rem(12),
    left: rem(12),
  },
  textBreakWord: { wordBreak: "break-word !important", overflowWrap: "break-word" },
  imgBox: { position: "relative" },
}));

const useShimmerCss = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
  dataWrapper: {
    height: "100%",
    width: "100%",
  },
  shimmerWrapper: {
    height: "100%",
  },
});

export default function BaseCard({ status, ...props }: TingCardProps) {
  const theme = useTheme();
  const cardStyles = useCardStyles();
  const palette = theme.palette;
  const isJustRead = status === "justRead";
  const notJustRead = status && !isJustRead;
  const isDeleting = status === "deleting";
  const shimmerCss = useShimmerCss();
  const isTextLoaded = !!props.category || !!props.title || !!props.description;

  return (
    <div
      ref={props.rootRef}
      className={mergeStyles(cardStyles.root, notJustRead && "is-disabled", props.className)}
      onClick={props.onClick}
    >
      <div className={cardStyles.imgBox}>
        <ShimmerImage className={cardStyles.cover} src={props.cover} />
        {props.fileExtension && (
          <FileTypeIcon
            styles={{ root: cardStyles.fileIcon }}
            extension={props.fileExtension}
          ></FileTypeIcon>
        )}
      </div>
      <div
        className={`${Cardcss.Cover} ${Cardcss.Cover_mask} ${
          notJustRead && cardStyles.statusMask
        } `}
      >
        {props.tag}
      </div>
      <div className={Cardcss.Body}>
        {props.category && (
          <Text block variant="small" className={`${Cardcss.Category} ${cardStyles.category}`}>
            {props.category}
          </Text>
        )}
        <Shimmer
          className={`${Cardcss.Title} ${props.category ? Cardcss.TitleOneLine : ""}`}
          styles={shimmerCss}
          isDataLoaded={isTextLoaded}
        >
          <Text
            className={`${Cardcss.Title} ${cardStyles.textBreakWord} ${
              props.category ? Cardcss.TitleOneLine : ""
            }`}
            styles={{ root: { color: notJustRead ? palette?.neutralSecondaryAlt : undefined } }}
            block
          >
            {props.title}
          </Text>
        </Shimmer>
        {props.description !== false && (
          <Shimmer
            className={`${Cardcss.Desc} ${cardStyles.textBreakWord}`}
            styles={shimmerCss}
            isDataLoaded={isTextLoaded}
          >
            {props.description && (
              <Text
                className={`${Cardcss.Desc} ${cardStyles.textBreakWord}`}
                styles={{
                  root: {
                    color: notJustRead ? palette?.neutralSecondaryAlt : palette?.neutralPrimaryAlt,
                  },
                }}
              >
                {props.description}
              </Text>
            )}
          </Shimmer>
        )}
        <div className={cardStyles.footer}>
          {(isJustRead || notJustRead) && <CardStatusText status={status} />}
          {!isDeleting && props.footer}
        </div>
      </div>
    </div>
  );
}
