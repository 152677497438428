import React, { useEffect } from "react";
import { findUsers } from "../../services/graph/graphUsers";
import { findGroups } from "../../services/graph/graphGroups";
import { GraphPagination, PrincipalType } from "../../services/request";
import {
  ActionButton,
  DirectionalHint,
  IBasePicker,
  IPickerItemProps,
  ISuggestionItemProps,
  Label,
  makeStyles,
  NormalPeoplePicker,
  PersonaSize,
  ValidationState,
  IStyleFunctionOrObject,
  ICalloutContentStyles,
  ICalloutContentStyleProps,
} from "@fluentui/react";

import debounce from "debounce-promise";
import {
  createPersonasFromGroups,
  createPersonasFromUsers,
  RCPersona,
  RCPersonaProps,
} from "../rc/RCPersona";
import { AxiosResponse } from "axios";
import { Group, User } from "@microsoft/microsoft-graph-types";
import { useTranslation } from "react-i18next";
import { RCPeoplePickerI18n } from "../../locales";
import { IconName } from "../../config/icons";
import { FontSizes } from "../../themes/fonts";
import { rem } from "../../lib/unit";

const useSuggestionsItemStyles = makeStyles({
  suggestionsItem: { padding: rem(8, 16), width: "100%" },
});

export function SuggestionsItem(
  props: RCPersonaProps,
  _itemProps: ISuggestionItemProps<RCPersonaProps>
) {
  const suggestionsItemStyles = useSuggestionsItemStyles();
  return (
    <div
      key={`suggestionItem_${props.principalId}`}
      className={suggestionsItemStyles.suggestionsItem}
    >
      <RCPersona
        size={PersonaSize.size32}
        showSecondaryText={true}
        withPhoto={true}
        principalId={props.principalId}
        principalType={props.principalType}
        principalData={props.principalData}
      />
    </div>
  );
}

const usePickerItemStyles = makeStyles(theme => ({
  itemContainer: {
    alignItems: "center",
    outline: "transparent",
    position: "relative",
    borderRadius: rem(12),
    display: "inline-flex",
    margin: rem(3, 0, 3, 5),
    maxWidth: "100%",
    verticalAlign: "middle",
    userSelect: "none",
    backgroundColor: theme.palette.neutralQuaternaryAlt,
  },
}));

const useItemPersonStyles = makeStyles({
  initials: {
    fontSize: FontSizes.small,
  },
});

const useItemBtnStyles = makeStyles({
  root: {
    fontSize: FontSizes.small,
    height: rem(24),
  },
});

export function PickerItem(p: IPickerItemProps<RCPersonaProps>) {
  const pickerItemStyles = usePickerItemStyles();
  const itemPersonStyle = useItemPersonStyles();
  const itemBtnStyle = useItemBtnStyles();
  return (
    <div key={`renderItem_${p.item.id}`} className={`pickerItem ${pickerItemStyles.itemContainer}`}>
      <RCPersona
        size={PersonaSize.size24}
        withPhoto={true}
        coinProps={{ styles: itemPersonStyle }}
        principalId={p.item.principalId}
        principalType={p.item.principalType}
        principalData={p.item.principalData}
      />
      <ActionButton
        styles={itemBtnStyle}
        iconProps={{ iconName: IconName.Cancel }}
        onClick={p.onRemoveItem}
      />
    </div>
  );
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const pickerCalloutMaxWidth = 350;
const pickerResolveDelay = 300;

const validateRawText = (inputText: string): ValidationState => ValidationState.invalid;

const validateEmail = (inputText: string): ValidationState => {
  if (!inputText || !inputText.trim()) {
    return ValidationState.invalid;
  }

  if (emailRegex.test(inputText)) {
    return ValidationState.valid;
  }

  return ValidationState.warning;
};

function onInputChange(input: string): string {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}

function createGenericItem(input: string, state: ValidationState) {
  return {
    text: input,
    email: input,
    ValidationState: state,
  };
}

function getTextFromItem(persona: RCPersonaProps): string {
  return persona.text as string;
}

function getPersonaFromResponseAndFilter(
  responses: {
    userResponse?: AxiosResponse<GraphPagination<User>>;
    groupResponse?: AxiosResponse<GraphPagination<Group>>;
  },
  selectedItems?: RCPersonaProps[]
): RCPersonaProps[] {
  const result = [
    ...createPersonasFromUsers(responses?.userResponse?.data?.value),
    ...createPersonasFromGroups(responses?.groupResponse?.data?.value),
  ];
  const selectedItemIds = selectedItems?.map(item => item.id).filter(i => !!i) ?? [];
  return result?.filter(item => selectedItemIds.indexOf(item.id) === -1);
}

export interface RCPeoplePickerProps {
  principalType?: PrincipalType;
  className?: string;
  currentSelectedItems?: RCPersonaProps[];
  error?: string;
  hideLabel?: boolean; // Optional property to hide people picker label
  itemLimit?: number; // Optional property to set a item limit to people picker
  setInitialFocus?: boolean; // Optional property to set initial focus on people picker
  innerRef?: React.RefObject<IBasePicker<RCPersonaProps>>; // Optional property to pass in a component ref
  label?: string;
  disabled?: boolean; // Optional property to set if the people picker is disabled
  enableEmail?: boolean; // Optional property to allow free email input
  onChanged?(currentPersonas?: RCPersonaProps[]): void;
  inputDefaultValue?: string;
}

const useStyles = makeStyles(theme => ({
  pickerSuggestions: {
    padding: rem(5, 0, 5, 0),
    height: "100%",
  },
  pickerSuggestionsItem: {
    selectors: {
      "::after": {
        border: "none",
      },
    },
  },
  label: {
    "&.ms-Label": {
      fontSize: FontSizes.xSmall,
      color: theme.palette.red,
    },
  },
}));

const pickerCalloutStyles: IStyleFunctionOrObject<
  ICalloutContentStyleProps,
  ICalloutContentStyles
> = {
  root: {
    borderRightWidth: 0,
  },
};

export default function RCPeoplePicker(props: RCPeoplePickerProps) {
  const {
    principalType,
    innerRef,
    itemLimit,
    hideLabel,
    currentSelectedItems,
    setInitialFocus,
    error,
    label,
    disabled,
    enableEmail,
    className,
    inputDefaultValue,
  } = props;
  const { t } = useTranslation();
  const onBlur = (evt: React.FocusEvent<HTMLInputElement>): void => {
    if (evt.target.value && innerRef && innerRef.current) {
      innerRef.current.completeSuggestion(true);
    }
  };

  const onFocus = (evt: React.FocusEvent<HTMLInputElement>): void => evt.stopPropagation();

  const onChanged = (currentPersonas?: RCPersonaProps[]): void =>
    props.onChanged && props.onChanged(currentPersonas!);

  const onFilterChanged = debounce(
    (filterText: string, selectedItems?: RCPersonaProps[]): Promise<RCPersonaProps[]> => {
      let result;
      if (principalType === PrincipalType.user) {
        result = findUsers(filterText).then(res =>
          getPersonaFromResponseAndFilter({ userResponse: res }, selectedItems)
        );
      } else if (principalType === PrincipalType.group) {
        result = findGroups(filterText).then(res =>
          getPersonaFromResponseAndFilter({ groupResponse: res }, selectedItems)
        );
      } else {
        result = Promise.allSettled([findGroups(filterText), findUsers(filterText)]).then(res =>
          getPersonaFromResponseAndFilter(
            {
              userResponse: res[1].status === "fulfilled" ? res[1].value : undefined,
              groupResponse: res[0].status === "fulfilled" ? res[0].value : undefined,
            },
            selectedItems
          )
        );
      }
      return result;
    },
    pickerResolveDelay
  );

  const styles = useStyles();

  const validateInput = (input: string): ValidationState =>
    enableEmail ? validateEmail(input) : validateRawText(input);

  useEffect((): void => {
    if (setInitialFocus && innerRef && innerRef.current) {
      innerRef.current.focusInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {!hideLabel && <Label>{label}</Label>}
      <NormalPeoplePicker
        onResolveSuggestions={onFilterChanged}
        onRenderSuggestionsItem={props => <SuggestionsItem {...props} />}
        onRenderItem={props => <PickerItem {...props} />}
        getTextFromItem={getTextFromItem}
        onChange={onChanged}
        key={"normal"}
        onValidateInput={validateInput}
        inputProps={{
          placeholder: t(RCPeoplePickerI18n.inputPlaceholder),
          onFocus,
          defaultVisibleValue: inputDefaultValue,
        }}
        pickerSuggestionsProps={{
          noResultsFoundText: t(RCPeoplePickerI18n.noResultsTip),
          suggestionsClassName: styles.pickerSuggestions,
          suggestionsItemClassName: styles.pickerSuggestionsItem,
        }}
        pickerCalloutProps={{
          calloutWidth: pickerCalloutMaxWidth,
          directionalHint: DirectionalHint.bottomLeftEdge,
          styles: pickerCalloutStyles,
        }}
        componentRef={innerRef}
        onInputChange={onInputChange}
        resolveDelay={pickerResolveDelay}
        disabled={disabled}
        selectedItems={currentSelectedItems}
        itemLimit={itemLimit}
        createGenericItem={createGenericItem}
        onBlur={onBlur}
      />
      <Label className={styles.label}>{error}</Label>
    </div>
  );
}
