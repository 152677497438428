import React, { ErrorInfo, Component, ComponentType, FC } from "react";
import { makeStyles, Text, PrimaryButton, Icon, DirectionalHint, Image } from "@fluentui/react";
import { rem } from "../../lib/unit";
import { ErrorBoundaryI18n } from "../../locales/namespace";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import CopyCallout from "../../components/common/CopyCallout";
import { useBoolean } from "@fluentui/react-hooks";
import { ThemeProvider } from "@fluentui/react";
import RootFragment from "../../components/RootFragment";
import { isPrerender } from "../../config/browser";
import { useTeamsTheme } from "../../hooks/useTeamsTheme";
import errorImage from "../../img/error_boundary.png";

const copyBtnId = "error-copy";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    height: "95vh",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  img: {
    height: rem(150),
    width: rem(200),
    paddingBottom: rem(20),
  },
  subTitle: {
    paddingTop: rem(16),
    marginBottom: rem(32),
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  copyBtn: { color: theme.palette.themePrimary, cursor: "pointer" },
}));

export function ErrorBoundaryPage({
  error,
  errorInfo,
  clearError,
}: {
  error?: Error;
  errorInfo?: React.ErrorInfo;
  clearError: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTeamsTheme();

  const errorMessage = `errorMessage: \r\n${error && error.message}`;
  const errorInfoText = `errorInfo: \r\n${errorInfo && errorInfo.componentStack}`;

  const [copyCalloutShow, { setFalse, setTrue }] = useBoolean(false);
  const copyText = `${errorMessage}\r\n${errorInfoText}`;

  const styles = useStyles();
  return (
    <ThemeProvider as={RootFragment} theme={theme} applyTo={isPrerender ? "none" : "body"}>
      <div className={styles.root}>
        <div className={styles.content}>
          <Image src={errorImage} className={styles.img} />
          <Text block variant="large">
            {t(ErrorBoundaryI18n.errorTitle)}
          </Text>
          <Text block variant="medium" className={styles.subTitle}>
            {t(ErrorBoundaryI18n.errorSubtitle)}
            <span id={copyBtnId} className={styles.copyBtn} onClick={setTrue}>
              {t(ErrorBoundaryI18n.errorContentCopy)}
              <Icon
                iconName={IconName.Copy}
                styles={{ root: { color: theme.palette.themePrimary, display: "inline" } }}
              />
            </span>
            {t(ErrorBoundaryI18n.errorSubtitle, { context: "end" })}
          </Text>
          <PrimaryButton text={t(ErrorBoundaryI18n.errorBack)} onClick={clearError} />
        </div>
        {copyCalloutShow && (
          <CopyCallout
            target={`#${copyBtnId}`}
            item={copyText}
            directionalHint={DirectionalHint.topAutoEdge}
            onDismiss={setFalse}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export interface ErrorBoundaryProps {
  defaultError?: Error;
  defaultErrorInfo?: ErrorInfo;
  children?: React.ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  state = { error: this.props.defaultError, errorInfo: this.props.defaultErrorInfo };
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  clearError = () => this.setState({ error: undefined, errorInfo: undefined });

  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  render() {
    const { error, errorInfo } = this.state;
    return error || errorInfo ? (
      <ErrorBoundaryPage error={error} errorInfo={errorInfo} clearError={this.clearError} />
    ) : (
      this.props.children
    );
  }
}

export function withErrorBoundary<Props = Record<string, unknown>>(
  WrappedComponent: ComponentType<Props>
): FC<Props> {
  return (props: Props) => (
    <ErrorBoundary>
      <WrappedComponent key="WrappedComponent" {...props} />
    </ErrorBoundary>
  );
}

export default ErrorBoundary;
