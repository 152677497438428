import React from "react";
import { IButtonProps, ActionButton, Icon, makeStyles } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Mine } from "../../locales";
import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";

export interface ExpandButtonProps {
  expandStatus: boolean;
  buttonProps?: IButtonProps;
}

const useStyles = makeStyles({ root: { height: rem(32) }, label: { marginRight: rem(8) } });

export default function ExpandButton({
  expandStatus,
  buttonProps,
}: ExpandButtonProps): React.ReactElement {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <ActionButton
      text={t(expandStatus ? Mine.collapse : Mine.expand)}
      {...buttonProps}
      styles={{ ...styles, ...buttonProps?.styles }}
    >
      <Icon iconName={expandStatus ? IconName.ChevronUp : IconName.ChevronDown} />
    </ActionButton>
  );
}
