import "./ContextualMenu.scss";

function bindMaskClick(document: HTMLElement, cb: (ev: Event) => void) {
  const items = document.querySelectorAll<HTMLElement>(
    ".ms-Callout-container>.ms-ContextualMenu-Callout"
  );
  items.forEach(item => (item.onclick = cb));
}

/**
 * 检测节点变化，转移点击事件
 * @param element the element to trigger
 */
export function watchBodyNodes(element: HTMLElement) {
  const callback = (ev: Event) => {
    // console.log("click", ev.target !== ev.currentTarget);
    // || ev.type === "touchend"
    if (ev.type === "click" && ev.target === ev.currentTarget) {
      element.click();
    }
  };
  const observer = new MutationObserver(mutationList => {
    const mutation = mutationList[0];
    if (mutation.type === "childList") {
      mutation.addedNodes.forEach(node => {
        bindMaskClick(node as HTMLElement, callback);
      });
    }
  });
  observer.observe(document.body, {
    childList: true,
    attributes: false,
    characterData: false,
    subtree: false,
  });
  return () => observer.disconnect();
}
