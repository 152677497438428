import { DefaultButton, makeStyles, mergeStyles } from "@fluentui/react";
import { useControllableValue } from "@fluentui/react-hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactSortable } from "react-sortablejs";
import SmallCard from "../cards/SmallCard";
import ShimmerCard from "../cards/ShimmerCard";
import { BundlesEditorI18n } from "../../locales";
import { rem } from "../../lib/unit";

const useStyles = makeStyles({
  root: [
    "CardList",
    {
      ">div": {
        cursor: "grab",
      },
      "&.is-loading>div": {
        cursor: "unset",
      },
    },
  ],
  chosenItem: {
    cursor: "grabbing !important",
  },
  removeBtn: {
    fontSize: rem(12),
    height: rem(24),
    minWidth: 0,
    padding: rem(0, 8),
  },
});
export interface SortableDocCardGridProps {
  isLoading?: boolean;
  docs?: CDS.Document[];
  defaultDocs?: CDS.Document[];
  className?: string;
  onUpdate?: (ev?: React.SyntheticEvent<HTMLElement, Event>, docs?: CDS.Document[]) => void;
}

export default function SortableDocCardGrid({
  defaultDocs = [],
  className,
  docs,
  onUpdate,
  isLoading,
}: React.PropsWithChildren<SortableDocCardGridProps>) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [items, setItems] = useControllableValue(docs, defaultDocs, onUpdate);
  return isLoading ? (
    <div className={mergeStyles(styles.root, className, "is-loading")}>
      <ShimmerCard showCreator type="doc" width={80} />
      <ShimmerCard showCreator type="doc" width={80} />
      <ShimmerCard showCreator type="doc" width={80} />
      <ShimmerCard showCreator type="doc" width={80} />
    </div>
  ) : (
    <ReactSortable
      animation={200}
      list={items as Required<CDS.Document>[]}
      setList={newState => setItems(newState)}
      className={mergeStyles(styles.root, className)}
      chosenClass={styles.chosenItem}
      // selectedClass = "sortable-selected",
      // chosenClass = "sortable-chosen",
      // /* eslint-disable */
      // dragClass = "sortable-drag",
      // fallbackClass = "sortable-falback",
      // ghostClass = "sortable-ghost",
      // swapClass = "sortable-swap-highlight",
      // /* eslint-enable */
      // filter = "sortable-filter",
    >
      {items?.map(doc => (
        <SmallCard key={doc.id} doc={doc} showCreator>
          <DefaultButton
            className={styles.removeBtn}
            onClick={() => setItems(items => items?.filter(i => i !== doc))}
          >
            {t(BundlesEditorI18n.remove)}
          </DefaultButton>
        </SmallCard>
      ))}
    </ReactSortable>
  );
}
