import { IBreadcrumbStyleProps } from "@fluentui/react";
import { rem } from "../../lib/unit";
import { FontSizes } from "../fonts";
export function getBreadcrumbStyles(thmeName: string) {
  return (props: IBreadcrumbStyleProps) => {
    const { palette } = props.theme;
    const activeStyle = {
      color: palette.themePrimary,
      textDecoration: "none",
      backgroundColor: "none",
    };

    const stateSelectors = {
      ":hover": activeStyle,
      ":active": activeStyle,
      "&:active:hover": activeStyle,
    };
    const styles = {
      root: {
        paddingRight: rem(24),
        lineHeight: rem(20),
        margin: "unset",
        selectors: {
          ".ms-Breadcrumb-listItem:last-child .ms-Breadcrumb-itemLink": {
            fontWeight: "blod",
            color: palette.black,
          },
          ".ms-Breadcrumb-listItem:last-child .ms-Breadcrumb-item": {
            fontWeight: "bold",
          },
        },
      },
      item: {
        color: palette.neutralDark,
        fontSize: FontSizes.medium,
        lineHeight: rem(40),
        height: rem(40),
        selectors: {
          ":last-child": {
            color: palette.neutralDark,
          },
        },
      },
      itemLink: {
        fontSize: FontSizes.medium,
        lineHeight: rem(40),
        height: rem(40),
        outline: "none",
        fontWeight: "regular",
        color: palette.neutralDark,
        paddingLeft: 0,
        selectors: {
          ".ms-Fabric--isFocusVisible &:focus": {
            // Necessary due to changes of Link component not using getFocusStyle.
            outline: "none",
          },
          ...stateSelectors,
        },
      },
      chevron: {
        color: palette.neutralDark,
        fontSize: FontSizes.small,
      },
      overflowButton: {
        color: palette.neutralDark,
        selectors: {
          ...stateSelectors,
        },
      },
    };
    return styles;
  };
}
