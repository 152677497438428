/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

import { ICalloutContentStyleProps, IRawStyle, ITheme } from "@fluentui/react";
import { ShadowLevels } from "../TeamsShadowLevels";
// tslint:disable-next-line:require-casing
import { teamsBorderRadius } from "./styleConstants";
import { CoreColors, DarkColors, ContrastColors } from "../teamsColors";

export function getCalloutContentStyles(themeName: string, _theme: ITheme) {
  // tslint:disable-next-line:max-func-body-length
  return (props: ICalloutContentStyleProps) => {
    const { theme } = props;
    const { palette } = theme;

    const styles: Record<string, Record<string, IRawStyle | string>> = {
      root: {
        borderRadius: teamsBorderRadius,
        boxShadow: ShadowLevels.shadowLevel3,
        background: palette.white,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: CoreColors.appBlackAlpha10,

        selectors: {
          ".ms-ProgressIndicator-itemName": {
            color: palette.black,
          },
          ".ms-ProgressIndicator-itemDescription > span": {
            color: CoreColors.appRed,
          },
        },
        // Todo - Move lines above this to files app
      },
      beakCurtain: {
        borderRadius: teamsBorderRadius,
      },
      calloutMain: {
        borderRadius: teamsBorderRadius,
        background: palette.white,
      },
    };

    // Dark theme overrides
    if (themeName === "dark") {
      styles.root.boxShadow = ShadowLevels.shadowLevel3Dark;
      styles.root.borderColor = palette.white;
      styles.root.background = palette.neutralLight;
      styles.calloutMain.background = palette.neutralLight;

      // Todo - Move lines below this to files app.
      (styles.root.selectors as any)[".ms-ProgressIndicator-itemDescription > span"].color =
        DarkColors.appRed;
      // Todo - Move lines above this to files app.
    }

    // Contrast theme overrides
    if (themeName === "contrast") {
      styles.root.boxShadow = "none";
      styles.root.borderColor = palette.black;
      styles.root.background = palette.white;
      styles.calloutMain.background = palette.white;

      // Todo - Move lines below this to files app.
      (styles.root.selectors as any)[".ms-ProgressIndicator-itemDescription > span"].color =
        ContrastColors.appRed;
      // Todo - Move lines above this to files app.
    }
    return styles;
  };
}
