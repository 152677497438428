const dateRegex: [RegExp, (date: Date) => string][] = [
  [RegExp("d+"), date => `${date.getDate()}`],
  [RegExp("h+"), date => `${date.getHours()}`],
  [RegExp("m+"), date => `${date.getMinutes()}`],
  [RegExp("s+"), date => `${date.getSeconds()}`],
  [RegExp("M+"), date => `${date.getMonth() + 1}`],
  // [RegExp("S+"), date => `${date.getMilliseconds()}`],
];

const dateYearRegex = RegExp("y+", "i");

/**
 * 时间类型转换
 * 对于以s为单位存储的自动转成毫秒
 * @param date
 */
function getDate(date: Date | string | number): Date {
  const MAX_DATE_NUM_IN_SEC = 10000000000;
  const Million = 1000;
  return typeof date === "object"
    ? date
    : new Date(date < MAX_DATE_NUM_IN_SEC ? (date as number) * Million : date);
}
/**
 * 日期格式化
 * @param date 日期 (s会自动转ms)
 * @param fmt 格式
 */
export function format(_date: Date | string | number, fmt: string, lng = "zh"): string {
  const date = getDate(_date);
  let r: RegExpExecArray | null;

  // eslint-disable-next-line no-cond-assign
  if ((r = dateYearRegex.exec(fmt))) {
    const YearLength = 4;
    fmt = fmt.replace(r[0], `${date.getFullYear()}`.substring(YearLength - r[0].length));
  }
  dateRegex.forEach(item => {
    // eslint-disable-next-line no-cond-assign
    if ((r = item[0].exec(fmt))) {
      let value = item[1](date);

      if (r[0].toString() === "MMMM") {
        value = date.toLocaleString(lng, { month: "long" });
      }

      // eslint-disable-next-line @dragongate/no-magic-numbers
      fmt = fmt.replace(r[0], r[0].length === 2 ? `00${value}`.substring(value.length) : value);
    }
  });
  return fmt;
}

/**
 * 自定义日期到天
 * @param _date
 * 今天；昨天；前天；(年)月日
 */
export function customizeDay(_date: Date | string | number, lng = "zh"): string {
  const today: Date = new Date();
  const date: Date = getDate(_date);
  const english = lng === "en";
  if (today.getFullYear() !== date.getFullYear()) {
    // 非今年显示完整日期
    // return format(date, english ? "yyyy/MM/dd" : "yyyy年MM月dd日");
    return format(date, "yyyy/MM/dd");
  } else {
    const MillionSecondsOfDay = 86400000; // 1000 * 60 * 60 * 24;
    switch (((+today - +date) / MillionSecondsOfDay).toFixed()) {
      case "0":
        return english ? "Today" : "今天";
      case "1":
        return english ? "Yesterday" : "昨天";
      case "2":
        return english ? format(date, "M/dd") : "前天";
      default:
        return format(date, english ? "M/dd" : "M月d日");
    }
  }
}

/**
 * 自定义时间显示
 * @param _date Date或者Date字符串
 */
export function customizeTime(_date: Date | string | number, lng = "zh"): string {
  const today: Date = new Date();
  const date: Date = getDate(_date);
  const pureLng = lng.replace(/[-_].*/, "");
  if (today.toDateString() !== date.toDateString()) {
    return customizeDay(date, pureLng);
  } else {
    // 今天
    const MillionSecondsInMinute = 60000;
    const MinuteInHour = 60;

    const diffMins = Math.round((+today - +date) / MillionSecondsInMinute);
    const MaxJustNow = 10;
    if (diffMins <= MaxJustNow) {
      return pureLng === "en" ? "Now" : "刚刚";
    } else if (diffMins < MinuteInHour) {
      return `${diffMins} ${pureLng === "en" ? "mins ago" : "分钟以前"}`;
    } else {
      return `${(diffMins / MinuteInHour).toFixed()} ${
        pureLng === "en" ? "hours ago" : "小时以前"
      }`;
    }
  }
}

// /**
//  * 比较日期（给定日期与当前日期作比较）
//  */
// function compareTime(_time: Date | string | number): boolean {
//   const today: Date = getDate();
//   const time: Date = typeof _time === "object" ? _time : getDate(_time);
//   return time.getTime() > today.getTime();
// }
