import { DriveItem } from "@microsoft/microsoft-graph-types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FileFilterI18n, FilePicker } from "../../locales";
import { DocInfo, requestWeb, TemplateInfo, WEB_API } from "../../services/request";
import { RCDocumentType } from "../../services/share";
import FilePickerDialog from "./FilePickerDialog";
import { getFileSize } from "../../lib/fileSize";

const MAX_TEMPLATE_FILE_SIZE = 104857600; // 100*1024*1024 = 100M;

function generateTemplateInfo(docInfo: DocInfo): TemplateInfo | undefined {
  let docType;
  if (docInfo.ext?.startsWith("ppt")) {
    docType = RCDocumentType.PPTTemplate;
  } else if (docInfo.ext?.startsWith("doc")) {
    docType = RCDocumentType.WordTemplate;
  } else if (docInfo.ext?.startsWith("xls")) {
    docType = RCDocumentType.ExcelTemplate;
  }
  if (docInfo.name && docInfo.downloadUrl && docType) {
    return {
      ...docInfo,
      template: docInfo.downloadUrl,
      docType,
      templateExt: docInfo.ext!,
    };
  }
}

export default function CreateTemplateDialog({
  hidden,
  onDismiss,
  onTemplateCreated,
}: {
  hidden?: boolean;
  onDismiss?: () => void;
  onTemplateCreated?: () => void;
}) {
  const submitTemplate = useCallback(
    (docInfo: DocInfo) => {
      const bodyInfo = generateTemplateInfo(docInfo);
      return (
        bodyInfo &&
        requestWeb(WEB_API.myTenantTemplateDocuments, {
          method: "POST",
          data: { ...bodyInfo, name: bodyInfo.name! },
        }).then(() => {
          onTemplateCreated?.();
          onDismiss?.();
        })
      );
    },
    [onDismiss, onTemplateCreated]
  );
  const { t } = useTranslation();

  const onPickerSubmit = useCallback(
    (item?: DriveItem & { "@microsoft.graph.downloadUrl"?: string }) => {
      if (item) {
        const docInfo: DocInfo = {
          downloadUrl: item["@microsoft.graph.downloadUrl"]!,
          name: (item.name || "").replace(/\.[^/.]+$/, ""),
          cover: item.thumbnails?.[0].large?.url!,
          itemId: item.id!,
          driveId: item.parentReference?.driveId!,
          driveType: item.parentReference?.driveType!,
          ext: item.name?.split(".").pop(),
        };
        submitTemplate(docInfo);
      }
    },
    [submitTemplate]
  );

  const filterInfo = useMemo(
    () => [
      {
        text: t(FileFilterI18n.presentation),
        key: "presentation",
        docTypeRange: ["ppt", "pptx"],
        maxSize: MAX_TEMPLATE_FILE_SIZE,
      },
      {
        text: t(FileFilterI18n.document),
        key: "document",
        docTypeRange: ["doc", "docx"],
        maxSize: MAX_TEMPLATE_FILE_SIZE,
      },
      {
        text: t(FileFilterI18n.sheet),
        key: "sheet",
        docTypeRange: ["xls", "xlsx"],
        maxSize: MAX_TEMPLATE_FILE_SIZE,
        hidden: true,
      },
    ],
    [t]
  );

  return hidden ? null : (
    <FilePickerDialog
      hidden={hidden}
      onSubmit={onPickerSubmit}
      onDismiss={onDismiss}
      confirmText={t(FilePicker.done)}
      pickerTips={t(FilePicker.pickerTips, {
        context: "template",
        size: getFileSize(MAX_TEMPLATE_FILE_SIZE),
      })}
      filterInfo={filterInfo}
    />
  );
}
