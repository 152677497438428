import { FontWeights, makeStyles } from "@fluentui/react";
import { useControllableValue } from "@fluentui/react-hooks";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFeedsLanguage } from "../../hooks/useFeedsLanguage";
import { MediaQuery, rem } from "../../lib/unit";
import { TemplateTagsI18n } from "../../locales";
import { logger } from "../../services/logger";
import { Dimension } from "../../services/request";
import { FontSizes } from "../../themes/fonts";

const useTagStyles = makeStyles(theme => ({
  root: {
    padding: rem(0, 32),
    [MediaQuery.mobile]: {
      padding: rem(5, 0),
    },
  },
  container: {
    display: "flex",
    [MediaQuery.mobile]: {
      display: "block",
    },
  },
  main: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: rem(28),
    padding: rem(0, 10),
    minWidth: rem(25),
    borderRadius: rem(14),
    margin: rem(16, 8, 0, 0),
    cursor: "pointer",
    backgroundColor: theme.palette.white,
    border: `solid 1px transparent`,
    "-webkit-tap-highlight-color": "transparent",
    selectors: {
      ":hover": {
        color: theme.palette.neutralPrimary,
        backgroundColor: theme.palette.neutralLight,
      },
      "&.is-active": {
        color: theme.palette.themePrimary,
        backgroundColor: theme.palette.themeLighterAlt,
        borderColor: theme.palette.themeLight,
        selectors: {
          ":hover": {
            backgroundColor: theme.palette.themeLight,
            [MediaQuery.mobile]: {
              backgroundColor: theme.palette.themeLighterAlt,
            },
          },
        },
      },
    },
    [MediaQuery.mobile]: {
      backgroundColor: theme.palette.neutralLighter,
    },
  },
  dimension: {
    width: rem(40),
    lineHeight: rem(28),
    margin: rem(16, 16, 0, 0),
    flexShrink: 0,
    [MediaQuery.mobile]: {
      display: "block",
      fontSize: FontSizes.mediumPlus,
      fontWeight: FontWeights.bold,
    },
  },
  tagWrap: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

export interface TemplateTagsProps {
  dimensions?: Dimension[];
  isAdmin?: boolean;
  selectedInfo?: {
    [key: string]: string;
  };
  onSelected?: (seletedInfo?: { [key: string]: string }) => void;
}

export default function TemplateTags({
  dimensions,
  isAdmin,
  onSelected,
  selectedInfo,
}: TemplateTagsProps) {
  const { t } = useTranslation();
  const { feedsLanguage } = useFeedsLanguage(!!isAdmin);
  const [newSelectedInfo, setNewSelectedInfo] = useControllableValue(
    selectedInfo,
    {},
    (ev, newValue) => {
      onSelected && onSelected(newValue);
    }
  );
  const onTagClick = useCallback(
    ev => {
      setNewSelectedInfo(data => {
        logger?.telemetry("ClickFilterTags", {
          DimensionId: ev.target.dataset.dimensionid,
          TagId: ev.target.dataset.tagid,
        });
        const newData: { [key: string]: string } = {
          ...data,
          [ev.target.dataset.dimensionid]: ev.target.dataset.tagid,
        };
        return newData;
      });
    },
    [setNewSelectedInfo]
  );

  const tagStyles = useTagStyles();

  return (
    <div className={tagStyles.root}>
      {dimensions?.map(
        item =>
          item && (
            <div key={item.id} className={tagStyles.container}>
              <span className={tagStyles.dimension}>{item.name}</span>
              <div className={tagStyles.tagWrap}>
                <span
                  data-dimensionid={item.id}
                  data-tagid={""}
                  onClick={onTagClick}
                  className={`${tagStyles.main} ${!newSelectedInfo?.[item.id] && "is-active"}`}
                >
                  {t(TemplateTagsI18n.all, { lng: feedsLanguage })}
                </span>
                {item.tags.map(tag => (
                  <span
                    key={tag.id}
                    data-dimensionid={item.id}
                    data-tagid={tag.id}
                    onClick={onTagClick}
                    className={`${tagStyles.main} ${
                      newSelectedInfo?.[item.id] === tag.id && "is-active"
                    }`}
                  >
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
}
