import React from "react";
import { Text, useTheme } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Card, Mine } from "../../locales";

export type StatusType =
  | "uploading"
  | "importing"
  | "deleting"
  | "translatingFailed"
  | "failed"
  | "justRead"
  | undefined;

export default function CardStatusText({
  status,
}: React.PropsWithChildren<{
  status: StatusType;
}>) {
  const { t } = useTranslation();
  const theme = useTheme();
  switch (status) {
    case "uploading":
      return <Text>{t(Card.importing)}</Text>;
    case "importing":
      return (
        <Text styles={{ root: { color: theme.semanticColors.successIcon } }}>
          {t(Card.importingWithDots)}
        </Text>
      );
    case "deleting":
      return <Text>{t(Card.deleting)}</Text>;
    case "translatingFailed":
      return (
        <Text styles={{ root: { color: theme.semanticColors.blockingIcon } }}>
          {t(Card.importingFailed)}
        </Text>
      );
    case "failed":
      return (
        <Text styles={{ root: { color: theme.semanticColors.blockingIcon } }}>
          {t(Card.uploadingFailed)}
        </Text>
      );
    case "justRead":
      return (
        <Text variant="small" style={{ color: `${theme.palette.neutralSecondary}` }}>
          {t(Mine.justRead)}
        </Text>
      );
    default:
      return null;
  }
}
