/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

import { BoxShadowColors } from "./teamsColors";

// tslint:disable-next-line:no-namespace
export const ShadowLevels = {
  shadowLevel1: `0 2px 4px -0.75px ${BoxShadowColors.boxShadowColor}`,
  shadowLevel1Dark: `0 2px 4px -0.75px ${BoxShadowColors.boxShadowColorDark}`,
  shadowLevel1Darker: `0 2px 4px -0.75px ${BoxShadowColors.boxShadowColorDarker}`,
  shadowLevel2: `0 4px 7px -10px ${BoxShadowColors.boxShadowColor}`,
  shadowLevel3: `0 8px 10px -2px ${BoxShadowColors.boxShadowColor}`,
  shadowLevel3Dark: `0 8px 10px -2px ${BoxShadowColors.boxShadowColorDark}`,
  shadowLevel4: `0 16px 18px -4px ${BoxShadowColors.boxShadowColor}`,
};
