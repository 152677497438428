import { CommandBar, ICommandBarItemProps, TeachingBubble } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { useStorageValue } from "../../hooks/useStorageValue";
import { CategoryActionI18n, NotificationTutor } from "../../locales";

export interface CategoryContentBarProps {
  onClickNotification?: () => void;
}
const NotificationButtonId = "CategoryContentBar-Notification";
export default function CategoryContentBar({ onClickNotification }: CategoryContentBarProps) {
  const { t } = useTranslation();
  const [tipFlag, setTipFlag] = useStorageValue("flag", "CategoryContentBarShow");
  const items = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        id: NotificationButtonId,
        key: "Notification",
        text: t(CategoryActionI18n.notify),
        cacheKey: "Notification", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Ringer },
        onClick: () => {
          setTipFlag(true);
          onClickNotification?.();
        },
      },
    ],
    [onClickNotification, setTipFlag, t]
  );
  return (
    <>
      <CommandBar items={items} />
      {tipFlag ? null : (
        <TeachingBubble target={`#${NotificationButtonId}`}>
          {t(NotificationTutor.btnTips)}
        </TeachingBubble>
      )}
    </>
  );
}
