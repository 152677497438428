import React, { ImgHTMLAttributes } from "react";
import { Image, IImageProps } from "@fluentui/react";
import { useFileToken } from "../../hooks/useFileToken";
import { useImgError } from "../../hooks/useImgError";

function isPrivate(url?: string) {
  if (
    !url || // empty
    !url.startsWith("https://") || // none https link
    url.includes("/public/") || // bublic
    url.includes("?") || // with token
    !(url.includes("blob.core.windows.net/") || url.includes(".o365cn.com/"))
  ) {
    return false;
  }
  return true;
}

const reloadTime = 500;
const imgResetNum = 5;

/**
 * 私有图片加上Token
 * @param param
 */
function PrivateImage({ src, ...rest }: React.PropsWithChildren<IImageProps>) {
  const { token } = useFileToken();
  const url = token && src ? `${src}?${token}` : "";
  const onError = useImgError(imgResetNum, url, reloadTime);
  return <Image src={url} onError={onError} {...rest} />;
}

export default function Img({ src, ...rest }: React.PropsWithChildren<IImageProps>) {
  const onError = useImgError(imgResetNum, src, reloadTime);
  if (isPrivate(src)) {
    return <PrivateImage src={src} {...rest} />;
  }
  return <Image src={src} onError={onError} {...rest} />;
}

/**
 * 私有图片加上Token
 * @param param
 */
function PrivatePureImg({ src, ...rest }: React.PropsWithChildren<ImgHTMLAttributes<any>>) {
  const { token } = useFileToken();
  const url = token && src ? `${src}?${token}` : "";
  const onError = useImgError(imgResetNum, url, reloadTime);

  return <img src={url} onError={onError} alt="" {...rest} />;
}

export function PureImg(props: React.PropsWithChildren<ImgHTMLAttributes<any>>) {
  const onError = useImgError(imgResetNum, props.src, reloadTime);
  if (isPrivate(props.src)) {
    return <PrivatePureImg {...props} />;
  }
  return <img alt="" onError={onError} {...props} />;
}
