/* eslint-disable @dragongate/no-magic-numbers */
import { Audio as AudioItem, Caption, TingDoc, TingPage } from "./TingDoc";

/**
 * bgm volume
 */
const bgmVolume = 0.02;

export class AudioPlayer {
  private _audioManager: HTMLAudioElement;
  private _bgmAudioManager: HTMLAudioElement;

  private _document?: TingDoc;

  private isPublic = true;
  private token?: string;

  /**
   * index of page/audio/caption
   */
  private _pageIndex = 0;
  private _audioIndex = -1;
  private _captionIndex = -1;

  /**
   * rate of progress bar
   */
  private _voiceProgessDisplayRatio = 0;
  private _playingProgress = 0;
  /**
   * the total time of previous audios in current page
   * 当前页已播放音频的总时长，不包括正在播放的音频
   */
  private _playedTime = 0;

  /**
   * all formated captions
   */
  private _displayedCaption: { [id: string]: Caption[] } = {};
  private _captionText = "";

  /**
   * 记录是否在播放，避免后台继续播放
   */
  private _isPlaying = false;
  private _isErrorReplay = false;
  /**
   * 是否已经播放到当前页结束位置
   */
  private _isPageEnd = false;
  /**
   * 记录是否有背景音
   */
  private _hasBGM = false;

  private _startTime = 0;
  /**
   * 无语音页面自动翻页
   */
  private _autoSwitch: NodeJS.Timeout | undefined;

  /**
   * the max width of one caption, 2 for Chinese & 1 for English
   */
  private captionWidthLimit = 32;
  /**
   * the width of per substring of caption, 2 for Chinese & 1 for English
   */
  private captionSplitWidth = 28;

  public onProgressUpdate?: (progress: number) => void;
  public onCaptionUpdate?: (caption: string) => void;
  public onPageEnd?: (nextPageIndex: number) => void;

  public onStatusChange?: (
    status: "playing" | "waiting" | "error" | "pause" | "stop",
    detials?: any
  ) => void;

  /**
   * 构造函数
   * @param audioManager
   * @param downloder
   */
  public constructor() {
    this._audioManager = new Audio();
    this._bgmAudioManager = new Audio();

    this._initAudioManager();
  }
  /**
   *
   * @param document
   */
  public init(
    document: TingDoc,
    isPublic: boolean,
    token?: string,
    captionWidthLimit?: number
  ): number {
    this._document = document;
    this._isPlaying = true;

    this.isPublic = isPublic;
    this.token = token;

    if (captionWidthLimit && captionWidthLimit > 0) {
      this.captionWidthLimit = captionWidthLimit;
      this.captionSplitWidth = captionWidthLimit > 4 ? captionWidthLimit - 4 : captionWidthLimit;
    }

    this._formatCaptions();

    // 背景音在线播放
    const bgmURL = document.settings && document.settings.bgm_url;
    if (bgmURL) {
      this._setBGMAudioPlayerMeta(bgmURL);
      this._hasBGM = true;
    } else {
      this._hasBGM = false;
    }

    return document.pages[0].duration;
  }

  /**
   * 开始/继续播放
   */
  public play(): void {
    this._isPlaying = true;
    if (this._document && this._pageIndex >= 0) {
      // 如果在当前页结尾，重新播放当前页
      if (this._isPageEnd) {
        this.goPage(this._pageIndex);
      } else if (this._audioIndex >= 0) {
        if (this._isErrorReplay || !this._audioManager.src) {
          const audio = this._document.pages[this._pageIndex].audios[this._audioIndex];
          if (audio) {
            this._setAudio(audio);
          }
          if (!this._audioManager.src) {
            // 其它播放页返回需要重新绑定回调
            this._initAudioManager();
          }
        } else {
          this._audioManager.play();
        }
      }
    }
    if (this._hasBGM && this._bgmAudioManager.paused) {
      this._bgmAudioManager.play();
    }
  }

  /**
   * 暂停语音
   */
  public pause(): void {
    this._audioManager.pause();
    this._bgmAudioManager.pause();
    this._isPlaying = false;
  }

  /**
   * 播放结束,会清理数据和回调
   */
  public stop(): void {
    this._audioManager.pause();
    this._audioManager.currentTime = 0;
    this._audioManager.src = "";
    this._bgmAudioManager.pause();
    this._bgmAudioManager.currentTime = 0;
    this._bgmAudioManager.src = "";

    this._isPlaying = false;

    this.onStatusChange = undefined;
    this.onProgressUpdate = undefined;
    this.onCaptionUpdate = undefined;
    this.onPageEnd = undefined;

    this._document = undefined;
    this._displayedCaption = {};
  }

  /**
   * 开始背景音播放
   */
  public playBGM(): void {
    if (this._hasBGM && this._bgmAudioManager.paused) {
      this._bgmAudioManager.play();
    }
  }

  /**
   * 暂停背景音播放
   */
  public pauseBGM(): void {
    this._bgmAudioManager.pause();
  }

  /**
   * 跳转到某一页
   * @param index
   */
  public goPage(index: number): number {
    this._audioManager.pause();
    this._audioManager.currentTime = 0;
    // if (this._autoSwitch) {
    //   clearTimeout(this._autoSwitch);
    // }
    this._isPageEnd = false;

    // 换页自动播放背景音
    if (this._hasBGM && this._bgmAudioManager.paused) {
      this._bgmAudioManager.play();
    }
    if (this._document) {
      this._isPlaying = true;
      const page: TingPage = this._document.pages[index];
      this._pageIndex = index;
      this._audioIndex = -1;
      this._playedTime = 0;

      this._captionIndex = -1;
      this._captionText = "";
      if (this.onCaptionUpdate) {
        this.onCaptionUpdate(this._captionText);
      }
      this._playingProgress = 0;
      if (this.onProgressUpdate) {
        this.onProgressUpdate(this._playingProgress);
      }
      if (page.audios.length) {
        this._voiceProgessDisplayRatio = 100.0 / page.duration;

        this._audioIndex = 0;
        this._setAudio(page.audios[0]);
      } else {
        this._voiceProgessDisplayRatio = 0;

        // 无语音/视频自动翻页
        // if (!page.txvideo && !page.video) {
        //   this._autoSwitch = this._setAutoSwitch();
        // }
      }
      return page.duration;
    }
    return 0;
  }

  /**
   * 设置自动跳转
   */
  private _setAutoSwitch(): NodeJS.Timeout {
    return setTimeout(() => {
      const nextPage = this._getNextPage();
      if (nextPage) {
        this.onPageEnd?.(this._pageIndex + 1);
      } else {
        this.onPageEnd?.(-1);
      }
    }, 3000);
  }

  /**
   * 初始化音频管理器
   */
  private _initAudioManager(): void {
    this._audioManager.ontimeupdate = () => {
      if (!this._isPlaying) {
        return;
      }

      if (this._audioIndex !== -1) {
        this._playingProgress =
          (this._playedTime + this._audioManager.currentTime * 1000) *
          this._voiceProgessDisplayRatio;
        if (this.onProgressUpdate) {
          this.onProgressUpdate(this._playingProgress);
        }
        if (this._document && this._document.pages.length > 0) {
          const audios = this._document.pages[this._pageIndex].audios;
          const nowVoice = audios[this._audioIndex];
          if (
            this._hasNextCaption() &&
            this._audioManager.currentTime * 1000 >=
              this._displayedCaption[nowVoice.id][this._captionIndex + 1].offset
          ) {
            this._captionText = this._displayedCaption[nowVoice.id][++this._captionIndex].data;
            if (this.onCaptionUpdate) {
              this.onCaptionUpdate(this._captionText);
            }
          } else if (
            this._captionText &&
            this._captionText.length !== 0 &&
            this._audioManager.currentTime * 1000 >=
              this._displayedCaption[nowVoice.id][this._captionIndex].offset +
                this._displayedCaption[nowVoice.id][this._captionIndex].duration
          ) {
            this._captionText = "";
            if (this.onCaptionUpdate) {
              this.onCaptionUpdate(this._captionText);
            }
          }
        }
      }
    };
    this._audioManager.onplay = () => {
      console.debug("AudioPlayer.OnPlay", {
        page: this._pageIndex,
        audio: this._audioIndex,
        src: this._audioManager.src,
      });
      this._changeStatus("playing");
    };
    this._audioManager.onerror = () => {
      // ignore the error of setting empty src.
      if (this._audioManager.error?.code === 4) {
        return;
      }
      console.error("AudioPlayer.OnError", {
        page: this._pageIndex,
        audio: this._audioIndex,
        src: this._audioManager.src,
        result: this._audioManager.error,
      });
      const audio = this._document?.pages[this._pageIndex]?.audios[this._audioIndex];
      if (!this._isErrorReplay && audio) {
        // 出错重试
        this._audioManager.src = this.isPublic
          ? audio.data
          : this.token
          ? `${audio.data}?${this.token}`
          : "";
      } else {
        this._changeStatus("error");
      }
      this._isErrorReplay = true;
    };
    this._audioManager.onwaiting = () => {
      console.debug("AudioPlayer.OnWaiting", {
        page: this._pageIndex,
        audio: this._audioIndex,
        src: this._audioManager.src,
      });
      this._changeStatus("waiting");
    };
    this._audioManager.onpause = () => {
      // logger.debug("AudioPlayer.OnPause", {
      //   page: this._pageIndex,
      //   audio: this._audioIndex,
      // });
      this._changeStatus("pause");
    };
    this._audioManager.oncanplay = () => {
      if (this._startTime > 0) {
        this._startTime = 0;
      }
      if (!this._isPlaying) {
        this._audioManager.pause();
      } else {
        // this._audioManager.play();
        this._changeStatus("playing");
      }
    };
    this._audioManager.onended = () => {
      console.debug("AudioPlayer.OnEnded", {
        page: this._pageIndex,
        audio: this._audioIndex,
      });

      // 语音结束的时候把字幕置空
      this._captionText = "";
      if (this.onCaptionUpdate) {
        this.onCaptionUpdate(this._captionText);
      }

      // gopage if the end.
      if (!this._getNextAudio()) {
        this._isPageEnd = true;

        this._playingProgress = 100;
        if (this.onProgressUpdate) {
          this.onProgressUpdate(this._playingProgress);
        }

        const nextPage = this._getNextPage();
        if (nextPage) {
          this.onPageEnd?.(this._pageIndex + 1);
        } else {
          // 文档结尾关闭背景音
          this._bgmAudioManager.pause();
          this.onPageEnd?.(-1);
        }
      } else if (this._document && this._document.pages.length > 0) {
        const audios = this._document.pages[this._pageIndex].audios;
        if (audios.length > 0) {
          this._playedTime += audios[this._audioIndex]?.duration;
          ++this._audioIndex;
          this._setAudio(audios[this._audioIndex]);
        }
      }
    };
  }
  /**
   * 切换播放状态
   * @param status
   */
  private _changeStatus(
    status: "playing" | "waiting" | "error" | "pause" | "stop",
    details?: any
  ): void {
    console.debug(`changeStatus to ${status}`);
    if (this.onStatusChange) {
      this.onStatusChange(status, details);
    }
  }

  /**
   * 设置背景音乐播放器
   * @param path
   */
  private _setBGMAudioPlayerMeta(path: string): void {
    // logger.debug("BGM audioplay", path);
    this._bgmAudioManager.loop = true;
    this._bgmAudioManager.volume = bgmVolume;
    this._bgmAudioManager.src = this.isPublic ? path : this.token ? `${path}?${this.token}` : "";
    this._bgmAudioManager.currentTime = 0;
    this._bgmAudioManager.load();
    this._bgmAudioManager.pause();
  }

  /**
   * 设置播放器
   * @param path
   */
  private _setAudioPlayerMeta(path: string): void {
    console.debug("audioplay", path);
    const document = this._document;
    const audioManager = this._audioManager;
    if (document) {
      audioManager.title = document.metadata.name || "听听文档";
    } else {
      // logger.warn("_initMeta:fail", "no document");
    }

    audioManager.src = this.isPublic ? path : this.token ? `${path}?${this.token}` : "";
    audioManager.load();
    audioManager.play().catch(ex => {
      if (ex.name === "NotAllowedError") {
        this._isPlaying = false;
        this._changeStatus("error", ex);
      }
      if (ex.name !== "AbortError") {
        console.error("audioManager play", ex.toString());
      }
    });
  }

  /**
   * 加载音频
   */
  private _setAudio(audio: AudioItem): void {
    this._startTime = Date.now();
    this._isErrorReplay = false; // 设置了新url, 清除播放错误状态
    const url = audio._localPath || audio.data;
    this._changeStatus("waiting");
    this._setAudioPlayerMeta(url);
    this._captionIndex = -1;
    this._captionText = "";
  }

  /**
   * 获取下一页
   */
  private _getNextPage(): TingPage | undefined {
    const doc = this._document;
    return doc && doc.pages[this._pageIndex + 1];
  }
  /**
   * 获取本页下个音频
   */
  private _getNextAudio(): AudioItem | undefined {
    if (this._document) {
      const page = this._document.pages[this._pageIndex];
      return page && page.audios[this._audioIndex + 1];
    }
    return undefined;
  }
  /**
   * 是否有下一条字幕
   */
  private _hasNextCaption(): boolean {
    if (this._document && this._document.pages.length) {
      const audios = this._document.pages[this._pageIndex].audios;
      return (
        !!audios &&
        this._displayedCaption[audios[this._audioIndex].id] &&
        this._captionIndex < this._displayedCaption[audios[this._audioIndex].id].length - 1
      );
    }
    return false;
  }
  /**
   * 格式化字幕
   */
  private _formatCaptions(): void {
    if (this._document) {
      const allVoices: AudioItem[] = this._document.pages
        .filter(page => page.audios && page.audios.length)
        .reduce<AudioItem[]>((array, page) => array.concat(page.audios || []), []);

      allVoices.forEach(voice => {
        if (voice.captions) {
          const newCaptions: Caption[] = [];
          voice.captions.forEach(caption => {
            const text: string = caption.data;
            const textLength: number = text.length;
            const rate: number = caption.duration / textLength;
            let offset: number = caption.offset;
            let startIndex = 0;
            while (startIndex < textLength) {
              const captionLength: number = this._getNextCaptionLength(text.substr(startIndex));
              let data: string = text.substr(startIndex, captionLength);
              const duration: number = captionLength * rate;

              data = this._removePunctations(data);
              newCaptions.push({
                data,
                duration,
                offset,
              });

              offset += duration;
              startIndex += captionLength;
            }
          });

          this._displayedCaption[voice.id] = newCaptions;
        }
      });
    }
  }

  /**
   * get the length of next caption.
   * @param text
   */
  private _getNextCaptionLength(text: string): number {
    const tempText = this._removePunctations(text);
    // return the whole length if less than captionWidthLimit
    if (this._getStringWidth(tempText) <= this.captionWidthLimit) {
      return text.length;
    } else {
      return this._getSubStringLength(text, this.captionSplitWidth);
    }
  }

  /**
   * get the string width.
   * @param text
   */
  private _getStringWidth(text: string): number {
    if (!text) {
      return 0;
    }

    const matchArray = text.match(/\w|\s/g);
    if (matchArray) {
      return text.length * 2 - matchArray.length;
    }
    return text.length * 2;
  }

  /**
   * get widest substring less than widthLimit
   * @param text
   * @param widthLimit
   */
  private _getSubStringLength(text: string, widthLimit: number): number {
    let nowWidth = 0;
    let lastNonLetterIndex = 0;
    let strLength = 0;

    for (let i = 0; i < text.length; i++) {
      if (!text[i].match(/\w/g)) {
        lastNonLetterIndex = i;
        // eslint-disable-next-line no-control-regex
        if (text[i].match(/[\u0000-\u00ff]/g)) {
          nowWidth++;
        } else {
          nowWidth += 2;
        }
      } else {
        nowWidth++;
      }
      if (nowWidth >= widthLimit) {
        strLength = i + 1;
        break;
      }
    }

    // remove the last part if the English word is broken.
    if (
      strLength < text.length &&
      strLength > 0 &&
      text[strLength - 1].match(/\w/g) &&
      text[strLength].match(/\w/g) &&
      lastNonLetterIndex > 0
    ) {
      strLength = lastNonLetterIndex + 1;
    }
    return strLength;
  }

  /**
   * get the string without punctation at the beginning and end.
   * @param text caption string
   */
  private _removePunctations(text: string): string {
    const replace =
      "[\\s。，、：；？！‘’′,﹑;?!|'〝〞＂*=<_¯#&﹡﹦﹤＿￣﹟﹠﹋﹏﹉﹍﹊﹎｜﹨‖^ˇ·¨¡¿—…︴﹃（）〈〉‹›﹛﹜『』〖〗［］\\[\\]〔〕{}「」【】︵︶︷︸︿﹀︹︺︽︾_ˉ﹁﹂﹃﹄︻︼○◇□△▽▷◁●◆■■▲▼▶◀♦■]";
    return (
      text &&
      text
        .replace(new RegExp(`^${replace}*|${replace}*$`, "g"), "")
        .replace(new RegExp(`${replace}+`, "g"), " ")
    );
  }
}
