import { ShadowLevels } from "../TeamsShadowLevels";
import { ITheme, IRawStyle, IModalStyles } from "@fluentui/react";
import { teamsBorderRadius } from "./styleConstants";
import { ContrastColors, CoreColors, DarkColors } from "../teamsColors";
import { rem } from "../../lib/unit";

export function getModalStyles(themeName: string, theme: ITheme): Partial<IModalStyles> {
  const MODAL_MIN_WIDTH = rem(400);
  const MODAL_MIN_HEIGHT = rem(116);
  const styles = {
    root: {
      backgroundColor: "none",
      selectors: {
        ".ms-Overlay--dark": {
          backgroundColor: CoreColors.overlayBackground,
        },
      },
    },

    main: {
      boxSizing: "content-box",
      backgroundColor: theme.palette.white,
      borderRadius: teamsBorderRadius,
      color: theme.palette.black,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: CoreColors.appBlackAlpha10,
      boxShadow: ShadowLevels.shadowLevel4,
      minWidth: `${MODAL_MIN_WIDTH} !important`,
      minHeight: `${MODAL_MIN_HEIGHT} !important`,
    },
  } as Partial<IModalStyles>;

  // Dark theme overrides
  if (themeName === "dark") {
    ((styles.root as any).selectors[".ms-Overlay--dark"] as IRawStyle).backgroundColor =
      DarkColors.overlayBackground;

    // (styles.main as IRawStyle).backgroundColor = theme.palette.neutralLight;
    (styles.main as IRawStyle).borderColor = theme.palette.white;
  }

  // Contrast theme overrides
  if (themeName === "contrast") {
    ((styles.root as any).selectors[".ms-Overlay--dark"] as IRawStyle).backgroundColor =
      ContrastColors.overlayBackground;

    (styles.main as IRawStyle).borderColor = theme.palette.black;
  }
  return styles;
}
