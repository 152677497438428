import { DefaultButton, FontSizes, makeStyles, PrimaryButton, Text } from "@fluentui/react";
import { rem } from "../../lib/unit";

export interface EditorHeaderProps {
  title: string;
  saveText: string;
  onSave?: () => void;
  cancelText: string;
  onCancel?: () => void;
  disabled?: boolean;
}

const useHeaderStyle = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    height: rem(55),
    justifyContent: "space-between",
    alignItems: "center",
    padding: rem(0, 20),
    borderBottom: `${rem(1)} solid ${theme.palette.neutralLight}`,
    boxSizing: "border-box",
  },
  rightActions: {
    display: "flex",
    alignItems: "center",
    fontSize: FontSizes.medium,
  },
  confirmBtn: { marginLeft: rem(8) },
}));

export default function EditorHeader(props: EditorHeaderProps) {
  const headerStyles = useHeaderStyle();

  return (
    <header className={headerStyles.root}>
      <Text variant="large">{props.title}</Text>
      <div className={headerStyles.rightActions}>
        <DefaultButton text={props.cancelText} onClick={props.onCancel} />
        <PrimaryButton
          className={headerStyles.confirmBtn}
          text={props.saveText}
          disabled={props.disabled}
          onClick={props.onSave}
        />
      </div>
    </header>
  );
}
