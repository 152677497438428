import IsMobile from "ismobilejs";
import { APP_ENV } from "./config";

function isWechatBrowser(): boolean {
  const ua = window.navigator.userAgent.toLowerCase();
  if (
    ua.match(/MicroMessenger/i) !== null &&
    ua.match(/MicroMessenger/i)?.[0] === "micromessenger"
  ) {
    return true;
  }
  return false;
}

function isMac(): boolean {
  const ua = window.navigator.userAgent;
  if (ua.match(/Mac OS.*Electron/i) !== null) {
    return true;
  }
  return false;
}

export const isIOS = IsMobile().apple.device;

export const isMobliePhone = IsMobile().phone;

export const isPrerender = APP_ENV === "production" && navigator.userAgent === "ReactSnap";

export const isWechat = isWechatBrowser();

export const isAppleMac = isMac();

export const isElectron = navigator.userAgent.indexOf("Electron") >= 0;

export const isReload =
  performance.navigation && performance.navigation.type === performance.navigation.TYPE_RELOAD;
