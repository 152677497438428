import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useLocation, Location } from "react-router-dom";
import Player from "../../components/player/Player";
import { selectAPI, UserRole } from "../../services/request";
import { ResourceScope } from "../../config/rc";
import { useUser } from "../../hooks/rc/useUser";
import { useQueryWeb } from "../../hooks/useQuery";
import { useRouteParams } from "../../hooks/rc/useRoute";
import { PagePath } from "../../config/routes";
import { FileTokenProvider, setFileToken } from "../../hooks/useFileToken";
import { SourcePageType } from "../../services/share";
import ErrorPage from "../../components/empty/ErrorPage";
import { goBack } from "../../services/history";

interface RCViewLocation extends Location {
  state: { playList?: CDS.Document[] };
}

export default function RCViewPage(): React.ReactElement {
  const {
    docId,
    scope = ResourceScope.feeds,
    bundleId,
    categoryId,
  } = useRouteParams<PagePath.view>();
  const { role } = useUser();
  const location: RCViewLocation = useLocation() as any;
  const apis = selectAPI(scope, role === UserRole.guest);
  const isGuest = role === UserRole.guest;
  const isPublic = scope === ResourceScope.feeds;
  const { data: document, isError } = useQueryWeb(apis.documentAPI, {
    vars: { id: docId! },
    params: {
      $expand: isPublic ? ["category"] : ["readtoken"],
    },
    skipAuth: isGuest && isPublic,
    enabled: !!docId && !!role,
    config: {
      onSuccess: d => d.id && d.readToken && setFileToken(d.id, d.readToken),
    },
  });
  return document ? (
    <FileTokenProvider id={isPublic ? "" : document.id!} defaultToken={document.readToken as any}>
      <Player
        document={document}
        hideTeamsShare={false}
        scope={scope}
        onClose={goBack}
        sourcePageType={
          bundleId ? SourcePageType.Bundle : categoryId ? SourcePageType.Category : undefined
        }
        playList={location.state?.playList}
      />
    </FileTokenProvider>
  ) : isError ? (
    <ErrorPage onBack={goBack} />
  ) : (
    <Spinner size={SpinnerSize.large} styles={{ root: { height: "99vh" } }} />
  );
}
