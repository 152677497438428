import {
  useQuery,
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "react-query";
import { AxiosError } from "axios";

import {
  WEB_API,
  WebResponseOfKey,
  WebParamTypeOfKey,
  WebVarTypeOfKey,
  requestWeb,
  WebRequestBodyTypeOfKey,
} from "../../services/request";
import { RequestConfig } from "../../services/request";
import {
  getRequestParams,
  makeCancelPromise,
  RequestQueryOptions,
  getQueryConfig,
  usePageValues,
  useFilterValues,
} from "./base";

type WebQueryKey<TAPI extends WEB_API> = [
  TAPI,
  RequestConfig<WebParamTypeOfKey<TAPI>, WebVarTypeOfKey<TAPI>, WebRequestBodyTypeOfKey<TAPI>>?
];

function queryWeb<TAPI extends WEB_API>(context: QueryFunctionContext<WebQueryKey<TAPI>, TAPI>) {
  const [api, config, cancel] = getRequestParams(context);
  const promise = requestWeb(api, config).then(res => res.data);
  return makeCancelPromise(promise, cancel);
}

/**
 * WEB API 查询(GET)
 * @param api
 * @param config
 */
export function useQueryWeb<Tkey extends WEB_API>(
  api: Tkey,
  options?: RequestQueryOptions<
    WebParamTypeOfKey<Tkey>,
    WebVarTypeOfKey<Tkey>,
    WebResponseOfKey<Tkey>,
    WebQueryKey<Tkey>,
    WebRequestBodyTypeOfKey<Tkey>
  >
) {
  const [requestConfig, config] = getQueryConfig(options || {});
  return useQuery([api, requestConfig], queryWeb, config);
}

type PaginationWebAPIs<T> = T extends infer U
  ? WebResponseOfKey<U> extends CDS.Pagination<any>
    ? U
    : never
  : never;

function getNextPageParam(p: CDS.Pagination<any>) {
  return p.nextLink || undefined;
}
/**
 * 分页查询
 * @param key
 * @param config
 * @param queryConfig
 */
export function useQueryWebPages<Tkey extends PaginationWebAPIs<WEB_API>>(
  key: Tkey,
  config?: RequestConfig<
    WebParamTypeOfKey<Tkey>,
    WebVarTypeOfKey<Tkey>,
    WebRequestBodyTypeOfKey<Tkey>
  >,
  queryConfig?: UseInfiniteQueryOptions<
    WebResponseOfKey<Tkey>,
    AxiosError,
    WebResponseOfKey<Tkey>,
    WebResponseOfKey<Tkey>,
    WebQueryKey<Tkey>
  >
) {
  const res = useInfiniteQuery([key, config], queryWeb, {
    enabled: !!key,
    getNextPageParam,
    ...queryConfig,
  });
  return usePageValues(res);
}

/**
 * 分页查询
 * @param key
 * @param config
 * @param queryConfig
 */
export function useQueryAllPages<Tkey extends PaginationWebAPIs<WEB_API>>(
  key: Tkey | false,
  config?: RequestConfig<
    WebParamTypeOfKey<Tkey>,
    WebVarTypeOfKey<Tkey>,
    WebRequestBodyTypeOfKey<Tkey>
  >,
  filter?: (value: WebResponseOfKey<Tkey>["value"][0]) => boolean
) {
  const res = useInfiniteQuery([key as Tkey, config], queryWeb, {
    enabled: !!key,
    getNextPageParam,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  } as UseInfiniteQueryOptions<
    WebResponseOfKey<Tkey>,
    AxiosError,
    WebResponseOfKey<Tkey>,
    WebResponseOfKey<Tkey>,
    WebQueryKey<Tkey>
  > & {
    getNextPageParam: typeof getNextPageParam;
  });
  return useFilterValues(res, filter);
}
