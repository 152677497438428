import React, { LegacyRef, useCallback } from "react";
import { PersonaSize, makeStyles } from "@fluentui/react";
import BaseCard from "./Card";

import { StatusType } from "./CardStatusText";
import { useCustomizeTime } from "../../hooks/useCustomizeTime";
import { RCUserPersona } from "../rc/RCPersona";
import { getDocExtension, RCDocumentType } from "../../services/share";
import { rem } from "../../lib/unit";

const Importing = 2;
const ImportingFailed = 3;

export function getDocStatus(
  doc: CDS.Document
): "uploading" | "importing" | "translatingFailed" | "failed" | undefined {
  const documentStatus = doc.metadata?.status;
  switch (documentStatus) {
    case Importing:
      if (doc.metadata?.type === RCDocumentType.Video && doc.metadata?.cover) {
        return "importing";
      }
      return "uploading";
    case ImportingFailed:
      if (doc.metadata?.type === RCDocumentType.Video) {
        return "translatingFailed";
      }
      return "failed";
    default:
      return undefined;
  }
}

const useCreatorStyles = makeStyles(theme => ({
  details: { paddingLeft: rem(8) },
  primaryText: { color: theme.palette.neutralPrimaryAlt, height: rem(16), lineHeight: rem(16) },
  secondaryText: {
    fontSize: rem(12),
    color: theme.palette.neutralPrimaryAlt,
    height: rem(14),
    lineHeight: rem(14),
  },
}));

export default function SmallCard({
  doc,
  showCreator,
  showTime,
  isJustRead,
  onTap,
  children,
  isDeleting,
  rootRef,
}: React.PropsWithChildren<{
  rootRef?: LegacyRef<HTMLDivElement>;
  isJustRead?: boolean;
  showCreator?: boolean;
  showTime?: boolean;
  doc?: CDS.Document;
  onTap?: (doc: CDS.Document) => void;
  isDeleting?: boolean;
}>) {
  const meta = doc?.metadata;
  const onClick = useCallback(() => onTap && doc && onTap(doc), [doc, onTap]);
  const displayTime = useCustomizeTime(meta?.create_at);
  const status: StatusType = isDeleting
    ? "deleting"
    : (doc && getDocStatus(doc)) ?? (isJustRead ? "justRead" : undefined);
  // const isTemplate = [
  //   RCDocumentType.ExcelTemplate,
  //   RCDocumentType.PPTTemplate,
  //   RCDocumentType.WordTemplate,
  // ].includes(doc?.metadata?.type as RCDocumentType);

  const extension = getDocExtension(doc?.metadata?.type) || undefined;
  const creatorStyles = useCreatorStyles();
  const notJustRead = status && !(status === "justRead");
  return (
    <BaseCard
      rootRef={rootRef}
      className="doc-card"
      title={meta?.name}
      description={false}
      cover={meta?.cover}
      status={status}
      onClick={onClick}
      fileExtension={extension}
      category={showCreator ? doc?.category?.name : undefined}
      footer={
        <>
          {showCreator}
          {!isJustRead &&
            !notJustRead &&
            (showCreator ? (
              <RCUserPersona
                size={PersonaSize.size28}
                userId={meta?.creator_id}
                secondaryText={displayTime}
                showSecondaryText
                styles={creatorStyles}
              />
            ) : showTime ? (
              displayTime
            ) : (
              <div />
            ))}
          {children}
        </>
      }
    />
  );
}
