import { DefaultButton, Icon, ImageFit, mergeStyles, Tooltip, ZIndexes } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { ResourceScope } from "../../config/rc";
import { AudioPlayer } from "../../models/AudioPlayer";
import { rem } from "../../lib/unit";
import { View } from "../../locales";
import { TingDoc } from "../../models/TingDoc";
import { logger } from "../../services/logger";
import { EntityType, RCDocumentType, ShareInfo } from "../../services/share";
import CopyCallout from "../common/CopyCallout";
import Img from "../common/Img";
import { Swiper } from "./Swiper";
import { useFileToken } from "../../hooks/useFileToken";

import "./TingDocPlayer.scss";
import PlayerHeader from "../navigation/PlayerHeader";
import { useMobileCss } from "../../hooks/rc/useCss";
import { isIOS } from "../../config/browser";

const audioPlayer = new AudioPlayer();
const videoPlayers: { [id: string]: HTMLVideoElement } = {};
const SHOW_NEXTTIP_SECOND = 10;

export default function DocPlayer({
  scope,
  doc,
  category,
  hideTeamsShare,
  rcShareInfo,
  onCloseToEnd,
  onEnd,
  onTapBack,
}: {
  scope: ResourceScope;
  doc: TingDoc;
  category?: { id: string; name: string };
  hideTeamsShare?: boolean;
  rcShareInfo?: ShareInfo;
  onCloseToEnd?: (isCloseToEnd: boolean, noVoice?: boolean) => void;
  onEnd?: () => void;
  onTapBack?: () => void;
}): React.ReactElement {
  const isPublic = scope === ResourceScope.feeds;
  const { token } = useFileToken();

  const { t } = useTranslation();
  const [captionText, setCaptionText] = useState("");
  const [playingProgress, setPlayingProgress] = useState(0);
  const [showingPageIndex, setShowingPageIndex] = useState(0);
  const [noVoice, setNoVoice] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [copyDeepLinkHidden, setCopyDeepLinkHidden] = useState(true);
  const [clickHintHidden, setClickHintHidden] = useState(true);
  const [isCloseToEnd, setIsCloseToEnd] = useState(false);
  const isMobileCss = useMobileCss();

  const [isAudioEnded, setIsAudioEnded] = useState(false);
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const getCaptionWidthLimit = useCallback((fontSize: string): number => {
    /**
     * Caption width ratio with screen width in desktop version.
     */
    const CaptionWidthRatio = 0.74;

    const tag = document.createElement("div");
    tag.style.fontSize = fontSize;
    tag.textContent = "a";
    tag.style.whiteSpace = "nowrap";
    tag.style.position = "absolute";
    document.body.appendChild(tag);

    const result = (CaptionWidthRatio * window.innerWidth) / tag.clientWidth;
    document.body.removeChild(tag);

    return result;
  }, []);

  const onSwipeChange = useCallback(
    (index: number) => {
      if (videoPlayers[showingPageIndex]) {
        videoPlayers[showingPageIndex].pause();
        videoPlayers[showingPageIndex].currentTime = 0;
      }
      const duration = audioPlayer.goPage(index);
      videoPlayers[index] && videoPlayers[index].play();

      setIsAudioEnded(false);
      setIsVideoEnded(false);

      setShowingPageIndex(index);
      setNoVoice(duration === 0);
    },
    [showingPageIndex]
  );

  /**
   * 初始化播放器
   */
  const initAudioPlayer = useCallback(() => {
    audioPlayer.onCaptionUpdate = (caption: string) => {
      setCaptionText(caption);
    };
    audioPlayer.onPageEnd = (nextPageIndex: number) => {
      setIsAudioEnded(true);
    };
    audioPlayer.onProgressUpdate = (playingProgress: number) => {
      setPlayingProgress(playingProgress);
    };
    audioPlayer.onStatusChange = (status, details) => {
      setIsPlaying(status === "playing");
      setIsWaiting(status === "waiting");
      if (status === "error" && details && details.name === "NotAllowedError") {
        setClickHintHidden(false);
      }
    };

    // cal caption width for desktop version
    const captionWidthLimit = getCaptionWidthLimit(isMobileCss ? rem(15) : rem(24));
    const duration = audioPlayer.init(doc, isPublic, token, captionWidthLimit);
    setNoVoice(duration === 0);

    audioPlayer.goPage(0);
    videoPlayers[0] && videoPlayers[0].play();
  }, [doc, getCaptionWidthLimit, isMobileCss, isPublic, token]);

  const initVideoPlayer = useCallback((video: HTMLVideoElement) => {
    video.onended = () => {
      setIsVideoEnded(true);
      if (isIOS) {
        (video as any).webkitExitFullScreen();
      }
    };
  }, []);

  const swiperItems = useMemo(
    () =>
      doc.pages?.map((item, index) => {
        if (item.video) {
          return (
            <div key={index} className="Swiper-page">
              <video
                ref={ref => {
                  if (ref) {
                    videoPlayers[index] = ref;
                    initVideoPlayer(ref);
                  }
                }}
                src={isPublic ? item.video.data : token ? `${item.video.data}?${token}` : undefined}
                className="Swiper-video"
                playsInline
                webkit-playsinline="true"
              />
            </div>
          );
        }
        return (
          <Img
            key={index}
            src={isPublic ? item.img : token ? `${item.img}?${token}` : undefined}
            alt={`img${index}`}
            imageFit={ImageFit.contain}
            className="Swiper-page"
          />
        );
      }) || [],
    [initVideoPlayer, doc.pages, isPublic, token]
  );

  const onHide = useCallback(() => {
    if (document.visibilityState === "visible") {
      audioPlayer.play();
    } else {
      audioPlayer.pause();
    }
  }, []);

  const tapOnPlayControl = useCallback(() => {
    if (!clickHintHidden) {
      setClickHintHidden(true);
    }
    if (isPlaying) {
      audioPlayer.pause();
      setIsPlaying(false);
    } else {
      if (isAudioEnded) {
        onSwipeChange(showingPageIndex);
      } else {
        audioPlayer.play();
        setIsPlaying(true);
      }
    }
  }, [clickHintHidden, isAudioEnded, isPlaying, onSwipeChange, showingPageIndex]);

  useEffect(() => {
    if (isAudioEnded && (isVideoEnded || !videoPlayers[showingPageIndex])) {
      if (showingPageIndex < doc.pages.length - 1) {
        onSwipeChange(showingPageIndex + 1);
      } else {
        setIsPlaying(false);
        onEnd && onEnd();
      }
    }
  }, [doc.pages.length, isAudioEnded, isVideoEnded, onEnd, onSwipeChange, showingPageIndex]);

  useEffect(() => {
    if (doc.pages) {
      setShowingPageIndex(0);
      initAudioPlayer();
    }

    document.addEventListener("visibilitychange", onHide);
    window.addEventListener("pagehide", onHide);

    return function cleanup() {
      audioPlayer.stop();
      document.removeEventListener("visibilitychange", onHide);
      window.removeEventListener("pagehide", onHide);
    };
  }, [doc, initAudioPlayer, onHide]);

  useEffect(() => {
    // last page
    if (showingPageIndex === doc.pages.length - 1) {
      const remainingTime =
        // eslint-disable-next-line @dragongate/no-magic-numbers
        doc.pages[showingPageIndex].duration * (100 - playingProgress) * 0.00001;
      if (remainingTime <= SHOW_NEXTTIP_SECOND && !isCloseToEnd) {
        onCloseToEnd && onCloseToEnd(true, doc.pages[showingPageIndex].duration === 0);
        setIsCloseToEnd(true);
      } else if (remainingTime > SHOW_NEXTTIP_SECOND && isCloseToEnd) {
        onCloseToEnd && onCloseToEnd(false);
        setIsCloseToEnd(false);
      }
    }
  }, [doc.pages, isCloseToEnd, onCloseToEnd, playingProgress, showingPageIndex]);

  return (
    <div className={"Player"}>
      <PlayerHeader
        title={doc.metadata.name}
        category={category}
        type={"tingdoc"}
        onTapBack={onTapBack}
      >
        <div className="ViewTop-pagenum">
          {doc.pages && `${showingPageIndex + 1} / ${doc.pages.length}`}
        </div>
        <div className="ViewTop-right">
          {!hideTeamsShare && (
            <Icon
              iconName={IconName.Link}
              title={t(View.copyLink)}
              onClick={() => {
                logger?.telemetry("CopyDeepLink", {
                  WorkType: EntityType.Document,
                  DocumentType: RCDocumentType.TingDoc,
                  WorkId: doc.id!,
                  CategoryId: doc.extended?.categoryId!,
                  Name: scope === ResourceScope.feeds ? doc.metadata.name : undefined!,
                  Scope: scope,
                  Target: "TingDocPlayer",
                });
                setCopyDeepLinkHidden(false);
              }}
              className="ViewTop-link"
            />
          )}
          <DefaultButton className="ViewTop-backButton" onClick={onTapBack}>
            <Trans i18nKey={View.close} />
          </DefaultButton>
        </div>
      </PlayerHeader>
      <Swiper
        items={swiperItems}
        onSwiped={index => onSwipeChange(index)}
        current={showingPageIndex}
        className="Swiper"
      />
      <div
        className={mergeStyles("ViewBottom", {
          zIndex: ZIndexes.Nav,
        })}
      >
        <progress value={playingProgress} max="100" className="ViewBottom-progress" />
        <div className={"ViewBottom-container"}>
          <Tooltip
            content={<Trans i18nKey={View.playHint} />}
            calloutProps={{
              hidden: clickHintHidden || isPlaying,
              gapSpace: 2,
              target: ".ViewBottom-playButton",
            }}
            styles={{ root: { display: "inline-block" } }}
          />
          <div
            onClick={tapOnPlayControl}
            className={`ViewBottom-playButton ${noVoice ? "is-empty" : ""}`}
          >
            <div
              className={
                isWaiting
                  ? "Icon-loading"
                  : `Icon-voice ${!noVoice && isPlaying ? "is-playing" : ""}`
              }
            ></div>
          </div>
          <div
            className={mergeStyles("Caption", {
              zIndex: ZIndexes.Nav,
            })}
          >
            {captionText}
          </div>
        </div>
      </div>
      {!copyDeepLinkHidden && (
        <CopyCallout
          item={rcShareInfo}
          target=".ViewTop-link"
          onDismiss={() => setCopyDeepLinkHidden(true)}
        />
      )}
      <div className="Mask-left" />
      <div className="Mask-right" />
    </div>
  );
}
