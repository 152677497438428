import { FontWeights, List, makeStyles } from "@fluentui/react";
import { useControllableValue } from "@fluentui/react-hooks";
import { useCallback, useMemo } from "react";
import { rem } from "../../lib/unit";
import type { TingPage } from "../../models/TingDoc";
import { FontSizes } from "../../themes/fonts";
import SwiperItem, { SwiperItemProps } from "./SwiperItem";

const useCss = makeStyles(theme => ({
  root: {
    maxHeight: "100%",
    overflow: "auto",
    "&.is-loading": {
      overflow: "hidden",
    },
  },
  item: {
    margin: rem(16, 27),
    position: "relative",
  },
  num: {
    color: theme.palette.neutralSecondary,
    fontSize: FontSizes.small,
    position: "absolute",
    textAlign: "right",
    top: 0,
    left: rem(-20),
    ".is-selected &": {
      color: theme.semanticColors.bodyText,
      fontWeight: FontWeights.bold,
    },
  },
}));

export interface SideSwiperProps {
  pages?: TingPage[];
  index?: number;
  disabled?: boolean;
  onIndexChange?: (index: number) => void;
}

export default function SideSwiper({ pages, onIndexChange, ...props }: SideSwiperProps) {
  const css = useCss();
  const [pageIndex, setPageIndex] = useControllableValue(
    props.index,
    0,
    onIndexChange && ((_, newValue) => onIndexChange(newValue!))
  );
  type ItemType = SwiperItemProps & { duration?: number };
  const items = useMemo<ItemType[]>(() => {
    if (!pages) {
      const shimmerCount = 9;
      return Array(shimmerCount);
    }
    return pages.map<ItemType>((p, index) => ({
      img: p.img,
      video: p.video?.data,
      checked: index === pageIndex,
      disabled: index !== pageIndex && props.disabled,
      duration: p.duration,
    }));
  }, [pageIndex, pages, props.disabled]);
  const renderCell = useCallback(
    (p?: ItemType, index?: number) => (
      <div
        className={`${css.item}${p?.checked ? " is-selected" : ""}`}
        onClick={() => !p?.disabled && setPageIndex(index!)}
      >
        <SwiperItem
          img={p?.img}
          video={p?.video}
          duration={p?.duration}
          checked={p?.checked}
          disabled={p?.disabled}
        />
        <span className={css.num}>{p?.img === undefined ? "" : index! + 1}</span>
      </div>
    ),
    [css.item, css.num, setPageIndex]
  );

  return (
    <List
      className={`${css.root}${pages ? "" : " is-loading"}`}
      items={items}
      onRenderCell={renderCell}
      data-is-scrollable
    />
  );
}
