import { useCallback, useEffect, useMemo, useState } from "react";
import { CommandBar, ICommandBarItemProps, makeStyles } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import { PagePath } from "../../config/routes";
import { useRouteParams } from "../../hooks/rc/useRoute";
import { requestWeb, WEB_API } from "../../services/request";
import { useTranslation } from "react-i18next";
import { BundlesEditorI18n, EditorI18n } from "../../locales";
import BundleDetailEditor from "../../components/cards/BundleDetailEditor";
import SortableDocCardGrid from "../../components/list/SortableDocCardGrid";
import EditorHeader from "../../components/navigation/EditorHeader";
import { IconName } from "../../config/icons";
import { useCancelToken } from "../../hooks/useCancelToken";
import { useBoolean } from "@fluentui/react-hooks";
import { showDeleteDialog } from "../../components/dialog/DeleteDialog";
import { MAX_DOCS_NUM_IN_BUNDLE } from "../../config/constant";
import EmptyContent from "../../components/empty/EmptyContent";
import { rem } from "../../lib/unit";
import { logger } from "../../services/logger";
import DocPickerDialog from "../../components/dialog/DocPickerDialog";
import { RCDocumentType } from "../../services/share";
import { useQueryAllPages } from "../../hooks/useQuery";
import { DocumentStatus } from "../../config/permission";
import { goBack } from "../../services/history";

const useStyles = makeStyles({
  contentBox: {
    maxWidth: rem(1200),
    margin: "auto",
  },
  emptyContent: {
    height: `calc( 100% - ${rem(300)})`,
  },
});

export default function EditBundlePage() {
  const { id } = useRouteParams<PagePath.editBundle>();
  const { t } = useTranslation();
  const location = useLocation();

  const [bundle, setBundle] = useState<CDS.Bundle | undefined>((location.state as any)?.bundle);
  const [docs, setDocs] = useState<CDS.Document[]>();
  const [docPickerHidden, { setTrue: closeDocPicker, setFalse: openDocPicker }] = useBoolean(true);
  const { token } = useCancelToken();
  const styles = useStyles();
  useEffect(() => {
    if (id) {
      requestWeb(WEB_API.myTenantBundle, {
        vars: { bundleId: id! },
        cancelToken: token,
      }).then(res => setBundle(res.data), console.error);
      requestWeb(WEB_API.myTenantBundleDocuments, {
        vars: { bundleId: id! },
        params: { $top: MAX_DOCS_NUM_IN_BUNDLE },
        cancelToken: token,
      }).then(res => setDocs(res.data.value), console.error);
    }
  }, [id, token]);

  const items = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        key: "add",
        text: t(BundlesEditorI18n.add),
        cacheKey: "add", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Add },
        onClick: openDocPicker,
      },
    ],
    [openDocPicker, t]
  );

  const onDiscard = useCallback(() => {
    showDeleteDialog({
      title: t(EditorI18n.discardDialogTitle, { context: "edit" }),
      subText: t(EditorI18n.discardDialogContent, { context: "edit" }),
      cancelButtonText: t(EditorI18n.discardDialogConfirm, { context: "edit" }),
      confirmButtonText: t(EditorI18n.discardDialogCancel, { context: "edit" }),
      doDelete: () => {
        goBack();
      },
    });
  }, [t]);

  const { values: allDocs, indicatorVisible } = useQueryAllPages(
    !docPickerHidden && WEB_API.myTenantDocuments,
    { params: { docTypes: [RCDocumentType.TingDoc, RCDocumentType.Video], $top: 5 } },
    useCallback(
      doc =>
        (doc.metadata?.status === DocumentStatus.Private ||
          doc.metadata?.status === DocumentStatus.Public) &&
        (!doc.extended?.bundleId || doc.extended.bundleId === id),
      [id]
    )
  );
  const data = useMemo(() => {
    const docIds = docs?.map(d => d.id!);
    const isSameDocs =
      docIds &&
      docIds.length === bundle?.documentIds?.length &&
      bundle.documentIds.every((d, i) => d[i] === docIds[i]);
    return {
      name: bundle?.name,
      description: bundle?.description,
      cover: bundle?.cover,
      documentIds: isSameDocs ? undefined : docIds,
    };
  }, [bundle, docs]);
  const onSave = useCallback(() => {
    requestWeb(WEB_API.myTenantBundle, {
      method: "PATCH",
      vars: { bundleId: id! },
      data,
    }).then(() => {
      logger?.telemetry("UpdateBundleSuccess", {
        BundleId: id!,
        DocumentsCount: data.documentIds?.length || 0,
      });
      goBack();
    });
  }, [data, id]);
  return (
    <>
      <EditorHeader
        title={t(BundlesEditorI18n.title)}
        cancelText={t(EditorI18n.discard, { context: "edit" })}
        onCancel={onDiscard}
        saveText={t(EditorI18n.save)}
        onSave={onSave}
      />
      <BundleDetailEditor
        isLoading={!bundle}
        cover={bundle?.cover}
        name={bundle?.name}
        description={bundle?.description}
        onCoverChanged={useCallback(
          cover => setBundle(data => ({ ...(data as CDS.Bundle), cover })),
          []
        )}
        onNameChange={useCallback(
          name => setBundle(data => ({ ...(data as CDS.Bundle), name })),
          []
        )}
        onDescChange={useCallback(
          description => setBundle(data => ({ ...(data as CDS.Bundle), description })),
          []
        )}
      />
      <CommandBar items={items} className={styles.contentBox} />
      {docs?.length === 0 ? (
        <EmptyContent
          className={styles.emptyContent}
          message={t(BundlesEditorI18n.emptyTitle)}
          subTitle={t(BundlesEditorI18n.emptySubTitle)}
          buttonProps={{
            text: t(BundlesEditorI18n.add),
            onClick: openDocPicker,
          }}
        />
      ) : (
        <SortableDocCardGrid
          className={styles.contentBox}
          docs={docs || []}
          isLoading={!docs}
          onUpdate={(_, newDocs) => setDocs(newDocs || [])}
        />
      )}
      <DocPickerDialog
        items={allDocs}
        selectedItems={docs}
        title={t(BundlesEditorI18n.docPickerTitle)}
        subTitle={t(BundlesEditorI18n.docPickerTips)}
        submitText={t(BundlesEditorI18n.docPickerSubmit)}
        hidden={docPickerHidden}
        maxItems={MAX_DOCS_NUM_IN_BUNDLE}
        enableShimmer={indicatorVisible}
        onDismiss={closeDocPicker}
        onSubmit={newDocs => {
          setDocs(newDocs as CDS.Document[]);
          closeDocPicker();
        }}
      />
    </>
  );
}
