import { usePrevious } from "@fluentui/react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { requestWeb, WEB_API } from "../../services/request";
import EditNotificationDialog from "./EditNotificationDialog";
import { SortableData } from "../cards/SortableCard";
import { ContentPickerI18n } from "../../locales";
import { useTranslation } from "react-i18next";
import { useQueryAllPages } from "../../hooks/useQuery";
import { MAX_NOTIFICATION_NUM_MAX } from "../../config/constant";
import DocPickerDialog from "./DocPickerDialog";

const STEP_PCIKER = 1;
const STEP_EIDTOR = 2;

export default function CategoryNotificationDialog({
  hidden,
  onDismiss,
  categoryId,
}: {
  hidden?: boolean;
  categoryId?: string;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEP_PCIKER);
  const [dataList, setDataList] = useState<SortableData[]>([]);
  const onNext = useCallback((items: SortableData[]) => {
    setDataList(items);
    setStep(STEP_EIDTOR);
  }, []);
  const onBack = useCallback((items: SortableData[]): void => {
    setDataList(items);
    setStep(STEP_PCIKER);
  }, []);
  const onSubmit = useCallback(
    (items: SortableData[], description?: string) => {
      setDataList(items);
      return requestWeb(WEB_API.myTenantCategoryNotify, {
        method: "POST",
        vars: { categoryId: categoryId! },
        data: { items: items.map(item => ({ id: item.id!, type: item.entityType! })), description },
      }).then(() => {
        onDismiss && onDismiss();
        setStep(STEP_PCIKER);
      });
    },
    [categoryId, onDismiss]
  );

  const prevCatgegoryId = usePrevious(categoryId);
  useEffect(() => {
    if (!hidden && categoryId !== prevCatgegoryId) {
      setStep(STEP_PCIKER);
      setDataList([]); // reset
    }
  }, [categoryId, hidden, prevCatgegoryId]);
  const { values: bundles = [], indicatorVisible: isLoadingBundles } = useQueryAllPages(
    !hidden && WEB_API.myTenantCategoryBundles,
    {
      vars: { categoryId: categoryId! },
      params: { $orderby: "publish_at desc" },
    }
  );
  const { values: docs = [], indicatorVisible: isLoadingDocs } = useQueryAllPages(
    !hidden && WEB_API.myTenantCategoryDocuments,
    {
      vars: { categoryId: categoryId! },
      params: { $orderby: "publish_at desc" },
    }
  );
  const items = useMemo(() => [...bundles, ...docs], [bundles, docs]);
  return (
    <>
      <DocPickerDialog
        hidden={hidden || step !== STEP_PCIKER}
        title={t(ContentPickerI18n.dialogTitle)}
        subTitle={t(ContentPickerI18n.pickerTips, { count: MAX_NOTIFICATION_NUM_MAX })}
        submitText={t(ContentPickerI18n.next)}
        enableShimmer={isLoadingBundles || isLoadingDocs}
        maxItems={MAX_NOTIFICATION_NUM_MAX}
        items={items}
        selectedItems={dataList}
        onSubmit={onNext}
        onDismiss={onDismiss}
      />
      <EditNotificationDialog
        hidden={hidden || step !== STEP_EIDTOR}
        categoryId={categoryId}
        dataList={dataList}
        onSubmit={onSubmit}
        onBack={onBack}
        onDismiss={onDismiss}
      />
    </>
  );
}
