import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { getFeedsLanguage, putFeedsLanguage } from "../services/feedsLanguage";
import { setUserSetting } from "../services/rc/settings";
import { UserSetting, WEB_API } from "../services/request";
import { FeedsLanguage } from "../services/storage";
import { useUser } from "./rc/useUser";
import { useQueryWeb } from "./useQuery";

export function useFeedsLanguage(isAdmin?: boolean) {
  const { role, idToken } = useUser();

  const client = useQueryClient();
  const setFeedsLanguage = useCallback(
    (feedsLanguage: FeedsLanguage) => {
      const languages = [feedsLanguage];
      putFeedsLanguage(languages, isAdmin ? "admin" : "user");
      if (!isAdmin) {
        if (role && idToken) {
          setUserSetting({ feedsLanguages: languages });
        }
        // 更新query数据
        client.setQueryData<UserSetting>([WEB_API.myUserSetting, {}], data => ({
          ...data,
          feedsLanguages: languages,
        }));
      }
    },
    [client, isAdmin, role, idToken]
  );

  const { data } = useQueryWeb(WEB_API.myUserSetting, {
    config: {
      enabled: !isAdmin && !!role && !!idToken,
      onSuccess: (userSetting: UserSetting) => {
        if (userSetting.feedsLanguages) {
          putFeedsLanguage(userSetting.feedsLanguages, "user");
        } else {
          setFeedsLanguage(getFeedsLanguage("user"));
        }
      },
      cacheTime: 120000,
    },
  });

  const feedsLanguage = isAdmin
    ? getFeedsLanguage("admin")
    : data?.feedsLanguages?.[0] || getFeedsLanguage("user");
  return { feedsLanguage, setFeedsLanguage };
}
