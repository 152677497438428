import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Spinner,
  SpinnerSize,
  Stack,
  mergeStyles,
  ActionButton,
  DirectionalHint,
  Tooltip,
  useTheme,
  IContextualMenuItem,
} from "@fluentui/react";
import { IconName } from "../../config/icons";
import { isMobliePhone, isPrerender } from "../../config/browser";
import { contentHeight, pageContentContainer } from "../../components/navigation/TopBar";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "./Content";
import { useNavLinks, NavLink, RCTreeNode, ActionKey } from "../../hooks/rc/useNavLinks";
import { rem } from "../../lib/unit";
import RCNav from "../../components/rc/RCNav";
import { useTranslation } from "react-i18next";
import { AdminPageI18n, CategoryActionI18n, AdministratorGuide } from "../../locales";
import CategoryDialog, { CategoryActionType } from "../../components/dialog/CategoryDialog";
import { CatalogNode, TreeNode } from "../../services/request";
import AppDeploymentDocBubble from "../../components/bubble/AppDeploymentDocBubble";
import { useUser } from "../../hooks/rc/useUser";
import { patchCatalog } from "../../services/rc/category";
import debounce from "debounce-promise";
import { logger } from "../../services/logger";
import { useMobileCss } from "../../hooks/rc/useCss";
import {
  AdminNavKeys,
  generateCategoryHash,
  HASHType,
  ResourceScope,
  TemplateNavKeys,
} from "../../config/rc";
import { getFeedsLanguage, putFeedsLanguage } from "../../services/feedsLanguage";
import { FeedsLanguage } from "../../services/storage";
import i18n from "../../config/i18n";
import AdministratorGuideAIAudioDialog from "../../components/dialog/AdministratorGuideAIAudioDialog";
import { AdminUserGuideIds } from "../../config/rc";
import { useBoolean } from "@fluentui/react-hooks";
import { Flag } from "../../config/flag";
import { useFlagStatus } from "../../hooks/useFlagStatus";
import AdminMessageBar from "../../components/rc/AdminMessageBar";
import CategoryNotificationDialog from "../../components/dialog/CategoryNotificationDialog";
import AppTextBubble from "../../components/bubble/AppTextBubble";

const patchCatalogDelay = 5000;
const tipShowTime = 10000;
const messageBarHeight = rem(40);

export default function RCAdminPage() {
  const [actionData, setActionData] = useState<RCTreeNode>();
  const [actionType, setActionType] = useState<CategoryActionType>();
  const [tipTarget, setTipTarget] = useState<string>();
  const [dialogHidden, { setTrue: setDialogHidden, setFalse: setDialogShow }] = useBoolean(true);
  const [notifyCategoryId, setNotifyCategoryId] = useState<string>();
  const [notifyDialogVisible, { setTrue: showNotifyDialog, setFalse: hideNotifyDialog }] =
    useBoolean(false);
  const pushNotify = useCallback(
    (cid: string) => {
      setNotifyCategoryId(cid);
      showNotifyDialog();
    },
    [showNotifyDialog]
  );

  const [adminAIAudioFlag, setAdminAIAudioFlag] = useFlagStatus(Flag.AdminLoaded);
  const [officeUploadFlag, setOfficeUploadFlag] = useFlagStatus(Flag.OfficeUploadFlag);

  const [feedsLanguage, setFeedsLanguage] = useState(() => getFeedsLanguage("admin"));
  const isMobileCss = useMobileCss();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [messageBarShow, setMessageBarShow] = useFlagStatus(Flag.AdminDeployMessageBar);
  const [deployBubbleHidden, setDeployBubbleHidden] = useFlagStatus(Flag.AdminDeployBubble);
  const { role } = useUser();
  const hashId = location.hash;
  const theme = useTheme();
  const {
    feedsTree,
    feedsCategoryLinks,
    myTenantTree,
    myTenantCategoryLinks,
    adminNavLinks,
    templateHubNavLinks,
    refecthMytenantCatalog,
    refecthFeedsCatalog,
    feedsSettled,
    myTenantSettled,
  } = useNavLinks({
    role,
    showEmpty: true,
    showEmptyPlaceHolder: true,
    feedsLanguage,
  });

  const onLinkClick = useCallback(
    (link: NavLink) => {
      link.target !== "_blank" && navigate(link.url);
    },
    [navigate]
  );
  const myTenantActions = useMemo(
    () => [
      {
        key: ActionKey.addCategory,
        text: t(CategoryActionI18n.addTreeNode),
        iconProps: {
          iconName: IconName.Add,
        },
        onClick: (ev: any, item: any) => {
          ev?.stopPropagation();
          console.log("addTreeNode", item);
          setActionType(CategoryActionType.addSubCategory);
          item && setActionData(item.data);
          setDialogShow();
        },
      },
      {
        key: ActionKey.editCategory,
        text: t(CategoryActionI18n.editTreeNode),
        iconProps: {
          iconName: IconName.Edit,
        },
        onClick: (ev: any, item: any) => {
          ev?.stopPropagation();
          console.log("editTreeNode", item);
          setActionType(
            (item?.data as RCTreeNode).parentId
              ? CategoryActionType.editSubCategory
              : CategoryActionType.editCategory
          );
          item && setActionData(item.data);
          setDialogShow();
        },
      },
      {
        key: ActionKey.pushNotification,
        text: t(CategoryActionI18n.notify),
        iconProps: {
          iconName: IconName.Ringer,
        },
        onClick: (ev: any, item?: IContextualMenuItem) => {
          ev?.stopPropagation();
          const categoryId = item?.data.raw?.treeNode.id;
          if (categoryId) {
            pushNotify(categoryId);
          }
        },
      },
      {
        key: ActionKey.deleteCategory,
        text: t(CategoryActionI18n.deleteTreeNode),
        iconProps: {
          iconName: IconName.Delete,
        },
        onClick: (ev: any, item: any) => {
          ev?.stopPropagation();
          console.log("deleteTreeNode", item);
          setActionType(CategoryActionType.delete);
          item && setActionData(item.data);
          setDialogShow();
        },
      },
    ],
    [pushNotify, setDialogShow, t]
  );

  const feedsActions = useMemo(
    () => [
      {
        key: ActionKey.editFeedsConfig,
        text: t(CategoryActionI18n.editTreeNode),
        iconProps: {
          iconName: IconName.Edit,
        },
        onClick: (ev: any, item: any) => {
          ev?.stopPropagation();
          console.log("editFeedsNode", item);
          setActionType(CategoryActionType.editFeedsConfig);
          item && setActionData(item.data);
          setDialogShow();
        },
      },
    ],
    [setDialogShow, t]
  );

  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  useEffect(() => {
    const nav =
      feedsCategoryLinks?.find(item => item.url === hashId) ||
      myTenantCategoryLinks?.find(item => item.url === hashId) ||
      templateHubNavLinks?.find(item => item.url === hashId);
    if (nav) {
      setSelectedKey(nav.key);
    } else if (hashId.startsWith(HASHType.myTenantContent)) {
      setSelectedKey(AdminNavKeys.adminContentHub);
    } else if (hashId.startsWith(HASHType.adminAssignment)) {
      setSelectedKey(AdminNavKeys.adminAssignment);
    } else if (hashId.startsWith(HASHType.myTenantTemplates)) {
      setSelectedKey(TemplateNavKeys.adminTemplateHub);
    } else if (hashId.startsWith(HASHType.myTenantBundles)) {
      setSelectedKey(AdminNavKeys.adminBundles);
    } else if (feedsCategoryLinks && myTenantCategoryLinks) {
      navigate(`${HASHType.myTenantContent}/`);
    }
  }, [feedsCategoryLinks, myTenantCategoryLinks, hashId, navigate, templateHubNavLinks]);

  // const isAdmin = role === UserRole.resourceAdmin || role === UserRole.tenantAdmin;

  const onCategoryDialogConfirm = useCallback(
    (actionType: CategoryActionType) => {
      if (actionType === CategoryActionType.editFeedsConfig) {
        refecthFeedsCatalog();
      } else {
        refecthMytenantCatalog();
      }
      setActionType(CategoryActionType.blank);
    },
    [refecthMytenantCatalog, refecthFeedsCatalog]
  );
  const onCategoryActionSuccess = useCallback(
    (actionType: CategoryActionType, data, categoryId?: string) => {
      switch (actionType) {
        case CategoryActionType.addCategory:
          setTipTarget(`#treeNode_${categoryId}`);
          setTimeout(() => {
            setTipTarget(undefined);
          }, tipShowTime);
          break;
        case CategoryActionType.addSubCategory:
          const { id } = data as TreeNode;
          navigate(generateCategoryHash(id, ResourceScope.mytenant));
          break;
        case CategoryActionType.delete:
          if (hashId === generateCategoryHash(categoryId!, ResourceScope.mytenant)) {
            navigate("#");
          }
          break;
      }
    },
    [hashId, navigate]
  );

  const onCategoryError = useCallback(() => navigate("#"), [navigate]);

  const onMyTenantRCTreeDrop = useMemo(
    () =>
      debounce((catalogNodes: CatalogNode[]) => {
        patchCatalog(catalogNodes).finally(refecthMytenantCatalog);
      }, patchCatalogDelay),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    setFeedsLanguage(getFeedsLanguage("admin"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return isPrerender || !role || !feedsSettled || !myTenantSettled ? (
    <Spinner size={SpinnerSize.large} styles={{ root: { height: "100vh" } }} />
  ) : (
    <>
      <AdminMessageBar
        height={messageBarHeight}
        hidden={!messageBarShow}
        onDismiss={setMessageBarShow}
      />
      <Stack
        horizontal
        className={mergeStyles(pageContentContainer, {
          height: messageBarShow ? `calc(${contentHeight} - ${messageBarHeight})` : contentHeight,
        })}
      >
        <Stack.Item
          styles={{
            root: {
              display: !isMobileCss ? "visible" : "none",
              position: "relative",
              overflow: "hidden",
              borderRight: `1px solid ${theme.palette.neutralLight}`,
              minWidth: rem(300),
              width: rem(300),
            },
          }}
        >
          <RCNav
            className={mergeStyles("Container", {
              height: `calc(100% - ${rem(50)})`,
              paddingRight: rem(5),
            })}
            pageName="admin"
            isAdmin
            adminNavLinks={adminNavLinks}
            showTemplateHubNav={true}
            templateNavLinks={templateHubNavLinks}
            feedsTree={feedsTree}
            myTenantTree={myTenantTree}
            onLinkClick={onLinkClick}
            onFeedsLanguageChange={option => {
              const lng = `${option?.key}` as FeedsLanguage;
              setFeedsLanguage(lng);
              putFeedsLanguage([lng], "admin");
              logger?.telemetry("ChangeFeedsLanguage", { Language: lng, Role: "admin" });
            }}
            onMyTenantRCTreeDrop={onMyTenantRCTreeDrop}
            selectedKey={selectedKey}
            myTenantActions={myTenantActions}
            feedsActions={feedsActions}
            feedsLanguage={feedsLanguage}
            onScroll={() => {
              setTipTarget(undefined);
            }}
          ></RCNav>
          {!isMobliePhone && (
            <ActionButton
              id={AdminUserGuideIds.createCategory}
              styles={{
                root: {
                  height: rem(50),
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  borderTop: `1px solid ${theme.palette.neutralLight}`,
                  paddingLeft: rem(16),
                },
                label: {
                  marginLeft: rem(10),
                },
              }}
              iconProps={{
                iconName: IconName.Add,
              }}
              text={t(AdminPageI18n.addCatalog)}
              onClick={() => {
                setActionType(CategoryActionType.addCategory);
                setActionData(undefined);
                setDialogShow();
              }}
            ></ActionButton>
          )}
        </Stack.Item>
        <Stack.Item
          grow={1}
          styles={{
            root: {
              overflow: "hidden",
            },
          }}
        >
          <Content
            isAdmin
            contentHashId={hashId}
            pushNotification={pushNotify}
            onCategoryError={onCategoryError}
          />
        </Stack.Item>
      </Stack>
      <CategoryDialog
        actionType={actionType!}
        hidden={dialogHidden}
        onDismiss={setDialogHidden}
        onConfirm={onCategoryDialogConfirm}
        onActionSuccess={onCategoryActionSuccess}
        category={actionData?.raw?.treeNode}
      ></CategoryDialog>
      <AppDeploymentDocBubble
        hidden={!deployBubbleHidden}
        target={`#${AdminNavKeys.appDeploymentHelp}`}
        onDismiss={setDeployBubbleHidden}
      />
      <Tooltip
        hidden={!tipTarget}
        content={t(AdminPageI18n.actionTip)}
        calloutProps={{
          target: tipTarget,
          directionalHint: DirectionalHint.rightCenter,
          backgroundColor: theme.palette.neutralDark,
          styles: {
            beakCurtain: { backgroundColor: theme.palette.neutralDark },
          },
        }}
        styles={{
          root: {
            backgroundColor: theme.palette.neutralDark,
          },
          subText: {
            backgroundColor: theme.palette.neutralDark,
            color: theme.palette.white,
          },
        }}
      ></Tooltip>
      <CategoryNotificationDialog
        onDismiss={hideNotifyDialog}
        hidden={!notifyDialogVisible}
        categoryId={notifyCategoryId}
      />
      {adminAIAudioFlag && (
        <AdministratorGuideAIAudioDialog onDismiss={() => setAdminAIAudioFlag()} />
      )}
      {officeUploadFlag && (
        <AppTextBubble
          target={`#${AdminNavKeys.adminContentHub}`}
          headline={t(AdministratorGuide.officeUploadTitle)}
          content={t(AdministratorGuide.officeUploadContent)}
          buttonText={t(AdministratorGuide.officeUploadConfirm)}
          directionalHint={DirectionalHint.rightCenter}
          onConfirmClick={() =>
            !HASHType.myTenantContent.startsWith(location.hash) &&
            navigate(`${HASHType.myTenantContent}/`)
          }
          onDismiss={setOfficeUploadFlag}
        />
      )}
    </>
  );
}
