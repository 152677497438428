import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { requestWeb, WEB_API } from "../../services/request";
import MixedContent from "./MixedContent";
import { ResourceScope } from "../../config/rc";
import { useQueryWebPages } from "../../hooks/useQuery";
import { useBoolean } from "@fluentui/react-hooks";
import { RCDocumentType } from "../../services/share";
import { pageContentContainer } from "../navigation/TopBar";
import { rem } from "../../lib/unit";
import TemplateHubBar from "../navigation/TemplateHubBar";
import { EmptyContentI18n } from "../../locales";
import { useRouteViewDocCallback } from "../../hooks/rc/useRoute";
import { logger } from "../../services/logger";
import CreateTemplateDialog from "../dialog/CreateTemplateDialog";
import TemplateNotificationDialog from "../dialog/TemplateNotificationDialog";

export interface TemplateHubProps {
  isAdmin?: boolean;
}

export default function TemplateHub({ isAdmin }: TemplateHubProps) {
  const {
    values: documents,
    hasNextPage,
    indicatorVisible,
    loadMore,
    refetch,
  } = useQueryWebPages(isAdmin ? WEB_API.myTenantDocuments : WEB_API.myTenantTemplateDocuments, {
    params: isAdmin
      ? {
          docTypes: [
            RCDocumentType.PPTTemplate,
            RCDocumentType.WordTemplate,
            RCDocumentType.ExcelTemplate,
          ],
        }
      : undefined,
  });

  const viewDoc = useRouteViewDocCallback({ scope: ResourceScope.mytenant });

  const delDoc = useCallback(
    (doc: CDS.Document) =>
      requestWeb(WEB_API.myTenantDocument, { vars: { id: doc.id! }, method: "DELETE" }).then(() => {
        refetch({ cancelRefetch: true });
      }),
    [refetch]
  );

  const { t } = useTranslation();

  const [
    isNotificationEditorVisible,
    { setFalse: closeNotificationEditor, setTrue: openNotificationEditor },
  ] = useBoolean(false);

  const [isCreateDialogVisible, { setFalse: closeCreateDialog, setTrue: openCreateDialog }] =
    useBoolean(false);

  const [isNotificationBubbleVisible, { setTrue: showNotificationBubbleVisible }] =
    useBoolean(false);

  const hide = useCallback(() => {
    closeCreateDialog();
    refetch();
  }, [closeCreateDialog, refetch]);
  return (
    <div className={pageContentContainer} style={{ height: "100%", overflow: "hidden" }}>
      <MixedContent
        bundles={[]}
        scope={ResourceScope.mytenant}
        hasNextPageDoc={hasNextPage}
        loadMoreDoc={loadMore}
        isFetchingDoc={indicatorVisible}
        documents={documents}
        tapDoc={viewDoc}
        delDoc={isAdmin ? delDoc : undefined}
        emptyTitle={t(EmptyContentI18n.emptyTitle)}
        emptySubTitle={isAdmin ? t(EmptyContentI18n.emptySubTitle, { context: "templateHub" }) : ""}
        showCreator={isAdmin}
        contentContainerStyle={{
          height: `calc(100% - ${isAdmin ? rem(50) : "0px"})`,
          overflow: "scroll",
        }}
      >
        {isAdmin && (
          <TemplateHubBar
            onClickNotification={() => {
              logger?.telemetry("ClickNotificationBtn", {
                Target: "template",
              });
              openNotificationEditor();
            }}
            onClickUpload={openCreateDialog}
            showNotificationBubble={isNotificationBubbleVisible}
          ></TemplateHubBar>
        )}
      </MixedContent>
      <CreateTemplateDialog
        hidden={!isCreateDialogVisible}
        onDismiss={hide}
        onTemplateCreated={showNotificationBubbleVisible}
      />
      <TemplateNotificationDialog
        onDismiss={closeNotificationEditor}
        hidden={!isNotificationEditorVisible}
      />
    </div>
  );
}
