import React, { useCallback, useEffect } from "react";
import {
  FontSizes,
  ImageCoverStyle,
  ImageFit,
  Label,
  Link,
  makeStyles,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Text,
  TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

import { rem } from "../../lib/unit";
import { FilePicker } from "../../locales";
import Img from "../common/Img";
import { useInputLengthValidation } from "../../hooks/useInputLengthValidation";
import { useFileInput } from "../../hooks/useFileInput";
import { MAX_TITLE_LEN } from "../../config/constant";

export interface DocumentMetaEditorProps {
  title?: string;
  cover?: string;
  progress?: number;
  onCoverError?: () => void;
  coverErr?: { message: string; type: MessageBarType; onDismiss?: () => void };
  typeErr?: { message: string; type: MessageBarType; onDismiss?: () => void };
  onTitleChanged?: (title: string) => void;
  setTitleErr?: (err?: boolean) => void;
  onUpload?: (file: File) => any;
}

const useDcoumentMetaStyle = makeStyles(theme => ({
  label: { "&.ms-Label": { padding: rem(24, 0, 16, 0) } },
  coverBox: { margin: rem(0, 0, 24) },
  coverEditArea: {
    padding: rem(0, 0, 16),
    display: "flex",
    alignItems: "center",
    gap: rem(16),
  },
  coverProgress: { marginTop: "-9px", borderRadius: "4px" },
  coverImg: {
    borderRadius: "4px",
    backgroundColor: theme.palette.neutralLighterAlt,
    width: rem(160),
    height: rem(90),
  },
  coverTips: {
    fontSize: theme.fonts.medium.fontSize,
    color: theme.palette.neutralSecondary,
  },
}));

const useDialogTextStyle = makeStyles(theme => ({
  root: {
    overflowX: "hidden",
    border: "0",
    selectors: {
      ".ms-TextField-fieldGroup": {
        fontSize: FontSizes.xLargePlus,
        height: rem(42),
        borderRadius: "0",
        background: "transparent",
        selectors: {
          ".ms-TextField-field": {
            border: "0",
            fontSize: "inherit",
            padding: "0",
            height: "100%",
          },
          ".ms-TextField-field::placeholder": {
            fontWeight: "normal",
            fontSize: "inherit",
            lineHeight: rem(32),
            color: theme.palette.neutralTertiaryAlt,
          },
          " input": {
            color: theme.palette.neutralPrimary,
          },
        },
      },
      "&.is-active": {
        selectors: {
          ".ms-TextField-fieldGroup": {
            borderWidth: "0 0 2px 0",
            borderBottomColor: theme.palette.themePrimary,
          },
        },
      },
    },
  },
  fieldGroup: [
    {
      borderWidth: "0 0 2px 0",
    },
  ],
}));
/**
 * 编辑 文档标题和封面图
 */
export default function DocumentMetaEditor({
  title,
  cover,
  progress,
  coverErr,
  typeErr,
  onTitleChanged,
  onCoverError,
  setTitleErr,
  onUpload,
}: DocumentMetaEditorProps) {
  const { t } = useTranslation();
  const styles = useDcoumentMetaStyle();
  const textStyles = useDialogTextStyle();
  const { isOk, onGetErrorMessage } = useInputLengthValidation(MAX_TITLE_LEN, true);
  useEffect(() => setTitleErr?.(isOk === false), [isOk, setTitleErr]);

  const { openPicker } = useFileInput(files => {
    files && onUpload && onUpload(files[0]);
  }, "image/png, image/jpeg");
  const onChange = useCallback((_: any, t?: string) => onTitleChanged?.(t!), [onTitleChanged]);
  return (
    <>
      {typeErr && (
        <MessageBar messageBarType={typeErr.type} onDismiss={typeErr.onDismiss}>
          {typeErr.message}
        </MessageBar>
      )}
      <TextField
        title={title}
        label={t(FilePicker.title)}
        defaultValue={title}
        onChange={onChange}
        validateOnLoad={false}
        onGetErrorMessage={onGetErrorMessage}
        styles={textStyles}
        placeholder={t(FilePicker.titlePlaceHolder)}
        maxLength={MAX_TITLE_LEN}
        required
      />
      <div className={styles.coverBox}>
        <Label className={styles.label}>{t(FilePicker.cover)}</Label>
        <div className={styles.coverEditArea}>
          <div>
            <Img
              className={styles.coverImg}
              coverStyle={ImageCoverStyle.landscape}
              imageFit={ImageFit.cover}
              src={cover}
              onError={onCoverError}
              onClick={openPicker}
            />
            {progress !== undefined && (
              <ProgressIndicator
                className={styles.coverProgress}
                barHeight={4}
                description={t(FilePicker.coverUpload)}
                percentComplete={progress === 0 ? undefined : progress}
              />
            )}
          </div>
          <Link onClick={openPicker}>{t(FilePicker.coverChange)}</Link>
        </div>
        {coverErr && (
          <MessageBar messageBarType={coverErr.type} onDismiss={coverErr.onDismiss}>
            {coverErr.message}
          </MessageBar>
        )}
        <Text className={styles.coverTips}>{t(FilePicker.coverTips)}</Text>
      </div>
    </>
  );
}
