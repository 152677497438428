import React, { useCallback, useMemo, useState } from "react";
import {
  IconButton,
  IContextualMenuItem,
  ActionButton,
  IButtonProps,
  ZIndexes,
  mergeStyles,
  BaseButton,
  Text,
  DirectionalHint,
  IButtonStyles,
  useTheme,
  FontWeights,
  makeStyles,
} from "@fluentui/react";
import { FontSizes } from "../../themes/fonts";

import { IconName, IconNameKey } from "../../config/icons";
import { isMobliePhone } from "../../config/browser";

import "./MoreButton.scss";
import { rem } from "../../lib/unit";

function isNotEmpty(item: any): item is IButtonProps & IContextualMenuItem {
  return !!item;
}

const useMoreButtonMobileTitleStyles = makeStyles({
  root: {
    marginTop: rem(15),
    overflow: "hidden",
    fontWeight: FontWeights.bold,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: FontSizes.mediumPlus,
  },
});

const useMoreButtonMobileButtonStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: rem(50),
    padding: rem(0, 0, 0, 15),
    selectors: {
      ":not(:last-child) .ms-Button-textContainer": {
        borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`,
      },
    },
  },
  flexContainer: {
    width: "100%",
  },
  icon: {
    fontSize: FontSizes.xLarge,
    color: `${theme.palette.black}`,
  },
  textContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    color: `${theme.palette.black}`,
  },
  label: {
    textAlign: "start",
    fontSize: FontSizes.xLarge,
    lineHeight: rem(22),
    width: "85%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export function MoreButtonMobile({
  id,
  items,
  title,
  iconName,
  iconText,
}: {
  id?: string;
  items: IButtonProps[];
  title?: string;
  iconName?: IconNameKey;
  iconText?: string;
}) {
  const [hidden, setHidden] = useState(true);
  const onMoreIconClick = useCallback((e: React.MouseEvent<BaseButton>) => {
    e.stopPropagation();
    e.preventDefault();
    setHidden(false);
    // disable ios scroll
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
  }, []);
  const onCloseIconClick = useCallback((e: React.MouseEvent) => {
    setHidden(true);
    e.stopPropagation();
    e.preventDefault();
    // recover ios scroll
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
  }, []);
  const theme = useTheme();
  const moreButtonMobileTitleStyles = useMoreButtonMobileTitleStyles();
  const moreButtonMobileButtonStyles = useMoreButtonMobileButtonStyles();
  const moreMaskClassName = useMemo(
    () =>
      mergeStyles("MoreButton", {
        zIndex: ZIndexes.Layer,
        backgroundColor: theme.palette.blackTranslucent40,
      }),
    [theme.palette.blackTranslucent40]
  );
  const morePannelClassName = useMemo(
    () =>
      mergeStyles("MoreButton-content", {
        backgroundColor: theme.palette.white,
      }),
    [theme.palette.white]
  );
  return (
    <>
      <ActionButton
        id={id}
        role="menuitem"
        styles={{ icon: { fontWeight: 600 } }}
        onClick={onMoreIconClick}
        iconProps={{ iconName }}
        text={iconText}
      />
      {hidden || (
        <div className={moreMaskClassName} onClick={onCloseIconClick}>
          <div className={morePannelClassName}>
            <Text styles={moreButtonMobileTitleStyles}>{title}</Text>
            {items.map((item, index) => (
              <ActionButton
                key={index}
                text={item.name}
                styles={moreButtonMobileButtonStyles}
                {...item}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

const usePcStyles = makeStyles(theme => {
  const style = {
    color: theme.palette.neutralPrimary,
    backdropFilter: "blur(4px)",
  };
  return {
    root: style,
    rootHovered: style,
    rootPressed: style,
    rootExpanded: style,
    rootFocused: style,
    menuIconHovered: {
      fontWeight: "bolder",
    },
  };
});

/**
 * PC 端
 */
export function MoreButtonPC({
  id,
  items,
  title,
  iconName,
  directionalHint,
  styles,
}: {
  id?: string;
  items: IContextualMenuItem[];
  iconName?: IconNameKey;
  title?: string;
  directionalHint?: DirectionalHint;
  styles?: IButtonStyles;
}) {
  const defaultStyles = usePcStyles();
  return (
    <IconButton
      id={id}
      role="menuitem"
      title={title}
      styles={styles || defaultStyles}
      menuIconProps={{
        iconName,
      }}
      menuProps={{ items, directionalHint }}
    />
  );
}

export type MoreActionItem = (IButtonProps & IContextualMenuItem) | undefined | false;

export default function MoreButton(prop: {
  id?: string;
  items: MoreActionItem[];
  title?: string;
  iconName?: IconNameKey;
  styles?: IButtonStyles;
}) {
  const items: (IButtonProps & IContextualMenuItem)[] = useMemo(
    () => prop.items.filter(isNotEmpty),
    [prop.items]
  );
  const iconName = prop.iconName || IconName.More;
  return isMobliePhone
    ? MoreButtonMobile({ ...prop, items, iconName })
    : MoreButtonPC({ ...prop, items, iconName });
}
