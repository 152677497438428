import { useTranslation } from "react-i18next";
import { FontWeights, Icon, makeStyles, Text, ZIndexes } from "@fluentui/react";
import { MediaQuery, rem } from "../../lib/unit";
import { FontSizes } from "../../themes/fonts";
import { PlayNextTipI18n } from "../../locales";
import { IconName } from "../../config/icons";
import { useCallback, useEffect } from "react";

const useCssStyle = makeStyles(theme => ({
  root: {
    position: "absolute",
    bottom: rem(106),
    left: rem(24),
    display: "flex",
    alignItems: "center",
    zIndex: ZIndexes.Nav,
    boxSizing: "border-box",
    padding: rem(13, 16),
    width: rem(343),
    height: rem(66),
    background: "#404040",
    boxShadow: "0px 0.6px 1.8px rgba(0, 0, 0, 0.14), 0px 3.2px 7.2px rgba(0, 0, 0, 0.12)",
    borderRadius: rem(12),
    cursor: "pointer",
    [MediaQuery.mobile]: {
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      bottom: rem(60),
    },
  },
  textContainer: { overflow: "hidden", flex: 1 },
  text: [
    {
      color: "white",
      lineHeight: "none",
      "&.is-header": {
        fontWeight: FontWeights.semibold,
      },
    },
    "ellipsis-line1",
  ],
  playBtn: {
    color: "white",
    lineHeight: "none",
    border: `1px solid white`,
    borderRadius: "4px",
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.small,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: rem(40),
    height: rem(24),
    [MediaQuery.mobile]: {
      display: "none",
    },
  },
  closeBtn: {
    color: "white",
    marginLeft: rem(16),
    fontSize: FontSizes.small,
  },
}));

export default function PlayNextTip({
  nextDoc,
  hidden,
  autoNextDoc,
  onClick,
  onDismiss,
}: {
  hidden: boolean;
  nextDoc?: CDS.Document;
  autoNextDoc?: boolean;
  onClick?: () => void;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();
  const style = useCssStyle();

  const onCloseClick = useCallback(
    e => {
      e.stopPropagation();
      onDismiss && onDismiss();
    },
    [onDismiss]
  );

  useEffect(() => {
    if (!hidden && nextDoc == null && onDismiss) {
      // eslint-disable-next-line @dragongate/no-magic-numbers
      setTimeout(onDismiss, 5000);
    }
  }, [hidden, nextDoc, onDismiss]);

  return !hidden && nextDoc !== undefined ? (
    <div className={style.root} onClick={onClick}>
      <div className={style.textContainer}>
        {nextDoc ? (
          <>
            <Text className={`${style.text} is-header`}>
              {autoNextDoc ? t(PlayNextTipI18n.nextPlay) : t(PlayNextTipI18n.next)}
            </Text>
            <Text className={style.text}>{nextDoc?.metadata?.name}</Text>
          </>
        ) : (
          <Text className={style.text}>{t(PlayNextTipI18n.noDoc)}</Text>
        )}
      </div>
      {nextDoc && <Text className={style.playBtn}>{t(PlayNextTipI18n.playNow)}</Text>}
      <Icon iconName={IconName.ChromeClose} onClick={onCloseClick} className={style.closeBtn} />
    </div>
  ) : (
    <></>
  );
}
