import React, { ReactNode } from "react";
import { FontWeights, ImageCoverStyle, ImageFit, makeStyles, Shimmer, Text } from "@fluentui/react";
import { TingCardProps } from "./Card";
import Img from "../common/Img";
import { rem } from "../../lib/unit";
import FileTypeIcon from "../common/FileTypeIcon";

const useCardStyles = makeStyles(theme => ({
  root: {
    // boxShadow: theme?.effects.elevation4,
    padding: rem(12),
    maxWidth: "100%",
    justifyContent: "space-between",
    display: "flex",
    color: theme.semanticColors.bodyText,
    backgroundColor: theme?.palette.white,
    cursor: "pointer",
    ":disabled,&.is-disabled": {
      opacity: 0.4,
    },
    ":active,:hover,&.is-active": {
      backgroundColor: theme.semanticColors.bodyBackgroundHovered,
    },
  },
  cover: {
    marginRight: rem(16),
    flexShrink: 0,
    width: rem(96),
    height: rem(54),
    backgroundColor: theme.palette.neutralLight,
    borderRadius: "3px",
    position: "relative",
  },
  fileIcon: {
    height: rem(16),
    width: rem(16),
    fontSize: rem(16),
    position: "absolute",
    bottom: rem(6),
    left: rem(6),
  },
  info: {
    padding: rem(4, 4, 4, 0),
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    flex: 1,
    overflow: "hidden",
  },
  title: {
    fontWeight: FontWeights.bold,
    marginBottom: rem(8),
  },
  subTitle: {
    "&.ellipsis-line1": { display: "flex", alignItems: "center", justifyContent: "flex-start" },
    " i": { marginRight: rem(4) },
  },
  footer: {
    flexShrink: 0,
    paddingTop: rem(4),
    marginLeft: "auto",
    color: theme.palette?.neutralSecondary,
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
  },
}));

export default function HorizontalCard({
  cover,
  title,
  disabled,
  ...props
}: React.PropsWithChildren<TingCardProps & { disabled?: boolean; subTitle?: ReactNode }>) {
  const cardStyles = useCardStyles();
  const titleClassName = `${cardStyles.title} ${
    props.subTitle ? "ellipsis-line1" : "ellipsis-line2"
  }`;
  return (
    <div
      className={`${cardStyles.root} ${disabled ? "is-disabled" : ""} ${props.className || ""}`}
      onClick={props.onClick}
      data-is-focusable
      aria-disabled={!!disabled}
    >
      {props.children}
      <Shimmer
        isDataLoaded={cover !== undefined}
        styles={{ shimmerWrapper: cardStyles.cover, dataWrapper: cardStyles.cover }}
        className={cardStyles.cover}
      >
        <Img
          width={rem(96)}
          height={rem(54)}
          src={cover}
          coverStyle={ImageCoverStyle.landscape}
          imageFit={ImageFit.cover}
          className={cardStyles.cover}
        />
        {props.fileExtension && (
          <FileTypeIcon
            styles={{ root: cardStyles.fileIcon }}
            extension={props.fileExtension}
          ></FileTypeIcon>
        )}
      </Shimmer>
      <div className={cardStyles.info}>
        <Shimmer isDataLoaded={title !== undefined} className={titleClassName} width="14em">
          {title}
        </Shimmer>
        {props.subTitle && (
          <Text className={`ellipsis-line1 ${cardStyles.subTitle}`}>{props.subTitle}</Text>
        )}
      </div>
      <div className={cardStyles.footer}>{props.footer}</div>
    </div>
  );
}
