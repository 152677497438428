import { makeStyles, mergeStyles, Text, PrimaryButton, IButtonProps } from "@fluentui/react";
import { rem } from "../../lib/unit";

import { ReactComponent as EmptSvg } from "../../img/components/empty.svg";
import { IconName } from "../../config/icons";

const useEmptyStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  img: {
    height: rem(150),
    width: rem(200),
    paddingBottom: rem(20),
  },
  subTitle: { paddingTop: rem(16), marginBottom: rem(32) },
});

/**
 *
 * @param param0
 */
export default function EmptyContent({
  message,
  subTitle,
  className = "",
  buttonProps,
}: {
  subTitle?: string;
  message?: string;
  className?: string;
  buttonProps?: IButtonProps;
}) {
  const styles = useEmptyStyles();

  return (
    <div className={`${styles.root} ${className}`}>
      <EmptSvg className={styles.img} />
      <Text block variant="large">
        {message}
      </Text>
      <Text block variant="small" className={styles.subTitle}>
        {subTitle}
      </Text>
      {buttonProps && <PrimaryButton iconProps={{ iconName: IconName.Add }} {...buttonProps} />}
    </div>
  );
}

export const pageHeightClass = mergeStyles({ height: "80%" });
