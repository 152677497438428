/**
 * flag的所有枚举
 */
export enum Flag {
  AdminLoaded = "AdminLoaded",
  ContentHubCreateTipFlag = "ContentHubCreateTipFlag",
  AdminDeployMessageBar = "AdminDeployMessageBar",
  AdminDeployBubble = "AdminDeployBubble",
  AdminCollectionBubble = "AdminCollectionBubble",
  UserCreationCenterBubble = "UserCreationCenterBubble",
  OfficeUploadFlag = "OfficeUploadFlag",
  OfficeUploadGuideFlag = "OfficeUploadGuideFlag",
}

/**
 * 关联关系绑定,key是需要前置项才能进行的flag，value是所有依赖项
 */
export const flagDependency: {
  [key in Flag]?: Flag[];
} = {
  [Flag.AdminDeployBubble]: [Flag.AdminDeployMessageBar],
  [Flag.OfficeUploadFlag]: [Flag.AdminLoaded],
  [Flag.OfficeUploadGuideFlag]: [Flag.OfficeUploadFlag],
};
