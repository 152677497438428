import { FontWeights, Icon, makeStyles, Text, ZIndexes, DefaultButton } from "@fluentui/react";
import { IconName } from "../../config/icons";
import { MediaQuery, rem } from "../../lib/unit";
import { FontSizes } from "../../themes/fonts";
import { DarkColors } from "../../themes/teamsColors";
import FileTypeIcon from "../common/FileTypeIcon";
import SubscribeButton from "../rc/SubscribeButton";
import { useTranslation } from "react-i18next";
import { Common } from "../../locales";
import { isLimited } from "../../config/app";

const useStyle = makeStyles(theme => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: rem(55),
    padding: `0 ${rem(20)}`,
    backgroundColor: DarkColors.appWhite,
    zIndex: ZIndexes.Nav,
    boxSizing: "border-box",
    "&.is-office": {
      backgroundColor: "unset",
    },
    "&.is-template": {
      backgroundColor: "unset",
      [MediaQuery.mobile]: {
        position: "fixed",
      },
    },
    "&.is-bundle": {
      borderBottom: `${rem(1)} solid ${theme.palette.neutralLight}`,
      backgroundColor: "unset",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    gridGap: rem(8),
  },
  text: [
    {
      fontSize: FontSizes.large,
      fontWeight: FontWeights.semibold,
      color: "white",
      "&.is-unset": {
        color: "unset",
      },
      lineHeight: "inherit",
    },
    "ellipsis-line1",
  ],
  chevron: {
    color: "white",
    fontWeight: FontWeights.semibold,
    "&.is-unset": {
      color: "unset",
    },
  },
  backIcon: {
    color: "white",
    cursor: "pointer",
    "&.is-unset": {
      color: "unset",
    },
  },
  goBackBtn: { marginLeft: rem(16) },
  headerRightContainer: { alignItems: "center", display: "flex" },
}));

const unsetTypes = ["template", "bundle", "office"];

type PlayerType = "tingdoc" | "video" | "template" | "bundle" | "office";

export default function PlayerHeader({
  title,
  category,
  type,
  extension,
  children,
  onTapBack,
}: React.PropsWithChildren<{
  title: string;
  type: PlayerType;
  extension?: string;
  category?: { id: string; name: string };
  onTapBack?: () => void;
}>) {
  const css = useStyle();
  const fileExtension = extension ?? (type === "video" ? "mp4" : "");
  const { t } = useTranslation();

  return (
    <div className={`${css.root} is-${type}`}>
      <div className={`${css.title} screen-pc`}>
        {category && (
          <>
            <Text className={`${css.text} ${type === "template" ? "is-unset" : ""}`}>
              {category.name}
            </Text>
            {!isLimited && <SubscribeButton categoryId={category.id} type={"large"} />}
            <Icon
              className={`${css.chevron} ${unsetTypes.includes(type) ? "is-unset" : ""}`}
              iconName={IconName.ChevronRight}
            />
          </>
        )}
        {renderFileTypeIcon(type, fileExtension)}
        <Text className={`${css.text} ${unsetTypes.includes(type) ? "is-unset" : ""}`}>
          {title}
        </Text>
      </div>
      <Icon
        iconName={IconName.ChromeClose}
        onClick={onTapBack}
        className={`${css.backIcon} ${unsetTypes.includes(type) ? "is-unset" : ""} screen-mobile`}
      />
      {type === "bundle" ? (
        <div className={css.headerRightContainer}>
          {children}
          <DefaultButton
            className={`${css.goBackBtn} screen-pc`}
            onClick={onTapBack}
            text={t(Common.close)}
          />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

function renderFileTypeIcon(type: PlayerType, extension: string) {
  switch (type) {
    case "bundle":
      return "";
    case "tingdoc":
      return <Icon iconName={IconName.TingDoc} styles={{ root: { fontSize: "32px" } }} />;
    case "video":
    case "template":
    case "office":
      return <FileTypeIcon extension={extension} size={32} />;
  }
}
