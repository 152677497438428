import { IPersonaProps, IPersonaSharedProps, Persona } from "@fluentui/react";
import { Group, User } from "@microsoft/microsoft-graph-types";
import React, { useMemo } from "react";
import { useQueryGraph } from "../../hooks/useQuery";
import { Graph_API, PrincipalType } from "../../services/request";

function generateBlobUrl(stream?: string) {
  if (stream) {
    try {
      const url = window.URL || window.webkitURL;
      const blob = new Blob([stream], { type: "image/jpeg" });
      const blobUrl = url.createObjectURL(blob);
      return blobUrl;
    } catch (e) {
      console.log("generateBlobUrl", e);
    }
  }
}
export interface RCPersonaProps extends IPersonaProps {
  principalId?: string;
  principalType?: PrincipalType;
  withPhoto?: boolean;
  principalData?: User | Group;
}

export interface RCGroupPersonaProps extends IPersonaProps {
  groupId?: string;
  withPhoto?: boolean;
  groupData?: Group;
}

export interface RCUserPersonaProps extends IPersonaProps {
  userId?: string;
  withPhoto?: boolean;
  userData?: User;
}

export const RCPersona = ({
  principalId,
  principalType,
  withPhoto = true,
  principalData,
  ...personnaProps
}: RCPersonaProps) => {
  if (principalType === PrincipalType.group) {
    return (
      <RCGroupPersona
        groupId={principalId}
        withPhoto={withPhoto}
        groupData={principalData as Group}
        {...personnaProps}
      ></RCGroupPersona>
    );
  } else if (principalType === PrincipalType.user) {
    return (
      <RCUserPersona
        userId={principalId}
        withPhoto={withPhoto}
        userData={principalData as User}
        {...personnaProps}
      ></RCUserPersona>
    );
  } else {
    return <Persona {...personnaProps}></Persona>;
  }
};

export const RCGroupPersona = ({
  groupId,
  withPhoto = true,
  groupData,
  ...personnaProps
}: RCGroupPersonaProps) => {
  const { data: group } = useQueryGraph(Graph_API.group, {
    enabled: !groupData && !!groupId,
    vars: { groupId: groupId! },
  });
  const { data: groupPhoto } = useQueryGraph(Graph_API.groupPhoto, {
    enabled: !!withPhoto && (!!groupId || !!groupData?.id),
    vars: { groupId: groupId! || groupData?.id! },
    config: {
      retry: false,
    },
  });
  let personaData = {} as IPersonaSharedProps;
  const groupPhotoUrl = generateBlobUrl(groupPhoto);

  if (groupData) {
    personaData = {
      imageUrl: groupPhotoUrl,
      text: groupData.displayName || "",
      secondaryText: groupData.mail || "",
    };
  } else {
    if (group) {
      personaData = {
        imageUrl: groupPhotoUrl,
        text: group.displayName || "",
        secondaryText: group.mail || "",
      };
    }
  }
  return <Persona {...personaData} {...personnaProps}></Persona>;
};

export const RCUserPersona = ({
  userId,
  withPhoto = true,
  userData,
  ...personnaProps
}: RCUserPersonaProps) => {
  const { data: user } = useQueryGraph(Graph_API.user, {
    enabled: !userData && !!userId,
    vars: { userId: userId! },
  });
  const { data: userPhoto } = useQueryGraph(Graph_API.userPhoto, {
    vars: { userId: userId! || userData?.id! },
    responseType: "arraybuffer",
    enabled: !!withPhoto && (!!userId || !!userData?.id),
    config: {
      retry: false,
    },
  });
  const userPhotoUrl = useMemo(() => generateBlobUrl(userPhoto), [userPhoto]);
  const userInfo = userData || user;
  return (
    <Persona
      imageUrl={userPhotoUrl}
      text={userInfo?.displayName || ""}
      secondaryText={userInfo?.mail || userInfo?.userPrincipalName || ""}
      {...personnaProps}
    ></Persona>
  );
};

export function createPersonasFromUsers(users?: User[]): RCPersonaProps[] {
  if (users && Array.isArray(users)) {
    return users.map(user => {
      const persona: RCPersonaProps = {
        text: user.displayName || user.userPrincipalName || "",
        secondaryText: user.mail || user.userPrincipalName || "",
        id: user.id,
        principalId: user.id ?? "",
        coinSize: 30,
        principalType: PrincipalType.user,
        principalData: user,
      };
      user.surname &&
        user.givenName &&
        (persona.imageInitials = `${user.givenName[0]}${user.surname[0]}`);
      return persona;
    });
  }
  return [];
}

export function createPersonasFromGroups(groups?: Group[]): RCPersonaProps[] {
  if (groups && Array.isArray(groups)) {
    return groups.map(group => {
      const persona: RCPersonaProps = {
        text: group.displayName || "",
        secondaryText: group.mail || "",
        id: group.id,
        principalId: group.id ?? "",
        coinSize: 30,
        principalType: PrincipalType.group,
        principalData: group,
      };
      return persona;
    });
  }
  return [];
}
