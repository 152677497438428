import React from "react";
import { useTranslation } from "react-i18next";
import { MessageBar } from "@fluentui/react";
import { CopyMessageBarI18n } from "../../locales";

function CopyMessageBar(props: React.PropsWithChildren<{ hidden?: boolean }>) {
  const { t } = useTranslation();
  return (
    <MessageBar
      styles={({ theme }) => ({
        root: {
          backgroundColor: theme.palette.neutralSecondary,
          position: "fixed",
          left: 0,
          top: 0,
          // height: rem(12 * 3),
          width: "100%",
          transition: "opacity 0.5s",
          opacity: props.hidden ? 0 : 1,
        },
        content: {
          height: "100%",
          selectors: {
            "div:first-child": {
              display: "none",
            },
          },
        },
        text: {
          color: theme.palette.white,
          justifyContent: "center",
          alignItems: "center",
        },
      })}
    >
      {t(CopyMessageBarI18n.message)}
    </MessageBar>
  );
}
export default CopyMessageBar;
