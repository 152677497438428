import { IDialogContentStyles, IDialogFooterStyles } from "@fluentui/react/lib/Dialog";
import { FontWeights, IDialogStyles, IRawStyle, ITheme } from "@fluentui/react";
import { FontSizes } from "../fonts";
import { rem } from "../../lib/unit";

export function getDialogContentStyles(
  themeName: string,
  theme: ITheme
): Partial<IDialogContentStyles> {
  const styles = {
    title: {
      fontSize: FontSizes.large,
      fontWeight: FontWeights.bold,
      padding: "20px 54px 15px 32px",
      lineHeight: FontSizes.xLargePlus,
      color: theme.palette.neutralDark,
    },
    topButton: {
      padding: "16px 24px 4px 0",
      fontSize: FontSizes.medium,
      selectors: {
        ".ms-Dialog-button": {
          color: theme.palette.neutralDark,
        },
        ".ms-Dialog-button:hover": {
          color: theme.palette.themePrimary,
          selectors: {
            i: {
              fontWeight: FontWeights.bold,
            },
          },
        },
        ".ms-Dialog-button:active": {
          color: theme.palette.themePrimary,
        },
      },
    },
    inner: {
      height: rem(500),
      padding: "1px 32px 32px",
      color: theme.palette.neutralDark,
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      selectors: {
        ".ms-Label": {
          color: theme.palette.neutralDark,
        },
      },
    },
    innerContent: {
      paddingTop: "4px",
      marginBottom: "20px",
    },
    subText: {
      color: theme.palette.neutralDark,
      fontWeight: FontWeights.regular,
      fontSize: FontSizes.medium,
      lineHeight: FontSizes.large,
      paddingTop: 0,
      marginBottom: "10px",
    },
  } as Partial<IDialogContentStyles>;

  // Contrast theme overrides
  if (themeName === "contrast") {
    ((styles.topButton as any).selectors[".ms-Dialog-button:hover"] as IRawStyle).color =
      theme.palette.themeDark;
    ((styles.topButton as any).selectors[".ms-Dialog-button:active"] as IRawStyle).color =
      theme.palette.themeDark;

    (styles.inner as IRawStyle).color = theme.palette.black;
    ((styles.inner as any).selectors[".ms-Label"] as IRawStyle).color = theme.palette.black;
  }
  return styles;
}

export function getDialogFooterStyles(
  _themeName: string,
  _theme: ITheme
): Partial<IDialogFooterStyles> {
  const styles = {
    actions: {
      margin: "16px 0 0",
    },
  } as Partial<IDialogFooterStyles>;

  return styles;
}

export function getDialogStyles(_themeName: string, _theme: ITheme): Partial<IDialogStyles> {
  const styles = {
    main: {
      "@media (min-width: 480px)": {
        maxWidth: `${rem(600)}`,
        width: `${rem(600)}`,
        maxHeight: `${rem(610)}`,
      },
    },
  } as Partial<IDialogStyles>;
  return styles;
}
