import { DirectionalHint, TeachingBubble, Text, makeStyles } from "@fluentui/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MePageI18n } from "../../locales";
import { rem } from "../../lib/unit";

const useStyles = makeStyles(() => ({
  textColor: { color: "inherit" },
  text: {
    paddingTop: rem(12),
    whiteSpace: "pre-wrap",
  },
}));

export default function UserCreationCenterBubble({
  target,
  onDismiss,
}: {
  target: string | Element;
  onDismiss?: () => void;
}): React.ReactElement {
  const { t } = useTranslation();
  const styles = useStyles();

  const dismiss = useRef(onDismiss);
  if (dismiss.current !== onDismiss) dismiss.current = onDismiss;
  useEffect(() => () => dismiss.current && dismiss.current(), []);

  return (
    <TeachingBubble
      target={target}
      onDismiss={onDismiss}
      calloutProps={{
        directionalHint: DirectionalHint.leftCenter,
        preventDismissOnLostFocus: true,
        directionalHintFixed: true,
      }}
      secondaryButtonProps={{
        text: t(MePageI18n.tipButton),
        onClick: onDismiss,
      }}
    >
      <Text className={styles.textColor} variant="large">
        {t(MePageI18n.tipTitle)}
      </Text>
      <Text block className={`${styles.textColor} ${styles.text}`} variant="small">
        {t(MePageI18n.tipContent)}
      </Text>
    </TeachingBubble>
  );
}
