import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { customizeTime } from "../lib/date";

/**
 *
 * @param date
 */
export function useCustomizeTime(date?: Date | string | number) {
  const { i18n } = useTranslation();
  return date ? customizeTime(date, i18n.language) : "";
}

export function useCustomizeTimeCallback() {
  const { i18n } = useTranslation();
  return useCallback(
    (date?: Date | string | number) => (date ? customizeTime(date, i18n.language) : ""),
    [i18n.language]
  );
}
