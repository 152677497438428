import { useRef, useEffect, useCallback, useState } from "react";

export function useFileInput(callback: (files: FileList) => void, accept = "*", multiple = false) {
  const input = useRef<HTMLInputElement>();
  const [files, setFiles] = useState<FileList | null>();

  useEffect(() => {
    if (!input.current) {
      input.current = document.createElement("input");
      input.current.type = "file";
    }
    input.current.accept = accept;
    input.current.multiple = multiple;
  }, [accept, multiple]);

  useEffect(() => {
    if (input.current) {
      input.current.onchange = (ev: Event) => {
        const files = (ev.target as any)?.files;
        setFiles(files);
        callback?.(files);
      };
    }
  }, [callback]);

  return {
    files,
    inputRef: input,
    openPicker: useCallback(() => {
      if (input.current) {
        input.current.value = "";
        input.current?.click();
      }
    }, []),
  };
}
