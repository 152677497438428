import { mergeStyles } from "@fluentui/react";
import { useEffect } from "react";

const overFlowHiddenClass = mergeStyles({ overflow: "hidden" });
const touchMoveEvent = (e: Event) => {
  e.preventDefault();
};
export function useTouchMove(sign: boolean) {
  useEffect(() => {
    if (sign) {
      document.body.classList.add(overFlowHiddenClass);
      document.addEventListener("touchmove", touchMoveEvent, { passive: false });
      return () => {
        document.body.classList.remove(overFlowHiddenClass);
        document.removeEventListener("touchmove", touchMoveEvent);
      };
    }
  }, [sign]);
}
