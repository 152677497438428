import React from "react";
import AssignmentCenter from "../../components/rc/AssignmentCenter";
import BundlesHub from "../../components/rc/BundlesHub";
import CategoryContent from "../../components/rc/CategoryContent";
import ContentHub from "../../components/rc/ContentHub";
import TemplateHub from "../../components/rc/TemplateHub";
import { HASHType, ResourceScope } from "../../config/rc";

export default function Content({
  contentHashId,
  onCategoryError,
  pushNotification,
  isAdmin,
}: {
  isAdmin?: boolean;
  contentHashId: string;
  pushNotification?: (id: string) => void;
  onCategoryError: () => void;
}) {
  if (!contentHashId) {
    return null;
  }
  const lastSlashIndex = contentHashId.lastIndexOf("/");
  const hashType = contentHashId.substring(0, lastSlashIndex);
  switch (hashType) {
    case HASHType.myTenantContent:
      return <ContentHub />;
    case HASHType.feedsCategory:
      const feedsCategoryId = contentHashId.substring(lastSlashIndex + 1);
      return (
        <CategoryContent
          isAdmin={isAdmin}
          scope={ResourceScope.feeds}
          categoryId={feedsCategoryId}
          onError={onCategoryError}
        ></CategoryContent>
      );
    case HASHType.myTenantCategory:
      const mytenantCategoryId = contentHashId.substring(lastSlashIndex + 1);
      return (
        <CategoryContent
          isAdmin={isAdmin}
          pushNotification={pushNotification}
          scope={ResourceScope.mytenant}
          categoryId={mytenantCategoryId}
          onError={onCategoryError}
        ></CategoryContent>
      );
    case HASHType.myTenantTemplates:
      return <TemplateHub isAdmin={isAdmin} />;
    case HASHType.myTenantBundles:
      return <BundlesHub />;
    case HASHType.adminAssignment:
      return <AssignmentCenter />;
    case HASHType.myContent:
      return <ContentHub isAdmin={false} />;
    default:
      throw new Error(`unkown hash type ${hashType}`);
  }
}
