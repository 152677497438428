import {
  DefaultButton,
  Dialog,
  DialogFooter,
  makeStyles,
  Spinner,
  Text,
  Image,
} from "@fluentui/react";
import { useEffect, useState, useCallback } from "react";
import { PagePath } from "../../config/routes";
import { setFileToken } from "../../hooks/useFileToken";
import { useQueryWeb } from "../../hooks/useQuery";
import { navigateTo } from "../../services/history";
import {
  DocInfo,
  FilePreviewTaskStatus,
  FileTokenInfo,
  requestWeb,
  uploadBlob,
  WEB_API,
} from "../../services/request";
import { rem } from "../../lib/unit";
import { useTranslation } from "react-i18next";
import { TingDocLoadingDialogI18n } from "../../locales";
import loadingErrorImg from "../../img/teams/loadingerror.png";
import { FontSizes } from "../../themes/fonts";
import { logger } from "../../services/logger";
import { getEditableTingDoc } from "../../services/tingDoc";
import { useCancelToken } from "../../hooks/useCancelToken";
import { setStorage } from "../../services/storage";

export interface TingDocLoadingDialogProps {
  isAdmin?: boolean;
  hidden?: boolean;
  docInfo: DocInfo;
  onDismiss?: () => void;
}

const useDialogStyle = makeStyles(() => ({
  innerContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  actionsRight: {
    justifyContent: "center",
    display: "flex",
  },
  img: {
    width: rem(200),
    height: rem(200),
  },
  text: {
    fontSize: FontSizes.medium,
    textAlign: "center",
    maxWidth: rem(500),
    "&.is-error": {
      paddingTop: rem(40),
    },
    "&.is-processing": {
      position: "absolute",
      bottom: rem(50),
    },
  },
  spinner: {
    marginTop: rem(40),
    marginBottom: rem(40),
  },
}));

export default function TingDocLoadingDialog({
  isAdmin = true,
  hidden,
  docInfo,
  onDismiss,
}: TingDocLoadingDialogProps) {
  const style = useDialogStyle();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [sessionId, setSessionId] = useState<string>();
  const fileOrigin = docInfo.driveId ? "onedrive" : "local";

  useQueryWeb(isAdmin ? WEB_API.queryTingDocSessionStatus : WEB_API.queryMyTingDocSessionStatus, {
    vars: { sessionId: sessionId! },
    config: {
      enabled: !!sessionId && !hasError && !hidden,
      refetchInterval: 2000,
      onSuccess: async data => {
        if (sessionId) {
          if (data?.previewTask?.status === FilePreviewTaskStatus.Finished) {
            logger?.telemetry("LoadTingDoc", {
              SessionId: sessionId,
              ActionSucceed: true,
              FileOrigin: fileOrigin,
            });
            const doc = getEditableTingDoc(data, docInfo?.name ?? "", docInfo?.cover);
            const coverUrl = doc.metadata.cover;
            // thumbnail need upload
            if (coverUrl && coverUrl.toLowerCase().includes("thumbnail")) {
              const blobUrl = await uploadCoverImage(coverUrl, data.sasToken);
              if (blobUrl) {
                doc.metadata.cover = blobUrl;
              }
            }
            if (doc) {
              setFileToken(sessionId, { ...data.sasToken, writable: true });
              setStorage("editDocAutoSave", doc.toDocument(), isAdmin ? "admin" : "me");
              window.parent.postMessage("tingDocLoadingEnd", "*"); // Viva-Engage嵌入页面功能
              navigateTo(PagePath.edit, { state: { isAdmin } });
            }
          } else if (
            data?.previewTask?.status === FilePreviewTaskStatus.Canceled ||
            data?.previewTask?.status === FilePreviewTaskStatus.Failed ||
            data?.previewTask?.status === FilePreviewTaskStatus.Timeout
          ) {
            logger?.telemetry("LoadTingDoc", {
              SessionId: sessionId,
              ActionSucceed: false,
              FileOrigin: fileOrigin,
            });
            setHasError(true);
          }
        }
      },
      onError: () => {
        logger?.telemetry("LoadTingDoc", {
          SessionId: sessionId!,
          ActionSucceed: false,
          FileOrigin: fileOrigin,
        });
        setHasError(true);
      },
    },
  });

  const { token: cancelToken } = useCancelToken();
  const initSession = useCallback(() => {
    const downloadUrl = docInfo.downloadUrl;
    setSessionId(undefined);
    setHasError(false);
    downloadUrl &&
      requestWeb(isAdmin ? WEB_API.initCreateSession : WEB_API.initMyCreateSession, {
        method: "POST",
        cancelToken,
        data: { fileUrl: downloadUrl, expands: ["Notes", "Audios"] },
      })
        .then(res => {
          setSessionId(res.data.sessionId);
        })
        .catch(() => {
          setHasError(true);
        });
  }, [cancelToken, docInfo.downloadUrl, isAdmin]);
  useEffect(() => initSession(), [initSession]);

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{
        title: t(TingDocLoadingDialogI18n.title, { context: docInfo.driveId ? "" : "local" }),
        showCloseButton: true,
        styles: { innerContent: style.innerContent },
      }}
      isBlocking={true}
      onDismiss={onDismiss}
    >
      {hasError ? (
        <>
          <Image src={loadingErrorImg} className={style.img} />
          <Text className={`${style.text} is-error`}>{t(TingDocLoadingDialogI18n.errorTips)}</Text>
        </>
      ) : (
        <>
          <Text className={style.text}>{`${docInfo?.name}.${docInfo?.ext}`}</Text>
          <Spinner className={style.spinner} />
          <Text className={`${style.text}`}>{t(TingDocLoadingDialogI18n.processingStatus)}</Text>
          <Text className={`${style.text} is-processing`}>
            {t(TingDocLoadingDialogI18n.processingTips)}
          </Text>
        </>
      )}
      <DialogFooter styles={{ actionsRight: style.actionsRight }}>
        <DefaultButton
          style={{ visibility: `${hasError ? "visible" : "hidden"}` }}
          text={t(TingDocLoadingDialogI18n.tryAgain)}
          onClick={initSession}
        />
      </DialogFooter>
    </Dialog>
  );
}

async function uploadCoverImage(coverUrl: string, token: FileTokenInfo): Promise<string> {
  try {
    const blob = await fetch(coverUrl, { mode: "cors" }).then(res => res.blob());
    const res = await uploadBlob(blob, {
      token: { token: token.token!, baseURL: token.baseUrl },
    });
    return Promise.resolve(res.file);
  } catch (err) {
    return Promise.reject(err);
  }
}
