import React from "react";
import { mergeStyles, Shimmer } from "@fluentui/react";

import Css from "./BundleDetailCard.module.scss";
import { isMobliePhone } from "../../config/browser";
import ShimmerImage from "../common/ShimmerImage";

export interface BundleDetailCardProps {
  title?: string;
  cover?: string;
  description?: string;
}

export default function BundleDetailCard({ title, description, cover }: BundleDetailCardProps) {
  return (
    <div
      className={mergeStyles(Css.Card, {
        width: "100%",
      })}
    >
      <ShimmerImage
        className={mergeStyles(Css.Cover, { " img": { objectFit: "cover" } })}
        src={cover}
        loading="eager"
        alt="cover"
      />
      <Shimmer
        isDataLoaded={title !== undefined}
        styles={{ shimmerWrapper: Css.Title, dataWrapper: Css.Title }}
        className={Css.Title}
        width={isMobliePhone ? "80%" : "40%"}
      >
        {title}
      </Shimmer>
      <Shimmer
        isDataLoaded={description !== undefined}
        styles={{ shimmerWrapper: { height: "1em" }, dataWrapper: Css.Desc }}
        className={Css.Desc}
      >
        {description}
      </Shimmer>
    </div>
  );
}
