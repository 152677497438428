import { CommandBar, DirectionalHint, ICommandBarItemProps, TeachingBubble } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { useStorageValue } from "../../hooks/useStorageValue";
import {
  AdministratorGuide,
  CategoryActionI18n,
  ContentHubNS,
  NotificationTutor,
} from "../../locales";
import { logger } from "../../services/logger";

export interface TemplateHubBarProps {
  onClickNotification?: () => void;
  onClickUpload?: () => void;
  showNotificationBubble?: boolean;
}
const NotificationButtonId = "TemplateHubBar-Notification";
const CreateTemplateButtonId = "TemplateHubBar-Creation";
export default function TemplateHubBar({
  onClickNotification,
  onClickUpload,
  showNotificationBubble,
}: TemplateHubBarProps) {
  const { t } = useTranslation();
  const [notificationFlag, setNotificationFlag] = useStorageValue(
    "flag",
    "TemplateHubNotificationShow"
  );
  const [creationFlag, setCreationFlag] = useStorageValue("flag", "TemplateHubCreationShow");
  const items = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        id: CreateTemplateButtonId,
        key: "upload",
        text: t(ContentHubNS.uploadTemplate),
        cacheKey: "createNew", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Upload },
        onClick: () => {
          logger?.telemetry("ClickCreateTemplateBtn");
          setCreationFlag(true);
          onClickUpload?.();
        },
      },
      {
        id: NotificationButtonId,
        key: "Notification",
        text: t(CategoryActionI18n.notify),
        cacheKey: "Notification", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Ringer },
        onClick: () => {
          setNotificationFlag(true);
          onClickNotification?.();
        },
      },
    ],
    [t, setCreationFlag, onClickUpload, setNotificationFlag, onClickNotification]
  );
  return (
    <>
      <CommandBar items={items} />
      {notificationFlag
        ? null
        : showNotificationBubble && (
            <TeachingBubble target={`#${NotificationButtonId}`}>
              {t(NotificationTutor.btnTips)}
            </TeachingBubble>
          )}
      {creationFlag ? null : (
        <TeachingBubble
          target={`#${CreateTemplateButtonId}`}
          calloutProps={{
            directionalHint: DirectionalHint.rightCenter,
            preventDismissOnLostFocus: true,
          }}
          // styles={styles}
          headline={t(AdministratorGuide.templateTitle)}
        >
          {t(AdministratorGuide.templateCreationTip)}
        </TeachingBubble>
      )}
    </>
  );
}
