import React, { useMemo } from "react";
import {
  Dropdown,
  FontWeights,
  Icon,
  IDropdownOption,
  makeStyles,
  Stack,
  Text,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";

import { rem } from "../../lib/unit";
import { SettingsI18n } from "../../locales";

import { IconName } from "../../config/icons";
import { FeedsLanguage } from "../../services/storage";

const useFeedsHeadStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    height: rem(42),
    position: "relative",
  },
  icon: {
    boxSizing: "border-box",
    width: rem(42),
    paddingLeft: rem(10),
  },
  dropdown: {
    width: "100%",
    flexGrow: 1,
  },
  title: [
    {
      fontWeight: FontWeights.bold,
      paddingRight: rem(25),
    },
    "ellipsis-line1",
  ],
});

const useDropdownStyles = makeStyles(theme => ({
  callout: {
    left: "0 !important",
  },
  title: {
    padding: 0,
  },
  dropdown: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    selectors: {
      ".ms-Dropdown-title": {
        backgroundColor: "transparent",
      },
      ":focus .ms-Dropdown-title": {
        border: 0,
      },
      ":hover .ms-Dropdown-title": {
        backgroundColor: theme.palette.neutralLight,
      },
    },
  },
  dropdownItem: {
    paddingLeft: rem(40),
  },
  dropdownItemSelected: {
    paddingLeft: rem(40),
  },
}));

export interface FeedsHeaderProps {
  isAdmin?: boolean;
  feedsLanguage?: FeedsLanguage;
  onFeedsLanguageChange?: (option?: IDropdownOption) => void;
}

export default function FeedsHeader({
  isAdmin,
  onFeedsLanguageChange,
  feedsLanguage,
}: FeedsHeaderProps) {
  const feedsHeadStyles = useFeedsHeadStyles();
  const dropdownStyles = useDropdownStyles();
  const { t } = useTranslation();

  const onBuiltInLanguageChange = (e: any, option?: IDropdownOption) => {
    onFeedsLanguageChange && onFeedsLanguageChange(option);
  };

  const onRenderTitle = (props?: IDropdownOption[]) => (
    <Stack horizontal verticalAlign="center">
      <Icon iconName={IconName.HomeGroup} className={feedsHeadStyles.icon}></Icon>
      <Text className={feedsHeadStyles.title}>{props![0].text}</Text>
    </Stack>
  );

  const feedsLngSettingOptions = useMemo(
    () => [
      {
        key: FeedsLanguage.zhCN,
        text: t(SettingsI18n.resourcelanguageOption, { context: FeedsLanguage.zhCN }),
      },
      {
        key: FeedsLanguage.zhTW,
        text: t(SettingsI18n.resourcelanguageOption, { context: FeedsLanguage.zhTW }),
      },
      {
        key: FeedsLanguage.zhHK,
        text: t(SettingsI18n.resourcelanguageOption, { context: FeedsLanguage.zhHK }),
      },
      {
        key: FeedsLanguage.en,
        text: t(SettingsI18n.resourcelanguageOption, { context: FeedsLanguage.en }),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={feedsHeadStyles.container}>
      {isAdmin ? (
        <Dropdown
          className={feedsHeadStyles.dropdown}
          options={feedsLngSettingOptions}
          selectedKey={feedsLanguage}
          onChange={onBuiltInLanguageChange}
          onRenderTitle={onRenderTitle}
          styles={dropdownStyles}
        ></Dropdown>
      ) : (
        <>
          <Icon iconName={IconName.HomeGroup} className={feedsHeadStyles.icon}></Icon>
          <Text className={feedsHeadStyles.title}>
            {feedsLngSettingOptions.find(item => item.key === feedsLanguage)?.text}
          </Text>
        </>
      )}
    </div>
  );
}
