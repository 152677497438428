import {
  Dropdown,
  DropdownMenuItemType,
  IDropdown,
  IDropdownOption,
  IDropdownStyleProps,
} from "@fluentui/react";
import { createRef, useCallback, useEffect, useMemo } from "react";
import type { Catalog } from "../../services/request";

const dropdownRef = createRef<IDropdown>();
function dropdownStyle({ theme }: IDropdownStyleProps) {
  return {
    dropdownItemHeader: {
      color: theme?.palette.neutralPrimary,
    },
  };
}
export default function CategoryPicker({
  catalog,
  selectedId,
  shouldFocus,
  placeholder,
  onSelect,
}: {
  catalog: Catalog;
  placeholder?: string;
  selectedId?: string;
  shouldFocus?: boolean;
  onSelect?: (selectedId: string) => void;
}) {
  useEffect(() => dropdownRef.current?.focus(shouldFocus), [shouldFocus]);
  const options = useMemo(
    () =>
      catalog.catalogNodesDetail
        .map(
          d =>
            d.childrenDetail?.reduce<IDropdownOption[]>(
              (p, c) => {
                p.push({ key: c.treeNode.id, text: `　${c.treeNode.title}` });
                return p;
              },
              [
                {
                  key: `${d.treeNode.id}-divider`,
                  text: "-",
                  itemType: DropdownMenuItemType.Divider,
                },
                {
                  key: d.treeNode.id,
                  text: d.treeNode.title,
                  itemType: DropdownMenuItemType.Header,
                },
              ]
            ) || []
        )
        .flat(1),
    [catalog.catalogNodesDetail]
  );

  const onChange = useCallback(
    (_, o?: IDropdownOption) => onSelect?.(o?.key as string),
    [onSelect]
  );
  return (
    <Dropdown
      componentRef={dropdownRef}
      // label="Select"
      placeholder={placeholder}
      selectedKey={selectedId}
      onChange={onChange}
      options={options}
      styles={dropdownStyle}
      // required
    />
  );
}
