import { FontSizes, ISettings, ITheme } from "@fluentui/react";
import { getBreadcrumbStyles } from "./teams/Breadcrumb";
import { getCalloutContentStyles } from "./teams/Callout";
import { getCheckboxStyles } from "./teams/Checkbox";
import { getCommandBarButtonStyles, getCommandBarStyles } from "./teams/CommandBar";
import { ContextualMenuStyles } from "./teams/ContextualMenu";
import { getDefaultButtonStyles } from "./teams/DefaultButton";
import { getDetailsListStyles, getDetailsRowStyles } from "./teams/DetailsList";
import { getDialogContentStyles, getDialogFooterStyles, getDialogStyles } from "./teams/Dialog";
import { getDropdownStyles } from "./teams/DropdownStyles";
import { getLabelStyles } from "./teams/Label";
import { getModalStyles } from "./teams/Modal";
import { getNavStyles } from "./teams/Nav";
import { getPeopePickerStyles } from "./teams/NormalPeoplePicker";
import { getPersonaCoinStyles } from "./teams/PersonaCoin";
import { getPivotStyles } from "./teams/Pivot";
import { getPrimaryButtonStyles } from "./teams/PrimaryButton";
import { teachingBubbleStyles } from "./teams/TeachingBubble";
import { getTextFieldStyles } from "./teams/TextField";
import { ThemeType } from "./teamsTheme";

export function getTeamStyles(themeName: string, theme: ITheme, isMobile: boolean) {
  // console.log(theme);
  // console.log(isMobile);
  let themeDisplayName: "default" | "dark" | "contrast";
  switch (themeName) {
    case ThemeType.default:
    case ThemeType.defaultV2:
      themeDisplayName = "default";
      break;
    case ThemeType.dark:
    case ThemeType.darkV2:
      themeDisplayName = "dark";
      break;
    case ThemeType.contrast:
    case ThemeType.contrastV2:
      themeDisplayName = "contrast";
      break;
    default:
      themeDisplayName = "default";
  }
  return {
    Nav: {
      styles: getNavStyles(themeDisplayName),
    },
    Breadcrumb: {
      styles: getBreadcrumbStyles(themeDisplayName),
    },
    CalloutContent: {
      styles: getCalloutContentStyles(themeDisplayName, theme),
    },
    CommandBar: {
      styles: getCommandBarStyles(themeDisplayName),
    },
    CommandBarButton: {
      styles: getCommandBarButtonStyles(themeDisplayName),
    },
    Pivot: {
      styles: getPivotStyles(themeDisplayName),
    },
    ContextualMenu: {
      styles: ContextualMenuStyles,
    },
    TextField: {
      styles: getTextFieldStyles(themeDisplayName, theme),
    },
    Dropdown: {
      styles: getDropdownStyles(themeDisplayName, theme),
    },
    NormalPeoplePicker: {
      styles: getPeopePickerStyles(themeDisplayName, theme),
    },
    Modal: {
      styles: getModalStyles(themeDisplayName, theme),
    },
    Dialog: {
      styles: getDialogStyles(themeDisplayName, theme),
    },
    DialogContent: {
      styles: getDialogContentStyles(themeDisplayName, theme),
    },
    DialogFooter: {
      styles: getDialogFooterStyles(themeDisplayName, theme),
    },
    PersonaCoin: {
      styles: getPersonaCoinStyles(themeDisplayName, theme),
    },
    Icon: {
      styles: { root: { display: "flex" } },
    },
    Button: {
      tokens: (props: ISettings) => [
        {
          iconSize: FontSizes.icon,
          iconWeight: 700,
          textWeight: 400,
        },
        !props.circular && {
          borderRadius: 3,
          borderWidth: 2,
          contentPadding: "4px 32px",
        },
        props.circular && {
          borderWidth: 1,
        },
        !props.disabled && {
          iconColor: "#252424", // this hardcoding doesn't work well with variants
          borderColorHovered: "transparent",
          borderColorPressed: "transparent",
        },
        props.expanded && {
          borderColor: "transparent",
        },
        props.circular &&
          !props.disabled && {
            backgroundColorHovered: "#464775", // this hardcoding doesn't work well with variants
            backgroundColorPressed: "#464775", // this hardcoding doesn't work well with variants
            textColorHovered: "#fff",
            textColorPressed: "#fff",
            iconColorHovered: "#fff",
            iconColorPressed: "#fff",
          },
        // props.primary &&
        //   !props.disabled && {
        //     iconColor: "white",
        //   },
      ],
    },
    DefaultButton: {
      styles: getDefaultButtonStyles(themeName, theme),
    },
    PrimaryButton: {
      styles: getPrimaryButtonStyles(themeName, theme),
    },
    IconButton: {
      styles: {
        root: { color: "inherit", "-webkit-tap-highlight-color": "transparent" },
        rootHovered: { backgroundColor: "transparent" },
        icon: {
          display: "flex",
          color: "inherit",
          fontSize: "inherit",
          lineHeight: "1em",
          height: "1em",
          margin: "auto 0.5em",
        },
        iconHovered: { color: theme.palette.themePrimary },
        menuIcon: {
          color: "inherit",
          fontSize: "inherit",
          lineHeight: "1em",
          height: "1em",
        },
      },
    },
    ActionButton: {
      styles: {
        root: {
          "-webkit-tap-highlight-color": "transparent",
        },
        icon: {
          display: "flex",
          color: "inherit",
          fontSize: "inherit",
          height: "1em",
          width: "1em",
          lineHeight: "1em",
          alignItems: "center",
        },
      },
    },
    DetailsRow: {
      styles: getDetailsRowStyles(themeDisplayName),
    },
    DetailsList: {
      styles: getDetailsListStyles(themeDisplayName),
    },
    Label: {
      styles: getLabelStyles(themeDisplayName),
    },
    Checkbox: {
      styles: getCheckboxStyles(themeDisplayName, theme),
    },
    TeachingBubble: {
      styles: teachingBubbleStyles,
    },
  };
}
