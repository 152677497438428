import React, { useCallback, useEffect, useRef } from "react";
import {
  IContextualMenuItem,
  Nav,
  IDropdownOption,
  Separator,
  makeStyles,
  ActionButton,
  INavStyles,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { generateCategoryHash, ResourceScope } from "../../config/rc";
import { NavLink, RCTreeNode } from "../../hooks/rc/useNavLinks";
import { RCNavI18n } from "../../locales";
import { IconName } from "../../config/icons";
import { CatalogNode } from "../../services/request";
import FeedsHeader from "./FeedsHeader";
import { FeedsLanguage } from "../../services/storage";
import { rem } from "../../lib/unit";
import RCTree from "./tree/RCTree";
import { TreeName } from "../../config/rc";
import { useStorageValue } from "../../hooks/useStorageValue";
import { getStorage, setStorage } from "../../services/storage";
import { PageName } from "../../config/routes";
import { isLimited } from "../../config/app";

function generateNavLinkFromDataNode(node: RCTreeNode, scope: ResourceScope) {
  return {
    key: `${node?.key}`,
    name: `${node?.title}`,
    url: generateCategoryHash(node?.key, scope),
  };
}

function convertRCTreeNodeToCatalogNode(dataNodes: RCTreeNode[]): CatalogNode[] {
  return dataNodes
    ? dataNodes
        .filter(n => !n.isPlaceHolder)
        .map(item => ({
          id: item.key,
          nodeETag: item.nodeETag,
          children: item.children
            ? item.children.filter(i => !i.isPlaceHolder).map(item => item.key)
            : ([] as string[]),
        }))
    : [];
}

function mergeCatalogNodes(o?: CatalogNode[], n?: CatalogNode[]) {
  let tempArray: CatalogNode[] = [];
  if (o) {
    tempArray = [...o];
  }
  if (n) {
    tempArray = [...n, ...tempArray];
  }
  const tempObj: { [key: string]: CatalogNode } = {};
  tempArray.forEach(item => {
    if (item.id in tempObj) {
      return;
    } else {
      tempObj[item.id] = item;
    }
  });
  return Object.keys(tempObj).map(i => tempObj[i]);
}

const useSepratorStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0",
    "::before": {
      background: "transparent",
    },
  },
  content: {
    background: "transparent",
    height: "1px",
    width: `calc(100% - ${rem(44)})`,
    borderBottom: `1px solid ${theme?.palette.neutralQuaternaryAlt}`,
  },
}));

const useAdminNavStyles = makeStyles<Partial<INavStyles>>(theme => ({
  link: {
    paddingLeft: "4px",
  },
  linkText: {
    marginLeft: "10px",
  },
  chevronButton: {
    display: "none",
  },
  navItems: {
    "& &": {
      // 嵌套
      paddingLeft: "20px",
    },
  },
}));

const useTemplateNavStyles = makeStyles(theme => ({
  link: {
    paddingLeft: "8px",
  },
  linkText: {
    marginLeft: "50px",
  },
  groupContent: {
    margin: 0,
  },
}));

const useTemplateActionButtonStyles = makeStyles(theme => {
  const style = {
    color: theme.palette.neutralDark,
  };
  return {
    root: style,
    rootHovered: style,
    rootPressed: style,
    rootExpanded: style,
    rootFocused: style,
    iconChecked: style,
    iconHovered: style,
    iconPressed: style,
  };
});

const useStyles = makeStyles({
  root: { paddingLeft: rem(16) },
  navContainer: { height: `calc(100% - ${rem(250)})`, overflowY: "auto" },
  adminFooter: { height: rem(232) },
});

export const companyTemplateClassName = "company-template";

export default function RCNav({
  pageName,
  className,
  feedsTree,
  feedsActions,
  myTenantTree,
  myTenantActions,
  selectedKey,
  isAdmin,
  showTemplateHubNav,
  adminNavLinks,
  templateNavLinks,
  onLinkClick,
  onMyTenantRCTreeDrop,
  feedsLanguage,
  onFeedsLanguageChange,
  onScroll,
}: {
  pageName?: PageName;
  className?: string;
  feedsTree?: RCTreeNode;
  feedsActions?: IContextualMenuItem[];
  myTenantTree?: RCTreeNode;
  myTenantActions?: IContextualMenuItem[];
  selectedKey?: string;
  isAdmin?: boolean;
  showTemplateHubNav?: boolean;
  adminNavLinks?: NavLink[];
  templateNavLinks?: NavLink[];
  onLinkClick?: (link: NavLink) => void;
  onMyTenantRCTreeDrop?: (catalogNodes: CatalogNode[]) => void;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  feedsLanguage?: FeedsLanguage;
  onFeedsLanguageChange?: (option?: IDropdownOption) => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<number>();
  const scrollTopName = `${pageName}ScrollTop`;
  useEffect(() => {
    const scrollTop = getStorage("scrollNum", scrollTopName);
    scrollTop && containerRef.current?.scrollTo({ top: scrollTop });
    const setScrollTopUnload = () => setStorage("scrollNum", scrollRef.current || 0, scrollTopName);
    window.addEventListener("unload", setScrollTopUnload);
    return () => {
      setScrollTopUnload();
      window.removeEventListener("unload", setScrollTopUnload);
    };
  }, [scrollTopName]);

  const { t } = useTranslation();
  const dropActionNodes = useRef<CatalogNode[]>();
  const onSelectFeedsRCTreeNode = useCallback(
    (node: RCTreeNode) => {
      !node?.children &&
        onLinkClick &&
        onLinkClick(generateNavLinkFromDataNode(node, ResourceScope.feeds));
    },
    [onLinkClick]
  );
  const onSelectMyTenantRCTreeNode = useCallback(
    (node: RCTreeNode) => {
      !node?.children &&
        onLinkClick &&
        onLinkClick(generateNavLinkFromDataNode(node, ResourceScope.mytenant));
    },
    [onLinkClick]
  );

  const onDrop = (actionNodes: RCTreeNode[]) => {
    const catalogNodes = convertRCTreeNodeToCatalogNode([...actionNodes]);
    dropActionNodes.current = mergeCatalogNodes(dropActionNodes.current, catalogNodes);
    onMyTenantRCTreeDrop &&
      dropActionNodes.current.length !== 0 &&
      onMyTenantRCTreeDrop([...dropActionNodes.current]);
  };

  const templateActionButtonStyles = useTemplateActionButtonStyles();

  const sepratorStyles = useSepratorStyles();
  const adminNavStyles = useAdminNavStyles();
  const templateNavStyles = useTemplateNavStyles();
  const styles = useStyles();

  const showFeedsSeparator = !!feedsTree?.children?.length && !!myTenantTree?.children?.length;
  const showFeedsHeader = !!feedsTree?.children?.length;

  const navlinkClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: NavLink) => {
      onLinkClick && item && onLinkClick(item);
    },
    [onLinkClick]
  );

  const [myTenantNarrow, setMyTenantNarrow] = useStorageValue(
    "treeNarrowKeys",
    `${pageName ?? ""}${TreeName.myTenantTree}`
  );

  const [feedsNarrow, setFeedsNarrow] = useStorageValue(
    "treeNarrowKeys",
    `${pageName ?? ""}${TreeName.feedsTree}`
  );

  return (
    <div
      className={`${className} ${styles.root}`}
      onScroll={e => {
        if (!isAdmin) {
          scrollRef.current = e.currentTarget.scrollTop;
          onScroll && onScroll(e);
        }
      }}
      ref={isAdmin ? undefined : containerRef}
    >
      <div
        className={isAdmin ? styles.navContainer : ""}
        onScroll={e => {
          if (isAdmin) {
            scrollRef.current = e.currentTarget.scrollTop;
            onScroll && onScroll(e);
          }
        }}
        ref={isAdmin ? containerRef : undefined}
      >
        <RCTree
          draggable={isAdmin}
          editable={isAdmin}
          actions={myTenantActions}
          data={myTenantTree}
          treeName={TreeName.myTenantTree}
          selectedKey={selectedKey}
          treeNarrowKeys={myTenantNarrow || []}
          onSelect={onSelectMyTenantRCTreeNode}
          onDrop={onDrop}
          onNarrowKeysChange={setMyTenantNarrow}
        ></RCTree>
        {showFeedsSeparator && <Separator styles={sepratorStyles} />}
        {showTemplateHubNav && (
          <>
            <Nav
              className={companyTemplateClassName}
              styles={templateNavStyles}
              onRenderGroupHeader={props => (
                <ActionButton
                  iconProps={{
                    iconName: IconName.FileTemplate,
                  }}
                  styles={templateActionButtonStyles}
                  text={props?.name}
                ></ActionButton>
              )}
              groups={[
                {
                  name: t(RCNavI18n.templateTitle),
                  collapseByDefault: false,
                  links: templateNavLinks ?? [],
                },
              ]}
              onLinkClick={navlinkClick}
              selectedKey={selectedKey}
            />
            <Separator styles={sepratorStyles} />
          </>
        )}
        {showFeedsHeader && (
          <FeedsHeader
            isAdmin={isAdmin}
            feedsLanguage={feedsLanguage}
            onFeedsLanguageChange={onFeedsLanguageChange}
          />
        )}
        <RCTree
          editable={isAdmin}
          subscribable={!isAdmin && !isLimited}
          actions={feedsActions}
          data={feedsTree}
          treeName={TreeName.feedsTree}
          hasNewTag
          selectedKey={selectedKey}
          treeNarrowKeys={feedsNarrow || []}
          onSelect={onSelectFeedsRCTreeNode}
          onNarrowKeysChange={setFeedsNarrow}
        ></RCTree>
      </div>

      {isAdmin && (
        <div className={styles.adminFooter}>
          <Separator styles={sepratorStyles} />
          <Nav
            styles={adminNavStyles}
            groups={[
              {
                links: adminNavLinks ?? [],
              },
            ]}
            onLinkClick={navlinkClick}
            selectedKey={selectedKey}
          />
        </div>
      )}
    </div>
  );
}
