import { DirectionalHint, TeachingBubble } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { AppDeployment } from "../../locales";

export default function AppDeploymentDocBubble({
  hidden,
  target,
  onDismiss,
}: {
  hidden: boolean;
  target: string | Element;
  onDismiss?: () => void;
}): React.ReactElement {
  const { t } = useTranslation();

  return hidden ? (
    <></>
  ) : (
    <TeachingBubble
      target={target}
      onDismiss={onDismiss}
      calloutProps={{
        directionalHint: DirectionalHint.bottomCenter,
        preventDismissOnLostFocus: true,
      }}
      secondaryButtonProps={{
        text: t(AppDeployment.bubbleBtn),
        onClick: onDismiss,
      }}
    >
      {t(AppDeployment.docBubbleContent)}
    </TeachingBubble>
  );
}
