import { useState, useEffect } from "react";
import { getTeamsContext, TeamsContext } from "../services/teams";

/**
 * 获取 TeamsContext
 * - `false` 表示非teams环境
 * - `undefined` 表示获取中
 * - {} 或者 {...} 表示在teams中
 */
export function useTeamsContext() {
  const [context, setContext] = useState<TeamsContext | undefined | false>();
  useEffect(() => {
    getTeamsContext().then(
      cnt => setContext(cnt),
      () => setContext(false)
    );
  }, []);
  return context;
}
