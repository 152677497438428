import { useEffect, useState } from "react";
import consentcompletebackground from "../img/teams/consentcompletebackground.svg";
import { rem } from "../lib/unit";
import { FontWeights, PrimaryButton, Spinner, Text } from "@fluentui/react";
import { FontSizes } from "../themes/fonts";
import { RC_APPID } from "../config/config";
import { getIdTokenADAL } from "../services/request/getIdToken";
import { requestWeb, WEB_API } from "../services/request";
import { useTranslation } from "react-i18next";
import { ConsentComplete } from "../locales";
import { logger } from "../services/logger";
import { useRouteParams } from "../hooks/rc/useRoute";
import { PagePath } from "../config/routes";

export default function ConsentCompletePage() {
  const { t } = useTranslation();
  const { tenant, state } = useRouteParams<PagePath.consentcomplete>();
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (state && tenant) {
      const [email, locale] = state.split(",");
      logger?.setContext("TenantId", tenant);
      logger?.telemetry("ConsentComplete", { Email: email });
      getIdTokenADAL(email, tenant).then(
        token => {
          setIsComplete(true);
          if (token) {
            requestWeb(WEB_API.myTenantInit, {
              data: {
                locale,
              },
              method: "POST",
              headers: { Authorization: `Bearer ${token}` },
              skipAuth: true,
            }).catch(err =>
              logger?.error(`Tenant init failed. email:${email} tenant:${tenant} error: ${err}`)
            );
          }
        },
        err => {
          setIsComplete(true);
          logger?.error(`GetIdTokenADAL failed. email:${email} tenant:${tenant} error:${err}`);
        }
      );
    }
  }, [tenant, state]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: `url(${consentcompletebackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {isComplete ? (
        <div
          style={{
            height: rem(284),
            width: rem(536),
            background: "white",
            boxShadow: "0 1.2px 3.6px rgba(0, 0, 0, 0.11), 0 6.4px 14.4px rgba(0, 0, 0, 0.13)",
            borderRadius: rem(3),
            padding: `${rem(47)} ${rem(52)} ${rem(52)}`,
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          <Text
            styles={{
              root: {
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.xxLargePlus,
                lineHeight: rem(45),
              },
            }}
          >
            {t(ConsentComplete.title)}
          </Text>
          <Text
            styles={{
              root: {
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.large,
                paddingTop: rem(20),
              },
            }}
          >
            {t(ConsentComplete.description)}
          </Text>
          <PrimaryButton
            styles={{
              root: {
                marginTop: "auto",
                marginLeft: "auto",
              },
            }}
            text={t(ConsentComplete.button)}
            href={`https://teams.microsoft.com/l/entity/${RC_APPID}/ResourceCenter`}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
