import { DefaultButton, makeStyles, Shimmer, ZIndexes } from "@fluentui/react";
import React, { useEffect, useMemo, lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import PlayerHeader from "../navigation/PlayerHeader";
import { contentHeight } from "../navigation/TopBar";
import { rem } from "../../lib/unit";
import { View } from "../../locales";
import { RCDocumentType, getDocExtension } from "../../services/share";
import { OfficeDoc } from "../../models/OfficeDoc";
import { useFileToken } from "../../hooks/useFileToken";
import { useBoolean } from "@fluentui/react-hooks";
import FileTypeIcon from "../common/FileTypeIcon";

const PDFView = lazy(() => import("./PDFView"));

const useButtonStyles = makeStyles(theme => ({
  root: { backgroundColor: "transparent", color: theme.palette.neutralPrimary },
  rootHovered: { backgroundColor: theme.palette.neutralTertiaryAlt },
}));

const useStyles = makeStyles({
  shimmer: {
    height: `calc(${contentHeight} - ${rem(8)})`,
  },
  iframe: {
    marginTop: rem(8),
    width: `calc(100% - ${rem(2)})`,
    height: "100%",
    border: "none",
  },
  pdfShimmerImgContainer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    height: `calc(${contentHeight} - ${rem(8)})`,
    zIndex: ZIndexes.Nav,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const PDFRatioView = 0.8;

export default function OfficeDocPlayer({
  doc,
  onTapBack,
}: {
  doc: OfficeDoc;
  onTapBack?: () => void;
}): React.ReactElement {
  const buttonStyles = useButtonStyles();
  const { token } = useFileToken();
  const { t } = useTranslation();
  const styles = useStyles();

  const [pdfLoaded, { setTrue, setFalse }] = useBoolean(false);

  const isPDF = doc.isPDF();

  const url = useMemo(() => {
    if (doc.fileUrl && token) {
      return isPDF
        ? `${doc.fileUrl}?${token}`
        : `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            `${doc.fileUrl}?${token}`
          )}`;
    }
  }, [doc.fileUrl, isPDF, token]);

  useEffect(() => {
    if (isPDF && url) {
      setFalse();
    }
  }, [isPDF, setFalse, url]);

  const isLoaded = isPDF ? Boolean(pdfLoaded && url) : Boolean(url);
  return (
    <div>
      <PlayerHeader
        title={doc.metadata.name}
        type="office"
        extension={getDocExtension(doc.metadata.type as RCDocumentType)}
        onTapBack={onTapBack}
      >
        <DefaultButton className="TemplateViewTop-back" onClick={onTapBack} styles={buttonStyles}>
          {t(View.close)}
        </DefaultButton>
      </PlayerHeader>

      {isPDF && !isLoaded && (
        <div className={styles.pdfShimmerImgContainer}>
          <FileTypeIcon extension="pdf" size={96}></FileTypeIcon>
        </div>
      )}
      <Shimmer
        styles={{ shimmerWrapper: styles.shimmer, dataWrapper: styles.shimmer }}
        isDataLoaded={isLoaded}
      >
        {url &&
          (isPDF ? (
            <Suspense fallback={<div />}>
              <PDFView pdfUrl={url} ratioView={PDFRatioView} pdfOnload={setTrue} />
            </Suspense>
          ) : (
            <iframe
              title={doc.metadata.name}
              src={url}
              className={styles.iframe}
              // eslint-disable-next-line @dragongate/react-iframe-missing-sandbox
              sandbox="allow-forms allow-scripts allow-same-origin"
            />
          ))}
      </Shimmer>
    </div>
  );
}
