import { Icon, makeStyles, Shimmer } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { IconName } from "../../config/icons";
import { useFileToken } from "../../hooks/useFileToken";
import { timeFormat } from "../../lib/timeFormat";
import { rem } from "../../lib/unit";
import { FontSizes } from "../../themes/fonts";
import { PureImg } from "../common/Img";

const useCss = makeStyles(theme => ({
  root: {
    width: "100%",
    position: "relative",
    ".ms-Shimmer-shimmerWrapper": {
      height: rem(100),
    },
    "&.is-disabled": {
      opacity: 0.6,
    },
  },
  img: {
    width: "100%",
    height: "auto",
    minHeight: "40px",
    border: `${rem(1)} solid ${theme.semanticColors.bodyFrameDivider}`,
    margin: rem(3),
    display: "inline-block",
    position: "relative",
    "&.is-selected": {
      border: `${rem(4)} solid ${theme.palette.redDark}`,
      margin: 0,
    },
  },
  duration: {
    position: "absolute",
    left: rem(8),
    bottom: rem(12),
    borderRadius: rem(3),
    padding: rem(4, 8),
    background: theme.palette.blackTranslucent40,
    color: theme.palette.white,
    minWidth: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: FontSizes.small,
  },
}));

export interface SwiperItemProps {
  img?: string;
  duration?: number;
  video?: string;
  checked?: boolean;
  disabled?: boolean;
}
export default function SwiperItem({ img, duration, video, ...props }: SwiperItemProps) {
  const css = useCss();
  const [isImgLoaded, { setTrue }] = useBoolean(false);
  const { token } = useFileToken();

  return (
    <Shimmer
      className={`${css.root} ${props.disabled ? "is-disabled" : ""}`}
      isDataLoaded={isImgLoaded}
    >
      {video ? (
        <video
          className={`${css.img} ${props.checked ? "is-selected" : ""}`}
          onLoadedData={setTrue}
          src={token && video ? `${video}?${token}` : ""}
        />
      ) : (
        <PureImg
          onLoad={setTrue}
          className={`${css.img} ${props.checked ? "is-selected" : ""}`}
          src={img}
          loading="lazy"
          alt=""
        />
      )}
      {duration! > 0 ? (
        <div className={css.duration}>
          <Icon iconName={IconName.TriangleSolidRight12} />
          <span>{timeFormat(duration!)}</span>
        </div>
      ) : null}
    </Shimmer>
  );
}
