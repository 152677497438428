import React, { useCallback, useMemo } from "react";
import {
  FontWeights,
  useTheme,
  IContextualMenuItem,
  ContextualMenuItemType,
  PrimaryButton,
  makeStyles,
} from "@fluentui/react";
import { IconName } from "../../config/icons";
import TopBar from "../../components/navigation/TopBar";
import { NavLink } from "../../hooks/rc/useNavLinks";
import { useMobileCss } from "../../hooks/rc/useCss";
import { useTranslation } from "react-i18next";
import { SettingsI18n, MePageI18n } from "../../locales";
import { FeedsLanguage } from "../../services/storage";
import { FEEDBACK_LINK } from "../../config/constant";
import { FontSizes } from "../../themes/fonts";
import { rem } from "../../lib/unit";
import { PagePath } from "../../config/routes";
import { logger } from "../../services/logger";
import { navigateTo } from "../../services/history";
import { DarkColors } from "../../themes/teamsColors";

export interface HeaderBarProps {
  currentNav?: NavLink;
  feedsLanguage?: FeedsLanguage;
  showUserCreation?: boolean;
  showSetting?: boolean;
  onNavChange?: (
    ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ) => boolean | void;
  onFeedsLanguageChange?: (ev?: any, item?: IContextualMenuItem) => boolean | void;
}

const useUserCreationButtonStyle = makeStyles(theme => ({
  root: {
    background: `${DarkColors.appBrand12} !important`,
    marginRight: rem(8),
    ":hover": {
      background: `${DarkColors.appBrand08} !important`,
    },
  },
  icon: {
    color: "white !important",
  },
}));

export default function HeaderBar({
  currentNav,
  onNavChange,
  showSetting,
  showUserCreation,
  feedsLanguage,
  onFeedsLanguageChange,
}: HeaderBarProps) {
  const { t } = useTranslation();
  const isMobileCss = useMobileCss();
  const theme = useTheme();

  const userCreationButtonStyle = useUserCreationButtonStyle();
  const onUserCreationButtonClick = useCallback(() => {
    logger?.telemetry("ClickUserCreationButton");
    navigateTo(PagePath.me);
  }, []);

  const items = useMemo(
    () => [
      {
        key: "lang-header",
        text: t(SettingsI18n.languageSettingTitle),
        itemType: ContextualMenuItemType.Header,
        className: "screen-mobile",
      },
      {
        key: FeedsLanguage.zhCN,
        text: t(SettingsI18n.languageOption, { context: FeedsLanguage.zhCN }),
        canCheck: true,
        checked: feedsLanguage === FeedsLanguage.zhCN,
      },
      {
        key: FeedsLanguage.zhTW,
        text: t(SettingsI18n.languageOption, { context: FeedsLanguage.zhTW }),
        canCheck: true,
        checked: feedsLanguage === FeedsLanguage.zhTW,
      },
      {
        key: FeedsLanguage.zhHK,
        text: t(SettingsI18n.languageOption, { context: FeedsLanguage.zhHK }),
        canCheck: true,
        checked: feedsLanguage === FeedsLanguage.zhHK,
      },
      {
        key: FeedsLanguage.en,
        text: t(SettingsI18n.languageOption, { context: FeedsLanguage.en }),
        canCheck: true,
        checked: feedsLanguage === FeedsLanguage.en,
      },
    ],
    [feedsLanguage, t]
  );

  const rightItems = useMemo(
    () => [
      ...(showUserCreation
        ? [
            {
              key: "userCreation",
              onRender: () => (
                <PrimaryButton
                  id={"rc-usercreation"}
                  className={userCreationButtonStyle.root}
                  iconProps={{
                    iconName: IconName.Lightbulb,
                    className: userCreationButtonStyle.icon,
                  }}
                  onClick={onUserCreationButtonClick}
                >
                  {t(MePageI18n.pageName)}
                </PrimaryButton>
              ),
            },
          ]
        : []),
      ...(showSetting
        ? [
            {
              key: "settings",
              text: t(SettingsI18n.button),
              iconOnly: isMobileCss,
              tooltipHostProps: {
                hidden: true,
              },
              iconProps: {
                iconName: isMobileCss ? IconName.More : undefined,
                styles: {
                  root: {
                    fontSize: FontSizes.xLargePlus,
                    fontWeight: "bolder",
                  },
                },
              },
              buttonStyles: {
                labelHovered: {
                  color: theme.palette.themePrimary,
                },
                menuIcon: {
                  display: isMobileCss ? "none" : undefined,
                },
              },
              subMenuProps: {
                gapSpace: 10,
                items: [
                  {
                    key: "header",
                    text: t(SettingsI18n.button),
                    itemType: ContextualMenuItemType.Header,
                    className: "screen-mobile",
                  },
                  {
                    key: "feedsLanguageSetting",
                    text: t(SettingsI18n.languageSettingTitle),
                    iconProps: {
                      iconName: IconName.Library,
                    },
                    subMenuProps: {
                      onItemClick: onFeedsLanguageChange,
                      items,
                    },
                  },
                  {
                    key: "feedback",
                    text: t(SettingsI18n.feedback),
                    iconProps: {
                      iconName: IconName.Emoji2,
                    },
                    target: "_blank",
                    href: FEEDBACK_LINK,
                  },
                ],
              },
            },
          ]
        : []),
    ],
    [
      isMobileCss,
      items,
      onFeedsLanguageChange,
      onUserCreationButtonClick,
      showSetting,
      showUserCreation,
      t,
      theme.palette.themePrimary,
      userCreationButtonStyle,
    ]
  );

  return (
    <TopBar
      left={[
        {
          key: "menu",
          iconProps: { iconName: IconName.GlobalNavButton },
          onClick: onNavChange,
        },
        {
          key: `${currentNav?.key ?? ""}`,
          text: isMobileCss ? `${currentNav?.name ?? ""}` : "",
          buttonStyles: {
            root: {
              paddingLeft: 0,
            },
            label: {
              fontWeight: FontWeights.semibold,
              color: theme.palette.neutralPrimary,
              margin: 0,
            },
          },
        },
      ]}
      right={rightItems}
    />
  );
}
