import React, { useCallback, useEffect, useMemo, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import TingDocPlayer from "./TingDocPlayer";
import { ResourceScope } from "../../config/rc";
import { TingDoc } from "../../models/TingDoc";
import { VideoDoc } from "../../models/VideoDoc";
import { logger } from "../../services/logger";
import { Template } from "../../models/Template";
import TemplatePlayer from "./TemplatePlayer/TemplatePlayer";
import { EntityType, generateRCShareInfo, SourcePageType } from "../../services/share";
import PlayNextTip from "./PlayNextTip";
import { currentApp } from "../../config/app";
import { requestWeb, selectAPI, UserRole } from "../../services/request";
import { useUser } from "../../hooks/rc/useUser";
import { useRouteViewDocCallback } from "../../hooks/rc/useRoute";
import { OfficeDoc } from "../../models/OfficeDoc";
import OfficeDocPlayer from "./OfficeDocPlayer";

export interface RCPlayerProps {
  scope: ResourceScope;
  document: CDS.Document;
  hideTeamsShare?: boolean;
  sourcePageType?: SourcePageType;
  playList?: CDS.Document[];
  onClose?: () => void;
}
function Player({
  document,
  hideTeamsShare,
  scope,
  onClose,
  sourcePageType,
  playList,
}: RCPlayerProps) {
  const { role } = useUser();
  const isGuest = role === UserRole.guest;
  const apis = selectAPI(scope as ResourceScope, isGuest);
  const [bundleDocs, setBundleDocs] = useState<CDS.Document[] | undefined>(playList);
  const [nextDoc, setNextDoc] = useState<CDS.Document>();
  const [nextTipHidden, setNextTipHidden] = useState<boolean>(true);
  const [autoNextDoc, setAutoNextDoc] = useState<boolean>();
  const bundleId = document.extended?.bundleId;
  const viewDoc = useRouteViewDocCallback({ scope, bundleId, replace: true });

  useEffect(() => {
    logger?.telemetry("ViewDocument", {
      DocumentType: document.metadata?.type!,
      DocumentId: document.id!,
      DocumentName: document.metadata?.name!,
      BundleId: document.extended?.bundleId!,
      CategoryId: document.extended?.categoryId!,
      Tags: document.tags! as any,
      Permission:
        document.metadata?.status === 0
          ? "public"
          : document.metadata?.status === 1
          ? "private"
          : "",
    });
    setNextTipHidden(true);
  }, [document]);

  const shareInfo = useMemo(
    () =>
      generateRCShareInfo(
        scope,
        EntityType.Document,
        document,
        currentApp?.entityId,
        sourcePageType
      ),
    [document, scope, sourcePageType]
  );

  const onCloseToEnd = useCallback(
    (isCloseToEnd: boolean, noVoice?: boolean) => {
      if (isCloseToEnd) {
        !!role &&
          !!bundleId &&
          (playList
            ? Promise.resolve({ data: { value: playList } })
            : requestWeb(apis.bundleDocumentsAPI, {
                vars: { bundleId },
                skipAuth: isGuest,
                params: {
                  $top: 200,
                },
              })
          ).then(res => {
            const docs = res?.data?.value;
            if (docs) {
              const index = docs.findIndex(doc => doc.id === document.id);
              setBundleDocs(docs);
              setNextDoc(docs[index + 1] ?? null);
              setAutoNextDoc(!noVoice);
              setNextTipHidden(false);
            }
          });
      } else {
        setNextTipHidden(true);
      }
    },
    [apis.bundleDocumentsAPI, bundleId, playList, document.id, isGuest, role]
  );

  const onNextDoc = useCallback(
    (autoNext?: boolean) => {
      if (nextDoc) {
        logger?.telemetry("ViewNextDoc", { AutoNext: autoNext ?? false, BundleId: bundleId! });
        viewDoc(nextDoc, bundleDocs);
      }
    },
    [bundleDocs, bundleId, nextDoc, viewDoc]
  );

  const formatDoc = useMemo(() => {
    if (VideoDoc.isVideoDoc(document)) {
      return new VideoDoc(document);
    } else if (Template.isTemplate(document)) {
      return new Template(document);
    } else if (TingDoc.isTingDoc(document)) {
      return new TingDoc(document);
    } else {
      return new OfficeDoc(document);
    }
  }, [document]);

  return (
    <>
      {formatDoc instanceof VideoDoc || (formatDoc as TingDoc)?.video ? (
        <VideoPlayer
          scope={scope}
          hideTeamsShare={!!hideTeamsShare}
          doc={formatDoc as VideoDoc}
          onTapBack={onClose}
          category={document.category}
          rcShareInfo={shareInfo}
          onCloseToEnd={onCloseToEnd}
          onEnd={() => onNextDoc(true)}
        />
      ) : formatDoc instanceof Template ? (
        <TemplatePlayer
          scope={scope}
          hideTeamsShare={!!hideTeamsShare}
          template={formatDoc}
          category={document.category}
          onTapBack={onClose}
          rcShareInfo={shareInfo}
        />
      ) : formatDoc instanceof TingDoc ? (
        <TingDocPlayer
          scope={scope}
          hideTeamsShare={!!hideTeamsShare}
          doc={formatDoc}
          category={document.category}
          onTapBack={onClose}
          rcShareInfo={shareInfo}
          onCloseToEnd={onCloseToEnd}
          onEnd={() => onNextDoc(true)}
        />
      ) : (
        <OfficeDocPlayer doc={formatDoc} onTapBack={onClose} />
      )}
      <PlayNextTip
        nextDoc={nextDoc}
        autoNextDoc={autoNextDoc}
        onClick={() => onNextDoc(false)}
        onDismiss={() => setNextTipHidden(true)}
        hidden={!(sourcePageType === SourcePageType.Bundle && !nextTipHidden)}
      />
    </>
  );
}

const RCPlayer = React.memo(
  Player,
  (pre, next) =>
    pre.document.id === next.document.id &&
    pre.scope === next.scope &&
    pre.hideTeamsShare === next.hideTeamsShare &&
    pre.sourcePageType === next.sourcePageType &&
    pre.onClose === next.onClose
);

export default RCPlayer;
