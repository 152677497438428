import { makeStyles, Text } from "@fluentui/react";
import PlayerHeader from "../navigation/PlayerHeader";
import errorimg from "../../img/teams/loadingerror.png";
import { rem } from "../../lib/unit";
import { useTranslation } from "react-i18next";
import { ErrorPageI18n } from "../../locales";

export interface ErrorPageProps {
  message?: string;
  error?: Error;
  onBack?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    height: "80vh",
  },
  img: {
    height: rem(150),
    paddingBottom: rem(40),
  },
});

export default function ErrorPage({ onBack, message }: ErrorPageProps) {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <PlayerHeader title={""} type="bundle" onTapBack={onBack} />
      <div className={styles.root}>
        <img className={styles.img} src={errorimg} alt="error" />
        <Text block variant="large">
          {message || t(ErrorPageI18n.message)}
        </Text>
      </div>
    </>
  );
}
