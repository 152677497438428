import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Breadcrumb,
  Dialog,
  DialogFooter,
  IDialogProps,
  mergeStyles,
  PrimaryButton,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import { requestGraph, Graph_API } from "../../services/request";
import { FilePicker as FilePickerNS } from "../../locales";
import { rem } from "../../lib/unit";
import FileList from "../picker/FilePicker";
import EmptyContent from "../empty/EmptyContent";
import { useQueryGraphPages } from "../../hooks/useQuery";
import FileFilter, { FileFilterOption } from "../filter/FileFilter";

const fileListClassName = mergeStyles({ height: rem(360), clear: "both" });
const fileFilterPositionClassName = mergeStyles({ float: "right" });

function filterFileAndFolder(item: DriveItem, condition?: { ext: string; maxSize: number }[]) {
  if (!condition) {
    return true;
  } else {
    const ext = item.name?.split(".").pop()?.toLowerCase();
    return (
      item.size! > 0 &&
      (item.folder ||
        condition.findIndex(c => c.ext.toLowerCase() === ext && c.maxSize >= item.size!) !== -1)
    );
  }
}

function getInfoById(id: string) {
  return requestGraph(Graph_API.myDriveItem, {
    vars: { id },
    params: { $expand: "thumbnails" },
  }).then(data => data.data);
}
export interface FilePickerDialogProps {
  onSubmit?: (item?: DriveItem) => void;
  onDismiss?: () => void;
  hidden?: boolean;
  confirmText?: string;
  pickerTips?: string;
  filterInfo?: FileFilterOption[];
}
export default function FilePickerDialog({
  onSubmit,
  hidden,
  onDismiss,
  confirmText,
  pickerTips,
  filterInfo,
}: FilePickerDialogProps) {
  const { t } = useTranslation();
  const [paths, setPaths] = useState<{ key: string; text: string; onClick: () => void }[]>(() => [
    {
      key: "root",
      text: "OneDrive",
      onClick: () => setPaths(paths => paths.slice(0, 1)),
    },
  ]);
  const { values, indicatorVisible, canLoadMore, loadMore } = useQueryGraphPages(
    Graph_API.myDriveChildren,
    {
      vars: { id: paths.slice(-1)[0].key },
    },
    {
      enabled: !hidden,
    }
  );
  useEffect(() => {
    if (canLoadMore) {
      loadMore();
    }
  }, [canLoadMore, loadMore]);
  const [isLoading, setIsloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<DriveItem>();
  const setSelectedFiles = useCallback((items?: DriveItem[]) => {
    const item = items?.[0];
    setSelectedFile(item);
  }, []);

  const [filterKeys, setFilterKeys] = useState<string[]>();

  const submit = useCallback(
    (id: string) => {
      if (onSubmit) {
        setIsloading(true);
        return getInfoById(id)
          .then(onSubmit)
          .finally(() => setIsloading(false));
      }
    },
    [onSubmit]
  );
  const onNext = useCallback(() => {
    if (selectedFile?.id) {
      submit(selectedFile.id);
    }
  }, [selectedFile?.id, submit]);

  const onItemInvoked = useCallback(
    (item?: DriveItem) => {
      if (item?.id && item.folder) {
        setPaths(state => {
          const newState = state.concat({
            key: item.id!,
            text: item.name!,
            onClick: () => setPaths(newState),
          });
          return newState;
        });
      } else if (item?.id) {
        submit(item.id);
      }
    },
    [submit]
  );

  const items = useMemo(() => {
    const condition = filterInfo
      ?.filter(i => (filterKeys?.length ? filterKeys.includes(`${i.key}`) : !i.hidden))
      .reduce<{ ext: string; maxSize: number }[]>(
        (p, c) => [...p, ...c.docTypeRange.map(t => ({ ext: t, maxSize: c.maxSize }))],
        []
      );
    return values?.filter(item => filterFileAndFolder(item, condition));
  }, [filterInfo, values, filterKeys]);

  const dialogContentProps = useMemo<IDialogProps["dialogContentProps"]>(
    () => ({
      title: <Breadcrumb items={paths} />,
      subText: pickerTips,
      showCloseButton: true,
    }),
    [paths, pickerTips]
  );

  return (
    <Dialog hidden={hidden} dialogContentProps={dialogContentProps} onDismiss={onDismiss}>
      <div className={fileFilterPositionClassName}>
        <FileFilter options={filterInfo ?? []} onChange={setFilterKeys}></FileFilter>
      </div>
      {items && items.length === 0 ? (
        <EmptyContent className={fileListClassName} message={t(FilePickerNS.emptyTips)} />
      ) : (
        <>
          <FileList
            className={fileListClassName}
            items={items}
            onItemInvoked={onItemInvoked}
            onSelectedChange={setSelectedFiles}
            enableShimmer={indicatorVisible}
          />
        </>
      )}
      <DialogFooter>
        <PrimaryButton disabled={!selectedFile || isLoading} onClick={onNext} text={confirmText} />
      </DialogFooter>
    </Dialog>
  );
}
