import { useCallback, useEffect, useMemo, useState } from "react";
import { mergeStyles, Spinner } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useLocation } from "react-router-dom";
import MetaEditorDialog from "../../components/dialog/MetaEditorDialog";
import TingDocEditor from "../../components/editor/TingDocEditor";
import { PagePath } from "../../config/routes";
import { FileTokenProvider } from "../../hooks/useFileToken";
import { useRouteParams } from "../../hooks/rc/useRoute";
import { EditableTingDoc } from "../../models/EditableTingDoc";
import { requestWeb, WEB_API } from "../../services/request";
import { delStorage, getStorage, setStorage } from "../../services/storage";
import { useTranslation } from "react-i18next";
import { EditorI18n } from "../../locales";
import { useEditableTingDocMetaData } from "../../hooks/rc/useEditableTingDoc";
import { logger } from "../../services/logger";
import { showDeleteDialog } from "../../components/dialog/DeleteDialog";
import EditorHeader from "../../components/navigation/EditorHeader";
import { useQueryClient } from "react-query";
import { goBack } from "../../services/history";
import { isPrerender } from "../../config/browser";

export default function EditorPage() {
  const { id } = useRouteParams<PagePath.edit>();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const location = useLocation();

  const isAdmin = (location?.state as any)?.isAdmin as boolean;
  const storageSuffix = isAdmin ? "admin" : "me";

  const [doc, setDoc] = useState(() => {
    // 非 EditableTingDoc, 重新载入localstorage
    const localdoc = getStorage("editDocAutoSave", storageSuffix);
    if (localdoc) {
      return new EditableTingDoc(localdoc);
    } else if (!isPrerender && !id) {
      goBack();
    }
  });

  // Viva-Engage嵌入页面功能
  const isCreateVideo = useMemo(() => {
    const isVideo = !!window.localStorage.getItem("isCreateVideo");
    if (isVideo) {
      window.localStorage.removeItem("isCreateVideo");
    }
    return isVideo;
  }, []);

  const meta = useEditableTingDocMetaData(doc || undefined);
  const [showMetaEditor, { setTrue: displayMetaEditor, setFalse: hideMetaEditor }] =
    useBoolean(false);
  const onDiscard = useCallback(() => {
    const i18nContext = id ? { context: "edit" } : undefined;
    showDeleteDialog({
      title: t(EditorI18n.discardDialogTitle, i18nContext),
      subText: t(EditorI18n.discardDialogContent, i18nContext),
      cancelButtonText: t(EditorI18n.discardDialogConfirm, i18nContext),
      confirmButtonText: t(EditorI18n.discardDialogCancel, i18nContext),
      doDelete: () => {
        window.parent.postMessage("discard", "*"); // Viva-Engage嵌入页面功能
        logger?.telemetry("ClickDiscardEditDocBtn", { DocId: id! });
        if (doc) {
          doc.autoSave = false;
        }
        delStorage("editDocAutoSave", storageSuffix);
        goBack();
      },
    });
  }, [doc, id, storageSuffix, t]);
  const onMetaUpdate = useCallback(
    (data: { name?: string; cover?: string }) => {
      data.cover && doc?.setCover(data.cover);
      data.name && doc?.setTitle(data.name);
      if (doc?.autoSave) {
        setStorage("editDocAutoSave", doc.toDocument(), storageSuffix);
      }
      return true;
    },
    [doc, storageSuffix]
  );
  const onSaveDoc = useCallback(async () => {
    const document = doc!.toDocument();
    logger?.telemetry("ClickSaveEditDocBtn", {
      PageCount: doc?.pages?.length!,
      VoiceCount: document?.content?.media?.filter(m => m.type === "audio")?.length!,
      DocId: doc?.id!,
    });
    if (doc?.extended.sessionId && !doc.id) {
      const sessionId = doc.extended.sessionId;
      // remove session Id for completed doc
      if (document?.extended?.sessionId) {
        document.extended.sessionId = undefined;
      }
      await requestWeb(
        isAdmin ? WEB_API.myTenantTingDocFinishCreation : WEB_API.myTingDocFinishCreation,
        {
          vars: { sessionId },
          data: { document },
          method: "POST",
        }
      )
        .then(res => {
          doc.autoSave = false;
          delStorage("editDocAutoSave", storageSuffix);
          queryClient.invalidateQueries(isAdmin ? WEB_API.myTenantDocuments : WEB_API.myDocuments, {
            refetchInactive: true,
          });

          // Viva-Engage嵌入页面功能
          window.localStorage.setItem("vivaLoading", "true");
          // Viva-Engage嵌入页面功能
          window.parent.postMessage(`videoCreated${res?.data as any as string}`, "*");
          goBack();
        })
        .catch(err => {
          logger?.error(err, "createTingDocError");
        });
    } else if (doc?.id) {
      await requestWeb(isAdmin ? WEB_API.myTenantDocument : WEB_API.myDocument, {
        vars: { id: doc.id! },
        data: document,
        method: "PUT",
      }).then(() => {
        doc.autoSave = false;
        delStorage("editDocAutoSave", storageSuffix);
        queryClient.invalidateQueries(isAdmin ? WEB_API.myTenantDocuments : WEB_API.myDocuments, {
          refetchInactive: true,
        });
        goBack();
      });
    }
  }, [doc, isAdmin, queryClient, storageSuffix]);

  useEffect(() => {
    if (doc && doc.autoSave) {
      const autoSave = () => {
        if (doc.autoSave) {
          setStorage("editDocAutoSave", doc.toDocument(), storageSuffix);
        }
      };
      window.addEventListener("unload", autoSave);
      return () => {
        window.removeEventListener("unload", autoSave);
        autoSave();
      };
    }
  }, [doc, storageSuffix]);
  useEffect(() => {
    if (id) {
      // loading doc for edit
      requestWeb(WEB_API.myTenantDocument, { vars: { id } }).then(docData => {
        const editableTingDoc = new EditableTingDoc(docData.data);
        editableTingDoc.autoSave = false;
        setDoc(editableTingDoc);
      });
    }
  }, [id]);
  return (
    <FileTokenProvider id={id || doc?.extended.sessionId} write isAdmin={isAdmin}>
      <div
        className={mergeStyles({
          display: "flex",
          flexDirection: "column",
          height: "100%",
        })}
      >
        <EditorHeader
          title={t(EditorI18n.title, id ? { context: "edit" } : undefined)}
          cancelText={t(EditorI18n.discard, id ? { context: "edit" } : undefined)}
          onCancel={onDiscard}
          saveText={t(EditorI18n.save)}
          onSave={displayMetaEditor}
        />
        <TingDocEditor doc={doc} />
      </div>
      <MetaEditorDialog
        title={t(EditorI18n.metaDialogTitle)}
        hidden={!showMetaEditor}
        cover={meta.cover}
        name={meta.name}
        loadingElement={
          isCreateVideo ? (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "calc(100% + 8px)",
                  top: "50%",
                  transform: "translate(0, -50%)",
                }}
              >
                <Spinner />
              </div>
              Waiting for video to complete..
            </div>
          ) : undefined
        }
        onUpdate={doc && onMetaUpdate}
        onDismiss={hideMetaEditor}
        onSubmit={onSaveDoc}
      />
    </FileTokenProvider>
  );
}
