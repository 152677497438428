import { ActionButton, TooltipHost, DirectionalHint, makeStyles, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";
import { AdminConsent } from "../../locales";
import { getConsentUrl } from "../../services/admin";
import { logger } from "../../services/logger";
import { setStorage } from "../../services/storage";

const useCssStyle = makeStyles(theme => ({
  consentArea: {
    height: rem(50),
    width: "100%",
    position: "absolute",
    bottom: 0,
    borderTop: `1px solid ${theme.palette.neutralLight}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionButton: { flexGrow: 1 },
  icon: { padding: `0 ${rem(4)}` },
  tipText: { color: theme.palette.white },
}));

const useTooltipStyle = makeStyles(theme => ({
  beak: {
    backgroundColor: theme.palette.neutralDark,
  },
  beakCurtain: {
    backgroundColor: theme.palette.neutralDark,
    borderRadius: rem(3),
  },
  calloutMain: {
    background: theme.palette.neutralDark,
  },
  root: {
    padding: `${rem(5)} ${rem(12)} ${rem(7)}`,
    maxWidth: rem(240),
    borderRadius: rem(3),
  },
}));

export default function ConsentButton() {
  const cssStyle = useCssStyle();
  const tooltipStyle = useTooltipStyle();
  const { t } = useTranslation();

  return (
    <div className={cssStyle.consentArea}>
      <ActionButton
        className={cssStyle.actionButton}
        iconProps={{ iconName: IconName.Settings, className: cssStyle.icon }}
        text={t(AdminConsent.consentBtn)}
        onClick={() => {
          logger?.telemetry("ClickConsentBtn");
          setStorage("consentClick", true);
        }}
        href={getConsentUrl()}
        target={"_blank"}
      />
      <TooltipHost
        content={<Text className={cssStyle.tipText}>{t(AdminConsent.tooltip)}</Text>}
        calloutProps={{
          beakWidth: 12,
          directionalHint: DirectionalHint.topRightEdge,
          styles: tooltipStyle,
        }}
      >
        <ActionButton iconProps={{ iconName: IconName.Info }} />
      </TooltipHost>
    </div>
  );
}
