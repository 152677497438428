import React, { useCallback, useMemo } from "react";
import { DialogType, Dialog, DialogFooter, PrimaryButton, makeStyles, Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { rem } from "../../lib/unit";
import { RecorderI18n, SwitchRecorderDialogI18n } from "../../locales";
import { IconName } from "../../config/icons";
import { useBoolean, useControllableValue } from "@fluentui/react-hooks";
import { logger } from "../../services/logger";
import { useFeedsLanguage } from "../../hooks/useFeedsLanguage";
import { FeedsLanguage } from "../../services/storage";

const useDialogStyles = makeStyles({
  subtext: {
    marginTop: rem(20),
  },
  inner: {
    minHeight: rem(100),
    height: "conent-height",
  },
});

const useCssStyles = makeStyles(theme => ({
  textTab: {
    width: "100%",
    display: "flex",
    alignContent: "space-between",
    "&.disabled .is-selected": {
      color: theme.palette.neutralPrimaryAlt,
      backgroundColor: `${theme.palette.neutralLight} !important`,
    },
    "&.disabled .is-selected:hover": {
      color: theme.palette.neutralPrimaryAlt,
    },
    "&.disabled div": {
      opacity: 0.64,
      color: theme.palette.neutralPrimaryAlt,
      backgroundColor: "transparent !important",
      cursor: "default",
    },
    "&.en": {
      "@media screen and (max-width: 1350px)": {
        " i": { display: "none !important" },
      },
    },
    "&.zh": {
      "@media screen and (max-width: 970px)": {
        " i": { display: "none !important" },
      },
    },
  },
  textTabItem: {
    borderRadius: rem(4, 4, 0, 0),
    width: "50%",
    height: rem(36),
    fontSize: rem(14),
    lineHeight: rem(36),
    textAlign: "center",
    fontWeight: 400,
    color: theme.palette.neutralPrimaryAlt,
    cursor: "pointer",
    boxShadow: theme.effects.elevation4,
    " i": { display: "inline", marginRight: rem(8) },
    "&.is-selected": {
      backgroundColor: theme.palette.neutralLight,
      color: theme.palette.themePrimary,
      cursor: "default",
      boxShadow: "none",
      fontWeight: 600,
    },
    "&:hover": {
      backgroundColor: theme.palette.neutralLighter,
      color: theme.palette.neutralDark,
    },
    "&.is-selected:hover": {
      color: theme.palette.themePrimary,
      backgroundColor: theme.palette.neutralLight,
    },
  },
}));

export interface SwitchRecorderProps {
  selectedRecorder?: "ai" | "human";
  onRecorderStatusChange?: (recorder: "ai" | "human") => void;
  isLocked?: boolean;
  disabled?: boolean;
}

export default function SwitchRecorderButton({
  selectedRecorder,
  onRecorderStatusChange,
  isLocked,
  disabled,
}: SwitchRecorderProps) {
  const { t } = useTranslation();
  const cssDialogStyles = useDialogStyles();
  const css = useCssStyles();
  const { feedsLanguage } = useFeedsLanguage(true);
  const [newSelectedRecorder, setNewSelectedRecorder] = useControllableValue(
    selectedRecorder,
    undefined,
    (ev, newValue: "ai" | "human" | undefined) => {
      onRecorderStatusChange && newValue && onRecorderStatusChange(newValue);
    }
  );
  const [switchRecorderConfirmationHidden, { setFalse, setTrue }] = useBoolean(true);
  const onRecorderChange = useCallback(
    (type: "ai" | "human") => {
      if (!disabled && newSelectedRecorder !== type) {
        logger?.telemetry("SwitchRecorder", { Recorder: type, ActionSucceed: !isLocked });
        if (isLocked) {
          setFalse();
        } else {
          setNewSelectedRecorder && setNewSelectedRecorder(type);
        }
      }
    },
    [disabled, isLocked, newSelectedRecorder, setFalse, setNewSelectedRecorder]
  );
  const switcherButton = useMemo(
    () => [
      {
        key: "ai",
        text: t(RecorderI18n.aiRecorder),
        iconName: IconName.AIRecorder,
        onClick: () => onRecorderChange("ai"),
      },
      {
        key: "human",
        text: t(RecorderI18n.humanRecorder),
        iconName: IconName.HumanRecorder,
        onClick: () => onRecorderChange("human"),
      },
    ],
    [onRecorderChange, t]
  );

  const languageClass = useMemo(
    () => (feedsLanguage === FeedsLanguage.en ? "en" : "zh"),
    [feedsLanguage]
  );

  return (
    <div>
      <div className={`${css.textTab} ${disabled ? "disabled" : ""} ${languageClass}`}>
        {switcherButton &&
          switcherButton.map(item => (
            <div
              className={`${css.textTabItem} ${
                item.key === newSelectedRecorder ? "is-selected" : ""
              }`}
              onClick={item.onClick}
            >
              <Icon iconName={item.iconName} />
              {item.text}
            </div>
          ))}
      </div>
      <Dialog
        hidden={switchRecorderConfirmationHidden}
        title={t(SwitchRecorderDialogI18n.dialogTitle)}
        subText={t(SwitchRecorderDialogI18n.alert)}
        dialogContentProps={{
          type: DialogType.close,
          styles: cssDialogStyles,
        }}
        onDismiss={setTrue}
      >
        <DialogFooter>
          <PrimaryButton onClick={setTrue} text={t(SwitchRecorderDialogI18n.close)} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}
