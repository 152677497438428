import { ITheme, IBasePickerStyles, IBasePickerStyleProps, IRawStyle } from "@fluentui/react";
import { teamsBorderRadius } from "./styleConstants";

export function getPeopePickerStyles(themeName: string, theme: ITheme) {
  const CoreColors = {
    appGray09: "#edebe9",
    appRed: "#c4314b",
    pickerPersonaContainerBackground: "#e9eaf6",
  };
  const DarkColors = {
    appGray09: "#323131",
    appRed: "#d74654",
    pickerPersonaContainerBackground: "#323348",
    appPersonaBackground: "#3B3A3A",
  };
  const ContrastColors = {
    appGreenContrast: "#30f42c",
    appRed: "#fff",
    pickerPersonaContainerBackground: "#ffff00",
  };
  return (props: IBasePickerStyleProps) => {
    const { disabled } = props;
    const { palette } = theme;
    let PLACEHOLDER_NOT_DISABLED = palette.neutralTertiary;
    let FIELD_GROUP_BACKGROUND_COLOR = palette.neutralLighter;
    let SELECTED_BG_COLOR = CoreColors.appGray09;
    let FIELD_GROUP_OPACITY = 0.64;
    const FIELD_COLOR = palette.black;
    let FIELD_COLOR_PLACEHOLDER_NOT_DISABLED = palette.neutralDark;
    let FIELD_COLOR_PLACEHOLDER_DISABLED = palette.neutralDark;
    let FIELD_GROUP_BORDER_WIDTH = "0 0 2px 0";
    let INPUT_GROUP_BORDER_WIDTH = "0 0 2px 0";
    let FIELD_GROUP_BORDER_COLOR = "transparent";
    let FIELD_GROUP_BORDER_COLOR_DISABLED = CoreColors.appGray09;
    let FIELD_GROUP_BORDER_BOTTOM_COLOR_ACTIVE = palette.themePrimary;
    let PRIMARY_TEXT_BORDER_BOTTOM_COLOR = CoreColors.appRed;
    let PERSONA_HOVER_BACKGROUND_COLOR = CoreColors.pickerPersonaContainerBackground;

    // Dark theme overrides
    if (themeName === "dark") {
      PLACEHOLDER_NOT_DISABLED = palette.neutralSecondary;
      FIELD_GROUP_BACKGROUND_COLOR = palette.white;
      FIELD_COLOR_PLACEHOLDER_NOT_DISABLED = palette.neutralDark;
      FIELD_COLOR_PLACEHOLDER_DISABLED = palette.neutralPrimary;
      FIELD_GROUP_BORDER_COLOR = DarkColors.appGray09;
      SELECTED_BG_COLOR = DarkColors.appPersonaBackground;
      PRIMARY_TEXT_BORDER_BOTTOM_COLOR = DarkColors.appRed;
      PERSONA_HOVER_BACKGROUND_COLOR = DarkColors.pickerPersonaContainerBackground;
    }

    // Contrast theme overrides
    if (themeName === "contrast") {
      PLACEHOLDER_NOT_DISABLED = palette.black;
      FIELD_GROUP_BACKGROUND_COLOR = palette.white;
      SELECTED_BG_COLOR = palette.white;
      FIELD_GROUP_OPACITY = 1;
      FIELD_COLOR_PLACEHOLDER_NOT_DISABLED = palette.black;
      FIELD_COLOR_PLACEHOLDER_DISABLED = ContrastColors.appGreenContrast;
      FIELD_GROUP_BORDER_WIDTH = "1px 1px 1px 1px";
      INPUT_GROUP_BORDER_WIDTH = "0";
      FIELD_GROUP_BORDER_COLOR = palette.black;
      FIELD_GROUP_BORDER_COLOR_DISABLED = ContrastColors.appGreenContrast;
      FIELD_GROUP_BORDER_BOTTOM_COLOR_ACTIVE = palette.themeDark;
      PRIMARY_TEXT_BORDER_BOTTOM_COLOR = ContrastColors.appRed;
      PERSONA_HOVER_BACKGROUND_COLOR = ContrastColors.pickerPersonaContainerBackground;
    }
    const style = {
      itemsWrapper: {
        selectors: {
          ".ms-PickerPersona-container": {
            backgroundColor: SELECTED_BG_COLOR,
            ...(themeName === "contrast" && {
              border: "1px solid white",
            }),
          },
          ".ms-PickerPersona-container, .ms-PickerPersona-container .ms-Button": {
            color: FIELD_COLOR,
            selectors: {
              ":hover": {
                backgroundColor: PERSONA_HOVER_BACKGROUND_COLOR,
                ...(themeName === "contrast" && {
                  color: palette.white,
                  selectors: {
                    ".ms-Persona-details": {
                      selectors: {
                        div: {
                          color: palette.white,
                        },
                      },
                    },
                  },
                }),
              },
            },
          },
          ".ms-PickerPersona-container .ms-Button": {
            selectors: {
              ":hover": {
                selectors: {
                  i: {
                    fontWeight: "bold",
                  },
                },
              },
            },
          },
        },
      },
      text: {
        borderWidth: FIELD_GROUP_BORDER_WIDTH,
        borderStyle: "Solid",
        borderRadius: teamsBorderRadius,
        borderColor: !disabled ? FIELD_GROUP_BORDER_COLOR : FIELD_GROUP_BORDER_COLOR_DISABLED,
        backgroundColor: FIELD_GROUP_BACKGROUND_COLOR,
        opacity: disabled && FIELD_GROUP_OPACITY,
        selectors: {
          "&:hover": {
            borderColor: FIELD_GROUP_BORDER_COLOR,
          },
          "&::after": {
            background: "transparent",
            borderWidth: FIELD_GROUP_BORDER_WIDTH,
            borderColor: FIELD_GROUP_BORDER_BOTTOM_COLOR_ACTIVE,
          },
          ".ms-Persona-details": {
            color: FIELD_COLOR,
            selectors: {
              div: {
                color: FIELD_COLOR,
              },
            },
          },
          ".ms-Persona-primaryText": {
            borderBottomColor: PRIMARY_TEXT_BORDER_BOTTOM_COLOR,
          },
        },
      },
      input: {
        height: "32px",
        background: "transparent",
        color: FIELD_COLOR,
        padding: "0 12px",
        borderWidth: INPUT_GROUP_BORDER_WIDTH,
        borderStyle: "Solid",
        borderRadius: teamsBorderRadius,
        borderColor: !disabled ? FIELD_GROUP_BORDER_COLOR : FIELD_GROUP_BORDER_COLOR_DISABLED,
        selectors: {
          "::placeholder": [
            !disabled && { color: FIELD_COLOR_PLACEHOLDER_NOT_DISABLED },
            disabled && { color: FIELD_COLOR_PLACEHOLDER_DISABLED },
          ],
          ":-ms-input-placeholder": [
            !disabled && { color: FIELD_COLOR_PLACEHOLDER_NOT_DISABLED },
            disabled && { color: FIELD_COLOR_PLACEHOLDER_DISABLED },
          ],
          "&:active, &:focus": {
            selectors: {
              "::placeholder": [!disabled && { color: PLACEHOLDER_NOT_DISABLED }],
              ":-ms-input-placeholder": [!disabled && { color: PLACEHOLDER_NOT_DISABLED }],
            },
          },
        },
      },
    } as Partial<IBasePickerStyles>;

    if (themeName === "contrast") {
      ((style.text as any).selectors["&:hover"] as IRawStyle).borderStyle = "solid";
      ((style.text as any).selectors["&:hover"] as IRawStyle).borderColor = !disabled
        ? `${FIELD_GROUP_BORDER_COLOR} !important`
        : `${FIELD_GROUP_BORDER_COLOR_DISABLED} !important`;
      ((style.text as any).selectors["&:hover"] as IRawStyle).borderWidth =
        FIELD_GROUP_BORDER_WIDTH;
      ((style.text as any).selectors["&:hover"] as IRawStyle).borderRadius = teamsBorderRadius;
    }
    return style;
  };
}
