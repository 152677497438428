import { AxiosResponse } from "axios";
import React, { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { checkUserRoles } from "../../services/rc/permission";
import { UserRole, WEB_API } from "../../services/request";
import { useIdToken } from "../useIdToken";

const userContext = createContext<{ role?: UserRole; idToken?: string }>({
  role: undefined,
  idToken: undefined,
});

const queryFn = () =>
  new Promise<UserRole>((resolve, reject) => {
    checkUserRoles([UserRole.resourceAdmin, UserRole.tenantAdmin])
      .then((res: AxiosResponse<UserRole[]>) => {
        if (res.data && res.data.indexOf(UserRole.tenantAdmin) !== -1) {
          resolve(UserRole.tenantAdmin);
        } else if (res.data && res.data.indexOf(UserRole.resourceAdmin) !== -1) {
          resolve(UserRole.resourceAdmin);
        } else {
          resolve(UserRole.member);
        }
      })
      .catch(e => {
        console.error(e);
        resolve(UserRole.guest);
      });
  });

export function UserProvider({ children }: React.PropsWithChildren<unknown>) {
  const { data: idToken, isError } = useIdToken();
  const { data: role } = useQuery(WEB_API.checkRole, queryFn, {
    enabled: !!idToken,
    refetchInterval: 480_000,
    refetchOnWindowFocus: true,
    staleTime: 300_000, // 5*60*1000
  });
  return (
    <userContext.Provider value={{ idToken, role: isError ? UserRole.guest : role }}>
      {children}
    </userContext.Provider>
  );
}

export function useUser() {
  return useContext(userContext);
}
