import {
  ActionButton,
  FontWeights,
  IButtonStyles,
  makeStyles,
  PrimaryButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { useUser } from "../../hooks/rc/useUser";
import { useQueryWeb } from "../../hooks/useQuery";
import { rem } from "../../lib/unit";
import { SubscribeButtonI18n } from "../../locales";
import { logger } from "../../services/logger";
import { getIdToken, requestWeb, UserRole, WEB_API } from "../../services/request";
import { FontSizes } from "../../themes/fonts";

const useSmallBtnCss = makeStyles<Partial<IButtonStyles>>(theme => ({
  root: {
    border: `0.4px solid ${theme.palette.neutralLight}`,
    borderRadius: rem(10),
    backgroundColor: theme.palette.neutralLighterAlt,
    fontSize: FontSizes.xSmall,
    "&.is-subscribed": {
      color: theme.palette.neutralPrimaryAlt,
    },
    height: rem(20),
    margin: rem(0, 8),
    padding: rem(6, 4),
  },
  icon: {
    padding: rem(2, 0),
    fontSize: FontSizes.xSmall,
  },
  label: { marginLeft: 0 },
  rootHovered: {
    backgroundColor: theme.palette.neutralLight,
    color: theme.palette.neutralPrimary,
  },
  iconHovered: {
    color: theme.palette.neutralPrimary,
  },
  rootPressed: {
    backgroundColor: theme.palette.neutralLighterAlt,
    color: theme.palette.neutralSecondary,
  },
  iconPressed: {
    color: theme.palette.neutralSecondary,
  },
}));

const useLargeBtnCss = makeStyles<Partial<IButtonStyles>>(theme => ({
  root: {
    borderRadius: rem(20),
    fontSize: FontSizes.medium,
    height: rem(32),
    padding: rem(0, 12),
    minWidth: 0,
  },
  rootFocused: {
    borderRadius: rem(20),
    border: "none",
  },
  rootHovered: {
    border: "none",
  },
  rootPressed: {
    border: "none",
  },
}));

const useIconStyle = makeStyles(() => ({
  root: {
    marginRight: 0,
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
  },
}));

export default function SubscribeButton({
  categoryId,
  type = "small",
}: {
  categoryId: string;
  type?: "small" | "large";
}) {
  const { t } = useTranslation();
  const smallCss = useSmallBtnCss();
  const largeCss = useLargeBtnCss();
  const iconStyle = useIconStyle();

  const { role } = useUser();
  const isGuest = role === UserRole.guest;

  const createSubscription = useCallback(
    (feedsCategoryId: string) =>
      requestWeb(WEB_API.createSubscription, {
        method: "POST",
        data: { feedsCategoryId },
      }),
    []
  );

  const deleteSubscription = useCallback(
    (categoryId: string) =>
      requestWeb(WEB_API.deleteFeedsSubscription, {
        method: "DELETE",
        vars: { categoryId },
      }),
    []
  );

  const { refetch, data: subscriptions } = useQueryWeb(WEB_API.mySubscriptions);
  const subscribed = useMemo(
    () => !!(subscriptions && subscriptions.find(s => s.feedsCategoryId === categoryId)),
    [categoryId, subscriptions]
  );
  const [hidden, { setTrue: setBtnHidden }] = useBoolean(subscribed);
  const [disable, { setTrue: setBtnDisable }] = useBoolean(false);

  const onClick = useCallback(
    ev => {
      ev.stopPropagation();
      let succeed = true;
      (isGuest ? getIdToken(false) : Promise.resolve())
        .then(res => {
          if (res) {
            logger?.telemetry("SubscribeConsentSuccess", {
              CategoryId: categoryId,
            });
          }
          (subscribed ? deleteSubscription(categoryId) : createSubscription(categoryId)).then(
            () => {
              refetch().then(() => {
                setBtnDisable();
                // eslint-disable-next-line @dragongate/no-magic-numbers
                setTimeout(setBtnHidden, 1000);
              });
            }
          );
        })
        .catch(() => {
          succeed = false;
        })
        .finally(() => {
          logger?.telemetry("SubscribeAction", {
            CategoryId: categoryId,
            Action: subscribed ? "unsubscribe" : "subscribe",
            ActionSucceed: succeed,
          });
        });
    },
    [
      categoryId,
      createSubscription,
      deleteSubscription,
      isGuest,
      refetch,
      setBtnDisable,
      setBtnHidden,
      subscribed,
    ]
  );

  return (
    <>
      {type === "small" ? (
        <ActionButton
          styles={smallCss}
          className={`${smallCss.root} ${subscribed ? "is-subscribed" : ""}`}
          iconProps={{ iconName: subscribed ? IconName.CheckMark : IconName.Add }}
          onClick={onClick}
          title={
            subscribed ? t(SubscribeButtonI18n.unSubscribeTip) : t(SubscribeButtonI18n.subscribeTip)
          }
        >
          {subscribed ? t(SubscribeButtonI18n.unSubscribe) : t(SubscribeButtonI18n.subscribe)}
        </ActionButton>
      ) : (
        !hidden && (
          <PrimaryButton
            styles={largeCss}
            iconProps={{
              iconName: subscribed ? IconName.CheckMark : IconName.Add,
              styles: iconStyle,
            }}
            onClick={ev => !disable && onClick(ev)}
            title={
              subscribed
                ? t(SubscribeButtonI18n.unSubscribeTip)
                : t(SubscribeButtonI18n.subscribeTip)
            }
          >
            {subscribed ? t(SubscribeButtonI18n.unSubscribe) : t(SubscribeButtonI18n.subscribe)}
          </PrimaryButton>
        )
      )}
    </>
  );
}
