import { AxiosRequestConfig, AxiosInstance, AxiosError } from "axios";

export interface RequestConfig<TParam, TVar, TRequestBody = any>
  extends Pick<
    AxiosRequestConfig,
    "skipAuth" | "retry" | "withCredentials" | "headers" | "method" | "responseType" | "cancelToken"
  > {
  vars?: TVar;
  params?: TParam;
  data?: TRequestBody;
}
/**
 * 替换模板变量
 * @param config
 */
export function replaceVars(config: AxiosRequestConfig) {
  const { vars } = config;
  let url = config.url;
  if (vars && url) {
    Object.keys(vars).forEach(key => {
      // 数组join
      const value = (vars as any)[key];
      url = url!.replace(
        new RegExp(`{${key}}`, "g"),
        value instanceof Array ? value.join(",") : value
      );
    });
    config.url = url;
  }
  return config;
}

const DELAY_TIME = 1000;
const SERVER_ERROR_CODE = 500;

/**
 * 服务器端错误,或者服务发送时重试请求
 * @param axios
 */
export function createRetryErrorInterceptor(axios: AxiosInstance) {
  /**
   * 错误重试
   * @param err
   */
  return (err?: AxiosError) => {
    const retry = err?.config?.retry;
    const statusCode = err?.response?.status;
    if (retry! > 0 && (!statusCode || statusCode >= SERVER_ERROR_CODE)) {
      err!.config!.retry!--;
      return new Promise(r => setTimeout(r, retry! * DELAY_TIME)).then(() => axios(err!.config!));
    } else {
      return Promise.reject(err);
    }
  };
}
