import React from "react";
import "./Swiper.scss";
import { Icon } from "@fluentui/react";
import { IconName } from "../../config/icons";

export class Swiper extends React.Component<
  {
    items: JSX.Element[];
    onSwiped?: (index: number) => void;
    current?: number;
    className?: string;
  },
  { pageIndex: number; marginleft: number }
> {
  private swipe: any = {};
  private minDistance: number;
  private screenWidth: number;
  // save margin after the last touch
  private preMarginleft: number;

  constructor(
    props: Readonly<{
      items: JSX.Element[];
      onSwiped?: (index: number) => void;
      current?: number;
      className?: string;
    }>
  ) {
    super(props);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.scrolltoPage = this.scrolltoPage.bind(this);
    this.increasePageIndex = this.increasePageIndex.bind(this);
    this.decreasePageIndex = this.decreasePageIndex.bind(this);

    this.state = {
      pageIndex: this.props.current || 0,
      marginleft: -(this.props.current || 0),
    };
    this.swipe = {};
    this.minDistance = 0.2;
    this.screenWidth = window.innerWidth;
    this.preMarginleft = 0;
  }

  public render() {
    return (
      <div
        className={this.props.className}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
      >
        <div
          className={`Swiper-container ${this.swipe.swiping ? "" : "page-change"}`}
          style={{
            // eslint-disable-next-line @dragongate/no-magic-numbers
            marginLeft: `${this.state.marginleft * 100}%`,
          }}
        >
          {this.props.items.map(element => (
            <element.type
              {...element.props}
              key={element.key}
              className={`Swiper-item ${element.props.className ?? ""}`}
            />
          ))}
        </div>
        {this.state.pageIndex !== 0 ? (
          <Icon
            iconName={IconName.ChevronLeft}
            className={"Icon-arrow"}
            onClick={this.decreasePageIndex}
          />
        ) : null}
        {this.state.pageIndex < this.props.items.length - 1 ? (
          <Icon
            iconName={IconName.ChevronRight}
            className={"Icon-arrow Right"}
            onClick={this.increasePageIndex}
          />
        ) : null}
      </div>
    );
  }

  public componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  public componentDidUpdate(prevProps: any) {
    if (
      prevProps.current !== this.props.current &&
      this.state.pageIndex !== this.props.current &&
      this.props.current !== undefined
    ) {
      this.scrolltoPage(this.props.current);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  private updateWindowDimensions() {
    this.screenWidth = window.innerWidth;
  }

  private onTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    const touch = e.touches[0];
    this.swipe = { x: touch.clientX };
    this.preMarginleft = this.state.marginleft;
  }

  private onTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (e.changedTouches && e.changedTouches.length) {
      this.swipe.swiping = true;
      const touch = e.changedTouches[0];
      const distance = touch.clientX - this.swipe.x;
      const newMargin = this.preMarginleft + distance / this.screenWidth;

      if (newMargin <= 0 && newMargin >= 1 - this.props.items.length) {
        this.setState({ marginleft: newMargin });
      }
    }
  }

  private onTouchEnd(e: React.TouchEvent<HTMLDivElement>) {
    const touch = e.changedTouches[0];
    const distance = touch.clientX - this.swipe.x;
    if (this.swipe.swiping && Math.abs(distance) > this.minDistance * this.screenWidth) {
      if (distance <= 0) {
        this.increasePageIndex();
      } else {
        this.decreasePageIndex();
      }
    } else {
      this.setState({ marginleft: -this.state.pageIndex });
    }
    this.swipe = {};
  }

  private increasePageIndex() {
    if (this.state.pageIndex < this.props.items.length - 1) {
      this.scrolltoPage(this.state.pageIndex + 1);
    }
  }

  private decreasePageIndex() {
    if (this.state.pageIndex >= 1) {
      this.scrolltoPage(this.state.pageIndex - 1);
    }
  }

  private scrolltoPage(index: number) {
    this.setState({ pageIndex: index, marginleft: -index }, () => {
      this.props.onSwiped && this.props.onSwiped(this.state.pageIndex);
    });
  }
}
