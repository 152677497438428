import React, { useCallback, useState } from "react";
import { IconButton, ImageFit, makeStyles } from "@fluentui/react";
import "./TemplateSwiper.scss";
import { IconName } from "../../../config/icons";
import Img from "../../common/Img";
import { rem } from "../../../lib/unit";
import { useFileToken } from "../../../hooks/useFileToken";

const useIconStyles = makeStyles(theme => ({
  root: { color: theme.palette.neutralPrimary },
  iconDisabled: { color: theme.palette.neutralTertiary },
}));

export interface TemplateSwiperProps {
  items: string[];
  showNumber: number;
  onSwiped?: (index: number) => void;
  onSelectedIndexChanged?: (index: number) => void;
  deafultIndex?: number;
  needPageButton?: boolean;
  direction: "vertical" | "horizontal";
}

export function TemplateSwiper(props: TemplateSwiperProps): React.ReactElement {
  const [index, setPageIndex] = useState<number>(props.deafultIndex || 0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { token } = useFileToken();
  const itemheight = 208;
  const defaultnumber = 5;
  const itemwidth = 176;
  const innerSwiped = props.onSwiped;
  const innerSelectedIndexChanged = props.onSelectedIndexChanged;

  const scrolltoPage = useCallback(
    (index: number) => {
      setPageIndex(index);
      innerSwiped && innerSwiped(index);
    },
    [innerSwiped]
  );

  const increasePageIndex = useCallback(() => {
    if (index + props.showNumber >= props.items.length - props.showNumber) {
      scrolltoPage(props.items.length - props.showNumber);
    } else {
      scrolltoPage(index + props.showNumber);
    }
  }, [index, props.showNumber, props.items.length, scrolltoPage]);

  const decreasePageIndex = useCallback(() => {
    if (index >= props.showNumber) {
      scrolltoPage(index - props.showNumber);
    } else {
      scrolltoPage(0);
    }
  }, [index, props.showNumber, scrolltoPage]);

  const selectSwiperItem = useCallback(
    (itemIndex: number) => {
      setSelectedIndex(itemIndex);
      innerSelectedIndexChanged && innerSelectedIndexChanged(itemIndex);
    },
    [innerSelectedIndexChanged]
  );

  const iconStyles = useIconStyles();
  const isVertical = props.direction === "vertical";

  return (
    <div className={isVertical ? "TemplateSwiper is-vertical" : "TemplateSwiper"}>
      {props.needPageButton ? (
        <IconButton
          iconProps={{
            iconName: isVertical ? IconName.ChevronUp : IconName.ChevronLeft,
          }}
          className={`TemplateSwiper-arrow${index === 0 ? " is-disabled" : ""}`}
          disabled={index === 0}
          onClick={decreasePageIndex}
          styles={iconStyles}
        />
      ) : null}

      <div
        className={
          isVertical
            ? `TemplateSwiper-three`
            : `TemplateSwiper-${props.showNumber === defaultnumber ? "five" : "seven"}`
        }
      >
        <div
          className={`TemplateSwiper-container`}
          style={
            isVertical
              ? {
                  marginTop: rem(-index * itemheight),
                }
              : {
                  marginLeft: rem(-index * itemwidth),
                }
          }
        >
          {props.items.map((item, index) => (
            <Img
              key={index}
              src={`${item}?${token}`}
              alt={`${index}`}
              imageFit={ImageFit.contain}
              className={`TemplateSwiper-item ${selectedIndex === index ? "is-selected" : ""}`}
              onClick={() => {
                selectSwiperItem(index);
              }}
            />
          ))}
        </div>
      </div>
      {props.needPageButton ? (
        <IconButton
          iconProps={{ iconName: isVertical ? IconName.ChevronDown : IconName.ChevronRight }}
          className={`TemplateSwiper-arrow${
            index > props.items.length - props.showNumber - 1 ? " is-disabled" : ""
          }`}
          disabled={index > props.items.length - props.showNumber - 1}
          onClick={increasePageIndex}
          styles={iconStyles}
        />
      ) : null}
    </div>
  );
}
