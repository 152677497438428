import { ACCESS_TOKEN } from "../../config/config";
import { GraphScopes, requestWeb, WEB_API } from "./requestWeb";

const tokenInfo = {
  expiresOn: 0,
  token: "",
  scopes: [
    "Directory.Read.All",
    "Sites.Read.All",
    "Files.ReadWrite.All",
    "Group.Read.All",
  ] as GraphScopes[],
};
let tokenRequest: Promise<typeof tokenInfo> | undefined;

// 获取AccesToken
export function getAccessToken(): Promise<string> {
  if (ACCESS_TOKEN) {
    return Promise.resolve(ACCESS_TOKEN);
  }
  if (tokenInfo.expiresOn > Date.now()) {
    return Promise.resolve(tokenInfo.token);
  }

  if (!tokenRequest) {
    tokenRequest = requestWeb(WEB_API.myAccessToken, {
      method: "POST",
      data: {
        scopes: tokenInfo.scopes,
      },
    }).then(
      tokenResponse => {
        const data = tokenResponse.data;
        tokenInfo.token = data.accessToken;
        tokenInfo.expiresOn = +new Date(data.expiresOn);
        tokenRequest = undefined;
        return tokenInfo;
      },
      err => {
        tokenRequest = undefined;
        return Promise.reject(err);
      }
    );
  }

  return tokenRequest.then(info => info.token);
}
