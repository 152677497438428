import React, { useMemo } from "react";
import {
  makeStyles,
  Text,
  Dropdown,
  Icon,
  IStyleFunctionOrObject,
  IDropdownStyleProps,
  IDropdownStyles,
  IDropdownOption,
} from "@fluentui/react";

import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";
import Img from "../common/Img";

export interface BundleDropDownProps {
  onChange?: (event: React.FormEvent<HTMLDivElement>, bundle?: CDS.Bundle, index?: number) => void;
  bundleOptions: CDS.Bundle[];
  styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
  label?: string;
  placeholder?: string;
}

interface BundleDropDownOption extends IDropdownOption {
  data?: CDS.Bundle;
}

const useStyles = makeStyles(theme => ({
  option: {
    width: "100%",
    "& .option-left": {
      float: "left",
      width: rem(60),
      height: rem(36),
      marginRight: rem(8),
      lineHeight: "calc(100%)",
      "& img": { width: "100%", height: "100%" },
    },
    " .option-right": {
      float: "right",
      width: `calc(100% - ${rem(68)})`,
      height: "100%",
      " span": {
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: rem(20),
      },
      "& .title": {
        color: theme.palette.neutralDark,
        fontWeight: 500,
      },
      "& .context": {
        color: theme.palette.neutralPrimaryAlt,
      },
    },
  },
}));

const useDropDownStyles = makeStyles(theme => ({
  dropdownItem: { minHeight: rem(56) },
  dropdownItemSelected: { minHeight: rem(56) },
  title: {
    "& i": {
      display: "inline",
      marginRight: rem(8),
    },
  },
}));

export default function BundleDropDown({
  label,
  styles,
  placeholder,
  bundleOptions,
  onChange,
}: BundleDropDownProps) {
  const options = useMemo(
    () =>
      bundleOptions.map(bundle => {
        const option: BundleDropDownOption = {
          text: bundle.name,
          key: bundle.id,
          data: bundle,
        };
        return option;
      }),
    [bundleOptions]
  );

  const currentStyles = useStyles();
  const dropDownOwnStyles = useDropDownStyles();
  const dropDownStyles = useMemo(
    () => ({
      ...dropDownOwnStyles,
      ...styles,
    }),
    [dropDownOwnStyles, styles]
  );

  return (
    <Dropdown
      label={label}
      styles={dropDownStyles}
      placeholder={placeholder}
      options={options}
      onChange={(ev, option, index) => onChange && onChange(ev, option?.data as CDS.Bundle, index)}
      onRenderTitle={options =>
        options && options[0] ? (
          <>
            <Icon iconName={IconName.Collection} aria-hidden="true" />
            <span>{options[0].text}</span>
          </>
        ) : (
          <></>
        )
      }
      onRenderOption={data => (
        <div className={currentStyles.option}>
          <Img className="option-left" src={data?.data?.cover} />
          <div className="option-right">
            <Text block className="title">
              {data?.text}
            </Text>
            <Text block className="context">
              {data?.data?.description}
            </Text>
          </div>
        </div>
      )}
    />
  );
}
