import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BundlesHubI18n } from "../../locales";
import { PagePath } from "../../config/routes";
import { navigateTo } from "../../services/history";
import { requestWeb, WEB_API } from "../../services/request";
import MetaEditorDialog from "./MetaEditorDialog";
import { logger } from "../../services/logger";

export default function CreateBundleDialog({
  onDismiss,
  ...props
}: {
  hidden: boolean;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();
  const [data, setData] = useState<Partial<CDS.Bundle>>({});
  const onSubmitClick = useCallback(
    () =>
      requestWeb(WEB_API.myTenantBundles, {
        method: "POST",
        data,
      }).then(res => {
        const bundleId = (res.data as any as CDS.Bundle).id;
        logger?.telemetry("CreateBundleSuccess", {
          BundleId: bundleId,
        });
        navigateTo(PagePath.editBundle, {
          params: {
            id: bundleId,
          },
          state: {
            bundle: res.data,
          },
        });
        onDismiss?.();
        setData({});
      }),
    [data, onDismiss]
  );
  return (
    <MetaEditorDialog
      title={t(BundlesHubI18n.new)}
      hasDesc
      name={data.name}
      cover={data.cover}
      hidden={props.hidden}
      onDismiss={onDismiss}
      onSubmit={onSubmitClick}
      onUpdate={useCallback(i => {
        setData(d => ({ ...d, ...i }));
        return true;
      }, [])}
    />
  );
}
