import { useCallback, useState } from "react";
import { requestWeb, WEB_API } from "../../services/request";
import { RCDocumentType } from "../../services/share";
import DocPickerDialog from "./DocPickerDialog";
import EditNotificationDialog from "./EditNotificationDialog";
import { SortableData } from "../cards/SortableCard";
import { MAX_NOTIFICATION_NUM_MAX } from "../../config/constant";
import { ContentPickerI18n } from "../../locales";
import { useQueryAllPages } from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { DocumentStatus } from "../../config/permission";

const STEP_PCIKER = 1;
const STEP_EIDTOR = 2;
export default function TemplateNotificationDialog({
  hidden,
  onDismiss,
}: {
  hidden?: boolean;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEP_PCIKER);
  const [dataList, setDataList] = useState<SortableData[]>([]);
  const onNext = useCallback((items: SortableData[]) => {
    setDataList(items);
    setStep(STEP_EIDTOR);
  }, []);
  const onBack = useCallback((items: SortableData[]): void => {
    setDataList(items);
    setStep(STEP_PCIKER);
  }, []);
  const onSubmit = useCallback(
    (docs: SortableData[], description?: string) => {
      setDataList(docs);
      return requestWeb(WEB_API.myTenantDocumentsNotify, {
        method: "POST",
        data: { documentIds: docs.map(doc => doc.id!), description },
      }).then(() => {
        onDismiss && onDismiss();
        setStep(STEP_PCIKER);
      });
    },
    [onDismiss]
  );
  const { values: items, indicatorVisible } = useQueryAllPages(
    WEB_API.myTenantDocuments,
    {
      params: {
        $top: 100,
        docTypes: [
          RCDocumentType.PPTTemplate,
          RCDocumentType.WordTemplate,
          RCDocumentType.ExcelTemplate,
        ],
      },
    },
    useCallback(
      doc =>
        doc.metadata?.status === DocumentStatus.Private ||
        doc.metadata?.status === DocumentStatus.Public,
      []
    )
  );
  return (
    <>
      <DocPickerDialog
        hidden={hidden || step !== STEP_PCIKER}
        title={t(ContentPickerI18n.dialogTitle)}
        subTitle={t(ContentPickerI18n.pickerTips, { count: MAX_NOTIFICATION_NUM_MAX })}
        submitText={t(ContentPickerI18n.next)}
        enableShimmer={indicatorVisible}
        maxItems={MAX_NOTIFICATION_NUM_MAX}
        items={items}
        selectedItems={dataList}
        onSubmit={onNext}
        onDismiss={onDismiss}
      />
      <EditNotificationDialog
        hidden={hidden || step !== STEP_EIDTOR}
        dataList={dataList}
        onSubmit={onSubmit}
        onBack={onBack}
        onDismiss={onDismiss}
      />
    </>
  );
}
