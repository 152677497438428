/* eslint-disable @dragongate/no-magic-numbers */
import { AppNoticePageI18n } from "../../locales/namespace";
import "./AppNotice.scss";
import { useTranslation } from "react-i18next";
// import { useEffect, useState } from "react";

export default function NoticePage() {
  const { t } = useTranslation();
  // const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsVisible(true);
  //   }, 2000);
  // }, []);
  // return isVisible ? (
  //   <div className="AppNotice">
  //     <div className="AppNotice-title">{t(AppNoticePageI18n.title)}</div>
  //     <div className="AppNotice-to">{t(AppNoticePageI18n.to)}</div>
  //     <br />
  //     <div className="AppNotice-body">{t(AppNoticePageI18n.body)}</div>
  //     <br />
  //     <div className="AppNotice-wish">{t(AppNoticePageI18n.foot)}</div>
  //     <div className="AppNotice-author">{t(AppNoticePageI18n.author)}</div>
  //   </div>
  // ) : (
  //   <></>
  // );

  return (
    <div className="AppNotice">
      <div className="AppNotice-title">{t(AppNoticePageI18n.title)}</div>
      <div className="AppNotice-to">{t(AppNoticePageI18n.to)}</div>
      <br />
      <div className="AppNotice-body">{t(AppNoticePageI18n.body)}</div>
      <br />
      <div className="AppNotice-wish">{t(AppNoticePageI18n.foot)}</div>
      <div className="AppNotice-author">{t(AppNoticePageI18n.author)}</div>
    </div>
  );
}
