import { ImageFit } from "@fluentui/react";
import React, { useState } from "react";
import { ResourceScope } from "../../../config/rc";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Template } from "../../../models/Template";
import Img from "../../common/Img";
import "./TemplateContentView.scss";
import { TemplateSwiper } from "./TemplateSwiper";

export interface TemplateContentViewProps {
  scope?: ResourceScope;
  template?: Template;
}

export function TemplateContentView({ template }: TemplateContentViewProps): React.ReactElement {
  let viewComponent = null;
  if (!template) {
    return <>{viewComponent}</>;
  }
  const isWord = template.isWord();
  const isExcel = template.isExcel();
  const isPPT = template.isPPT();
  if (isPPT || isExcel) {
    viewComponent = <HorizontalView template={template}></HorizontalView>;
  }
  if (isWord) {
    viewComponent = <VerticalView template={template}></VerticalView>;
  }
  return <>{viewComponent}</>;
}

/**
 * 横板播放 模板
 * @param param0
 */
export function HorizontalView({ template }: TemplateContentViewProps): React.ReactElement {
  const sevenWitdh = 1920;
  const seven = 7;
  const five = 5;

  const [showingPageIndex, setShowingPageIndex] = useState<number>(0);
  const { width } = useWindowSize();
  const curNumber = width >= sevenWitdh ? seven : five;

  return (
    <div className="TemplateContentHorizontalView">
      <Img
        src={template?.pages[showingPageIndex]}
        imageFit={ImageFit.contain}
        className="TemplateContentHorizontalView-img"
      />
      <TemplateSwiper
        direction="horizontal"
        items={template?.pages ?? []}
        showNumber={curNumber}
        deafultIndex={0}
        needPageButton={!!template?.pages?.length && template?.pages?.length > 1}
        onSelectedIndexChanged={setShowingPageIndex}
      />
    </div>
  );
}

/**
 * 竖版播放 模板
 * @param param0
 */
export default function VerticalView({ template }: TemplateContentViewProps): React.ReactElement {
  const [showingPageIndex, setShowingPageIndex] = useState<number>(0);
  const curNumber = 3;

  return (
    <div className="TemplateContentVerticalView">
      <div className="TemplateContentVerticalView-swiper">
        <TemplateSwiper
          direction="vertical"
          items={template?.pages ?? []}
          showNumber={curNumber}
          deafultIndex={0}
          needPageButton={!!template?.pages?.length && template?.pages?.length > 1}
          onSelectedIndexChanged={setShowingPageIndex}
        />
      </div>

      <Img
        className="TemplateContentVerticalView-img"
        src={template?.pages[showingPageIndex]}
        imageFit={ImageFit.contain}
      />
    </div>
  );
}
