import { makeStyles, mergeStyles, Slider, Text } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { timeFormat } from "../../lib/timeFormat";
import { rem } from "../../lib/unit";
import { useTranslation } from "react-i18next";
import { AudioBarI18n } from "../../locales";

const MAX_ONTICKS_DISTANCE_MILLISECOND = 1000;
const PERCENTRATIO = 100;

const useCss = makeStyles(theme => ({
  progress: {
    flex: 1,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  bar: {
    flex: 1,
    backgroundColor: theme.palette.neutralTertiaryAlt,
    height: rem(4),
    position: "relative",
    display: "flex",
    alignItems: "center",
    borderRadius: rem(100),
    overflow: "hidden",
  },
  tickList: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    alignItems: "center",
    pointerEvents: "none",
  },
  tick: {
    position: "absolute",
    backgroundColor: theme.palette.neutralTertiaryAlt,
    width: rem(6),
    height: "100%",
    padding: 0,
  },
  thumb: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    width: rem(12),
    height: rem(12),
    marginLeft: rem(-6),
    backgroundColor: theme.palette.themeDark,
    borderRadius: "50%",
    pointerEvents: "none",
    cursor: "pointer",
  },
  timeTip: {
    position: "absolute",
    top: rem(-20),
    whiteSpace: "nowrap",
  },
}));

const useSliderStyle = makeStyles(theme => ({
  titleLabel: { display: "none" },
  thumb: { display: "none" },
  line: { alignItems: "center" },
  lineContainer: { borderRadius: 0 },
  activeSection: { backgroundColor: theme.palette.themeDarkAlt },
  inactiveSection: {
    backgroundColor: theme.palette.themeTertiary,
  },
  slideBox: {
    padding: 0,
    ":hover .ms-Slider-inactive, :active .ms-Slider-inactive": {
      backgroundColor: theme.palette.themeTertiary,
    },
    ":hover .ms-Slider-active, :active .ms-Slider-active": {
      backgroundColor: theme.palette.themeDarkAlt,
    },
  },
}));

export interface AudioProgressBarProps {
  audioTicks: number[];
  audioDuration: number;
  totalDuration: number;
  disableSeek?: boolean;
  milliSecondComplete?: number;
  onSeek?: (currentTime: number, audioIndex: number) => void;
}

export default function AudioProgressBar({
  audioTicks,
  audioDuration,
  totalDuration,
  disableSeek,
  milliSecondComplete,
  onSeek,
}: AudioProgressBarProps) {
  const css = useCss();
  const sliderStyles = useSliderStyle();
  const [thumbXRatio, setThumbXRatio] = useState(-1);
  const { t } = useTranslation();
  const [progress, setProgress] = useState(milliSecondComplete);

  useEffect(() => setProgress(milliSecondComplete), [milliSecondComplete]);

  const timeTips = useMemo(() => {
    if (thumbXRatio >= 0) {
      const progress = thumbXRatio * totalDuration;
      const index = getAudioIndex(audioTicks, progress);
      return progress - audioTicks[index] < MAX_ONTICKS_DISTANCE_MILLISECOND
        ? t(AudioBarI18n.section, { count: index + 1 })
        : timeFormat(thumbXRatio * totalDuration);
    }
  }, [audioTicks, totalDuration, t, thumbXRatio]);

  return (
    <div className={css.progress}>
      <div
        className={css.bar}
        onMouseMove={useCallback(e => {
          const rect = e.currentTarget.getBoundingClientRect();
          const ratio = (e.clientX - rect.left) / e.currentTarget.clientWidth;
          if (ratio >= 0 && ratio <= 1) {
            setThumbXRatio(ratio);
          }
        }, [])}
        onMouseOut={useCallback(() => {
          setThumbXRatio(-1);
        }, [])}
      >
        <Slider
          styles={sliderStyles}
          className={mergeStyles({
            width: `${(audioDuration / totalDuration) * PERCENTRATIO}%`, // stylelint-disable-line value-keyword-case
          })}
          min={0}
          max={audioDuration}
          showValue={false}
          value={progress}
          onChange={useCallback(
            value => {
              setProgress(value);
              const newAudioIndex = getAudioIndex(audioTicks, value);
              // play from start when near ticks
              const currentTime =
                value - audioTicks[newAudioIndex] < MAX_ONTICKS_DISTANCE_MILLISECOND
                  ? 0
                  : value - audioTicks[newAudioIndex];
              onSeek && onSeek(currentTime, newAudioIndex);
            },
            [audioTicks, onSeek]
          )}
        />
        <div className={css.tickList}>
          {audioTicks.slice(1)?.map((t, index) => (
            <div
              key={index}
              className={css.tick}
              style={{
                left: `calc(${(t / totalDuration) * PERCENTRATIO}%`, // stylelint-disable-line value-keyword-case
              }}
            />
          ))}
        </div>
      </div>
      {!disableSeek && (
        <div
          className={css.thumb}
          style={{
            left: `${thumbXRatio * PERCENTRATIO}%`, // stylelint-disable-line value-keyword-case
            display: thumbXRatio * totalDuration <= audioDuration ? "inherit" : "none", // stylelint-disable-line value-keyword-case
          }}
        >
          <Text className={css.timeTip} variant="small">
            {timeTips}
          </Text>
        </div>
      )}
    </div>
  );
}

function getAudioIndex(array: number[], target: number): number {
  let low = 0;
  let high = array.length - 1;

  while (low <= high) {
    // eslint-disable-next-line @dragongate/no-magic-numbers
    const mid = Math.floor((low + high) / 2);
    if (array[mid] > target) {
      high = mid - 1;
    } else {
      low = mid + 1;
    }
  }
  return high;
}
