import { useCallback, useRef, useMemo } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { CommandBar, ICommandBarItemProps, makeStyles } from "@fluentui/react";
import { requestWeb, WEB_API } from "../../services/request";
import { ResourceScope } from "../../config/rc";
import { useQueryWebPages } from "../../hooks/useQuery";
import { useRouteViewBundleCallback } from "../../hooks/rc/useRoute";
import MixedContent from "./MixedContent";
import CategoryPickerDialog from "../dialog/CategoryPickerDialog";
import { logger } from "../../services/logger";
import { useRemoveFromCategory } from "../../hooks/useRemoveFromCategory";
import { useTranslation } from "react-i18next";
import { BundlesHubI18n } from "../../locales";
import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";
import CreateBundleDialog from "../dialog/CreateBundleDialog";
import { navigateTo } from "../../services/history";
import { PagePath } from "../../config/routes";

const useStyles = makeStyles({
  contentContainer: { height: `calc(100% - ${rem(50)})`, overflow: "scroll" },
});

export default function BundlesHub() {
  const { t } = useTranslation();
  const {
    values: bundles,
    loadMore,
    hasNextPage,
    refetch,
    indicatorVisible,
  } = useQueryWebPages(WEB_API.myTenantBundles, {
    params: { $expand: ["category"] },
  });
  const onClickBundle = useRouteViewBundleCallback({
    params: { scope: ResourceScope.mytenant },
    state: { isAdmin: true },
  });
  const [catePickerHidden, { setTrue: closeCategoryPicker, setFalse: openCategoryPicker }] =
    useBoolean(true);

  const [bundleDialogHidden, { setTrue: closeBundleDialog, setFalse: openBundleDialog }] =
    useBoolean(true);

  const bundleRef = useRef<CDS.Bundle>();
  const onAddBundleToCategory = useCallback(
    (b: CDS.Bundle) => {
      openCategoryPicker();
      bundleRef.current = b;
    },
    [openCategoryPicker]
  );
  const onCategorySubmit = useCallback(
    (categoryId: string) => {
      const bundleId = bundleRef?.current?.id;
      if (bundleId && categoryId) {
        return requestWeb(WEB_API.myTenantCategoryBundles, {
          vars: { categoryId },
          method: "POST",
          data: { bundleIds: [bundleId] },
        })
          .then(() => {
            refetch();
            logger?.telemetry("AddBundleToCategorySuccess", { BundleId: bundleId! });
          })
          .finally(closeCategoryPicker);
      }
    },
    [closeCategoryPicker, refetch]
  );

  const barActions = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        key: "newBundle",
        text: t(BundlesHubI18n.new),
        cacheKey: "createNewBundle",
        iconProps: { iconName: IconName.Add },
        onClick: () => {
          logger?.telemetry("ClickCreateBundleButton");
          openBundleDialog();
        },
      },
    ],
    [t, openBundleDialog]
  );

  const styles = useStyles();
  const onEditBundle = useCallback(
    (bundle: CDS.Bundle) =>
      navigateTo(PagePath.editBundle, { params: { id: bundle.id! }, state: { bundle } }),
    []
  );
  return (
    <>
      <MixedContent
        showCreator
        hideLink
        contentContainerStyle={styles.contentContainer}
        bundles={bundles}
        scope={ResourceScope.mytenant}
        delBundle={useCallback(
          bundleId =>
            requestWeb(WEB_API.myTenantBundle, { vars: { bundleId }, method: "DELETE" })
              .then(() => logger?.telemetry("DeleteBundleSuccess", { BundleId: bundleId }))
              .finally(refetch),
          [refetch]
        )}
        onEditBundle={onEditBundle}
        onAddBundleToCategory={onAddBundleToCategory}
        onRemoveBundleFromCategory={useRemoveFromCategory(refetch)}
        isFetchingBundle={indicatorVisible}
        hasNextPageBundle={hasNextPage}
        loadMoreBundle={loadMore}
        tapBundle={onClickBundle}
        emptyTitle={t(BundlesHubI18n.title)}
        emptySubTitle={t(BundlesHubI18n.content)}
        emptyButtonProps={{
          onClick: openBundleDialog,
          text: t(BundlesHubI18n.create),
        }}
      >
        <CommandBar items={barActions} />
      </MixedContent>
      <CategoryPickerDialog
        hidden={catePickerHidden}
        onSubmit={onCategorySubmit}
        onDismiss={closeCategoryPicker}
        onCreate={closeCategoryPicker}
      />
      <CreateBundleDialog hidden={bundleDialogHidden} onDismiss={closeBundleDialog} />
    </>
  );
}
