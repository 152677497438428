export function getVideoCover(
  videoFile?: File,
  width = 1000,
  height = 560
): Promise<Blob | undefined> {
  return new Promise((resolve, reject) => {
    if (!videoFile || !videoFile.type.startsWith("video") || !width || !height) {
      return resolve(undefined);
    }
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const videoObjUrl = URL.createObjectURL(videoFile);
    if (video && ctx) {
      video.addEventListener("loadeddata", e => {
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(video!, 0, 0, width, height);
        document.body.removeChild(video);
        canvas.toBlob(blob => {
          blob && resolve(blob);
        });
      });
      video.addEventListener("error", () => {
        document.body.removeChild(video);
        reject(video.error?.code);
      });
      video.height = height;
      video.width = width;
      video.src = videoObjUrl;
      video.preload = "auto";
      document.body.appendChild(video);
    }
  });
}
