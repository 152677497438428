import { IPivotStyleProps } from "@fluentui/react";

export function getPivotStyles(themeName: string) {
  return (props: IPivotStyleProps) => {
    const { palette } = props.theme!;
    const styles = {
      link: {
        selectors: {
          ":hover": { backgroundColor: palette.neutralLighter },
          ":active": { backgroundColor: palette.neutralLight },
        },
      },
      linkIsSelected: {
        selectors: {
          ":hover": { backgroundColor: palette.neutralLighter },
          ":active": { backgroundColor: palette.neutralLight },
        },
      },
    };
    return styles;
  };
}
