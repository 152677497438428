import { useForceUpdate } from "@fluentui/react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * 语音以及播放控制
 * @param src
 * @returns
 */
export function useAudio(
  src: string | string[],
  { timeupdate, autoNext }: { timeupdate?: boolean; autoNext?: boolean } = {}
) {
  const audio = useMemo(() => new Audio(), []);
  const audioSrcs = useMemo(() => (typeof src === "string" ? [src] : src), [src]);
  const [index, setIndex] = useState(0);

  // audio src not change if audio playing is remain in src array
  useEffect(() => {
    if (!audio.src || audio.src !== audioSrcs?.[index]) {
      setIndex(0);
      audio.src = audioSrcs[0];
      audio.load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, audioSrcs]);

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    audio.addEventListener("play", forceUpdate);
    audio.addEventListener("pause", forceUpdate);
    audio.addEventListener("ended", forceUpdate);
    return () => {
      audio.removeEventListener("play", forceUpdate);
      audio.removeEventListener("pause", forceUpdate);
      audio.removeEventListener("ended", forceUpdate);
    };
  }, [audio, forceUpdate]);

  useEffect(() => {
    if (timeupdate) {
      audio.addEventListener("timeupdate", forceUpdate);
      return () => audio.removeEventListener("timeupdate", forceUpdate);
    }
  }, [audio, forceUpdate, timeupdate]);

  // auto play next audio
  useEffect(() => {
    if (autoNext) {
      const playNext = () => {
        setIndex(pre => {
          if (pre < audioSrcs.length - 1) {
            audio.src = audioSrcs[pre + 1];
            audio.play().catch(ex => console.warn(ex));
            return pre + 1;
          }
          audio.currentTime = 0;
          return 0;
        });
      };
      audio.addEventListener("ended", playNext);
      return () => audio.removeEventListener("ended", playNext);
    }
  }, [audio, audioSrcs, autoNext]);

  // 销毁时自动停止
  useEffect(
    () => () => {
      !audio.paused && audio.pause();
    },
    [audio]
  );

  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const play = useCallback(() => audio.play().catch(ex => console.warn(ex)), [audio]);
  const pause = useCallback(() => audio.pause(), [audio]);
  const stop = useCallback(() => {
    audio.pause();
    audio.currentTime = 0;
  }, [audio]);
  const seek = useCallback(
    (currentTime: number, audioIndex = 0) => {
      const isPlaying = !audio.paused;
      if (audio.src !== audioSrcs[audioIndex]) {
        setIndex(audioIndex);
        audio.src = audioSrcs[audioIndex];
        audio.load();
      }
      // eslint-disable-next-line @dragongate/no-magic-numbers
      audio.currentTime = currentTime / 1000;
      isPlaying && audio.play();
    },
    [audio, audioSrcs]
  );

  return {
    currentTime: audio.currentTime,
    duration: audio.duration,
    isPlaying: !audio.paused,
    audioIndex: index,
    stop,
    play,
    pause,
    seek,
  };
}
