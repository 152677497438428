import { useCallback, useEffect, useState } from "react";
import {
  Spinner,
  SpinnerSize,
  Stack,
  ActionButton,
  mergeStyles,
  useTheme,
  IContextualMenuItem,
  makeStyles,
  Shimmer,
} from "@fluentui/react";
import { IconName } from "../../config/icons";
import { isMobliePhone, isPrerender } from "../../config/browser";
import { contentHeight } from "../../components/navigation/TopBar";
import { useLocation } from "react-router-dom";
import Content from "./Content";
import { useNavLinks, NavLink } from "../../hooks/rc/useNavLinks";
import { useMobileCss } from "../../hooks/rc/useCss";
import { rem } from "../../lib/unit";
import RCNav from "../../components/rc/RCNav";
import { useTranslation } from "react-i18next";
import { AdminPageI18n } from "../../locales";
import { requestWeb, UserRole, WEB_API } from "../../services/request/requestWeb";
import DeployGuideDialog from "../../components/dialog/DeployGuideDialog";
import AppDeploymentBubble from "../../components/bubble/AppDeploymentBubble";
import { useUser } from "../../hooks/rc/useUser";
import { logger } from "../../services/logger";
import { delStorage, FeedsLanguage, getStorage } from "../../services/storage";
import HeaderBar from "../../components/navigation/HeaderBar";
import { useFeedsLanguage } from "../../hooks/useFeedsLanguage";
// import { useQueryWebPages } from "../../hooks/useQuery";
import { PagePath } from "../../config/routes";
import { useRouteNavCallbck } from "../../hooks/rc/useRoute";
import ConsentButton from "../../components/rc/ConsentButton";
import { Flag } from "../../config/flag";
import { useFlagStatus } from "../../hooks/useFlagStatus";
import UserCreationCenterBubble from "../../components/bubble/UserCreationCenterBubble";
import HomeNavShimmer from "../../components/rc/HomeNavShimmer";
import HomeContentShimmer from "../../components/rc/HomeContentShimmer";
import { history } from "../../services/history";
import { isLimited } from "../../config/app";

const ADMINPAGE_ID = "adminpage";

const useStyles = makeStyles(() => ({
  leftNav: { height: `calc(100% - ${rem(50)})`, padding: rem(0, 20) },
  leftFooter: { height: rem(50) },
  contentShimmer: { padding: rem(20), height: "100%" },
}));

const useShimmerStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
  dataWrapper: {
    height: "100%",
    width: "100%",
  },
  shimmerWrapper: {
    height: "100%",
    width: "100%",
  },
});

const getShimmerShow = (role: UserRole | undefined, hasFeeds: boolean, hasMyTenant: boolean) =>
  !role || (!hasFeeds && !hasMyTenant);

export default function RCActiveHomePage() {
  const { t } = useTranslation();
  const isMobileCss = useMobileCss();

  const [isNavOpen, setIsNavOpen] = useState(!isMobliePhone);
  const [isDeployGuideDialogHidden, setDeployGuideDialogHidden] = useState(true);
  const [isAppDeploymentBubbleHidden, setAppDeploymentBubbleHidden] = useState(true);
  const { feedsLanguage, setFeedsLanguage } = useFeedsLanguage();

  const switchNav = useCallback(() => setIsNavOpen(isOpen => !isOpen), []);
  const { role } = useUser();
  const [isUserCreationEnabled, setIsUserCreationEnabled] = useState<boolean>(false);

  const location = useLocation();
  const theme = useTheme();
  const {
    feedsTree,
    allFeedsDisabled,
    feedsCategoryLinks,
    myTenantTree,
    myTenantCategoryLinks,
    feedsSettled,
    myTenantSettled,
    templateHubNavLinks,
  } = useNavLinks({
    role,
    feedsLanguage,
  });
  const [currentNav, setCurrentNav] = useState<NavLink>();
  const [userCreationCenterFlag, setUserCreationCenterFlag] = useFlagStatus(
    Flag.UserCreationCenterBubble
  );
  const onLinkClick = useCallback((link: NavLink) => {
    setCurrentNav(link);
    history.replace(link.url);
  }, []);
  const onFeedsLanguageChange = (ev?: any, item?: IContextualMenuItem) => {
    if (item) {
      const lng = item.key as FeedsLanguage;
      setFeedsLanguage(lng);
      logger?.telemetry("ChangeFeedsLanguage", { Language: lng, Role: "user" });
    }
  };
  useEffect(() => {
    const nav =
      feedsCategoryLinks?.find(item => item.url === location.hash) ??
      myTenantCategoryLinks?.find(item => item.url === location.hash) ??
      templateHubNavLinks?.find(item => item.url === location.hash) ??
      feedsCategoryLinks?.[0] ??
      myTenantCategoryLinks?.[0];
    nav && feedsSettled && myTenantSettled && onLinkClick(nav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedsCategoryLinks, myTenantCategoryLinks, templateHubNavLinks]);

  const isAdmin = role === UserRole.resourceAdmin || role === UserRole.tenantAdmin;
  const needConsent = role === UserRole.guest || !role;
  // const { values: templates } = useQueryWebPages(WEB_API.myTenantTemplateDocuments);
  const withTemplateHubNav = !isLimited; // !!templates?.length;

  const isShimmerShow = getShimmerShow(role, !!feedsTree, !!myTenantTree);

  useEffect(() => {
    const isConsentClick = getStorage("consentClick");
    if (isConsentClick) {
      if (!needConsent) {
        delStorage("consentClick");
      }
      if (isAdmin) {
        setDeployGuideDialogHidden(false);
      }
    }
  }, [isAdmin, needConsent]);

  useEffect(() => {
    if (currentNav && isMobileCss) {
      setIsNavOpen(false);
    }
  }, [currentNav, isMobileCss]);

  useEffect(() => {
    if (currentNav && location.hash !== currentNav.url) {
      const nav =
        feedsCategoryLinks?.find(item => item.url === location.hash) ||
        myTenantCategoryLinks?.find(item => item.url === location.hash) ||
        templateHubNavLinks?.find(item => item.url === location.hash);
      setCurrentNav(nav);
    }
  }, [currentNav, feedsCategoryLinks, location.hash, myTenantCategoryLinks, templateHubNavLinks]);

  useEffect(() => {
    if (!isLimited) {
      requestWeb(WEB_API.checkUserCreationPermission).then(res => {
        setIsUserCreationEnabled(res.data);
      });
    }
  }, []);

  const onAppDeployClick = useRouteNavCallbck(PagePath.admin);
  const showAdminCenterButton = isAdmin && !isMobliePhone;
  const showConsentButton = false; // don't show consent button for new tenant users // needConsent && !isMobliePhone;
  const showUserCreationCenterButton = !isMobliePhone && isUserCreationEnabled;

  const styles = useStyles();
  const shimmerStyles = useShimmerStyles();

  return isPrerender ? (
    <Spinner size={SpinnerSize.large} styles={{ root: { height: "100vh" } }} />
  ) : (
    <>
      <HeaderBar
        onNavChange={switchNav}
        feedsLanguage={feedsLanguage}
        onFeedsLanguageChange={onFeedsLanguageChange}
        currentNav={currentNav}
        showSetting={!allFeedsDisabled}
        showUserCreation={showUserCreationCenterButton}
      ></HeaderBar>
      <Stack horizontal>
        <Stack.Item
          styles={{
            root: {
              flexShrink: 0,
              display: isNavOpen ? "block" : "none",
              width: isMobileCss ? "100vw" : rem(300),
              height: contentHeight,
              position: "relative",
              overflow: "hidden",
              borderRight: `1px solid ${theme.palette.neutralLight}`,
            },
          }}
        >
          {isShimmerShow ? (
            <HomeNavShimmer className={styles.leftNav}></HomeNavShimmer>
          ) : (
            <RCNav
              className={mergeStyles("Container", {
                height:
                  showAdminCenterButton || showConsentButton ? `calc(100% - ${rem(50)})` : "100%",
                paddingRight: rem(5),
              })}
              pageName="home"
              feedsTree={feedsTree}
              myTenantTree={myTenantTree}
              showTemplateHubNav={withTemplateHubNav}
              templateNavLinks={templateHubNavLinks}
              onLinkClick={onLinkClick}
              feedsLanguage={feedsLanguage}
              selectedKey={currentNav?.key}
            ></RCNav>
          )}

          <div className={styles.leftFooter}>
            <Shimmer isDataLoaded={!isShimmerShow} styles={shimmerStyles}>
              {showAdminCenterButton && (
                <ActionButton
                  styles={{
                    root: {
                      height: rem(50),
                      width: "100%",
                      position: "absolute",
                      bottom: 0,
                      borderTop: `1px solid ${theme.palette.neutralLight}`,
                      paddingLeft: rem(16),
                    },
                    label: {
                      marginLeft: rem(10),
                    },
                  }}
                  iconProps={{
                    id: ADMINPAGE_ID,
                    iconName: IconName.Settings,
                  }}
                  text={t(AdminPageI18n.pageName)}
                  onClick={onAppDeployClick}
                ></ActionButton>
              )}
              {showConsentButton && !isLimited && <ConsentButton />}
            </Shimmer>
          </div>
        </Stack.Item>
        <Stack.Item
          grow={1}
          styles={{
            root: {
              height: contentHeight,
              overflow: "hidden",
            },
          }}
        >
          {isShimmerShow ? (
            <HomeContentShimmer />
          ) : (
            <Content
              contentHashId={location.hash}
              onCategoryError={() => {
                feedsCategoryLinks && onLinkClick(feedsCategoryLinks[0]);
              }}
              isAdmin={false}
            ></Content>
          )}
        </Stack.Item>
      </Stack>
      <DeployGuideDialog
        hidden={isDeployGuideDialogHidden}
        onDismiss={() => {
          setDeployGuideDialogHidden(true);
          setAppDeploymentBubbleHidden(false);
        }}
      />
      <AppDeploymentBubble
        hidden={isAppDeploymentBubbleHidden}
        target={`#${ADMINPAGE_ID}`}
        onDismiss={() => setAppDeploymentBubbleHidden(true)}
      />
      {userCreationCenterFlag && showUserCreationCenterButton && (
        <UserCreationCenterBubble target="#rc-usercreation" onDismiss={setUserCreationCenterFlag} />
      )}
    </>
  );
}
