import { Graph_API, requestGraph } from "../request/requestGraph";
import { PrincipalType } from "../request/requestWeb";
import { groupsQueryBuilder, usersQueryBuilder } from "./queryBuilder";

export function getMe() {
  return requestGraph(Graph_API.me);
}

/**
 * @param {string} userPrincipleName
 */
export function getUser(userId: string) {
  const response = requestGraph(Graph_API.user, {
    vars: {
      userId,
    },
  });

  return response;
}

export function findUsers(query: string, top = 10) {
  return requestGraph(Graph_API.users, {
    params: usersQueryBuilder(query, top, false),
  });
}

export function findGroupMembers(
  query: string,
  groupId: string,
  top = 10,
  principalType = PrincipalType.user,
  transitive = false
) {
  return requestGraph(
    principalType === PrincipalType.user ? Graph_API.groupUserMembers : Graph_API.groupGroupMembers,
    {
      vars: {
        groupId,
        transiveType: transitive ? "transitiveMembers" : "members",
      },
      params: groupsQueryBuilder(query, top),
    }
  );
}
