import {
  ActionButton,
  FontWeights,
  makeStyles,
  Modal,
  Stack,
  StackItem,
  Text,
  ZIndexes,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { useTouchMove } from "../../hooks/useTouchMove";
import { rem } from "../../lib/unit";
import { TemplateTagsI18n } from "../../locales";
import { Dimension, Tag } from "../../services/request";
import { FontSizes } from "../../themes/fonts";
import FilterTag from "../filter/FilterTag";
import TemplateTags, { TemplateTagsProps } from "./TemplateTags";

function getTagBySelectedInfo(
  selectedInfo?: { [key: string]: string },
  dimensions?: Dimension[]
): Tag[] {
  const tagArray: Tag[] = [];
  dimensions?.forEach(d => {
    const tag = d.tags.find(t => t.id === selectedInfo?.[d.id]);
    tag && tagArray.push(tag);
  });
  return tagArray;
}

const useModalStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  main: {
    position: "absolute",
    bottom: 0,
    minWidth: "100%",
    borderRadius: rem(15, 15, 0, 0),
  },
  scrollableContent: {
    padding: rem(16),
    maxHeight: `calc(80vh-${rem(20)})`,
    overflow: "hidden",
  },
}));

const useMobileTagsStyles = makeStyles(theme => ({
  tag: {
    boxSizing: "border-box",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: rem(32),
    lineHeight: rem(32),
    backgroundColor: theme.palette.neutralLighter,
    borderRadius: rem(16),
    color: theme.palette.neutralDark,
    cursor: "default",
    fontSize: FontSizes.mediumPlus,
    margin: rem(0, 4),
    padding: rem(4, 0, 4, 10),
  },
  completeButton: {
    fontSize: FontSizes.mediumPlus,
    color: theme.palette.themePrimary,
    fontWeight: FontWeights.semibold,
  },
  cancelButton: {
    fontSize: FontSizes.mediumPlus,
  },
  clearButton: {
    fontSize: FontSizes.mediumPlus,
    color: theme.palette.themePrimary,
  },
  titleButton: {
    fontSize: FontSizes.mediumPlus,
    fontWeight: FontWeights.bold,
  },
  header: {
    height: rem(20),
  },
  tagsContainer: {
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  controlButton: {
    height: rem(40),
    position: "fixed",
    top: rem(5),
    right: rem(40),
    zIndex: ZIndexes.Nav,
  },
}));

export type TemplateMobileTagsProps = TemplateTagsProps;

export default function TemplateMobileTags({
  dimensions,
  isAdmin,
  onSelected,
  selectedInfo,
}: TemplateMobileTagsProps) {
  const { t } = useTranslation();
  const [newSelectedInfo, setNewSelectedInfo] = useState(selectedInfo);
  const selectedTags = useMemo(
    () => getTagBySelectedInfo(selectedInfo, dimensions),
    [selectedInfo, dimensions]
  );

  const modalStyles = useModalStyles();
  const styles = useMobileTagsStyles();
  const [isModalOpen, { setFalse, toggle }] = useBoolean(false);
  const onComplete = useCallback(() => {
    onSelected && onSelected(newSelectedInfo);
    setFalse();
  }, [onSelected, newSelectedInfo, setFalse]);
  const onCancel = useCallback(() => {
    onSelected && onSelected(selectedInfo);
    setFalse();
  }, [onSelected, selectedInfo, setFalse]);
  const onClose = useCallback(
    (ev, key?: string) => {
      dimensions &&
        setNewSelectedInfo(v => {
          const newValue = { ...v };
          dimensions?.forEach(d => {
            if (newValue && newValue[d.id] === key) {
              delete newValue[d.id];
            }
          });
          onSelected && onSelected(newValue);
          return newValue;
        });
    },
    [dimensions, onSelected]
  );
  const onClear = useCallback(
    ev => {
      setNewSelectedInfo(undefined);
      onSelected && onSelected(undefined);
    },
    [onSelected]
  );

  useEffect(() => {
    setNewSelectedInfo(selectedInfo);
  }, [selectedInfo]);

  useTouchMove(isModalOpen);

  return (
    <>
      {dimensions?.length! > 0 && (
        <ActionButton
          iconProps={{ iconName: IconName.FilterSolid }}
          className={styles.controlButton}
          onClick={() => {
            toggle();
            setNewSelectedInfo(selectedInfo);
          }}
        ></ActionButton>
      )}
      {selectedTags?.length ? (
        <Stack
          horizontal
          horizontalAlign={"space-between"}
          verticalAlign={"center"}
          tokens={{ maxWidth: "100%", padding: rem(6, 11, 0, 9) }}
        >
          <StackItem>
            {selectedTags.map(
              tag =>
                tag && (
                  <FilterTag
                    key={tag?.id}
                    tagId={tag?.id}
                    text={tag?.name}
                    onCancel={onClose}
                  ></FilterTag>
                )
            )}
          </StackItem>
          <Text className={styles.clearButton} onClick={onClear}>
            {t(TemplateTagsI18n.clear)}
          </Text>
        </Stack>
      ) : null}

      <Modal
        isOpen={isModalOpen}
        onDismiss={setFalse}
        allowTouchBodyScroll={true}
        styles={modalStyles}
      >
        <div className={styles.header}>
          <Stack horizontal horizontalAlign={"space-between"}>
            <Text className={styles.cancelButton} onClick={onCancel}>
              {t(TemplateTagsI18n.cancel)}
            </Text>
            <Text className={styles.titleButton}>{t(TemplateTagsI18n.title)}</Text>
            <Text className={styles.completeButton} onClick={onComplete}>
              {t(TemplateTagsI18n.complete)}
            </Text>
          </Stack>
        </div>
        <div className={styles.tagsContainer}>
          <TemplateTags
            selectedInfo={newSelectedInfo}
            dimensions={dimensions}
            isAdmin={isAdmin}
            onSelected={setNewSelectedInfo}
          ></TemplateTags>
        </div>
      </Modal>
    </>
  );
}
