import React, { useCallback } from "react";
import { IDropdownOption, Text, IconButton, makeStyles } from "@fluentui/react";

import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";
import { FontSizes } from "../../themes/fonts";

export interface FileFilterOption extends IDropdownOption {
  docTypeRange: string[];
  maxSize: number;
}
export interface FilterTagProps {
  tagId: string;
  text: string;
  onCancel?: (ev: any, key?: string) => void;
}

const useFilterTagStyles = makeStyles(theme => ({
  root: {
    boxSizing: "border-box",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    height: rem(32),
    lineHeight: rem(32),
    backgroundColor: theme.palette.neutralLighter,
    borderRadius: rem(16),
    color: theme.palette.neutralDark,
    cursor: "default",
    fontSize: FontSizes.mediumPlus,
    margin: rem(0, 4),
    padding: rem(4, 0, 4, 10),
  },
}));

const useFilterTagIconStyles = makeStyles(theme => ({
  root: {
    height: rem(24),
    width: rem(32),
    padding: 0,
    borderRadius: rem(24),
    color: theme.palette.neutralDark,
    backgroundColor: "transparent",
  },
  rootHovered: {
    height: rem(24),
    width: rem(32),
    padding: 0,
    borderRadius: rem(24),
    backgroundColor: "transparent",
  },
  rootPressed: {
    backgroundColor: "transparent",
  },
}));

export default function FilterTag({ tagId, text, onCancel }: FilterTagProps) {
  const fileFilterStyles = useFilterTagStyles();
  const filterTagIconStyles = useFilterTagIconStyles();
  const onClose = useCallback(
    ev => {
      onCancel && onCancel(ev, tagId);
    },
    [tagId, onCancel]
  );
  return (
    <Text className={fileFilterStyles.root}>
      {text}
      <IconButton
        styles={filterTagIconStyles}
        onClick={onClose}
        iconProps={{
          iconName: IconName.Cancel,
        }}
      />
    </Text>
  );
}
