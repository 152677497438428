import { FontWeights, DefaultButton, makeStyles } from "@fluentui/react";
import TopBar from "../navigation/TopBar";
import { rem } from "../../lib/unit";
import { useTranslation } from "react-i18next";
import { Common, View } from "../../locales";
import { FontSizes } from "../../themes/fonts";
import { useMobileCss } from "../../hooks/rc/useCss";
import { PagePath } from "../../config/routes";
import { useRouteNavCallbck } from "../../hooks/rc/useRoute";

const useStyles = makeStyles(theme => ({
  topBarRight: { marginRight: rem(32) },
  topBarLeft: { marginLeft: rem(20) },
  label: {
    fontWeight: FontWeights.semibold,
    color: theme.palette.neutralPrimary,
    fontSize: FontSizes.large,
    marginLeft: rem(4),
  },
}));

export default function PageTitleBar({ title }: { title: string }) {
  const { t } = useTranslation();
  const isMobileCss = useMobileCss();
  const backHome = useRouteNavCallbck(PagePath.home, { replace: true });

  const styles = useStyles();
  return (
    <TopBar
      left={[
        {
          key: "pageTitle",
          text: isMobileCss ? "" : title,
          className: styles.topBarLeft,
          buttonStyles: { label: styles.label },
        },
      ]}
      right={[
        {
          key: "close",
          text: t(Common.close),
          onRender: () => (
            <DefaultButton text={t(View.close)} onClick={backHome} className={styles.topBarRight} />
          ),
        },
      ]}
    />
  );
}
