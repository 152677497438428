import React, { useCallback } from "react";
import { Checkbox, Icon, Shimmer } from "@fluentui/react";
import HorizontalCard from "./HorizontalCard";
import { SortableData } from "./SortableCard";
import { useCustomizeTime } from "../../hooks/useCustomizeTime";
import { rem } from "../../lib/unit";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { Mine } from "../../locales";
import { getDocExtension } from "../../services/share";

export interface SelectableCardProps {
  data?: SortableData;
  onTap?: (doc: SortableData) => void;
  status?: "checked" | "unchecked" | "disabled";
}
export default function SelectableCard({ data, onTap, status }: SelectableCardProps) {
  const { t } = useTranslation();

  const onClick = useCallback(() => onTap && data && onTap(data), [data, onTap]);

  const isBundle = data?.entityType === "bundle";
  const title = data
    ? isBundle
      ? (data as CDS.Bundle).name
      : (data as CDS.Document).metadata?.name
    : "";
  const cover = data
    ? isBundle
      ? (data as CDS.Bundle).cover
      : (data as CDS.Document).metadata?.cover
    : undefined;
  const subTitle = data && isBundle ? (data as CDS.Bundle).stats?.itemCount : undefined;
  const displayTime = useCustomizeTime(
    data
      ? isBundle
        ? (data as CDS.Bundle).createAt
        : (data as CDS.Document).metadata?.create_at
      : undefined
  );

  return (
    <HorizontalCard
      title={title}
      cover={cover}
      onClick={onClick}
      fileExtension={
        !isBundle ? getDocExtension((data as CDS.Document)?.metadata?.type) : undefined
      }
      subTitle={
        subTitle ? (
          <>
            <Icon iconName={IconName.Collection} />
            {t(Mine.content, { count: subTitle })}
          </>
        ) : undefined
      }
      disabled={status === "disabled"}
      footer={
        <Shimmer isDataLoaded={!!data} width="5em">
          {displayTime}
        </Shimmer>
      }
    >
      <Checkbox
        disabled={status === "disabled" || data === undefined}
        checked={status === "checked"}
        styles={{ root: { paddingRight: rem(14) } }}
        onChange={onClick}
      />
    </HorizontalCard>
  );
}
