import { Subject } from "rxjs";
import { guid } from "../lib/guid";
import { TTS, uploadBlob } from "../services/request";
import { TingDoc, Audio, TingPage } from "./TingDoc";
import { filter, debounceTime, distinct, map } from "rxjs/operators";
import { logger } from "../services/logger";

function pass<T>(v: T) {
  return v;
}
interface ObservablePageData {
  index: number;
  diff: Partial<TingPage>;
  page: TingPage;
}

/**
 * @todo ensure audio upload ready before update
 */
export class EditableTingDoc extends TingDoc {
  /**
   * 文档etag
   */
  private readonly _etag?: string;
  /**
   * 所属tenant
   */
  private readonly _tenantId?: string;

  /**
   * 是否自动保存
   */
  public autoSave = true;

  /**
   * upload token
   */
  public token?: { token: string; baseURL: string };
  public readonly pageSubject = new Subject<ObservablePageData>();
  public readonly metaSubject = new Subject<Partial<CDS.DocumentMeta>>();

  public constructor(doc: CDS.Document, tingPages?: TingPage[]) {
    super(doc, true);
    if (!tingPages) {
      const pages = (doc.content as CDS.TingDocumentContent)?.presentation?.pages;
      if (pages) {
        // add notes for editing
        pages.forEach((p, i) => {
          const notedId = p.effects?.note_id;
          if (notedId) {
            this.pages[i].note = this.mediaMap[notedId];
          }
        });
      }
    } else {
      Object.assign(this.pages, tingPages);
    }
    this._etag = doc._etag;
    this._tenantId = doc.tenantId;
  }

  /**
   * 订阅页面数据变化
   * @param pageIndex
   * @param key
   * @param observer
   * @param debounceMs
   * @returns
   */
  public subscribePageData<T extends keyof TingPage>(
    pageIndex: number,
    key: T,
    observer: (value: TingPage[T]) => void,
    debounceMs = 100
  ) {
    const s = this.pageSubject
      .pipe(
        filter(v => v.index === pageIndex && key in v.diff),
        distinct(v => v.diff[key]),
        debounceMs ? debounceTime(debounceMs) : pass,
        map(v => v.page[key])
      )
      .subscribe(observer);
    return s.unsubscribe.bind(s);
  }

  /**
   * 订阅所有页面
   * @param observer
   * @param debounceMs
   * @returns
   */
  public subscribePages(observer: (value: TingPage[]) => void, debounceMs = 100) {
    const s = this.pageSubject
      .pipe(debounceMs ? debounceTime(debounceMs) : pass)
      .subscribe(() => observer([...this.pages]));
    return s.unsubscribe.bind(s);
  }

  /**
   * 插入声音
   * 1. 创建媒体资源(语音和字幕)
   * 2. 插入语音ID
   * 3. 更新本页总时长
   * @param pageIndex 页码
   * @param audio 语音元数据
   */
  public insertTTSAudio(pageIndex: number, audio: TTS.Audio.Response) {
    if (!audio.id) {
      audio.id = this._newId();
    }

    const captions = this._createCaptionMedium(audio.captions);
    const pageAudio: Audio = {
      id: audio.id,
      data: audio.url,
      duration: audio.duration,
      captions: captions.map(c => ({
        id: c.id,
        data: c.data,
        duration: c.meta?.duration!,
        offset: c.meta?.offset!,
      })),
    };
    this.mediaMap[audio.id] = {
      id: audio.id,
      data: audio.url,
      type: "audio",
      data_type: "link",
      meta: {
        /**
         * tts 转换
         */
        voice: audio.voice,
        duration: audio.duration,
      },
    };
    captions.forEach(c => (this.mediaMap[c.id] = c));
    const currentPage = this.pages[pageIndex];
    this._updatePage(pageIndex, {
      audios: (currentPage.audios || []).concat(pageAudio),
      duration: (currentPage.duration || 0) + pageAudio.duration,
    });
  }

  /**
   * 插入声音
   * 1. 创建媒体资源(语音和字幕)
   * 2. 插入语音ID
   * 3. 更新本页总时长
   * @param pageIndex 页码
   * @param audio 语音元数据
   */
  public insertBlobAudio(pageIndex: number, audioBlob: Blob, duration: number) {
    const id = this._newId();
    const localUrl = URL.createObjectURL(audioBlob);
    const currentPage = this.pages[pageIndex];
    const pageAudio: Audio & { task: Promise<any> } = {
      id,
      data: "",
      // data: audio.url,
      _localPath: localUrl,
      duration,
      task: uploadBlob(audioBlob, {
        token: this.token,
      }).then(res => {
        if (this.pages[pageIndex]?.audios.includes(pageAudio)) {
          pageAudio.data = res.file;
          // 此条录音仍在
          this.mediaMap[id] = {
            id,
            type: "audio",
            data: res.file,
            data_type: "link",
            meta: {
              duration,
              voice: "human", // 人工录音
            },
          };
        }
      }),
    };
    this._updatePage(pageIndex, {
      audios: (currentPage.audios || []).concat(pageAudio),
      duration: (currentPage.duration || 0) + pageAudio.duration,
    });
  }

  /**
   * 删除页面语音
   * 1. 删除语音媒体文件
   * 2. 删除字幕
   * 3. 更新本页时间
   * 3. 更新本页语音索引
   * @param pageIndex 页码
   * @param audioId 语音Id
   */
  public deleteAudio(pageIndex: number, audioId: string) {
    const currentPage = this.pages[pageIndex];
    const audios = currentPage?.audios;
    const audio = audios?.find(a => a.id === audioId);
    if (audio) {
      // 删除语音 和 字幕
      logger?.telemetry("DeleteAudio", {
        Duration: audio.duration,
        DocId: this.id!,
      });
      delete this.mediaMap[audioId];
      audio.captions?.forEach(a => delete this.mediaMap[a.id!]);
      this._updatePage(pageIndex, {
        audios: audios.filter(a => a.id !== audioId),
        duration: Math.round(currentPage.duration - audio.duration),
      });
    }
  }

  /**
   * 添加备注
   * 1. 检查 note 是否存在
   * 2. 如果已存在直接替换文本
   * 3. 如果不存在创建meida 并写入
   * @param pageIndex 页码索引
   * @param text 备注
   */
  public setPageNote(pageIndex: number, text: string): void {
    const note = this.pages[pageIndex].note;

    if (note) {
      note.data = text;
      this.mediaMap[note.id].data = text;
      this._updatePage(pageIndex, { note: { ...note } });
    } else {
      const id = this._newId();
      const noteMedium: CDS.Medium = {
        id,
        data: text,
        type: "text",
      };
      this.mediaMap[id] = noteMedium;
      this._updatePage(pageIndex, {
        note: noteMedium,
      });
    }
  }

  /**
   * 设置封面图
   * @param cover
   */
  public setCover(cover: string) {
    this.metadata.cover = cover;
    this.metaSubject.next({ cover });
  }

  /**
   * 修改标题
   * @param title
   */
  public setTitle(title: string) {
    this.metadata.name = title.replace(/[\r\n]+/g, " ");
    this.metaSubject.next({ name: title });
  }

  /**
   * parse to CDS document
   * @returns new cds document
   */
  public toDocument(): CDS.Document {
    return {
      id: this.id,
      metadata: { cover: this.pages[0].img, ...this.metadata, type: "tingdoc" },
      extended: this.extended,
      content: this.getContent(),
      _etag: this._etag,
      tenantId: this._tenantId,
    };
  }

  /**
   * get TingDocumentContent
   * @returns
   */
  public getContent(): CDS.TingDocumentContent {
    const media = Object.values(this.mediaMap);
    return {
      $schema: "https://dragongate.live.com/v1.1/cds/schema/document/content.json",
      media,
      presentation: {
        pages: this.pages.map(p => {
          const audios = p.audios?.filter(a => this.mediaMap[a.id]) || [];
          return {
            id: p.id,
            background_img_id: p.img
              ? media.find(m => m.data === p.img || m.urls?.includes(p.img!))?.id
              : undefined,
            duration: audios.reduce((duration, a) => a.duration + duration, 0),
            elements: [
              ...audios.map<CDS.Element>(a => ({
                media_id: a.id,
                type: "audio",
                captions: a.captions?.map(c => c.id!),
              })),
              ...(p.video ? [{ media_id: p.video.id, type: "video" } as CDS.Element] : []),
            ],
            effects: {
              note_id: p.note?.id,
            },
          };
        }),
      },
    };
  }

  public toJSON() {
    return {};
  }

  /**
   * 从 Caption 创建 Medium
   */
  private _createCaptionMedium(captions: TTS.Caption[]): CDS.Medium[] {
    const mediums = captions.map(
      caption =>
        ({
          data: caption.displayText,
          id: this._newId(),
          data_type: "raw",
          type: "text",
          meta: { duration: caption.duration, offset: caption.offset },
        } as CDS.Medium)
    );
    return mediums;
  }

  private _updatePage(index: number, data: Partial<TingPage>) {
    this.pages[index] = {
      ...this.pages[index],
      ...data,
    };
    this.pageSubject.next({ index, diff: data, page: this.pages[index] });
  }
  private _newId() {
    return guid();
  }
}
