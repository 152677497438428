import { FontWeights, IFontStyles } from "@fluentui/react";
import { isMobliePhone } from "../config/browser";
import { rem } from "../lib/unit";

/**
 * 自定义字体大小(rem)
 * 尽量使用theme: styles={(p)=>[{fontSize:p.theme.fonts.small}]}
 */
export const FontSizes = {
  // mini: rem(10),
  /**
   * 超小 10
   */
  xSmall: isMobliePhone ? rem(9) : rem(10),
  /**
   * 小号 12
   */
  small: isMobliePhone ? rem(10) : rem(12),
  /**
   * 默认 14
   */
  medium: isMobliePhone ? rem(12) : rem(14),
  /**
   * @deprecated
   */
  mediumPlus: isMobliePhone ? rem(13) : rem(16),
  /**
   * 图标 16
   */
  icon: isMobliePhone ? rem(14) : rem(16),
  /**
   * 标题 18
   */
  large: isMobliePhone ? rem(14) : rem(18),
  /**
   * @deprecated
   */
  xLarge: isMobliePhone ? rem(16) : rem(20),
  /**
   * 大标题 24
   */
  xLargePlus: isMobliePhone ? rem(18) : rem(24),
  /**
   * @deprecated
   */
  xxLarge: isMobliePhone ? rem(20) : rem(28),
  /**
   * @deprecated
   */
  xxLargePlus: isMobliePhone ? rem(24) : rem(32),
  /**
   * @deprecated
   */
  superLarge: isMobliePhone ? rem(30) : rem(42),
  /**
   * @deprecated
   */
  mega: isMobliePhone ? rem(40) : rem(68),
} as const;

/**
 * Icon 大小
 */
export const IconFontSizes = {
  xSmall: rem(10),
  small: rem(12),
  medium: rem(16),
  large: rem(20),
} as const;

export const fonts: IFontStyles = {
  tiny: {
    fontSize: FontSizes.xSmall,
  },
  xSmall: {
    fontSize: FontSizes.xSmall,
  },
  small: {
    fontSize: FontSizes.small,
    // lineHeight: isMobliePhone ? rem(12) : rem(16),
  },
  smallPlus: {
    fontSize: FontSizes.small,
  },
  medium: {
    fontSize: FontSizes.medium,
    lineHeight: isMobliePhone ? rem(16) : rem(20),
  },
  mediumPlus: {
    fontSize: FontSizes.mediumPlus,
  },
  large: {
    fontSize: FontSizes.large,
    lineHeight: isMobliePhone ? rem(20) : rem(24),
    fontWeight: FontWeights.semibold,
  },
  xLarge: {
    fontSize: FontSizes.xLarge,
  },
  xLargePlus: {
    fontSize: FontSizes.xLargePlus,
    lineHeight: isMobliePhone ? rem(24) : rem(32),
    fontWeight: FontWeights.semibold,
  },
  xxLarge: {
    fontSize: FontSizes.xxLarge,
  },
  xxLargePlus: {
    fontSize: FontSizes.xxLargePlus,
  },
  superLarge: {
    fontSize: FontSizes.superLarge,
  },
  mega: {
    fontSize: FontSizes.mega,
  },
};
