import React from "react";
import { RouteProps } from "react-router-dom";
import { AboutPage } from "../pages/About";
import RCHomePage from "../pages/rc/Home";
import RCBundleDetailPage from "../pages/rc/RCBundleDetail";
import RCViewPage from "../pages/rc/RCView";
import RCAdminPage from "../pages/rc/Admin";
import ConsentCompletePage from "../pages/ConsentComplete";
import EditPage from "../pages/rc/Edit";
import { ResourceScope } from "./rc";
import EditBundlePage from "../pages/rc/EditBundle";
import RCMePage from "../pages/rc/Me";
import NoMatchPage from "../pages/rc/NoMatch";
import { PUBLIC_URL } from "./config";
import CreateTingDocPage from "../pages/rc/CreateTingDoc";
import NoticePage from "../pages/rc/NoticePage";

type ExtractRouteOptionalParam<T extends string, U = string> = T extends `${infer Param}?`
  ? Param extends keyof StrongTypeFiled
    ? { [k in Param]?: StrongTypeFiled[Param] }
    : { [k in Param]?: U }
  : T extends keyof StrongTypeFiled
  ? { [k in T]: StrongTypeFiled[T] }
  : { [k in T]: U };

export type ExtractRouteParams<T extends string, U = string> = string extends T
  ? { [k in string]?: U }
  : T extends `${string}:${infer Param}/${infer Rest}`
  ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
  : T extends `${string}:${infer Param}`
  ? ExtractRouteOptionalParam<Param, U>
  : // eslint-disable-next-line @typescript-eslint/ban-types
    {};

export type ExtractRoutePath<T extends string> = T extends `${infer Start}:${string}/${infer Rest}`
  ? Start extends `${string}/`
    ? ExtractRoutePath<`${Start}${Rest}`>
    : ExtractRoutePath<`${Start}/${Rest}`>
  : T extends `${infer Start}:${string}`
  ? Start extends `${infer SS}/`
    ? `${SS}.html`
    : `${Start}.html`
  : T extends `${string}/`
  ? `${T}index.html`
  : `${T}.html`;

export enum PagePath {
  /**
   * 首页介绍 `/index.html`
   */
  about = "/",
  /**
   * 授权完成 `/consentcomplete.html`
   */
  consentcomplete = "/consentcomplete/:tenant/:state?",
  /**
   * APP 首页 `/rc/index.html`
   */
  home = "/rc/",
  /**
   * 管理员操作 `/rc/admin.html`
   */
  admin = "/rc/admin",
  /**
   * 普通用户操作 `/rc/me.html`
   */
  me = "/rc/me",
  /**
   * 播放页 `/rc/view.html`
   */
  view = "/rc/view/:scope/:docId/:categoryId?/:bundleId?",
  /**
   * 编辑 `/rc/edit.html`
   */
  edit = "/rc/edit/:id?",
  /**
   * 编辑 `/rc/editbundle.html`
   */
  editBundle = "/rc/editbundle/:id?",
  /**
   * 合辑页 `/rc/bundles.html`
   */
  bundle = "/rc/:scope/bundles/:bundleId/:categoryId?",
  /**
   * 网页端创建TingDoc页面
   */
  createTingDoc = "/rc/createTingDoc",
  /**
   *
   */
  notice = "/rc/notice",
  /**
   *
   */
  noMatch = "*",
}

export type PageName = keyof typeof PagePath;

interface StrongTypeFiled {
  scope: ResourceScope;
}
function getPageHtmlPath<T extends PagePath>(page: T): ExtractRoutePath<T> {
  const url = page
    .replace(/\/(:.+?)\//g, "/")
    .replace(/\/:.*$/, ".html")
    .replace(/\/$/, "/index.html");
  return (url.endsWith(".html") ? url : `${url}.html`) as ExtractRoutePath<T>;
}
function getAllPaths<T extends PagePath>(page: T, alias?: string[]): (T | ExtractRoutePath<T>)[] {
  const htmlPage = getPageHtmlPath(page);
  return alias ? [page, htmlPage].concat(alias as T[]) : [page, htmlPage];
}

interface RouteFCProps extends RouteProps {
  Component: React.ComponentType<any>;
  path: string;
}

function getRoutesFromPaths<T extends PagePath>(
  Component: React.ComponentType<any>,
  page: T,
  alias?: string[]
): RouteFCProps[] {
  const pageUrlList = getAllPaths(page, alias);
  return pageUrlList.map(item => ({
    Component,
    path: item,
  }));
}

export const routes: RouteFCProps[] = [
  ...getRoutesFromPaths(AboutPage, PagePath.about, ["/about.html"]),
  ...getRoutesFromPaths(ConsentCompletePage, PagePath.consentcomplete),
  ...getRoutesFromPaths(RCHomePage, PagePath.home, ["/rc/home", "/rc/home.html"]),
  ...getRoutesFromPaths(RCBundleDetailPage, PagePath.bundle),
  ...getRoutesFromPaths(RCViewPage, PagePath.view),
  ...getRoutesFromPaths(RCAdminPage, PagePath.admin),
  ...getRoutesFromPaths(RCMePage, PagePath.me),
  ...getRoutesFromPaths(EditPage, PagePath.edit),
  ...getRoutesFromPaths(EditBundlePage, PagePath.editBundle),
  ...getRoutesFromPaths(CreateTingDocPage, PagePath.createTingDoc),
  ...getRoutesFromPaths(NoticePage, PagePath.notice),
  ...getRoutesFromPaths(NoMatchPage, PagePath.noMatch),
];

/**
 * generate url Link with parameters
 * @param path
 * @param param
 * @returns
 */
export function generateURL<T extends PagePath>(path: T, param?: ExtractRouteParams<T>) {
  return `${
    PUBLIC_URL && window.location.pathname.startsWith(PUBLIC_URL) ? PUBLIC_URL : ""
  }${getPageHtmlPath(path)}${param ? `?${new URLSearchParams(param as any).toString()}` : ""}`;
}
