import { PrimaryButton } from "@fluentui/react";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { EditorI18n, RecorderI18n, RecorderButtonI18n } from "../../locales";
import { requestWeb, TTS, WEB_API } from "../../services/request";
import { useRecordBtn } from "./RecorderButton";

export interface TTSButtonProps {
  audioRequest: TTS.Audio.Request;
  disabled?: boolean;
  onClick?: () => void;
  onComplete?: (res: TTS.Audio.Response) => void;
  onLoadingStateChange?: (isloading: boolean) => void;
  onErrorMsgChange?: (errorMsg: string) => void;
}
export default function TTSButton({
  audioRequest,
  disabled,
  onClick,
  onComplete,
  onLoadingStateChange,
  onErrorMsgChange,
}: TTSButtonProps) {
  const recordBtnCss = useRecordBtn();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onRecordBtnClick = useCallback(() => {
    if (!disabled && !isLoading && audioRequest?.text) {
      setIsLoading(true);
      onErrorMsgChange && onErrorMsgChange("");
      onLoadingStateChange && onLoadingStateChange(true);
      requestWeb(WEB_API.ttsAudios, {
        method: "POST",
        data: audioRequest,
      })
        .then(res => onComplete && onComplete(res.data))
        .catch(
          err =>
            onErrorMsgChange &&
            onErrorMsgChange(
              t(EditorI18n.audioGenerationError, { context: err.response?.data?.code || " " })
            )
        )
        .finally(() => {
          setIsLoading(false);
          onLoadingStateChange && onLoadingStateChange(false);
        });
    }
    onClick && onClick();
  }, [
    audioRequest,
    disabled,
    isLoading,
    onClick,
    onComplete,
    onErrorMsgChange,
    onLoadingStateChange,
    t,
  ]);

  const disabledClick = useCallback(() => {
    disabled &&
      onErrorMsgChange &&
      onErrorMsgChange(t(RecorderButtonI18n.error, { context: "aiDisabled" }));
  }, [disabled, onErrorMsgChange, t]);

  return (
    <div onClick={disabledClick}>
      <PrimaryButton
        title={isLoading ? t(EditorI18n.ttsProgress) : t(RecorderI18n.aiRecorder)}
        disabled={disabled || isLoading}
        styles={recordBtnCss}
        text={t(RecorderButtonI18n.generate)}
        iconProps={{ iconName: IconName.AIRecorder }}
        onClick={onRecordBtnClick}
      />
    </div>
  );
}
