import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { logger } from "../services/logger";

// function mapErrorState({ message }: Error) {
//   if (message === "Requested device not found") {
//     return "deviceNotFound";
//   } else if (message === "Permission denied") {
//     return "denied";
//   } else {
//     return "error";
//   }
// }

export type recorderStatus =
  | "inactive"
  | "recording"
  | "paused"
  | "denied"
  | "granted"
  | "prompt"
  | "deviceNotFound"
  | "error";

function getUserMedia(recorder?: MediaRecorder): Promise<MediaRecorder> {
  return new Promise<MediaRecorder>((resove, reject) => {
    if (recorder) {
      return Promise.resolve(recorder);
    }
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      stream => {
        const recorder = new MediaRecorder(stream);
        resove(recorder);
      },
      error => {
        console.error(error);
        logger?.error(error, "getUserMedia");

        if (error?.message === "Requested device not found") {
          error.status = "deviceNotFound";
        } else if (error?.message === "Permission denied") {
          error.status = "denied";
        } else if (error?.message === "Could not start audio source") {
          error.status = "notStart";
        } else if (error) {
          error.status = "error";
        } else {
          error = { status: "error" };
        }
        reject(error);
      }
    );
  });
}
/**
 * 录音
 * @param callback
 * @returns
 */
export function useAudioRecorder(
  callback?: (blob: Blob, duration: number) => void
  // maxDuration?: number,
  // autoInit?: boolean
) {
  const [status, setStatus] = useState<recorderStatus>();
  const stateRef = useRef<{
    startTime?: number;
    stopTime?: number;
    recorder?: MediaRecorder;
    cb?: (blob: Blob, duration: number) => void;
    _stop: () => void;
    _start: (timeslice?: number) => Promise<MediaRecorder>;
    // _initRecorder: () => Promise<MediaRecorder>;
    // _maxDuration?: number;
    // _durationTimmer?: number;
  }>({
    cb: callback,
    _start: timeslice =>
      getUserMedia().then(
        recorder => {
          stateRef.current.recorder = recorder;
          recorder.onstart = e => {
            stateRef.current.startTime = e.timeStamp;
            stateRef.current.stopTime = undefined;
            setStatus("recording");
          };
          recorder.onstop = e => {
            stateRef.current.stopTime = e.timeStamp;
            setStatus("inactive");
          };
          recorder.ondataavailable = be => {
            const duration = Math.round(
              (stateRef.current.stopTime || be.timeStamp) - stateRef.current.startTime!
            );
            stateRef.current.cb?.(be.data, duration);
            stateRef.current.startTime = undefined;
          };
          // setStatus(recorder.state);
          recorder.start(timeslice);
          return recorder;
        },
        ({ status }: { status: recorderStatus }) => {
          setStatus(status);
          return Promise.reject(status);
        }
      ),
    _stop: () => {
      if (stateRef.current.recorder?.state === "recording") {
        stateRef.current.recorder.stop();
      }
      stateRef.current.recorder?.stream?.getTracks().forEach(t => t.stop());
      stateRef.current.recorder = undefined;
    },
  });
  stateRef.current.cb = callback;
  // stateRef.current._maxDuration = maxDuration;

  // 自动刷新 state状态
  const refreshPermission = useCallback(
    () =>
      navigator.permissions?.query({ name: "microphone" as PermissionName }).then(({ state }) => {
        if (status !== "recording" && status !== state && status !== "deviceNotFound") {
          setStatus(state);
        }
        if (state === "denied" && stateRef.current.recorder) {
          // 取消授权清除已有recorder
          stateRef.current._stop();
          stateRef.current.recorder = undefined;
        }
      }),
    [status]
  );
  useEffect(() => {
    refreshPermission();
  });

  useEffect(
    () =>
      //
      () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        stateRef.current._stop();
      },
    []
  );
  return useMemo(
    () =>
      ({
        /**
         * 录音状态
         */
        status,
        /**
         * 是否正在录音
         */
        isRecording: status === "recording",
        isError: status === "denied" || status === "deviceNotFound" || status === "error",
        /**
         * 开始录音
         */
        record: stateRef.current._start,
        /**
         * 停止录音
         */
        stop: stateRef.current._stop,
        /**
         * 检查权限
         */
        refreshPermission,
        // pause: stateRef.current.recorder?.pause;
      } as const),
    [refreshPermission, status]
  );
}
