import { useEffect, useState } from "react";
import { useSetTimeout } from "@fluentui/react-hooks";

export function useImgError(maxErrorNum: number, imgSrc?: string, reloadTime?: number) {
  const [reloadNum, setReloadNum] = useState(0);
  const [clearId, setClearId] = useState<number | undefined>();
  const { setTimeout, clearTimeout } = useSetTimeout();
  useEffect(() => setReloadNum(0), [imgSrc]);
  useEffect(
    () => () => {
      typeof clearId === "number" && clearTimeout(clearId);
    },
    [clearId, clearTimeout]
  );

  return (imgObj: HTMLImageElement | React.SyntheticEvent<HTMLImageElement, Event>) => {
    const clearId = setTimeout(() => {
      const img = imgObj instanceof Image ? imgObj : imgObj.currentTarget;
      if (img && imgSrc && reloadNum < maxErrorNum) {
        img.src = imgSrc;
        setReloadNum(s => s + 1);
      }
    }, reloadTime || 0);
    setClearId(clearId);
  };
}
