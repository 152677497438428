import React, { useCallback } from "react";
import {
  Callout,
  FocusZone,
  Label,
  Checkbox,
  IDropdownOption,
  ActionButton,
  FontSizes,
  FontWeights,
  DirectionalHint,
  makeStyles,
} from "@fluentui/react";

import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { FileFilterI18n, FilePicker } from "../../locales";
import { useBoolean, useControllableValue, useId } from "@fluentui/react-hooks";
import FilterTag from "./FilterTag";

export interface FileFilterOption extends IDropdownOption {
  docTypeRange: string[];
  maxSize: number;
}
export interface FileFilerProps {
  options: FileFilterOption[];
  selected?: string[];
  onChange?: (keys?: string[]) => void;
}

const useFileFilterStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.black,
    fontSize: FontSizes.size12,
    fontWeight: FontWeights.bold,
    lineHeight: FontSizes.size16,
    margin: "0 1.458em",
  },
  callout: {
    padding: "10px 0",
  },
}));

const useCheckboxStyles = makeStyles(theme => ({
  root: {
    margin: "0.5em 1.25em",
    selectors: {
      ".ms-Checkbox-label": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
  checkbox: {
    border: `1px solid ${theme.palette.neutralDark}`,
  },
  text: {
    color: theme.palette.neutralDark,
    fontSize: FontSizes.size14,
    lineHeight: FontSizes.size20,
  },
}));

export default function FileFilter({ options, onChange, selected }: FileFilerProps) {
  const { t } = useTranslation();
  const [isVisible, { setFalse, toggle }] = useBoolean(false);
  const [selectedKeys, setSelectedKeys] = useControllableValue(selected, [], (ev, newValue) => {
    onChange && onChange(newValue);
  });

  const onCancel = useCallback(
    (ev, id) => {
      const key: string = id;
      setSelectedKeys(keys => {
        let newKeys: string[] | undefined;
        if (!keys) {
          newKeys = [key];
        } else {
          const keyIndex = keys.indexOf(key);
          if (keyIndex !== -1) {
            keys.splice(keyIndex, 1);
            newKeys = [...keys];
          } else {
            newKeys = [...keys, key];
          }
        }
        return newKeys;
      });
    },
    [setSelectedKeys]
  );
  const onChangeCheck = useCallback(
    ev => {
      const key: string = ev.currentTarget.dataset.key;
      onCancel(ev, key);
    },
    [onCancel]
  );
  const fileFilterStyles = useFileFilterStyles();
  const checkboxStyles = useCheckboxStyles();
  const refId = useId("filterButton");
  return (
    <>
      {options
        .filter(i => !i.hidden && selectedKeys?.includes(`${i.key}`))
        .map(item => (
          <FilterTag tagId={`${item.key}`} text={item.text} onCancel={onCancel}></FilterTag>
        ))}

      <ActionButton
        id={refId}
        iconProps={{
          iconName: IconName.FilterSolid,
        }}
        text={`${t(FileFilterI18n.title)}${
          selectedKeys?.length ? `(${selectedKeys?.length})` : ""
        }`}
        onClick={toggle}
      ></ActionButton>
      {isVisible && (
        <Callout
          onDismiss={setFalse}
          target={`#${refId}`}
          directionalHint={DirectionalHint.bottomRightEdge}
          calloutWidth={278}
          isBeakVisible={false}
          className={fileFilterStyles.callout}
          setInitialFocus
        >
          <FocusZone>
            <Label className={fileFilterStyles.label}>{t(FilePicker.type)}</Label>
            {options
              .filter(i => !i.hidden)
              .map(item => (
                <Checkbox
                  inputProps={
                    {
                      "data-key": item.key,
                    } as any
                  }
                  key={item.key}
                  label={item.text}
                  boxSide="end"
                  checked={selectedKeys?.includes(`${item.key}`)}
                  onChange={onChangeCheck}
                  styles={checkboxStyles}
                />
              ))}
          </FocusZone>
        </Callout>
      )}
    </>
  );
}
