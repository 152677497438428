/* istanbul ignore file */
import { ICheckboxStyleProps, ICheckboxStyles, ITheme } from "@fluentui/react";
import { teamsBorderRadius } from "./styleConstants";
import { CoreColors } from "../teamsColors";

// eslint-disable-next-line max-lines-per-function
export function getCheckboxStyles(themeName: string, _theme: ITheme) {
  // eslint-disable-next-line max-lines-per-function, complexity
  return (props: ICheckboxStyleProps): Partial<ICheckboxStyles> => {
    const { disabled, checked, theme } = props;
    const { palette } = theme;

    let CHECKBOX_BORDER_COLOR = palette.neutralTertiary;
    let CHECKBOX_LABEL_COLOR = palette.black;
    let CHECKBOX_DISABLED_LABEL_COLOR = palette.neutralTertiary;

    let CHECKBOX_COLOR = palette.themePrimary;
    let CHECKBOX_CHECKED_HOVER_COLOR = palette.black;
    let CHECKMARK_COLOR = palette.white;

    if (themeName === "defaultV2" || themeName === "darkV2") {
      CHECKBOX_LABEL_COLOR = palette.neutralPrimary;
      CHECKBOX_CHECKED_HOVER_COLOR = palette.neutralDark;
    }

    if (themeName === "darkV2") {
      CHECKBOX_COLOR = CoreColors.appBrand06;
      CHECKMARK_COLOR = palette.black;
    }
    if (themeName === "contrast") {
      CHECKBOX_BORDER_COLOR = "green";
      CHECKBOX_DISABLED_LABEL_COLOR = "green";
    }

    const styles = {
      input: {
        selectors: {
          ".ms-Fabric--isFocusVisible &": {
            selectors: {
              ":focus + label": {
                selectors: {
                  "::before": {
                    top: "-2px",
                    left: "-4px",
                    bottom: "-3px",
                    right: "-5px",
                    outline: "none",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: palette.black,
                    borderRadius: teamsBorderRadius,
                  },
                },
              },
            },
          },
        },
      },
      checkbox: [
        {
          borderRadius: 4,
          height: 17,
          width: 17,
        },
        !disabled &&
          !checked && {
            borderColor: palette.black,
          },
        disabled && {
          borderColor: CHECKBOX_BORDER_COLOR,
        },
        checked &&
          disabled && {
            background: CHECKBOX_BORDER_COLOR,
            borderColor: CHECKBOX_BORDER_COLOR,
          },
        !disabled &&
          checked && {
            background: palette.themePrimary,
          },
      ],
      checkmark: [
        {
          fontWeight: "bold",
          fontSize: 12,
          border: "none",
          background: "none",
          color: CHECKMARK_COLOR,
        },
      ],
      root: [
        {
          color: palette.neutralDark,
          selectors: {
            a: {
              color: "inherit",
              selectors: {
                ":link": {
                  color: "inherit",
                },
                ":visited": {
                  color: "inherit",
                },
                ":hover": {
                  color: "inherit",
                },
              },
            },
          },
        },
        !disabled &&
          checked && {
            color: palette.black,
            selectors: {
              ".ms-Checkbox-checkbox": {
                background: CHECKBOX_COLOR,
                borderColor: CHECKBOX_COLOR,
              },
              ".ms-Checkbox-text": {
                color: CHECKBOX_CHECKED_HOVER_COLOR,
              },
              ":hover": {
                color: palette.black,
                selectors: {
                  ".ms-Checkbox-checkbox": {
                    background: CHECKBOX_COLOR,
                    borderColor: CHECKBOX_COLOR,
                  },
                  ".ms-Checkbox-text": {
                    color: CHECKBOX_CHECKED_HOVER_COLOR,
                  },
                },
              },
            },
          },
        !disabled &&
          !checked && {
            color: palette.neutralDark,
            selectors: {
              ":hover": {
                selectors: {
                  ".ms-Checkbox-text": {
                    color: palette.neutralDark,
                  },
                  ".ms-Checkbox-checkbox": {
                    border: `1px solid ${palette.black}`,
                  },
                  ".ms-Checkbox-checkmark": {
                    color: palette.neutralSecondary,
                  },
                },
              },
            },
          },
      ],
      label: [
        {
          alignItems: "center",
        },
      ],
      text: [
        disabled && {
          color: CHECKBOX_DISABLED_LABEL_COLOR,
        },
        !disabled &&
          checked && {
            color: CHECKBOX_LABEL_COLOR,
          },

        !disabled &&
          !checked && {
            color: palette.neutralDark,
          },
      ],
    } as Partial<ICheckboxStyles>;

    return styles;
  };
}
