import { RCDocumentType } from "../services/share";
import { BaseDocumentModel } from "./BaseDocumentModel";

export class OfficeDoc extends BaseDocumentModel {
  /**
   * 文件地址
   */
  public readonly fileUrl: string;

  public static isOfficeDoc(doc: CDS.Document) {
    return [
      RCDocumentType.PPTFile,
      RCDocumentType.WordFile,
      RCDocumentType.ExcelFile,
      RCDocumentType.PDFFile,
    ].includes(doc.metadata?.type as unknown as RCDocumentType);
  }

  constructor(doc: CDS.Document) {
    if (!OfficeDoc.isOfficeDoc(doc)) {
      throw new Error(`invalid OfficeDoc type ${doc.metadata?.type} ${doc.id}`);
    }

    super(doc);
    const content = doc.content as CDS.OfficeContent;
    this.fileUrl = this.getMediaUrl(content.fileMediaId);
  }

  public isPDF() {
    return this.metadata.type === RCDocumentType.PDFFile;
  }

  public isPPT() {
    return this.metadata.type === RCDocumentType.PPTFile;
  }

  public isWord() {
    return this.metadata.type === RCDocumentType.WordFile;
  }

  public isExcel() {
    return this.metadata.type === RCDocumentType.ExcelFile;
  }
}
