import { WEB_API, Principal, CatalogNode, requestWeb } from "../request";

export function addCategory(title: string, visibilityScopes: Principal[], parentId?: string) {
  return requestWeb(WEB_API.myTenantCategories, {
    data: {
      category: {
        title,
        visibilityScopes,
      },
      parentId,
    },
    method: "POST",
  });
}

export function deleteCategory(categoryId: string) {
  return requestWeb(WEB_API.myTenantCategory, {
    vars: {
      categoryId,
    },
    method: "DELETE",
  });
}

export function patchCategory(
  categoryId: string,
  body: {
    nodeETag?: string;
    title?: string;
    visibilityScopes?: Principal[];
    notificationSwitch?: boolean;
  }
) {
  return requestWeb(WEB_API.myTenantCategory, {
    vars: {
      categoryId,
    },
    data: body,
    method: "PATCH",
  });
}

export function patchCatalog(catalogNodes: CatalogNode[]) {
  return requestWeb(WEB_API.myTenantCatalog, {
    data: catalogNodes,
    method: "PATCH",
  });
}

export function putFeedsCategoryConfig(
  categoryId: string,
  config: { visibilityScopes: Principal[] }
) {
  return requestWeb(WEB_API.feedsCategoryConfig, {
    vars: {
      categoryId,
    },
    data: config,
    method: "PUT",
  });
}
