/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

import { ITheme, ITextFieldStyleProps } from "@fluentui/react";
import { teamsBorderRadius } from "./styleConstants";
import { ContrastColors, DarkColors, CoreColors } from "../teamsColors";

export function getTextFieldStyles(themeName: string, _theme: ITheme) {
  // tslint:disable-next-line:max-func-body-length
  return (props: ITextFieldStyleProps) => {
    const { focused, disabled, hasErrorMessage, multiline, theme } = props;
    const { palette } = theme;

    let PLACEHOLDER_NOT_DISABLED = palette.neutralTertiary;
    const FIELD_GROUP_BACKGROUND_COLOR = palette.neutralLight;
    let FIELD_GROUP_DISABLED_OPACITY = 0.64;
    let FIELD_COLOR = palette.black;
    let FIELD_COLOR_PLACEHOLDER_NOT_DISABLED = palette.neutralSecondary;
    let FIELD_COLOR_PLACEHOLDER_DISABLED = palette.neutralDark;
    let FIELD_GROUP_BORDER_WIDTH = "0px";
    const FIELD_GROUP_BORDER_WIDTH_ERRORMESSAGE = "1px";
    let FIELD_GROUP_BORDER_COLOR: string = CoreColors.appGray09;
    const FIELD_GROUP_BORDER_COLOR_ERRORMESSAGE: string = CoreColors.appRed;
    let FIELD_GROUP_BORDER_COLOR_DISABLED: string = CoreColors.appGray09;
    let FIELD_GROUP_BORDER_BOTTOM_COLOR_ACTIVE = palette.themePrimary;
    let FIELD_GROUP_BORDER_WIDTH_ACTIVE = "0 0 2px 0";

    // Dark theme overrides
    if (themeName === "dark") {
      PLACEHOLDER_NOT_DISABLED = palette.neutralSecondary;
      // FIELD_GROUP_BACKGROUND_COLOR = palette.white;
      FIELD_COLOR = palette.black;
      FIELD_GROUP_BORDER_COLOR = DarkColors.appGray09;
      FIELD_COLOR_PLACEHOLDER_NOT_DISABLED = palette.neutralDark;
      FIELD_COLOR_PLACEHOLDER_DISABLED = palette.neutralPrimary;
    }

    // Contrast theme overrides
    if (themeName === "contrast") {
      PLACEHOLDER_NOT_DISABLED = palette.black;
      // FIELD_GROUP_BACKGROUND_COLOR = palette.white;
      FIELD_GROUP_DISABLED_OPACITY = 1;
      FIELD_COLOR = palette.black;
      FIELD_COLOR_PLACEHOLDER_NOT_DISABLED = palette.black;
      FIELD_COLOR_PLACEHOLDER_DISABLED = ContrastColors.appGreenContrast;
      FIELD_GROUP_BORDER_WIDTH = "1px 1px 1px 1px";
      FIELD_GROUP_BORDER_COLOR = palette.black;
      FIELD_GROUP_BORDER_COLOR_DISABLED = ContrastColors.appGreenContrast;
      FIELD_GROUP_BORDER_BOTTOM_COLOR_ACTIVE = palette.themeDark;
      FIELD_GROUP_BORDER_WIDTH_ACTIVE = "1px 1px 2px 1px";
    }

    const styles = {
      root: {
        selectors: {
          ".ms-TextField-fieldGroup": {
            backgroundColor: `${FIELD_GROUP_BACKGROUND_COLOR}`,
          },
          "&.is-active": {
            selectors: {
              ".ms-TextField-fieldGroup": {
                borderBottomColor: FIELD_GROUP_BORDER_BOTTOM_COLOR_ACTIVE,
                borderWidth: FIELD_GROUP_BORDER_WIDTH_ACTIVE,
              },
              input: {
                selectors: {
                  "::placeholder": [!disabled && { color: PLACEHOLDER_NOT_DISABLED }],
                  ":-ms-input-placeholder": [!disabled && { color: PLACEHOLDER_NOT_DISABLED }],
                },
              },
              textarea: {
                selectors: {
                  "::placeholder": [!disabled && { color: PLACEHOLDER_NOT_DISABLED }],
                  ":-ms-input-placeholder": [!disabled && { color: PLACEHOLDER_NOT_DISABLED }],
                },
              },
            },
          },
          "&.is-disabled": {
            selectors: {
              ".ms-TextField-fieldGroup": {
                borderColor: FIELD_GROUP_BORDER_COLOR_DISABLED,
              },
            },
          },
        },
      },
      fieldGroup: [
        {
          borderRadius: teamsBorderRadius,
          borderWidth: FIELD_GROUP_BORDER_WIDTH,
          borderStyle: "Solid",
          borderColor: FIELD_GROUP_BORDER_COLOR,
          selectors: {
            "::after": {
              display: "none",
            },
          },
        },
        disabled && {
          opacity: FIELD_GROUP_DISABLED_OPACITY,
        },
        !focused &&
          !disabled &&
          !hasErrorMessage && {
            selectors: {
              ":hover": {
                borderColor: FIELD_GROUP_BORDER_COLOR,
              },
            },
          },
        hasErrorMessage && [
          {
            borderWidth: FIELD_GROUP_BORDER_WIDTH_ERRORMESSAGE,
            borderColor: FIELD_GROUP_BORDER_COLOR_ERRORMESSAGE,
            color: "red",
            selectors: {
              "&:focus, &:hover": {
                borderColor: FIELD_GROUP_BORDER_COLOR,
              },
            },
          },
        ],
      ],
      field: {
        color: FIELD_COLOR,
        backgroundColor: "transparent",
        padding: !multiline ? "0 12px" : "7px 12px",
        selectors: {
          "&:disabled": [{ color: FIELD_COLOR_PLACEHOLDER_DISABLED }],
          "::placeholder": [
            !disabled && { color: FIELD_COLOR_PLACEHOLDER_NOT_DISABLED },
            disabled && { color: FIELD_COLOR_PLACEHOLDER_DISABLED },
          ],
          ":-ms-input-placeholder": [
            !disabled && { color: FIELD_COLOR_PLACEHOLDER_NOT_DISABLED },
            disabled && { color: FIELD_COLOR_PLACEHOLDER_DISABLED },
          ],
        },
      },
    };
    return styles;
  };
}
