import { makeStyles, mergeStyles, MessageBar, PrimaryButton } from "@fluentui/react";
import { rem } from "../../lib/unit";
import { useTranslation } from "react-i18next";
import { AdminPageI18n, AppDeployment } from "../../locales";
import { FontSizes } from "../../themes/fonts";
import { APPDEPLOYMENT_DOC_URL } from "../../config/constant";
import { logger } from "../../services/logger";
import PageTitleBar from "./PageTitleBar";

const useStyles = makeStyles({
  messageBarBtn: { fontSize: FontSizes.small, height: rem(24), marginLeft: rem(12) },
});

const useMessageBarStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.palette.themeLighter,
    color: theme.palette.themePrimary,
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    justifyContent: "center",
  },
  innerText: {
    " span a": {
      padding: 0,
    },
  },
  iconContainer: { display: "none" },
}));

export default function AdminMessageBar({
  height,
  hidden,
  onDismiss,
}: {
  height: string;
  hidden?: boolean;
  onDismiss?: () => void;
}) {
  const { t } = useTranslation();

  const messageBarStyles = useMessageBarStyles();
  const styles = useStyles();
  return (
    <>
      {!hidden && (
        <MessageBar
          className={mergeStyles({ height })}
          isMultiline={false}
          onDismiss={onDismiss}
          styles={messageBarStyles}
        >
          {t(AppDeployment.messageBarContent)}
          <PrimaryButton
            className={styles.messageBarBtn}
            text={t(AppDeployment.messageBarBtn)}
            href={APPDEPLOYMENT_DOC_URL}
            target={"_blank"}
            onClick={() => logger?.telemetry("ClickAppDeploymentDocBtn")}
          />
        </MessageBar>
      )}
      <PageTitleBar title={t(AdminPageI18n.title)} />
    </>
  );
}
