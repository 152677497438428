import Axios from "axios";
import { axiosTimeLogger, createAxiosErrorLogger, createAxiosResponseLogger } from "../logger";
import { DriveItem, User, Group } from "@microsoft/microsoft-graph-types";
import { DEFAULT_GRAPH_ENDPOINT, API_ROOT_GRAPH } from "../../config/config";
import { setBearerToken } from "./token";
import { getAccessToken } from "./getAccessToken";
import { createRetryErrorInterceptor, RequestConfig, replaceVars } from "./injectors";
import { paramsSerializer } from "../../lib/paramsSerializer";

interface ODataQueryParams {
  $expand?: string; //	Retrieves related resources.	/groups?$expand=members
  $filter?: string; // Filters results (rows).	/users?$filter=startswith(givenName,'J')
  $format?: string; // Returns the results in the specified media format.	/users?$format=json
  $orderby?: string; // Orders results.	/users?$orderby=displayName desc
  $search?: string; // Returns results based on search criteria.	/me/messages?$search=pizza
  $select?: string; // Filters properties (columns).	/users?$select=givenName,surname
  $skip?: number; // Indexes into a result set. Also used by some APIs to implement paging and can be used together with $top to manually page results.	/me/messages?$skip=11
  $top?: number;
  $count?: boolean; //	Retrieves the total count of matching resources.	/me/messages?$top=2&$count=true
}

/**
 * only use for tests and mock
 */
const _graphAxios = Axios.create({
  baseURL: API_ROOT_GRAPH,
  paramsSerializer,
  proxy: false,
});
_graphAxios.interceptors.request.use(replaceVars);
_graphAxios.interceptors.request.use(axiosTimeLogger);
_graphAxios.interceptors.request.use(config => {
  if (config.baseURL !== DEFAULT_GRAPH_ENDPOINT) {
    replaceVars(config);
    //  using sandbox graph api proxy
    let url = _graphAxios.getUri(config);
    if (!url.startsWith("http")) {
      url = DEFAULT_GRAPH_ENDPOINT + url;
    }
    config.params = { url };
    config.url = undefined;
    return setBearerToken(config, "{token:https://graph.microsoft.com/}");
  }
  return getAccessToken().then(token => setBearerToken(config, token));
});

_graphAxios.interceptors.response.use(
  createAxiosResponseLogger("graph"),
  createAxiosErrorLogger("graph")
);
_graphAxios.interceptors.response.use(undefined, createRetryErrorInterceptor(_graphAxios));

/**
 * Teams 调用 API
 */
export enum Graph_API {
  // listMyDriveItems = "/v1.0/me/drive/root/children",
  myDriveItem = "/v1.0/me/drive/items/{id}",
  myDriveChildren = "/v1.0/me/drive/items/{id}/children",
  sharedItem = "/v1.0/shares/{id}/driveItem",
  me = "/v1.0/me",
  users = "/v1.0/users",
  user = "/v1.0/users/{userId}",
  groups = "/v1.0/groups",
  group = "/v1.0/gorups/{groupId}",
  userPhoto = "/v1.0/users/{userId}/photo/$value",
  groupPhoto = "/v1.0/groups/{groupId}/photo/$value",
  groupGroupMembers = "/v1.0/groups/{groupId}/{transiveType}/microsoft.graph.group",
  groupUserMembers = "/v1.0/groups/{groupId}/{transiveType}/microsoft.graph.user",
}

/**
 * API 路径参数类型定义
 */
interface GraphPathVars {
  [Graph_API.myDriveItem]: {
    id: string;
  };
  [Graph_API.myDriveChildren]: {
    id: string;
  };
  [Graph_API.sharedItem]: {
    /**
     * id or encode url
     */
    id: string;
  };
  [Graph_API.user]: {
    userId: string;
  };
  [Graph_API.userPhoto]: {
    userId: string;
  };
  [Graph_API.group]: {
    groupId: string;
  };
  [Graph_API.groupPhoto]: {
    groupId: string;
  };
  [Graph_API.groupGroupMembers]: {
    groupId: string;
    transiveType: string;
  };
  [Graph_API.groupUserMembers]: {
    groupId: string;
    transiveType: string;
  };
}

/**
 * 参数返回类型定义
 */
interface GraphResponse {
  [Graph_API.users]: GraphPagination<User>;
  [Graph_API.groups]: GraphPagination<Group>;
  [Graph_API.me]: User;
  [Graph_API.user]: User;
  [Graph_API.userPhoto]: string;
  [Graph_API.group]: Group;
  [Graph_API.groupPhoto]: string;

  [Graph_API.groupUserMembers]: GraphPagination<User>;
  [Graph_API.groupGroupMembers]: GraphPagination<Group>;
  [Graph_API.myDriveChildren]: GraphPagination<DriveItem>;
  [Graph_API.myDriveItem]: DriveItem & { ["@microsoft.graph.downloadUrl"]?: string };
  [Graph_API.sharedItem]: DriveItem & { ["@microsoft.graph.downloadUrl"]?: string };
}

/**
 * API 请求参数类型
 */
interface GraphQueryParam {
  [Graph_API.sharedItem]: {
    $expand: string;
  };
  [Graph_API.myDriveItem]: {
    $expand: string;
  };
  [Graph_API.myDriveChildren]: ODataQueryParams;
  [Graph_API.users]: ODataQueryParams;
  [Graph_API.user]: ODataQueryParams;
  [Graph_API.groups]: ODataQueryParams;
  [Graph_API.groupGroupMembers]: ODataQueryParams;
  [Graph_API.groupUserMembers]: ODataQueryParams;
}

// 返回类型推断
export type GraphResponseOfKey<T> = T extends keyof GraphResponse ? GraphResponse[T] : unknown;
// 自动推断请求对应的类型
export type GraphParamTypeOfKey<T> = T extends keyof GraphQueryParam ? GraphQueryParam[T] : never;
// 自动推断Key对应路径参数的类型
export type GraphVarTypeOfKey<T> = T extends keyof GraphPathVars ? GraphPathVars[T] : never;

/**
 * Graph 分页数据
 */
export interface GraphPagination<T> {
  value: T[];
  "@odata.nextLink"?: string;
}

/**
 * 对URL
 * @param url
 */
export function encodeSharingUrl(url: string) {
  return `u!${btoa(url || "")
    .replace(/=*$/, "")
    .replace(/\//g, "_")
    .replace(/\+/g, "-")}`;
}

/**
 * requestGraph 查询函数
 * @param api 调用API
 * @param config 配置
 */
export function requestGraph<TAPI extends Graph_API>(
  api: TAPI,
  config?: RequestConfig<GraphParamTypeOfKey<TAPI>, GraphVarTypeOfKey<TAPI>>
) {
  return _graphAxios.request<GraphResponseOfKey<TAPI>>({ url: api, ...config });
}
