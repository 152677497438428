import {
  Dialog,
  DialogFooter,
  DialogType,
  IDialogProps,
  mergeStyles,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { User } from "@microsoft/microsoft-graph-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { rem } from "../../lib/unit";
import { AssignmentDialogI18n } from "../../locales";
import { addAssignment, deleteAssignment } from "../../services/rc/permission";
import { AssignmentEntity, PrincipalType, RoleType } from "../../services/request";
import RCPeoplePicker from "../picker/RCPeoplePicker";
import { RCPersonaProps } from "../rc/RCPersona";
import DeleteDialog from "./DeleteDialog";

interface AssignmentDialogSettings {
  dialogTitle: string;
  showPermissionPart: boolean;
  showDeletePart: boolean;
}

export enum AssignmentActionType {
  edit = "edit",
  delete = "delete",
  add = "add",
  blank = "blank",
}
export interface AssignmentInfo extends AssignmentEntity {
  principalDetail?: User;
}
export interface AssignmentDialogProps {
  assignmentInfos?: AssignmentInfo[];
  assignment?: AssignmentEntity;
  onDismiss?: () => void;
  onConfirm?: () => void;
  actionType: AssignmentActionType;
  hidden: boolean;
  roleType: RoleType;
}

interface AssignmentViewItem {
  roleType: RoleType;
  principalId: string;
  principalType: PrincipalType;
}

function findAssigned(
  assignmentInfos?: AssignmentInfo[],
  personas?: RCPersonaProps[]
): RCPersonaProps[] | undefined {
  if (!assignmentInfos || !personas) {
    return undefined;
  } else {
    const ids = assignmentInfos.map(item => item.principalId);
    return personas.filter(item => item.id && ids.indexOf(item.id) !== -1);
  }
}

function checkIsReady(newAssignments?: AssignmentViewItem[], errorInfo?: string): boolean {
  return newAssignments?.length !== 0 && !errorInfo;
}

export default function AssignmentDialog({
  assignmentInfos,
  assignment,
  onDismiss,
  onConfirm,
  hidden,
  actionType,
  roleType,
}: AssignmentDialogProps) {
  const { t } = useTranslation();
  const [confirmDisabled, setConfirmDisabled] = useState(false);
  const [peoplePickerError, setPeoplePickerError] = useState("");
  const [assignments, setAssignments] = useState<AssignmentViewItem[]>([]);

  const { dialogTitle, showPermissionPart, showDeletePart } = useMemo(() => {
    const dialogSettings = {
      dialogTitle: t(AssignmentDialogI18n.dialogTitle, { context: actionType }),
    } as AssignmentDialogSettings;
    switch (actionType) {
      case AssignmentActionType.add:
        dialogSettings.showPermissionPart = true;
        dialogSettings.showDeletePart = false;
        break;
      case AssignmentActionType.delete:
        dialogSettings.showPermissionPart = false;
        dialogSettings.showDeletePart = true;
        break;
    }
    return dialogSettings;
  }, [actionType, t]);
  const dialogProps: IDialogProps = {
    hidden,
    onDismiss,
    dialogContentProps: {
      type: DialogType.normal,
      title: dialogTitle,
    },
    modalProps: {
      isBlocking: true,
    },
  };
  useEffect(() => {
    setConfirmDisabled(!checkIsReady(assignments, peoplePickerError));
  }, [actionType, assignments, peoplePickerError]);

  return showDeletePart ? (
    <DeleteDialog
      hidden={hidden}
      title={dialogTitle}
      subText={t(AssignmentDialogI18n.deleteAlert)}
      onDismiss={onDismiss}
      doDelete={() => {
        try {
          assignment &&
            deleteAssignment(assignment.id).finally(() => {
              onConfirm && onConfirm();
            });
        } finally {
          onDismiss && onDismiss();
        }
      }}
    ></DeleteDialog>
  ) : (
    <Dialog {...dialogProps}>
      <Stack>
        {showPermissionPart && (
          <RCPeoplePicker
            principalType={PrincipalType.user}
            className={mergeStyles({
              marginTop: rem(16),
            })}
            error={peoplePickerError}
            label={t(AssignmentDialogI18n.peoplePickerLabel)}
            onChanged={(personas: RCPersonaProps[]) => {
              const assignedPersonas = findAssigned(assignmentInfos, personas);
              const assignedIds = assignedPersonas?.map(i => i.id);
              if (assignedPersonas && assignedPersonas.length !== 0) {
                setPeoplePickerError(
                  t(AssignmentDialogI18n.errorTip, {
                    assignedPersonas: assignedPersonas.map(item => item.text).join(","),
                  })
                );
              } else {
                setPeoplePickerError("");
              }
              personas &&
                setAssignments(
                  personas
                    .filter(
                      item => item.id && item.principalType && assignedIds?.indexOf(item.id) === -1
                    )
                    .map(item => ({
                      roleType,
                      principalId: item.id!,
                      principalType: item.principalType!,
                    }))
                );
            }}
          ></RCPeoplePicker>
        )}
      </Stack>
      <DialogFooter>
        <PrimaryButton
          disabled={confirmDisabled}
          onClick={() => {
            try {
              switch (actionType) {
                case AssignmentActionType.add:
                  addAssignment(assignments).finally(() => {
                    onConfirm && onConfirm();
                  });
                  break;
              }
              setConfirmDisabled(true);
            } finally {
              setConfirmDisabled(false);
            }
          }}
          text={t(AssignmentDialogI18n.confirmButton)}
        />
      </DialogFooter>
    </Dialog>
  );
}
