import { useMemo } from "react";
import ShimmerCard from "../../components/cards/ShimmerCard";
import "./Common.scss";

const SHIMMER_SUM = 10;

export default function HomeContentShimmer() {
  const Shimmers = useMemo(() => {
    const result: JSX.Element[] = [];
    for (let i = 0; i < SHIMMER_SUM; i++) {
      result.push(<ShimmerCard type="doc" key={i} />);
    }
    return result;
  }, []);

  return <div className="CardList">{Shimmers}</div>;
}
