import { INavStyleProps } from "@fluentui/react";
import { rem } from "../../lib/unit";
export function getNavStyles(themeName: string) {
  return (props: INavStyleProps) => {
    const { palette } = props.theme;
    return {
      chevronButton: {
        paddingLeft: rem(30),
        borderBottom: 0,
        // fontSize: rem(14),
      },
      chevronIcon: {
        // fontSize: rem(12),
      },
      groupContent: {
        marginTop: 0,
        marginBottom: rem(12),
      },
      compositeLink: {
        ":hover .ms-Nav-link": {
          backgroundColor: `${palette.neutralLight} !important`,
        },
      },
      link: [
        {
          paddingLeft: rem(30),
          color: palette.neutralPrimary,
          selectors: {
            ":hover": {
              color: "inherit",
              backgroundColor: palette.neutralLight,
              selectors: {
                ".ms-Button-icon": {
                  color: palette.neutralPrimary,
                },
              },
            },
            ".ms-Button-icon": {
              color: palette.neutralPrimary,
            },
          },
        },
        props.isSelected && {
          backgroundColor: palette.neutralQuaternaryAlt,
          selectors: {
            "&:after": {
              borderLeft: "none",
            },
          },
        },
      ],
      linkText: {
        // fontSize: rem(14),
        color: palette.neutralPrimary,
      },
    };
  };
}
