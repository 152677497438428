import { VERSION } from "../config/config";

/**
 * init vConsole
 */
export function initVConsole() {
  const script = document.createElement("script");
  script.src = "https://unpkg.com/vconsole@latest/dist/vconsole.min.js";
  script.async = true;
  document.body.appendChild(script);

  script.onload = () => {
    (window as any).VConsole && new (window as any).VConsole();
    console.log("version", VERSION);
  };
}
