/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

// tslint:disable-next-line:require-casing
import { teamsButtonBorderRadius } from "./styleConstants";
import { IButtonStyles } from "@fluentui/react/lib/Button";
import { ITheme, IRawStyle } from "@fluentui/react";
import { ShadowLevels } from "../TeamsShadowLevels";
import { ContrastColors, DarkColors } from "../teamsColors";

// tslint:disable-next-line:max-func-body-length
export function getDefaultButtonStyles(themeName: string, theme: ITheme): Partial<IButtonStyles> {
  const BUTTON_MIN_WIDTH = "96px";
  const styles = {
    root: {
      borderRadius: teamsButtonBorderRadius,
      backgroundColor: theme.palette.white,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.neutralTertiaryAlt,
      color: theme.palette.black,
      boxShadow: ShadowLevels.shadowLevel1,
      cursor: "pointer",
      minWidth: BUTTON_MIN_WIDTH,
      "-webkit-tap-highlight-color": "transparent",
    },
    rootHovered: {
      backgroundColor: theme.palette.neutralLighter,
      borderColor: theme.palette.neutralTertiary,
    },
    rootPressed: {
      backgroundColor: theme.palette.neutralTertiaryAlt,
      borderColor: theme.palette.neutralTertiary,
      boxShadow: "none",
    },
    rootChecked: {
      backgroundColor: theme.palette.neutralTertiaryAlt,
    },
    rootDisabled: {
      backgroundColor: theme.palette.neutralLighter,
      color: theme.palette.neutralTertiary,
      borderWidth: 0,
      borderColor: "transparent",
      boxShadow: "none",
    },
    rootFocused: {
      backgroundColor: theme.palette.neutralTertiaryAlt,
      borderWidth: "1px",
      borderColor: theme.palette.black,
      borderRadius: teamsButtonBorderRadius,
      color: theme.palette.black,
      selectors: {
        ".ms-Fabric--isFocusVisible &": {
          selectors: {
            ":focus::after": {
              outline: "none",
              borderRadius: teamsButtonBorderRadius,
              borderColor: theme.palette.white,
            },
          },
        },
      },
    },
    icon: { display: "flex" },
  } as Partial<IButtonStyles>;

  // Dark theme overrides
  if (themeName === "dark") {
    // Root
    (styles.root as IRawStyle).boxShadow = ShadowLevels.shadowLevel1Dark;
    (styles.root as IRawStyle).backgroundColor = DarkColors.appWhite;
  }

  // Contrast theme overrides
  if (themeName === "contrast") {
    // Root
    (styles.root as IRawStyle).borderWidth = "2px";
    (styles.root as IRawStyle).borderColor = theme.palette.black;

    // Root Hovered
    (styles.rootHovered as IRawStyle).borderColor = theme.palette.themeDark;
    (styles.rootHovered as IRawStyle).color = theme.palette.white;
    (styles.rootHovered as IRawStyle).backgroundColor = theme.palette.themeDark;

    // Root Pressed
    (styles.rootPressed as IRawStyle).borderColor = theme.palette.themeDark;
    (styles.rootPressed as IRawStyle).color = theme.palette.white;
    (styles.rootPressed as IRawStyle).backgroundColor = theme.palette.themeDark;

    // Root Disabled
    (styles.rootDisabled as IRawStyle).color = ContrastColors.appGreenContrast;
    (styles.rootDisabled as IRawStyle).borderWidth = "2px";
    (styles.rootDisabled as IRawStyle).borderColor = ContrastColors.appGreenContrast;
    (styles.rootDisabled as IRawStyle).backgroundColor = theme.palette.white;

    // Root Focused
    (styles.rootFocused as IRawStyle).color = theme.palette.white;
    (styles.rootFocused as IRawStyle).borderWidth = "2px";
    (styles.rootFocused as IRawStyle).backgroundColor = theme.palette.themeDark;
    (styles.rootFocused as IRawStyle).borderColor = theme.palette.themeDark;
    (
      ((styles.rootFocused as any).selectors[".ms-Fabric--isFocusVisible &"] as any).selectors[
        ":focus::after"
      ] as IRawStyle
    ).borderColor = theme.palette.themeDark;
  }
  return styles;
}
