import Axios, { CancelToken } from "axios";
import { axiosTimeLogger, createAxiosErrorLogger, createAxiosResponseLogger } from "../logger";
import { createRetryErrorInterceptor } from "./injectors";
import { getBlobWriteToken } from "./getBlobWriteToken";
import { guid } from "../../lib/guid";

const blobAxios = Axios.create();
blobAxios.interceptors.request.use(axiosTimeLogger);
blobAxios.interceptors.response.use(
  createAxiosResponseLogger("blob"),
  createAxiosErrorLogger("blob")
);
blobAxios.interceptors.response.use(undefined, createRetryErrorInterceptor(blobAxios));

/**
 * 后缀
 * @param fileName
 * @returns
 */
function pasreFileExtension(fileName: string) {
  const ext = /(?:\.([^.]+))?$/.exec(fileName)?.[1];
  return ext ? `.${ext}` : "";
}

/**
 * 上传文件
 * @param file
 * @param options
 * @returns
 */
export function uploadBlob(
  file: File | Blob,
  options?: {
    token?: { token: string; baseURL: string };
    onProgress?: (p: { rate: number; p: Event }) => void;
    name?: string;
    cancelToken?: CancelToken;
  }
) {
  const contentType = file.type;
  const name = options?.name || `${guid()}${pasreFileExtension((file as File)?.name)}`;
  const id = btoa(unescape(encodeURIComponent(name)));
  const onProgress = options?.onProgress;
  const tokenPromise: Promise<{ token: string; baseURL: string }> = options?.token
    ? Promise.resolve(options.token)
    : getBlobWriteToken();
  return tokenPromise.then(tokenInfo =>
    blobAxios
      .put(`/${name}?${tokenInfo.token}&comp=block&blockid=${id}`, file, {
        baseURL: tokenInfo.baseURL,
        cancelToken: options?.cancelToken,
        onUploadProgress: onProgress && (p => onProgress({ rate: p.loaded / p.total, p })),
      })
      .then(() =>
        blobAxios.put(
          `/${name}?${tokenInfo.token}&comp=blocklist`,
          `<?xml version="1.0" encoding="UTF-8" standalone="yes"?><BlockList><Latest>${id}</Latest></BlockList>`,
          {
            // onDownloadProgress: p => {
            //   // onProgress&&onProgress(p.)
            // },
            baseURL: tokenInfo.baseURL,
            cancelToken: options?.cancelToken,
            headers: {
              "x-ms-blob-content-type": contentType,
              "x-ms-blob-cache-control": "immutable",
            },
          }
        )
      )
      .then(() => ({ file: `${tokenInfo.baseURL}/${name}`, token: tokenInfo }))
  );
}
