import { AAD_CLIENT_ID } from "../config/config";
import { getLng } from "../config/i18n";
import { getCachedTeamsContext } from "./teams";
import { PUBLIC_URL } from "../config/config";

const queryParams = {
  client_id: AAD_CLIENT_ID!,
  scope: "./default",
  redirect_uri: `${window.location.origin}${PUBLIC_URL}/consentcomplete.html`,
};

function toQueryString(queryParams: { [x: string]: string | number | boolean }) {
  return Object.entries(queryParams)
    .map(item => `${item[0]}=${encodeURIComponent(item[1])}`)
    .join("&");
}

export function getConsentUrl() {
  const context = getCachedTeamsContext();
  const login_hint = (context && context.userPrincipalName) || "";
  const url = `https://login.microsoftonline.com/common/adminconsent?${toQueryString({
    ...queryParams,
    state: `${login_hint},${getLng()}`,
  })}`;
  return url;
}
