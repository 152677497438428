import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { showDeleteDialog } from "../components/dialog/DeleteDialog";
import { ContentHubNS } from "../locales";
import { requestWeb, WEB_API } from "../services/request";

/**
 * return a hook to remove bundle/ document from Category
 * @param refetch
 * @returns
 */
export function useRemoveFromCategory(refetch: () => void) {
  const { t } = useTranslation();
  return useCallback(
    (doc: CDS.Document | CDS.Bundle) => {
      const id = doc?.id!;
      const isBundle = doc.entityType === "bundle";
      const categoryId =
        doc?.entityType === "bundle" ? doc.extension?.categoryId : doc?.extended?.categoryId;
      const textContext = isBundle ? "bundle" : "doc";
      if (categoryId) {
        showDeleteDialog({
          title: t(ContentHubNS.removeFromCatalog),
          subText: t(ContentHubNS.removeFromCatalogDesc, {
            name: doc?.category?.name,
            context: textContext,
          }),
          doDelete: () => {
            requestWeb(
              isBundle ? WEB_API.myTenantCategoryBundle : WEB_API.myTenantCategoryDocument,
              {
                vars: isBundle ? { categoryId, bundleId: id } : { categoryId, documentId: id },
                method: "DELETE",
              }
            ).then(refetch, console.error);
          },
        });
      }
    },
    [refetch, t]
  );
}
