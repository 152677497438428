import {
  DirectionalHint,
  FontSizes,
  IContextualMenuItem,
  TooltipHost,
  Text,
  Icon,
  makeStyles,
} from "@fluentui/react";
import React from "react";
import { Trans } from "react-i18next";
import { IconName } from "../../../config/icons";
import { ActionKey, RCTreeNode } from "../../../hooks/rc/useNavLinks";
import { rem } from "../../../lib/unit";
import { CategoryDialogI18n, RCTreeI18n } from "../../../locales";
import { PrincipalType } from "../../../services/request";
import { MoreButtonPC } from "../../common/MoreButton";
import SubscribeButton from "../SubscribeButton";

const useStyles = makeStyles(theme => ({
  toolTip: {
    display: "flex",
    alignItems: "center",
    fontSize: FontSizes.small,
    lineHeight: rem(16),
  },
  newNode: {
    width: rem(36),
    height: rem(16),
    lineHeight: rem(16),
    background: theme.palette.themePrimary,
    borderRadius: rem(16),
    color: "white",
    fontSize: FontSizes.xSmall,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: rem(8),
  },
  placeHolder: { color: theme.palette.neutralSecondary },
  hoverIcon: { marginRight: "8px", fontSize: FontSizes.medium },
}));

const useMoreButtonStyles = makeStyles(theme => ({
  root: {
    marginRight: rem(8),
    color: theme.palette.neutralDark,
    fontSize: FontSizes.icon,
  },
  rootHovered: {
    backgroundColor: "transparent",
  },
  menuIconHovered: {
    color: theme.palette.themePrimary,
    fontWeight: "bolder",
  },
}));

const useCalloutStyles = makeStyles({ root: { marginLeft: "auto" } });
interface RCTreeNodeViewProps {
  dataNode?: RCTreeNode;
  editable?: boolean;
  actions?: IContextualMenuItem[];
  subscribable?: boolean;
  isNew?: boolean;
}
/**
 * 因应用在RCTree的titleRender里，此组件内容不能使用hook
 */
export function RCTreeNodeView({
  dataNode,
  actions,
  editable,
  subscribable,
  isNew,
}: RCTreeNodeViewProps) {
  const filtedActions = actions
    ?.filter(item => dataNode?.actionKeys?.includes(item.key as ActionKey))
    .map(item => {
      item.data = dataNode;
      return { ...item };
    });
  const showMoreButton = editable && !dataNode?.isPlaceHolder && !!filtedActions?.length;
  const showSubscribeButton = subscribable && dataNode?.isLeaf;
  const category = dataNode?.raw?.treeNode;
  let i18nKey;
  if (category?.visibilityScopes?.length === 0) {
    i18nKey = CategoryDialogI18n.visibleToAdmin;
  } else if (
    category?.visibilityScopes?.findIndex(item => item.principalType === PrincipalType.everyone) !==
    -1
  ) {
    i18nKey = CategoryDialogI18n.visibleToAll;
  } else {
    i18nKey = CategoryDialogI18n.visibleToScope;
  }

  const styles = useStyles();
  const moreButtonStyles = useMoreButtonStyles();
  const calloutStyles = useCalloutStyles();
  const isPlaceHolder = dataNode?.level === 1 || dataNode?.isPlaceHolder;

  const title = (
    <div
      className="rcTreeNode"
      id={dataNode?.isPlaceHolder ? dataNode.key : `treeNode_${dataNode?.key}`}
    >
      <div className="rcTreeNode-title">
        <span className="rcTreeNode-title_text">
          {dataNode?.isPlaceHolder ? (
            <span className={styles.placeHolder}>
              <Trans i18nKey={RCTreeI18n.emptyCatalog}></Trans>
            </span>
          ) : (
            dataNode?.title ?? ""
          )}
        </span>
        {isNew && (
          <Text className={styles.newNode}>
            <Trans i18nKey={RCTreeI18n.newTag}></Trans>
          </Text>
        )}
      </div>
      {showSubscribeButton && <SubscribeButton categoryId={dataNode?.key!} />}
      {showMoreButton && (
        <MoreButtonPC
          id={`morebutton_${dataNode?.key}`}
          iconName={IconName.More}
          directionalHint={DirectionalHint.bottomRightEdge}
          styles={moreButtonStyles}
          items={filtedActions ?? []}
        ></MoreButtonPC>
      )}
    </div>
  );

  return editable ? (
    <TooltipHost
      title=""
      content={
        isPlaceHolder ? (
          ""
        ) : (
          <span className={styles.toolTip}>
            <Icon iconName={IconName.View} className={styles.hoverIcon} />
            <Trans i18nKey={i18nKey}></Trans>
          </span>
        )
      }
      id={"tooltip"}
      calloutProps={{
        directionalHint: DirectionalHint.bottomRightEdge,
        beakWidth: 0,
        gapSpace: 7,
        styles: calloutStyles,
      }}
    >
      <div title="">{title}</div>
    </TooltipHost>
  ) : (
    title
  );
}
