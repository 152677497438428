import React, { PropsWithChildren, useCallback, useEffect, useState, useRef } from "react";
import { IStyle, mergeStyles, Text, makeStyles, IButtonProps } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Mine } from "../../locales";
import { useMobileCss } from "../../hooks/rc/useCss";
import RCDocumentList from "./DocumentList";
import RCBundleList from "./BundleList";
import { ResourceScope } from "../../config/rc";
import "./Common.scss";
import EmptyContent, { pageHeightClass } from "../empty/EmptyContent";
import ExpandButton from "../button/ExpandButton";
import { useStorageValue } from "../../hooks/useStorageValue";
import { useWindowSize } from "../../hooks/useWindowSize";
import { rem } from "../../lib/unit";
import { RCDocumentType } from "../../services/share";

// 最少合集数目，超过此值显示全部合集
// const BUNDLES_MIN_LEN = 2;
const BUNDLES_MIN_LEN_MOBILE = 4;

export interface MixedContentProps {
  scope: ResourceScope;
  hideLink?: boolean;
  showCreator?: boolean;
  showTime?: boolean;
  bundles?: CDS.Bundle[];
  delBundle?: (bundleId: string) => void;
  tapBundle?: (bundle: CDS.Bundle) => void;
  hasNextPageBundle?: boolean;
  loadMoreBundle?: () => void;
  isFetchingBundle?: boolean;
  hasNextPageDoc?: boolean;
  loadMoreDoc?: () => void;
  isFetchingDoc?: boolean;
  documents?: CDS.Document[];
  tapDoc?: (doc: CDS.Document) => void;
  delDoc?: (doc: CDS.Document) => any;
  onAddToCategory?: (doc: CDS.Document) => any;
  onRemoveBundleFromCategory?: (doc: CDS.Bundle) => any;
  onAddBundleToCategory?: (doc: CDS.Bundle) => any;
  onRemoveDocFromCategory?: (doc: CDS.Document) => any;
  onReadingDetailsDownload?: (docId: string) => void;
  onEditBundle?: (doc: CDS.Bundle) => void;
  editDoc?: (doc: CDS.Document) => any;
  patchDocMeta?: (meta: Partial<CDS.Document["metadata"]>, doc: CDS.Document) => any;
  emptyTitle?: string;
  emptySubTitle?: string;
  emptyButtonProps?: IButtonProps;
  contentContainerStyle?: IStyle;
  categoryId?: string;
  allowLinkType?: RCDocumentType[];
}

const useStyles = makeStyles({
  headBtn: { height: rem(32), " i": { marginLeft: rem(8) } },
  headText: { lineHeight: rem(32) },
});

const getBundleLoading = (
  hasDocuments: boolean,
  isFetchingDoc: boolean,
  hasAll: boolean,
  expandStatus: boolean,
  isFetchingBundle: boolean
) => ((!hasDocuments && !isFetchingDoc) || (hasAll && expandStatus)) && isFetchingBundle;

const getDocLoading = (hasBundles: boolean, hasDocuments: boolean, isFetchingDoc: boolean) =>
  !(hasBundles && !hasDocuments) && isFetchingDoc;

const getShowExpand = (isMobile: boolean, isOneLine: boolean, bundles?: CDS.Bundle[]) =>
  bundles && (isMobile ? bundles.length > BUNDLES_MIN_LEN_MOBILE : !isOneLine);

const getBundleListClass = (
  hasBundles: boolean,
  isBundleLoading: boolean,
  hasAll: boolean,
  expandStatus: boolean | null
) =>
  `${hasBundles || isBundleLoading ? "CardList" : ""} ${
    hasAll && !expandStatus ? "CardList_sum" : ""
  }`;

export default function MixedContent({
  documents,
  bundles,
  isFetchingDoc,
  isFetchingBundle,
  emptyTitle,
  emptySubTitle,
  emptyButtonProps,
  contentContainerStyle,
  showCreator,
  showTime,
  hideLink,
  scope,
  loadMoreBundle,
  loadMoreDoc,
  ...props
}: PropsWithChildren<MixedContentProps>): React.ReactElement {
  const { t } = useTranslation();
  const isMobile = useMobileCss();
  const { width } = useWindowSize();
  const hasDocuments = Boolean(documents?.length);
  const hasBundles = Boolean(bundles?.length);
  const hasAll = hasDocuments && hasBundles;
  const isEmpty = !isFetchingDoc && !hasDocuments && !isFetchingBundle && !hasBundles;
  const [expandStatus, setExpandStatus] = useStorageValue(
    "expandStatus",
    `${props.categoryId}_BundleList`
  );
  const isBundleLoading = getBundleLoading(
    hasDocuments,
    !!isFetchingDoc,
    hasAll,
    !!expandStatus,
    !!isFetchingBundle
  );
  const isDocLoading = getDocLoading(hasBundles, hasDocuments, !!isFetchingDoc);
  const bundleListClass = getBundleListClass(hasBundles, isBundleLoading, hasAll, expandStatus);

  const bundleHasMore = props.hasNextPageBundle && (!hasAll || !!expandStatus);

  const bundleLoadMore = useCallback(() => loadMoreBundle && loadMoreBundle(), [loadMoreBundle]);
  const documentLoadMore = useCallback(() => loadMoreDoc && loadMoreDoc(), [loadMoreDoc]);

  const styles = useStyles();

  const [isOneLine, setIsOneLine] = useState(true);
  const showExpandBtn = getShowExpand(isMobile, isOneLine, bundles);

  const bundlesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bundlesRef.current) {
      setIsOneLine(
        bundlesRef.current.children.length <= 1
          ? true
          : (bundlesRef.current.firstChild as HTMLDivElement).offsetTop ===
              (bundlesRef.current.lastChild as HTMLDivElement).offsetTop
      );
    }
  }, [width, bundles]);

  return (
    <>
      {props.children}
      {isEmpty ? (
        <EmptyContent
          message={emptyTitle}
          subTitle={emptySubTitle}
          className={pageHeightClass}
          buttonProps={emptyButtonProps}
        />
      ) : (
        <div className={mergeStyles(contentContainerStyle)}>
          {hasAll && (
            <div className="CardList">
              <div className="CardList-head">
                <Text className={styles.headText} variant="large">
                  {t(Mine.collection)}
                </Text>
                {showExpandBtn && (
                  <ExpandButton
                    expandStatus={!!expandStatus}
                    buttonProps={{ onClick: () => setExpandStatus(!expandStatus) }}
                  />
                )}
              </div>
            </div>
          )}
          <div className={bundleListClass} ref={bundlesRef}>
            <RCBundleList
              isLoading={isBundleLoading}
              hasNextPage={bundleHasMore}
              loadMore={bundleLoadMore}
              scope={scope}
              bundles={bundles}
              showCreator={showCreator}
              hideLink={hideLink}
              onRemoveFromCategory={props.onRemoveBundleFromCategory}
              onAddToCategory={props.onAddBundleToCategory}
              onEdit={props.onEditBundle}
              delBundle={props.delBundle}
              tapBundle={props.tapBundle}
            />
          </div>

          {hasAll && (
            <div className="CardList">
              <Text className={`CardList-head ${styles.headText}`} variant="large">
                {t(Mine.contentTitle)}
              </Text>
            </div>
          )}
          <div className="CardList">
            <RCDocumentList
              isLoading={isDocLoading}
              loadMore={documentLoadMore}
              hasNextPage={props.hasNextPageDoc}
              hideLink={hideLink}
              allowLinkType={props.allowLinkType}
              showCreator={showCreator}
              showTime={showTime}
              scope={scope}
              docs={documents}
              delDoc={props.delDoc}
              editDoc={props.editDoc}
              tapDoc={props.tapDoc}
              patchMeta={props.patchDocMeta}
              onAddToCategory={props.onAddToCategory}
              onReadingDetailsDownload={props.onReadingDetailsDownload}
              onRemoveFromCategory={props.onRemoveDocFromCategory}
            />
          </div>
        </div>
      )}
    </>
  );
}
