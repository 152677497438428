import { Group, User } from "@microsoft/microsoft-graph-types";
import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { batchQueryBuilder } from "../../services/graph/queryBuilder";
import { Graph_API, Principal, PrincipalType, requestGraph } from "../../services/request";

const maxPagesize = 15;

export function usePrincipalsDetail(principals?: Principal[]) {
  const userPrincipals =
    principals && principals?.filter(item => item.principalType === PrincipalType.user);
  const groupPrincipals =
    principals && principals?.filter(item => item.principalType === PrincipalType.group);
  const userIds =
    userPrincipals && userPrincipals.length !== 0 && userPrincipals?.map(item => item.principalId);
  const groupIds =
    groupPrincipals &&
    groupPrincipals.length !== 0 &&
    groupPrincipals?.map(item => item.principalId);

  const fetchUsersInfo = ({ pageParam = 0 }) =>
    new Promise<{
      value: User[];
      nextPage: number | undefined;
    }>((resolve, reject) => {
      if (!userIds || userIds.length === 0) {
        resolve({
          value: [],
          nextPage: undefined,
        });
      } else {
        const start = pageParam * maxPagesize;
        const end = (pageParam + 1) * maxPagesize;
        const nextEnd = (pageParam + 1 + 1) * maxPagesize;
        const requestIds = userIds.slice(start, end);
        const nextIds = userIds.slice(end, nextEnd);
        requestGraph(Graph_API.users, {
          params: batchQueryBuilder(requestIds),
        }).then(res => {
          resolve({
            value: res.data.value,
            nextPage: nextIds.length !== 0 ? pageParam + 1 : undefined,
          });
        }, reject);
      }
    });
  const fetchGroupsInfo = ({ pageParam = 0 }) =>
    new Promise<{
      value: Group[];
      nextPage: number | undefined;
    }>((resolve, reject) => {
      if (!groupIds || groupIds.length === 0) {
        resolve({
          value: [],
          nextPage: undefined,
        });
      } else {
        const start = pageParam * maxPagesize;
        const end = (pageParam + 1) * maxPagesize;
        const nextEnd = (pageParam + 1 + 1) * maxPagesize;
        const requestIds = groupIds.slice(start, end);
        const nextIds = groupIds.slice(end, nextEnd);
        requestGraph(Graph_API.groups, {
          params: batchQueryBuilder(requestIds),
        }).then(res => {
          resolve({
            value: res.data.value,
            nextPage: nextIds.length !== 0 ? pageParam + 1 : undefined,
          });
        }, reject);
      }
    });

  const {
    data: usersData,
    hasNextPage: hasMoreUsers,
    isFetchingNextPage: isFetchingUsers,
    fetchNextPage: fetchMoreUsers,
  } = useInfiniteQuery([Graph_API.users, userIds], fetchUsersInfo, {
    getNextPageParam: (lastPage, pages) => lastPage.nextPage,
  });

  hasMoreUsers && !isFetchingUsers && fetchMoreUsers();

  const users = useMemo(
    () => usersData?.pages?.reduce<User[]>((pre, item) => pre.concat(item.value), []),
    [usersData?.pages]
  );

  const {
    data: groupData,
    hasNextPage: hasMoreGroups,
    isFetchingNextPage: isFetchingGroups,
    fetchNextPage: fetchMoreGroups,
  } = useInfiniteQuery([Graph_API.groups, groupIds], fetchGroupsInfo, {
    getNextPageParam: (lastPage, pages) => lastPage.nextPage,
  });

  hasMoreGroups && !isFetchingGroups && fetchMoreGroups();

  const groups = useMemo(
    () => groupData?.pages?.reduce<Group[]>((pre, item) => pre.concat(item.value), []),
    [groupData?.pages]
  );

  return { users, groups };
}
