import { useCallback } from "react";
import { List } from "@fluentui/react";
import SortableCard, { SortableData } from "../cards/SortableCard";

export interface SortableCardListProps {
  className?: string;
  onChanged?: (dataList: SortableData[]) => void;
  dataList: SortableData[];
}
export default function SortableCardList({
  dataList,
  className,
  onChanged,
}: SortableCardListProps) {
  const renderCell = useCallback(
    (item?: CDS.Document | CDS.Bundle, index?: number) =>
      item && (
        <SortableCard
          data={item}
          disabled={dataList.length === 1}
          isDownBtnDisabled={index === dataList.length - 1}
          isUpBtnDisabled={index === 0}
          onUpBtnTap={() => {
            const newDataList = moveDocs(dataList, index!, -1);
            onChanged && onChanged(newDataList);
          }}
          onDownBtnTap={() => {
            const newDataList = moveDocs(dataList, index!, 1);
            onChanged && onChanged(newDataList);
          }}
          onDeleteBtnTap={() => {
            const newDataList = moveDocs(dataList, index!, 0);
            onChanged && onChanged(newDataList);
          }}
        />
      ),
    [dataList, onChanged]
  );

  return (
    <List data-is-scrollable className={className} items={dataList} onRenderCell={renderCell} />
  );
}

/**
 * move the doc up and down in list
 * @param docs all docs
 * @param fromIndex doc index
 * @param step -1 for up, 1 for down, 0 for delete
 * @returns new doc list
 */
function moveDocs(docs: SortableData[], fromIndex: number, step: 1 | -1 | 0): SortableData[] {
  const newDocs = [...docs];
  const element = newDocs[fromIndex];
  const toIndex = fromIndex + step;
  if (toIndex >= 0 && toIndex < newDocs.length) {
    newDocs.splice(fromIndex, 1);
    if (fromIndex !== toIndex) {
      newDocs.splice(toIndex, 0, element);
    }
  }
  return newDocs;
}
