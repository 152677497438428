import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Text, Image } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { APPDEPLOYMENT_DOC_URL } from "../../config/constant";
import deployImg from "../../img/teams/deploydialog.png";
import { rem } from "../../lib/unit";
import { AppDeployment } from "../../locales";
import { logger } from "../../services/logger";

export default function DeployGuideDialog({
  hidden,
  onDismiss,
}: {
  hidden: boolean;
  onDismiss?: () => void;
}): React.ReactElement {
  const { t } = useTranslation();

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        title: (
          <Image src={deployImg} styles={{ root: { width: "100%" }, image: { width: "100%" } }} />
        ),
        titleProps: { style: { padding: 0 } },
        styles: {
          inner: {
            height: `${rem(300)} !important`,
          },
        },
      }}
      modalProps={{ isBlocking: true, isModeless: true }}
    >
      <Text
        variant="large"
        block
        styles={{
          root: {
            margin: `${rem(27)} 0 ${rem(19)}`,
          },
        }}
      >
        {t(AppDeployment.dialogHeadline)}
      </Text>
      <Text styles={{ root: { whiteSpace: "pre-wrap" } }}>{t(AppDeployment.dialogDetails)}</Text>
      <DialogFooter>
        <DefaultButton
          styles={{ root: { width: rem(107) } }}
          onClick={onDismiss}
          text={t(AppDeployment.dialogCancelBtn)}
        />
        <PrimaryButton
          href={APPDEPLOYMENT_DOC_URL}
          text={t(AppDeployment.dialogConfirmBtn)}
          target="_blank"
          onClick={() => logger?.telemetry("ClickAppDeploymentDocBtn")}
        />
      </DialogFooter>
    </Dialog>
  );
}
