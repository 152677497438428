/* eslint-disable no-redeclare */
import { useCallback, useMemo, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ResourceScope } from "../../config/rc";
import { ExtractRouteParams, PagePath } from "../../config/routes";
import { NavigateOptions, navigateTo } from "../../services/history";

export function useRouteParams<T extends PagePath>(): Partial<ExtractRouteParams<T>> {
  const params = useParams() as ExtractRouteParams<T>;
  const [query] = useSearchParams();

  return useMemo<ExtractRouteParams<T>>(() => {
    const p = { ...params };
    query.forEach((value, key) => {
      // @ts-expect-error
      p[key] = value;
    });

    return p;
  }, [params, query]);
}

/**
 * 跳转hook
 * @param page
 * @param options
 * @returns
 */
export function useRouteNavCallbck<T extends PagePath>(
  page: T,
  options?: NavigateOptions<T>
): () => void {
  const ref = useRef<[T, NavigateOptions<T> | undefined]>([page, options]);
  return useCallback(() => navigateTo(ref.current[0], ref.current[1]), []);
}

export function useRouteViewDocCallback(options: {
  scope: ResourceScope;
  categoryId?: string;
  bundleId?: string;
  replace?: boolean;
}): (doc: CDS.Document, playList?: CDS.Document[]) => void {
  const ref = useRef(options);
  ref.current = options;
  return useCallback((doc: CDS.Document, playList?: CDS.Document[]) => {
    navigateTo(PagePath.view, {
      params: {
        ...ref.current,
        docId: doc.id!,
      },
      replace: ref.current.replace,
      state: { playList },
    });
  }, []);
}

export function useRouteViewBundleCallback(options: {
  params: { scope: ResourceScope; categoryId?: string };
  state?: { isAdmin?: boolean };
}): (doc: CDS.Bundle) => void {
  const ref = useRef(options);
  ref.current = options;
  return useCallback(
    (b: CDS.Bundle) =>
      navigateTo(PagePath.bundle, {
        params: {
          ...ref.current.params,
          bundleId: b.id!,
        },
        state: ref.current.state ? { ...ref.current.state } : undefined,
      }),
    []
  );
}
