/* eslint-disable @dragongate/no-magic-numbers */
import React, { useState, useEffect, useMemo } from "react";
import Tree from "rc-tree";
import { ActionButton, FontSizes, IContextualMenuItem, makeStyles } from "@fluentui/react";
import { useControllableValue } from "@fluentui/react-hooks";
import { DataNode, EventDataNode, Key } from "rc-tree/lib/interface";

import { NodeDragEventParams } from "rc-tree/lib/contextTypes";
import { RCTreeNodeView } from "./RCTreeNodeView";
import "./RCTree.scss";
import { RCTreeNode } from "../../../hooks/rc/useNavLinks";
import { IconName } from "../../../config/icons";
import { TreeName } from "../../../config/rc";
import { NEW_TAG_CRITICAL_TIME } from "../../../config/constant";
import { useStorageValue } from "../../../hooks/useStorageValue";

function findRCTreeNodeByKey(
  data: RCTreeNode,
  key: string,
  parent?: RCTreeNode,
  index?: number
):
  | {
      parent?: RCTreeNode;
      node: RCTreeNode;
      index?: number;
    }
  | undefined {
  let result;
  if (key === data.key) {
    result = {
      node: data,
      parent,
      index,
    };
  } else if (data.children) {
    for (let i = 0; i < data.children.length; i++) {
      result = findRCTreeNodeByKey(data.children[i], key, data, i);
      if (result) break;
    }
  } else {
    result = undefined;
  }
  return result;
}

function dndRCTreeNodes(
  treeNode: RCTreeNode,
  dragKey: string,
  dropKeyObj: {
    key: string;
    isParent?: boolean;
  },
  offset: number,
  callBack: (newTree?: RCTreeNode, actionNodes?: RCTreeNode[]) => void
) {
  const newRCTreeNode = treeNode;
  const dragRes = findRCTreeNodeByKey(newRCTreeNode, dragKey);
  if (dragRes && dragRes.parent) {
    dragRes.parent.children?.splice(dragRes.index!, 1);
    if (!dropKeyObj.isParent) {
      const dropRes = findRCTreeNodeByKey(newRCTreeNode, dropKeyObj.key);
      if (dropRes && dropRes.parent) {
        dropRes.parent.children?.splice(dropRes?.index! + offset, 0, dragRes.node);
        callBack && callBack(newRCTreeNode, [dragRes.parent, dropRes.parent]);
      } else {
        dragRes.parent && dragRes.parent.children?.splice(dragRes.index!, 0, dragRes.node);
      }
    } else {
      const dropRes = findRCTreeNodeByKey(newRCTreeNode, dropKeyObj.key);
      if (dropRes) {
        dropRes.node = { ...dropRes.node, children: [dragRes.node] };
        callBack && callBack(newRCTreeNode, [dragRes.parent, dropRes.node]);
      } else {
        dragRes.parent && dragRes.parent.children?.splice(dragRes.index!, 0, dragRes.node);
      }
    }
  }
}

function isNewNode(createAt: number) {
  return new Date().getTime() - createAt < NEW_TAG_CRITICAL_TIME;
}

function generateTreeNodeRender(
  newNodeKeys: string[],
  editable?: boolean,
  actions?: IContextualMenuItem[],
  subscribable?: boolean
) {
  return (dataNode: DataNode) => (
    <RCTreeNodeView
      dataNode={dataNode as RCTreeNode}
      actions={actions}
      editable={editable}
      subscribable={subscribable}
      isNew={newNodeKeys.includes(dataNode.key as string)}
    ></RCTreeNodeView>
  );
}

function getExpandTreeNodes(treeNodes: RCTreeNode[], lastNodes: RCTreeNode[] = []) {
  treeNodes.forEach(item => {
    lastNodes.push({ ...item, children: undefined });
    if (item.children && item.children.length > 0) {
      getExpandTreeNodes(item.children, lastNodes);
    }
  });
  return lastNodes;
}

const useTreeStyles = makeStyles(theme => ({
  tree: {
    selectors: {
      ".rc-tree-treenode.drop-target": {
        backgroundColor: theme.palette.neutralLight,
      },
      ".rc-tree-treenode-selected": {
        color: theme.palette.neutralPrimary,
        fontWeight: 600,
        backgroundColor: theme.palette.neutralQuaternaryAlt,
      },
      ".rc-tree-treenode:hover": {
        color: "inherit",
        backgroundColor: theme.palette.neutralLight,
        selectors: {
          ".ms-Button-icon": {
            color: theme.palette.neutralPrimary,
          },
        },
      },
      ".rc-tree-iconEle": {
        marginRight: 0,
      },
    },
  },
}));

export interface RCTreeProps {
  draggable?: boolean;
  editable?: boolean;
  subscribable?: boolean;
  data?: RCTreeNode;
  selectedKey?: string;
  actions?: IContextualMenuItem[];
  treeNarrowKeys?: string[];
  hasNewTag?: boolean;
  treeName?: TreeName;
  onSelect?: (node: RCTreeNode) => void;
  onDrop?: (actionNodes: RCTreeNode[]) => void;
  onNarrowKeysChange?: (keys: string[]) => void;
}

export default function RCTree(props: RCTreeProps) {
  const {
    data,
    draggable = false,
    editable = false,
    subscribable = false,
    actions,
    treeNarrowKeys,
    onNarrowKeysChange,
    hasNewTag,
  } = props;
  const [tree, setTree] = useState(() => data);

  const [narrowKeys, setNarrowKeys] = useControllableValue(
    treeNarrowKeys ? [...treeNarrowKeys] : [],
    [],
    onNarrowKeysChange && ((_, newValue) => onNarrowKeysChange(newValue ?? []))
  );

  const [hasReadList, setHasReadList] = useStorageValue("readCategoryIds", props.treeName);

  const expandedKeys = useMemo(
    () =>
      narrowKeys && tree && tree.children
        ? tree.children.filter(item => !narrowKeys.includes(item.key)).map(item => item.key)
        : [],
    [narrowKeys, tree]
  );
  const treeNodes = useMemo(() => (tree && tree.children ? tree.children : []), [tree]);
  useEffect(() => {
    data && setTree(data);
  }, [data]);

  const expandTreeNodes = useMemo(() => getExpandTreeNodes(treeNodes), [treeNodes]);

  const newNodeKeys = useMemo(
    () =>
      expandTreeNodes
        .filter(item => {
          const createAt = item?.createAt;
          const isNew =
            hasNewTag && !!createAt && isNewNode(createAt) && !hasReadList?.includes(item.key);
          return isNew;
        })
        .map(item => item.key),
    [expandTreeNodes, hasNewTag, hasReadList]
  );

  const [isClear, setIsClear] = useState(false);

  // 清除已经超出14天之内的新创建目录和已经被删除的新增目录,因为每次onSelect都会触发数据更新，增加isClear记录
  useEffect(() => {
    if (!isClear && expandTreeNodes.length > 0 && hasReadList) {
      const currentReadList = hasReadList.filter(key => {
        const node = expandTreeNodes.filter(item => item.key === key)[0];
        return node && node.createAt && isNewNode(node.createAt);
      });
      currentReadList.length !== hasReadList.length && setHasReadList(currentReadList);
      setIsClear(true);
    }
  }, [expandTreeNodes, hasReadList, setHasReadList, isClear]);

  const onDragStart = (info: NodeDragEventParams) => {
    props.onSelect && props.onSelect(info.node as unknown as RCTreeNode);
  };

  const onDropTreeNode = (
    info: NodeDragEventParams & {
      dragNode: EventDataNode<RCTreeNode>;
      dragNodesKeys: Key[];
      dropPosition: number;
      dropToGap: boolean;
    }
  ) => {
    const dropNode = info.node as unknown as RCTreeNode;
    const dragRCTreeNode = info.dragNode;
    if (dragRCTreeNode.isPlaceHolder) {
      return;
    }
    const dropKey = dropNode.key;
    const dragKey = dragRCTreeNode.key;
    const dropPos = info.node.pos;
    const dropPosLevel = info.node.pos.split("-").length - 1;
    const dropPosition = info.dropPosition - Number(dropPos.split("-")[dropPosLevel]);
    const dropKeyObj: {
      key: string;
      isParent?: boolean;
    } = {
      key: dropKey,
    };

    const dndCallBack = (newTree?: RCTreeNode, actionNodes?: RCTreeNode[]) => {
      props.onDrop && actionNodes && props.onDrop(actionNodes);
      setTree(preTree =>
        preTree
          ? {
              ...preTree,
              children: newTree?.children?.length ? [...newTree.children] : [],
            }
          : preTree
      );
    };
    if (dropPosLevel <= 2) {
      // 目前只有两级
      if (dropPosLevel < dragRCTreeNode.level!) {
        if (dropNode.children && dropNode.children.length !== 0) {
          dropKeyObj.key = `${dropNode.children[0].key}`;
        } else {
          dropKeyObj.isParent = true;
        }
        tree && dndRCTreeNodes(tree, dragKey, dropKeyObj, 0, dndCallBack);
      } else if (dropPosLevel === dragRCTreeNode.level!) {
        if (dropPosition === -1) {
          // Drop on the gap insert before
          tree && dndRCTreeNodes(tree, dragKey, dropKeyObj, 0, dndCallBack);
        } else {
          // Drop on the gap insert after
          tree && dndRCTreeNodes(tree, dragKey, dropKeyObj, 1, dndCallBack);
        }
      }
    }
  };

  const styles = useTreeStyles();

  return !tree || !tree.children || tree.children.length === 0 ? null : (
    <Tree
      className={styles.tree}
      autoExpandParent={true}
      expandedKeys={expandedKeys}
      defaultExpandAll={true}
      selectable={true}
      multiple={false}
      checkable={false}
      showLine={false}
      showIcon={true}
      treeData={treeNodes}
      selectedKeys={[props.selectedKey ?? ""]}
      dropIndicatorRender={props => null}
      switcherIcon={props => {
        if (!props.data?.children || props.data.children.length === 0) {
          return null;
        }
        return (
          <ActionButton
            iconProps={{
              iconName: props.expanded ? IconName.ChevronUp : IconName.ChevronDown,
              styles: {
                root: {
                  fontSize: FontSizes.xSmall,
                },
              },
            }}
            onClick={() => {
              if (props && props.data && props.data.key) {
                if (props.expanded) {
                  setNarrowKeys(
                    narrowKeys
                      ? [...narrowKeys, props.data.key as string]
                      : [props.data.key as string]
                  );
                } else {
                  setNarrowKeys(
                    narrowKeys
                      ? narrowKeys.filter(item => item !== (props?.data?.key as string))
                      : []
                  );
                }
              }
            }}
          ></ActionButton>
        );
      }}
      titleRender={generateTreeNodeRender(newNodeKeys, editable, actions, subscribable)}
      onClick={(e, n) => {
        if (n.isLeaf) {
          return;
        }
        if (n.expanded) {
          setNarrowKeys(narrowKeys ? [...narrowKeys, n.key as string] : [n.key as string]);
        } else {
          setNarrowKeys(
            narrowKeys ? [...narrowKeys].filter(item => item !== (n.key as string)) : []
          );
        }
      }}
      draggable={draggable}
      onDrop={onDropTreeNode}
      onDragStart={onDragStart}
      onSelect={(keys, info) => {
        if (!hasReadList?.includes(info.node.key as string)) {
          setHasReadList([...(hasReadList || []), info.node.key as string]);
        }
        props.onSelect && props.onSelect(info.node as RCTreeNode);
      }}
    />
  );
}
