import { ICheckStyleProps } from "@fluentui/react/lib/Check";
import { IDetailsRowStyleProps, IDetailsListStyleProps } from "@fluentui/react/lib/DetailsList";
import { ITheme, FontWeights } from "@fluentui/react";
import { FontSizes } from "../fonts";

export function getCheckStyles(_themeName: string, _theme: ITheme) {
  return (props: ICheckStyleProps) => {
    const { theme, checked } = props;
    const { palette } = theme;

    const styles = {
      circle: [!checked && { color: palette.neutralSecondary }],
      check: [!checked && { color: palette.neutralSecondary }],
    };

    return styles;
  };
}

export function getDetailsListStyles(themeName: string) {
  // tslint:disable-next-line:max-func-body-length
  return (props: IDetailsListStyleProps) => {
    const { theme } = props;
    const { palette } = theme;

    const styles = {
      root: {
        background: "transparent",
      },
      headerWrapper: {
        selectors: {
          ".ms-DetailsHeader": {
            background: "transparent",
            paddingTop: "2px",
            selectors: {
              ".is-actionable": {
                selectors: {
                  ":hover": {
                    background: "transparent",
                    color: palette.black,
                    selectors: {
                      ".ms-DetailsHeader-cellTitle": {
                        color: palette.black,
                        selectors: {
                          i: {
                            color: palette.black,
                          },
                        },
                      },
                    },
                  },
                  ":active": {
                    background: "transparent",
                  },
                },
              },
              ".ms-DetailsHeader-cellTitle": {
                color: palette.black,
                selectors: {
                  "i[data-icon-name='ChevronDown']": {
                    color: palette.black,
                    fontSize: FontSizes.xSmall,
                  },
                },
              },
              ".ms-DetailsHeader-filterChevron": {
                paddingTop: "2px",
              },
              ":hover .ms-DetailsHeader-cellTitle": {
                background: "none",
              },
            },
          },
        },
      },
    };

    // Contrast theme overrides
    if (themeName === "contrast") {
      styles.headerWrapper.selectors[".ms-DetailsHeader"].selectors[".is-actionable"].selectors[
        ":hover"
      ].background = palette.themeDark;

      styles.headerWrapper.selectors[".ms-DetailsHeader"].selectors[".is-actionable"].selectors[
        ":hover"
      ].color = palette.white;
      styles.headerWrapper.selectors[".ms-DetailsHeader"].selectors[".is-actionable"].selectors[
        ":hover"
      ].selectors[".ms-DetailsHeader-cellTitle"].selectors["i"].color = palette.white;
      styles.headerWrapper.selectors[".ms-DetailsHeader"].selectors[".is-actionable"].selectors[
        ":hover"
      ].selectors[".ms-DetailsHeader-cellTitle"].color = palette.white;
      styles.headerWrapper.selectors[".ms-DetailsHeader"].selectors[
        ".ms-DetailsHeader-cellTitle"
      ].color = palette.black;
    }

    return styles;
  };
}

export function getDetailsRowStyles(themeName: string) {
  // tslint:disable-next-line:max-func-body-length
  return (props: IDetailsRowStyleProps) => {
    const { theme, isSelected } = props;
    const { palette } = theme;

    let SELECTED_ROW_BACKGROUND_COLOR = palette.white;
    let HOVER_STYLES_COLOR = `${palette.black}`;
    let FOCUS_STYLES_COLOR = `${palette.black}`;
    let SELECTED_ROW_COLOR = `${palette.black}`;
    let ROOT_COLOR = `${palette.black}`;
    let SELECTED_COLOR = `${palette.black}`;

    const HERO_BUTTON = "[class*='heroButton']";

    // Dark theme overrides
    if (themeName === "dark") {
      SELECTED_ROW_BACKGROUND_COLOR = palette.white;
      HOVER_STYLES_COLOR = `${palette.black}`;
      SELECTED_COLOR = `${palette.black}`;
    }

    // Contrast theme overrides
    if (themeName === "contrast") {
      SELECTED_ROW_BACKGROUND_COLOR = palette.themePrimary;
      FOCUS_STYLES_COLOR = `${palette.white}`;
      SELECTED_ROW_COLOR = `${palette.white}`;
      ROOT_COLOR = `${palette.black}`;
      SELECTED_COLOR = `${palette.white}`;
    }

    const hoverStyles = {
      background: palette.neutralLighter,
      color: HOVER_STYLES_COLOR,
      selectors: {
        ".ms-DetailsRow-cell": {
          color: HOVER_STYLES_COLOR,
          selectors: {
            div: {
              color: HOVER_STYLES_COLOR,
            },
            ".ms-Link": {
              color: HOVER_STYLES_COLOR,
            },
          },
        },
      },
    };

    const FocusStyles = {
      background: palette.neutralLighter,
      color: FOCUS_STYLES_COLOR,
      selectors: {
        ".ms-DetailsRow-cell": {
          color: FOCUS_STYLES_COLOR,
          selectors: {
            div: {
              color: FOCUS_STYLES_COLOR,
            },
            ".ms-Link": {
              color: FOCUS_STYLES_COLOR,
            },
          },
        },
      },
    };

    // Dark theme overrides
    if (themeName === "dark") {
      hoverStyles.background = palette.white;
      FocusStyles.background = palette.white;
    }

    // Contrast theme overrides
    if (themeName === "contrast") {
      hoverStyles.background = palette.themePrimary;
      FocusStyles.background = palette.themePrimary;
    }

    const styles = {
      root: [
        {
          background: "transparent",
          color: palette.black,
          borderBottomColor: palette.neutralLighter,
          fontSize: FontSizes.medium,
          selectors: {
            ".ms-DetailsRow-cell": {
              color: ROOT_COLOR,
              paddingTop: 0,
              paddingBottom: 0,
              display: "flex",
              alignItems: "center",
              selectors: {
                div: {
                  color: ROOT_COLOR,
                },
                ".ms-Link": {
                  color: ROOT_COLOR,
                },
              },
            },
            ":focus $check": {
              opacity: 1,
            },
            ".ms-Details": {
              selectors: {
                ".ms-Button": {
                  background: "transparent",
                },
              },
            },
            ":hover": {
              ...hoverStyles,
            },
            ":focus:hover": {
              ...hoverStyles,
            },
            "&.is-selected:hover": {
              ...hoverStyles,
            },
            [HERO_BUTTON]: {
              background: "transparent ",
              selectors: {
                ":hover": {
                  background: "transparent ",
                  selectors: {
                    i: {
                      fontWeight: FontWeights.bold,
                    },
                  },
                },
              },
            },
          },
        },
        isSelected && [
          {
            background: SELECTED_ROW_BACKGROUND_COLOR,
            color: SELECTED_ROW_COLOR,
            selectors: {
              ".ms-DetailsRow-cell": {
                color: SELECTED_COLOR,
                selectors: {
                  div: {
                    color: SELECTED_COLOR,
                  },
                  ".ms-Link": {
                    color: SELECTED_COLOR,
                  },
                },
              },
              ":hover": {
                ...hoverStyles,
              },
              ":focus": { ...FocusStyles },
              ":focus:hover": {
                ...hoverStyles,
              },
            },
          },
        ],
      ],
    };

    return styles;
  };
}
