import { DriveItem } from "@microsoft/microsoft-graph-types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileFilterI18n, FilePicker } from "../../locales";
import {
  DocInfo,
  requestWeb,
  VideoInfo,
  WEB_API,
  FileDocType,
  FileInfo,
} from "../../services/request";
import FilePickerDialog from "./FilePickerDialog";
import MetaEditorDialog, { MetaEditorDialogProps, emptyCoverImg } from "./MetaEditorDialog";
import { getFileSize } from "../../lib/fileSize";
import UploadLocalFile, { UploadDocFileType } from "./UploadLocalFile";
import { logger } from "../../services/logger";
import { toAbsURL } from "../../lib/absUrl";
import {
  MAX_VIDEO_FILE_SIZE,
  MAX_TEMPLATE_FILE_SIZE,
  MAX_EXCEL_FILE_SIZE,
  MAX_PDF_FILE_SIZE,
  MAX_PPT_FILE_SIZE,
  MAX_WORD_FILE_SIZE,
} from "../../config/constant";
import {
  TING_DOC_RANGE,
  VIDEO_RANGE,
  PDF_RANGE,
  EXCEL_RANGE,
  PPT_RANGE,
  WORD_RANGE,
} from "../../config/uploadType";
import pptFileImg from "../../img/create_file_pptx.png";
import pdfFileImg from "../../img/create_file_pdf.png";
import excelFileImg from "../../img/create_file_excel.png";
import wordFileImg from "../../img/create_file_word.png";
import videoFileImg from "../../img/create_file_video.png";
// import { MessageBarType } from "@fluentui/react";
// const MP4Box = require("mp4box");

const officeCoverMap = {
  [FileDocType.excel]: toAbsURL(excelFileImg),
  [FileDocType.pdf]: toAbsURL(pdfFileImg),
  [FileDocType.ppt]: toAbsURL(pptFileImg),
  [FileDocType.word]: toAbsURL(wordFileImg),
};

const STEP_PCIKER = 1;
const STEP_META = 2;

function generateVideoInfo(docInfo?: DocInfo): VideoInfo | undefined {
  if (docInfo?.name && docInfo.downloadUrl) {
    return {
      ...docInfo,
      video: docInfo.downloadUrl!,
    };
  }
}

function generateOfficeInfo(docInfo?: DocInfo, officeType?: FileDocType): FileInfo | undefined {
  if (docInfo?.name && docInfo.downloadUrl) {
    return {
      ...docInfo,
      fileUrl: docInfo.downloadUrl!,
      type: officeType!,
    };
  }
}

function getOfficeFileType(fileExt: string) {
  if (EXCEL_RANGE.includes(fileExt)) {
    return FileDocType.excel;
  } else if (PDF_RANGE.includes(fileExt)) {
    return FileDocType.pdf;
  } else if (PPT_RANGE.includes(fileExt)) {
    return FileDocType.ppt;
  } else if (WORD_RANGE.includes(fileExt)) {
    return FileDocType.word;
  }
}

export default function CreateDocDialog({
  isAdmin = true,
  hidden,
  onDismiss,
  fileOrigin,
  uploadFileType,
  localFileInputRef,
  onFilePickerSubmit,
  onInputChange,
}: {
  isAdmin?: boolean;
  hidden?: boolean;
  fileOrigin?: "local" | "onedrive";
  uploadFileType?: UploadDocFileType;
  localFileInputRef?: any;
  onDismiss?: () => void;
  onFilePickerSubmit?: (docInfo: DocInfo) => void;
  onInputChange?: () => void;
}) {
  const { t } = useTranslation();
  const [step, setStep] = useState(STEP_PCIKER);
  const [state, setState] = useState<DocInfo>();
  const [typeErr, setTypeError] = useState<MetaEditorDialogProps["typeErr"]>();
  const onMetaUpdate = useCallback(
    (data: { name?: string | undefined; cover?: string | undefined }) => {
      setState(s => ({ ...s, ...data }));
      return true;
    },
    []
  );
  const onBack = useCallback(() => {
    if (fileOrigin === "local") {
      if (localFileInputRef.current) localFileInputRef.current.value = "";
      localFileInputRef.current.click();
    }
    setStep(STEP_PCIKER);
    setTypeError(undefined);
  }, [fileOrigin, localFileInputRef]);

  const uploadVideo = useCallback(async () => {
    const bodyInfo = generateVideoInfo(state);
    if (!bodyInfo) {
      return;
    }
    if (bodyInfo.coverBlobUrl) {
      bodyInfo.cover = bodyInfo.coverBlobUrl;
    }
    return requestWeb(isAdmin ? WEB_API.myTenantDocumentsVideo : WEB_API.myDocumentsVideo, {
      method: "POST",
      data: { ...bodyInfo, name: bodyInfo.name! },
    }).then(() => {
      onDismiss && onDismiss();
      setState(undefined);
      setStep(STEP_PCIKER);
    });
  }, [isAdmin, onDismiss, state]);

  const videoSubmit = useCallback(async () => {
    logger?.telemetry("ClickVideoDocSaveBtn", {
      VideoSize: state?.size!,
      FileOrigin: fileOrigin!,
    });

    // 暂不检验onedrive mp4的编码格式，download文件太耗时
    await uploadVideo();
    // check file codec: only support H.264
    // const mp4boxFile = MP4Box.createFile();
    // mp4boxFile.onReady = async (info: any) => {
    //   const mime = info.mime;
    //   const codec = mime.match(/codecs="(\S*),/)[1];
    //   console.log(`codecs:${codec}`);
    //   if (codec.indexOf("avc") === -1) {
    //     // 非H.264
    //     setTypeError({
    //       message: t(View.errContent),
    //       type: MessageBarType.error,
    //       onDismiss: () => setTypeError(undefined),
    //     });
    //     return;
    //   }

    //   await uploadVideo();
    // };

    // if (state?.downloadUrl) {
    //   fetch(new URL(state?.downloadUrl)).then(async response => {
    //     if (response.ok) {
    //       const reader = new FileReader();
    //       reader.readAsArrayBuffer(await response.blob());
    //       reader.onload = (e: any) => {
    //         const arrayBuffer = e.target.result;
    //         arrayBuffer.fileStart = 0;
    //         mp4boxFile.appendBuffer(arrayBuffer);
    //       };
    //     }
    //   });
    // }
  }, [fileOrigin, uploadVideo, state]);

  const officeSubmit = useCallback(
    async (officeType: FileDocType) => {
      logger?.telemetry("ClickFileDocSaveBtn", {
        FileSize: state?.size!,
        FileOrigin: fileOrigin!,
      });
      const bodyInfo = generateOfficeInfo(state, officeType);
      if (!bodyInfo) {
        return;
      }
      if (bodyInfo.coverBlobUrl) {
        bodyInfo.cover = bodyInfo.coverBlobUrl;
      }
      return requestWeb(WEB_API.myTenantDocumentsFile, {
        method: "POST",
        data: { ...bodyInfo, name: bodyInfo.name! },
      }).then(res => {
        onDismiss && onDismiss();
        setState(undefined);
        setStep(STEP_PCIKER);
      });
    },
    [fileOrigin, onDismiss, state]
  );

  const onSubmit = useCallback(async () => {
    const fileExt = state?.ext || "";
    const officeType = getOfficeFileType(fileExt);
    return officeType ? officeSubmit(officeType) : videoSubmit();
  }, [officeSubmit, state?.ext, videoSubmit]);

  const onPickerSubmit = useCallback(
    (item?: DriveItem & { "@microsoft.graph.downloadUrl"?: string }) => {
      if (item) {
        const docInfo: DocInfo = {
          downloadUrl: item["@microsoft.graph.downloadUrl"]!,
          name: (item.name || "").replace(/\.[^/.]+$/, ""),
          cover: item.thumbnails?.[0].large?.url!,
          itemId: item.id!,
          driveId: item.parentReference?.driveId!,
          driveType: item.parentReference?.driveType!,
          ext: item.name?.split(".").pop(),
        };

        if (docInfo.ext?.startsWith("ppt")) {
          return onFilePickerSubmit && onFilePickerSubmit(docInfo);
        }

        setState(s => {
          if (s?.itemId === docInfo.itemId) {
            // 保留之前修改
            return s;
          } else {
            if (!docInfo.cover) {
              docInfo.cover = emptyCoverImg;
            }
            return docInfo;
          }
        });
        setStep(STEP_META);
      }
    },
    [onFilePickerSubmit]
  );

  const onLocalFilePikerSubmit = useCallback(
    docInfo => {
      if (uploadFileType === "tingdoc") {
        return onFilePickerSubmit && onFilePickerSubmit(docInfo);
      }
      setState(docInfo);
      setStep(STEP_META);
    },
    [onFilePickerSubmit, uploadFileType]
  );

  const defaultUploadCover = useMemo(() => {
    if (step === STEP_META && state) {
      const officeType = getOfficeFileType(state.ext || "");
      if (officeType) {
        return officeCoverMap[officeType];
      } else if (VIDEO_RANGE.includes(state.ext || "")) {
        return toAbsURL(videoFileImg);
      }
    }
  }, [state, step]);

  const fileAccept = useMemo(
    () =>
      (uploadFileType === "office"
        ? [...EXCEL_RANGE, ...PPT_RANGE, ...PDF_RANGE, ...WORD_RANGE, ...VIDEO_RANGE]
        : uploadFileType === "video"
        ? [...VIDEO_RANGE]
        : [...TING_DOC_RANGE]
      )
        .map(item => `.${item}`)
        .join(","),
    [uploadFileType]
  );

  const filterInfo = useMemo(
    () =>
      uploadFileType === "office"
        ? [
            {
              text: t(FileFilterI18n.presentation),
              key: "presentation",
              docTypeRange: PPT_RANGE,
              maxSize: MAX_PPT_FILE_SIZE,
            },
            {
              text: t(FileFilterI18n.sheet),
              key: "sheet",
              docTypeRange: EXCEL_RANGE,
              maxSize: MAX_EXCEL_FILE_SIZE,
            },
            {
              text: t(FileFilterI18n.pdf),
              key: "pdf",
              docTypeRange: PDF_RANGE,
              maxSize: MAX_PDF_FILE_SIZE,
            },
            {
              text: t(FileFilterI18n.document),
              key: "document",
              docTypeRange: WORD_RANGE,
              maxSize: MAX_WORD_FILE_SIZE,
            },
            {
              text: t(FileFilterI18n.video),
              key: "video",
              maxSize: MAX_VIDEO_FILE_SIZE,
              docTypeRange: VIDEO_RANGE,
            },
          ]
        : uploadFileType === "video"
        ? [
            {
              text: t(FileFilterI18n.video),
              key: "video",
              maxSize: MAX_VIDEO_FILE_SIZE,
              docTypeRange: VIDEO_RANGE,
            },
          ]
        : [
            {
              text: t(FileFilterI18n.presentation),
              key: "presentation",
              docTypeRange: TING_DOC_RANGE,
              maxSize: MAX_TEMPLATE_FILE_SIZE,
            },
          ],
    [t, uploadFileType]
  );

  return (
    <>
      <FilePickerDialog
        hidden={hidden || fileOrigin === "local" || step !== STEP_PCIKER}
        onSubmit={onPickerSubmit}
        onDismiss={onDismiss}
        confirmText={t(FilePicker.next)}
        pickerTips={t(
          FilePicker.pickerTips,
          uploadFileType === "tingdoc"
            ? {
                context: "tingdoc",
                size: getFileSize(MAX_TEMPLATE_FILE_SIZE),
              }
            : {
                context: "video",
                size: getFileSize(MAX_VIDEO_FILE_SIZE),
              }
        )}
        filterInfo={filterInfo}
      />
      <MetaEditorDialog
        title={t(FilePicker.editDialogTitle)}
        hidden={hidden || step !== STEP_META}
        name={state?.name}
        cover={state?.cover}
        cacheKey={state?.itemId}
        defaultUploadCover={defaultUploadCover}
        onBack={onBack}
        onUpdate={onMetaUpdate}
        onSubmit={onSubmit}
        onDismiss={() => {
          setStep(STEP_PCIKER);
          setTypeError(undefined);
          onDismiss && onDismiss();
        }}
        typeErr={typeErr}
      />
      <UploadLocalFile
        fileInputRef={localFileInputRef}
        fileAccept={fileAccept}
        uploadFileType={uploadFileType}
        onInputChange={onInputChange}
        onFilePickSuccess={onLocalFilePikerSubmit}
        onBack={onBack}
      ></UploadLocalFile>
    </>
  );
}
