import React from "react";

/**
 * render an empty root
 * @see https://github.com/microsoft/fluentui/issues/16633
 */
export default class RootFragment extends React.PureComponent {
  public render() {
    return <>{this.props.children}</>;
  }
}
