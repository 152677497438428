import { DirectionalHint, TeachingBubble, Text, makeStyles } from "@fluentui/react";
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppDeployment } from "../../locales";
import { rem } from "../../lib/unit";

const useStyles = makeStyles(() => ({
  text: {
    paddingTop: rem(12),
    whiteSpace: "pre-wrap",
    color: "inherit",
  },
}));

export interface AppTextBubbleProps {
  target: string | Element;
  headline?: string;
  content?: string;
  directionalHint: DirectionalHint;
  buttonText?: string;
  onConfirmClick?: () => void;
  onDismiss?: () => void;
}

const headlineStyles = { headline: { marginBottom: rem(12) } };

export default function AppTextBubble({
  target,
  headline,
  content,
  buttonText,
  directionalHint,
  onConfirmClick,
  onDismiss,
}: AppTextBubbleProps): React.ReactElement {
  const { t } = useTranslation();
  const styles = useStyles();
  const [hidden, setHidden] = useState(false);

  const confirmBtn = useCallback(() => {
    onConfirmClick && onConfirmClick();
    setHidden(true);
    onDismiss && onDismiss();
  }, [onConfirmClick, onDismiss]);

  const dismiss = useRef(onDismiss);
  if (dismiss.current !== onDismiss) dismiss.current = onDismiss;
  useEffect(() => () => dismiss.current && dismiss.current(), []);

  const hasButton = Boolean(buttonText || onConfirmClick);
  const secondaryButton = useMemo(
    () =>
      hasButton
        ? {
            text: buttonText || t(AppDeployment.bubbleBtn),
            onClick: confirmBtn,
          }
        : undefined,
    [buttonText, confirmBtn, hasButton, t]
  );

  return hidden ? (
    <></>
  ) : (
    <TeachingBubble
      target={target}
      onDismiss={onDismiss}
      hasCloseButton
      calloutProps={{
        directionalHint,
        preventDismissOnLostFocus: true,
        directionalHintFixed: true,
      }}
      secondaryButtonProps={secondaryButton}
      styles={headlineStyles}
      headline={headline}
    >
      {content && (
        <Text block className={styles.text} variant="small">
          {content}
        </Text>
      )}
    </TeachingBubble>
  );
}
