import { useMemo } from "react";
import {
  AdminNavKeys,
  generateCategoryHash,
  HASHType,
  ResourceScope,
  TemplateNavKeys,
  UserNavKeys,
} from "../../config/rc";
import { Catalog, CatalogNodeDetail, selectAPI, UserRole, WEB_API } from "../../services/request";
import { DataNode } from "rc-tree/lib/interface";
import { useQueryWeb } from "../useQuery";
import { AdminPageI18n, RCNavI18n, MePageI18n } from "../../locales";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { ADMINCENTER_DOC_URL } from "../../config/constant";
import { logger } from "../../services/logger";
import { INavLink } from "@fluentui/react";

export const emptyNodeKeyPrefix = "emptyNode_";

export interface NavLink {
  name: string;
  key?: string;
  url: string;
  target?: string;
}

export enum ActionKey {
  addCategory = "addCategory",
  editCategory = "editCategory",
  deleteCategory = "deleteCategory",
  editFeedsConfig = "editFeedsConfig",
  pushNotification = "pushNotification",
}

export interface RCTreeNode extends DataNode {
  key: string;
  nodeETag?: string;
  parentId?: string;
  children?: RCTreeNode[];
  isPlaceHolder?: boolean;
  raw?: CatalogNodeDetail;
  level?: number;
  actionKeys?: ActionKey[];
  createAt?: number;
}

const getRCTreeNodeAndLinksFromCatalog = (
  type: ResourceScope,
  catalog?: Catalog,
  showEmpty?: boolean,
  showEmptyPlaceHolder?: boolean,
  builtInLocale?: string
) => {
  let navLinks: NavLink[] | undefined;
  let rcTreeNode: RCTreeNode | undefined;
  if (catalog) {
    navLinks = [];
    rcTreeNode = {
      key: catalog.id,
      isLeaf: false,
      nodeETag: catalog.nodeETag,
      level: 0,
      children: catalog.catalogNodesDetail
        ? getLinksFromCatalogNodesDetail(
            type,
            1,
            "",
            showEmpty,
            showEmptyPlaceHolder,
            catalog.catalogNodesDetail,
            navLinks,
            builtInLocale
          )
        : [],
    };
  }
  return {
    rcTreeNode,
    navLinks,
  };
};

function getActionKeys(scope: ResourceScope, level: number, hasChildren: boolean) {
  let actionKeys = [];
  switch (scope) {
    case ResourceScope.feeds:
      actionKeys = level === 1 ? [] : [ActionKey.editFeedsConfig];
      break;
    case ResourceScope.mytenant:
      actionKeys =
        level === 1
          ? hasChildren
            ? [ActionKey.addCategory, ActionKey.editCategory]
            : [ActionKey.addCategory, ActionKey.deleteCategory, ActionKey.editCategory]
          : [ActionKey.deleteCategory, ActionKey.editCategory, ActionKey.pushNotification];

      break;
  }
  return actionKeys;
}

const maxDepth = 2;

const getLinksFromCatalogNodesDetail = (
  scope: ResourceScope,
  level: number,
  parentId: string,
  showEmpty?: boolean,
  showEmptyPlaceHolder?: boolean,
  catalogNodesDetail?: CatalogNodeDetail[],
  allCategoryNavLinks?: NavLink[],
  builtInLocale?: string
): RCTreeNode[] => {
  const rcTreeNodes: RCTreeNode[] = [];
  if (!catalogNodesDetail || catalogNodesDetail.length === 0 || level > maxDepth) {
    return rcTreeNodes;
  } else {
    catalogNodesDetail
      .filter(n => {
        if (!builtInLocale || !n.treeNode?.languages) {
          return true;
        } else if (
          n.treeNode?.languages?.find(item => item.toLowerCase() === builtInLocale.toLowerCase()) ||
          n.treeNode?.languages?.find(
            item => item.toLowerCase().replace(/[-_].*/, "") === builtInLocale.toLowerCase()
          )
        ) {
          return true;
        } else {
          return false;
        }
      })
      .forEach(item => {
        const nextLevel = level + 1;
        const hasChildren = item.childrenDetail && item.childrenDetail.length !== 0;
        let rcTreeNode;
        rcTreeNode = {
          nodeETag: item.nodeETag,
          title: item.treeNode.title,
          key: item.treeNode.id,
          isLeaf: level !== 1,
          raw: item,
          level,
          parentId,
          actionKeys: getActionKeys(scope, level, !!hasChildren),
          createAt: item.treeNode.createAt,
        } as RCTreeNode;
        if (hasChildren) {
          rcTreeNode.children = getLinksFromCatalogNodesDetail(
            scope,
            nextLevel,
            item.treeNode.id,
            showEmpty,
            showEmptyPlaceHolder,
            item.childrenDetail,
            allCategoryNavLinks,
            builtInLocale
          );
        } else {
          if (level === 1 && showEmpty && showEmptyPlaceHolder) {
            rcTreeNode.children = [
              {
                isPlaceHolder: true,
                selectable: false,
                key: `${emptyNodeKeyPrefix}${item.treeNode.id}`,
              },
            ];
          } else if (level === 1) {
            rcTreeNode = undefined;
          }
        }
        if (allCategoryNavLinks && level === maxDepth) {
          allCategoryNavLinks.push({
            name: item.treeNode.title,
            key: item.treeNode.id,
            url: generateCategoryHash(item.treeNode.id, scope),
          });
        }
        rcTreeNode && rcTreeNodes.push(rcTreeNode);
      });
    return rcTreeNodes;
  }
};

export interface useNavLinksConfig {
  role?: UserRole;
  showEmptyPlaceHolder?: boolean;
  showEmpty?: boolean;
  feedsLanguage?: string;
}

export function useNavLinks(config: useNavLinksConfig) {
  const { t } = useTranslation();
  const { role, showEmptyPlaceHolder, showEmpty, feedsLanguage } = config;
  const isGuest = role === UserRole.guest;
  const apis = selectAPI(ResourceScope.feeds, isGuest);
  const {
    data: feedsCatalog,
    refetch: refecthFeedsCatalog,
    isLoading: isFeedsLoading,
  } = useQueryWeb(apis.catalogAPI, {
    skipAuth: isGuest,
    config: {
      enabled: !!role,
    },
  });

  const templateHubNavLinks: NavLink[] = useMemo(
    () => [
      {
        id: TemplateNavKeys.adminTemplateHub,
        key: TemplateNavKeys.adminTemplateHub,
        url: `${HASHType.myTenantTemplates}/`,
        name: t(RCNavI18n.templateSubTitle),
      },
    ],
    [t]
  );

  const adminNav: INavLink[] = useMemo(
    () => [
      {
        isExpanded: true,
        key: "admin-conenthub-folder",
        name: t(AdminPageI18n.contentHubNav),
        icon: IconName.Library,
        url: `${HASHType.myTenantContent}/`,
        disabled: true,
        links: [
          {
            id: AdminNavKeys.adminContentHub,
            key: AdminNavKeys.adminContentHub,
            url: `${HASHType.myTenantContent}/`,
            name: t(AdminPageI18n.navContent),
          },
          {
            id: AdminNavKeys.adminBundles,
            key: AdminNavKeys.adminBundles,
            url: `${HASHType.myTenantBundles}/`,
            name: t(AdminPageI18n.navBundles),
          },
        ],
      },
      {
        key: AdminNavKeys.adminAssignment,
        url: `${HASHType.adminAssignment}/`,
        icon: IconName.PlayerSettings,
        name: t(AdminPageI18n.assignmentNav),
      },
      {
        id: AdminNavKeys.appDeploymentHelp,
        key: AdminNavKeys.appDeploymentHelp,
        url: ADMINCENTER_DOC_URL,
        target: "_blank",
        icon: IconName.Rocket,
        name: t(AdminPageI18n.appDeploymentHelpNav),
        onClick: () => logger?.telemetry("ClickAppDeploymentDocBtn"),
      },
    ],
    [t]
  );

  const userNav: INavLink[] = useMemo(
    () => [
      {
        key: UserNavKeys.userContentHub,
        url: `${HASHType.myContent}/`,
        name: t(MePageI18n.navContent),
        icon: IconName.Library,
      },
    ],
    [t]
  );

  const allFeedsDisabled = !feedsCatalog?.catalogNodesDetail?.length;
  const {
    data: myTenantCatalog,
    refetch: refecthMytenantCatalog,
    isLoading: isMytenantLoading,
  } = useQueryWeb(WEB_API.myTenantCatalog, {
    config: {
      enabled: !!role && !isGuest,
    },
  });

  const { rcTreeNode: feedsTree, navLinks: feedsCategoryLinks } = useMemo(
    () =>
      getRCTreeNodeAndLinksFromCatalog(
        ResourceScope.feeds,
        feedsCatalog,
        showEmpty,
        showEmptyPlaceHolder,
        feedsLanguage
      ),
    [feedsCatalog, showEmpty, showEmptyPlaceHolder, feedsLanguage]
  );
  const { rcTreeNode: myTenantTree, navLinks: myTenantCategoryLinks } = useMemo(
    () =>
      getRCTreeNodeAndLinksFromCatalog(
        ResourceScope.mytenant,
        myTenantCatalog,
        showEmpty,
        showEmptyPlaceHolder
      ),
    [myTenantCatalog, showEmptyPlaceHolder, showEmpty]
  );

  return {
    feedsTree,
    allFeedsDisabled,
    feedsCategoryLinks,
    myTenantTree,
    myTenantCategoryLinks,
    templateHubNavLinks,
    adminNavLinks: adminNav,
    userNavLinks: userNav,
    refecthFeedsCatalog,
    refecthMytenantCatalog,
    feedsSettled: !!role && !isFeedsLoading,
    myTenantSettled: isGuest || !isMytenantLoading,
  };
}
