import { createTheme, ITheme, IRawStyle, IPalette } from "@fluentui/react";
import { fonts } from "./fonts";
import { ContrastColors, CoreColors, DarkColors } from "./teamsColors";

export enum ThemeType {
  defaultV2 = "defaultV2",
  darkV2 = "darkV2",
  contrastV2 = "contrastV2",
  default = "default",
  dark = "dark",
  contrast = "contrast",
}

export const FluentUITheme_TeamsDefault = (fontFamily: IRawStyle): ITheme => {
  const palette = {
    themeDarker: "#33344A",
    themeDark: "#464775",
    themeDarkAlt: "#585A96",
    themePrimary: "#6264A7", // brand
    themeSecondary: "#6f70b5",
    themeTertiary: "#A6A7DC",
    themeLight: "#BDBDE6",
    themeLighter: "#E2E2F6",
    themeLighterAlt: "#F4F4FC",
    neutralDark: "#484644",
    neutralPrimary: "#252423", // text color
    neutralPrimaryAlt: "#605E5C",
    neutralSecondary: "#979593",
    neutralTertiary: "#C8C6C4",
    neutralTertiaryAlt: "#E1DFDD",
    neutralLight: "#EDEBE9",
    neutralLighter: "#F3F2F1",
    neutralLighterAlt: "#FAF9F8",
    neutralQuaternaryAlt: "#dadada",
    neutralQuaternary: "#d0d0d0",
    black: "#000",
    white: "#fff",
  } as const;
  // const neutralTheme = getNeutralVariant({ palette });

  return createTheme({
    fonts,
    defaultFontStyle: fontFamily,
    palette,
    semanticColors: {
      // approvals colors for status
      inputText: CoreColors.requestedDark,
      inputBackground: CoreColors.requestedLight,
      successText: CoreColors.approvedDark,
      successBackground: CoreColors.approvedLight,
      errorText: CoreColors.rejectedDark,
      errorBackground: CoreColors.rejectedLight,
      warningText: CoreColors.customRespDark,
      warningBackground: CoreColors.customRespLight,
    },
  });
};

export const FluentUITheme_TeamsDark = (fontFamily: IRawStyle): ITheme => {
  const palette: Partial<IPalette> = {
    black: DarkColors.appBlack,
    blackTranslucent40: DarkColors.appGray04,

    neutralDark: DarkColors.appGray02,
    neutralPrimary: DarkColors.appGray03,
    neutralPrimaryAlt: DarkColors.appGray03,
    neutralSecondary: DarkColors.appGray04,
    neutralSecondaryAlt: DarkColors.appGray16,
    neutralTertiary: DarkColors.appGray06,

    neutralTertiaryAlt: DarkColors.appGray08,
    neutralQuaternary: DarkColors.appGray09,
    neutralQuaternaryAlt: DarkColors.appGray09,
    neutralLight: DarkColors.appGray10,
    neutralLighter: DarkColors.appGray12,
    neutralLighterAlt: DarkColors.appGray14,

    white: DarkColors.appWhite,
    whiteTranslucent40: DarkColors.appWhiteAlpha10,

    // themeDark & ThemeDarkAlt has same color values, themeLighter & themeLighterAlt has same color values
    themeDarker: DarkColors.appBrand02,
    themeDark: DarkColors.appBrand04,
    themeDarkAlt: DarkColors.appBrand04,
    themePrimary: DarkColors.appBrand06,
    themeSecondary: DarkColors.appBrand08,
    themeTertiary: DarkColors.appBrand12,
    themeLight: DarkColors.appBrand14,
    themeLighter: DarkColors.appGray02,
    themeLighterAlt: DarkColors.appBrand16,
    redDark: DarkColors.appRed,
  };
  // const neutralTheme = getNeutralVariant({ palette });

  return createTheme({
    fonts,
    defaultFontStyle: fontFamily,
    palette,
    // semanticColors: neutralTheme.semanticColors,
    semanticColors: {
      // approvals colors for status
      inputText: DarkColors.requestedDark,
      inputBackground: DarkColors.requestedLight,
      successText: DarkColors.approvedDark,
      successBackground: DarkColors.approvedLight,
      errorText: DarkColors.rejectedDark,
      errorBackground: DarkColors.rejectedLight,
      warningText: DarkColors.customRespDark,
      warningBackground: DarkColors.customRespLight,
    },
    isInverted: true,
  });
};

export const FluentUITheme_TeamsContrast = (fontFamily: IRawStyle): ITheme => {
  const palette = {
    themeDarker: "#33344A",
    themeDark: "#464775",
    themeDarkAlt: "#585A96",
    themePrimary: "#6264A7", // brand
    themeSecondary: "#6f70b5",
    themeTertiary: "#A6A7DC",
    themeLight: "#BDBDE6",
    themeLighter: "#E2E2F6",
    themeLighterAlt: "#F4F4FC",
    neutralDark: "#484644",
    neutralPrimary: "#252423", // text color
    neutralPrimaryAlt: "#605E5C",
    neutralSecondary: "#979593",
    neutralTertiary: "#C8C6C4",
    neutralTertiaryAlt: "#E1DFDD",
    neutralLight: "#EDEBE9",
    neutralLighter: "#F3F2F1",
    neutralLighterAlt: "#FAF9F8",
    neutralQuaternaryAlt: "#dadada",
    neutralQuaternary: "#d0d0d0",
    black: "#000",
    white: "#fff",
  } as const;
  // const neutralTheme = getNeutralVariant({ palette });

  return createTheme({
    fonts,
    defaultFontStyle: fontFamily,
    palette,

    semanticColors: {
      // approvals colors for status
      inputText: ContrastColors.requestedDark,
      inputBackground: ContrastColors.requestedLight,
      successText: ContrastColors.approvedDark,
      successBackground: ContrastColors.approvedLight,
      errorText: ContrastColors.rejectedDark,
      errorBackground: ContrastColors.rejectedLight,
      warningText: ContrastColors.customRespDark,
      warningBackground: ContrastColors.customRespLight,
    },
  });
};
