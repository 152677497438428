import { useMemo, useState, useEffect } from "react";
import { delStorage, getStorage, setStorage } from "../services/storage";

/**
 * set
 * @param key storage key
 * @returns [value, setValue, delValue]
 */
export function useStorageValue<T extends Parameters<typeof getStorage>[0]>(
  key: T,
  suffix?: string | number
) {
  const [value, setValue] = useState(() => getStorage(key, suffix));
  useEffect(() => {
    setValue(getStorage(key, suffix));
  }, [key, suffix]);
  type ValueType = typeof value;
  return useMemo<[ValueType, (v: NonNullable<ValueType>) => void, () => void]>(
    () => [
      value,
      v => {
        setValue(v);
        setStorage(key, v, suffix);
      },
      () => {
        setValue(null);
        delStorage(key, suffix);
      },
    ],
    [key, suffix, value]
  );
}
