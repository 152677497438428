import * as microsoftTeams from "@microsoft/teams-js";
import { isTeamsApp } from "../config/app";
import { APP_ENV } from "../config/config";

export { executeDeepLink, settings } from "@microsoft/teams-js";
const TIMEOUT = 2500;

microsoftTeams.initialize(() => console.debug("teams initialized"));

/**
 * 缓存context
 */
let cachedContext: microsoftTeams.Context | undefined | false;
export function getCachedTeamsContext() {
  return cachedContext;
}

let contextPrommise: Promise<TeamsContext> | undefined;
/**
 * 获取最新teamsContext
 */
export function getTeamsContext(): Promise<TeamsContext> {
  if (cachedContext === false) {
    return Promise.reject("not in Teams");
  }
  if (!contextPrommise) {
    contextPrommise = new Promise((resolve, reject) => {
      let timer = 0;
      microsoftTeams.getContext(context => {
        cachedContext = context;
        timer && clearTimeout(timer);
        contextPrommise = undefined;
        resolve(context);
      });
      if (!cachedContext && !isTeamsApp) {
        timer = window.setTimeout(() => {
          console.debug("getContext timeout");
          contextPrommise = undefined;
          if (!cachedContext) {
            cachedContext = false;
            reject("getTeamsContext timeout");
          }
        }, TIMEOUT);
      }
    });
  }
  return contextPrommise;
}

export const guestCancelled = "CancelledByUser";

let authSilentStatus = false;

let idTokenPrommise: Promise<string> | undefined;

/*
 * Teams sso 登录
 * @returns Id Token
 */
export function ssoAuth(silent?: boolean): Promise<string> {
  if (silent === false) {
    // show login dialog next time, if is blocked
    authSilentStatus = false;
  }
  if (!idTokenPrommise) {
    idTokenPrommise = new Promise<string>((resolve, reject) => {
      microsoftTeams.authentication.getAuthToken({
        resources: ["openid"],
        silent: silent ?? authSilentStatus,
        successCallback(token: string) {
          if (APP_ENV !== "production") {
            console.debug("SSO Token:", token);
          }
          idTokenPrommise = undefined;
          resolve(token);
        },
        failureCallback(error: any) {
          idTokenPrommise = undefined;
          console.error("SSO Failure:", error);
          if (error === guestCancelled) {
            authSilentStatus = true;
          }
          reject(error);
        },
      });
      if (!isTeamsApp) {
        setTimeout(() => {
          idTokenPrommise = undefined;
          console.debug("sso timeout");
          reject("sso timeout, may not run in teams app");
        }, TIMEOUT);
      }
    });
  }
  return idTokenPrommise;
}

export const registerOnThemeChangeHandler = microsoftTeams.registerOnThemeChangeHandler;
export type TeamsContext = microsoftTeams.Context;
export const registerBeforeUnloadHandler = microsoftTeams.registerBeforeUnloadHandler;
