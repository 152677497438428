import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import BundleCard from "../cards/BundleCard";
import { IconName } from "../../config/icons";
import { Mine } from "../../locales";
import DeleteDialog from "../dialog/DeleteDialog";
import CopyCallout from "../common/CopyCallout";
import MoreButton from "../common/MoreButton";
import { EntityType, generateRCShareInfo } from "../../services/share";
import { currentApp } from "../../config/app";
import { logger } from "../../services/logger";
import { Icon, makeStyles } from "@fluentui/react";
import { ResourceScope } from "../../config/rc";
import { getStorage } from "../../services/storage";
import ShimmerCard from "../cards/ShimmerCard";
import useInfiniteScroll from "react-infinite-scroll-hook";

const targetPrefix = "BundleCard_Button";
const ImportingFailed = 2;

const useStyles = makeStyles({ deleteBtn: { pointerEvents: "auto" } });

export interface RCBundleListProps {
  showCreator?: boolean;
  hideLink?: boolean;
  scope: ResourceScope;
  bundles?: CDS.Bundle[];
  onEdit?: (collection: CDS.Bundle) => void;
  onAddToCategory?: (doc: CDS.Bundle) => any;
  onRemoveFromCategory?: (doc: CDS.Bundle) => any;
  delBundle?: (bundleId: string) => any;
  tapBundle?: (bundleId: CDS.Bundle) => any;
  isLoading?: boolean;
  hasNextPage?: boolean;
  loadMore?: () => void;
}

export default function RCBundleList({
  bundles,
  hideLink,
  delBundle,
  tapBundle,
  scope,
  showCreator,
  isLoading,
  hasNextPage,
  loadMore = () => {},
  onEdit,
  onAddToCategory,
  onRemoveFromCategory,
}: RCBundleListProps) {
  const { t } = useTranslation();

  const [deletingItem, setDeletingItem] = useState<CDS.Bundle | undefined>();
  const closeDeleteDialog = useCallback(() => setDeletingItem(undefined), []);

  const [deletingBundleArray, setdeletingBundleArray] = useState<string[]>([]);
  const RemoveBundle = useCallback(
    (id: string) => {
      setdeletingBundleArray(array => [...array, id]);
      Promise.resolve(
        delBundle &&
          delBundle(id).then(() => {
            setdeletingBundleArray(array => array.filter(item => item !== id));
          })
      );
    },
    [delBundle]
  );

  const deleteBunbdle = useCallback(() => {
    RemoveBundle(deletingItem?.id!);
    setDeletingItem(undefined);
  }, [RemoveBundle, deletingItem]);

  const [ref] = useInfiniteScroll({
    loading: !!isLoading,
    onLoadMore: loadMore,
    hasNextPage: !!hasNextPage,
  });

  const [copyItem, setCopyItem] = useState<CDS.Bundle | undefined>();
  const closeCopyCallout = useCallback(() => setCopyItem(undefined), []);
  const justReadId = getStorage("justReadId");
  const styles = useStyles();

  return (
    <>
      {bundles &&
        bundles.length !== 0 &&
        bundles.map((c, i) => {
          const categoryId = c.extension?.categoryId;
          return (
            <BundleCard
              rootRef={hasNextPage && i + 1 === bundles.length ? ref : undefined}
              isJustRead={justReadId === c.id}
              key={c.id}
              bundle={c}
              showCreator={showCreator}
              onTap={tapBundle}
              isDeleting={!!c.id && deletingBundleArray.includes(c.id)}
            >
              {c.status === ImportingFailed && !deletingBundleArray.includes(c.id) ? (
                <Icon
                  className={styles.deleteBtn}
                  iconName={IconName.ChromeClose}
                  onClick={e => {
                    c.id && RemoveBundle(c.id);
                    e.stopPropagation();
                  }}
                />
              ) : (
                <MoreButton
                  id={`${targetPrefix}_${c?.id}`}
                  title={c.name}
                  items={[
                    !hideLink && {
                      key: "link",
                      name: t(Mine.actionDeeplink),
                      iconProps: { iconName: IconName.Link },
                      onClick: () => {
                        logger?.telemetry("CopyDeepLink", {
                          WorkType: EntityType.Bundle,
                          WorkId: c.id,
                          Name: scope === ResourceScope.feeds ? c.name : undefined!,
                          Scope: scope,
                          CategoryId: categoryId!,
                          Location: window.location.pathname,
                          Target: "BundleCard",
                        });
                        setCopyItem(c);
                      },
                    },
                    onAddToCategory &&
                      !categoryId && {
                        key: `addCategory`,
                        name: t(Mine.actionAddCategory),
                        iconProps: { iconName: IconName.Add },
                        onClick: () => onAddToCategory(c),
                      },
                    onRemoveFromCategory &&
                      !!categoryId && {
                        key: `removeCategory`,
                        name: t(Mine.actionRemoveCategory),
                        iconProps: { iconName: IconName.PageRemove },
                        onClick: () => onRemoveFromCategory(c),
                      },
                    onEdit && {
                      key: "edit",
                      name: t(Mine.actionEdit),
                      iconProps: { iconName: IconName.Edit },
                      onClick: () => {
                        onEdit(c);
                      },
                    },
                    delBundle &&
                      !categoryId && {
                        key: "delete",
                        name: t(Mine.actionDelete),
                        onClick: () => setDeletingItem(c),
                        iconProps: { iconName: IconName.Delete },
                      },
                  ]}
                />
              )}
            </BundleCard>
          );
        })}
      {isLoading && (
        <>
          <ShimmerCard type="bundle" showCreator={showCreator} />
          <ShimmerCard type="bundle" showCreator={showCreator} />
          <ShimmerCard type="bundle" showCreator={showCreator} />
          <ShimmerCard type="bundle" showCreator={showCreator} />
        </>
      )}
      <DeleteDialog
        hidden={!deletingItem}
        title={t(Mine.deleteTitle)}
        subText={t(Mine.deleteContent, { context: "bundle", collectionName: deletingItem?.name })}
        doDelete={deleteBunbdle}
        onDismiss={closeDeleteDialog}
      />
      <CopyCallout
        item={
          copyItem && generateRCShareInfo(scope, EntityType.Bundle, copyItem, currentApp?.entityId)
        }
        target={copyItem && `#${targetPrefix}_${copyItem?.id}`}
        onDismiss={closeCopyCallout}
      />
    </>
  );
}
