import { BASE_FONT_SIZE } from "../config/config";

/**
 * 转成rem单位
 * rem(16) ==> "0.8rem"
 * rem(0, 16, 32) => "0 0.8rem 1.6rem"
 * @param pxs
 */
export function rem(...pxs: (number | string)[]): string {
  return pxs
    .map(px => (px === 0 ? "0" : typeof px === "string" ? px : `${px / BASE_FONT_SIZE}rem`))
    .join(" ");
}

export const MediaQuery = {
  mobile: `@media (orientation: portrait) and (max-width: 500px)`,
  // add more
} as const;
