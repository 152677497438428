import { Spinner } from "@fluentui/react";
// import { Spinner } from "@fluentui/react";
import React, { useCallback, useEffect, useState, useRef } from "react";
import CreateDocDialog from "../../components/dialog/CreateDocDialog";
import TingDocLoadingDialog, {
  TingDocLoadingDialogProps,
} from "../../components/dialog/TingDocLoadingDialog";
import { UploadDocFileType } from "../../components/dialog/UploadLocalFile";
import { pageContentContainer } from "../../components/navigation/TopBar";
import { useUser } from "../../hooks/rc/useUser";
import { logger } from "../../services/logger";
import { UserRole } from "../../services/request";

export default function CreateTingDocPage() {
  const { role } = useUser();
  const isAdmin = role === UserRole.resourceAdmin || role === UserRole.tenantAdmin;
  const inputRef = useRef<HTMLInputElement>();
  const triggerFilePicker = useCallback(() => (inputRef.current as any)?.click(), []);
  const [hiddenNewModal, setHiddenNewModal] = useState(true);
  const hide = useCallback(() => {
    setHiddenNewModal(true);
  }, []);

  const [tingDocLoadingDialogProps, setTingDocLoadingDialogProps] =
    useState<TingDocLoadingDialogProps>({ hidden: true, docInfo: {} });

  const [fileOrigin, setFileOrigin] = useState<"local" | "onedrive">();
  const [uploadType, setUploadType] = useState<UploadDocFileType>();
  const [uploadStart, setUploadStart] = useState<boolean>();

  const [show, setShow] = useState(false);
  const [showLoading, setShowLoading] = useState(() => {
    const isLoading = !!window.localStorage.getItem("vivaLoading");
    if (isLoading) {
      window.localStorage.removeItem("vivaLoading");
    }
    return isLoading;
  });

  // 解决setUploadStart更新延时问题
  useEffect(() => {
    if (uploadStart) {
      setUploadStart(false);
      triggerFilePicker();
      window.localStorage.setItem("isCreateVideo", "true");
    }
  }, [triggerFilePicker, uploadStart]);

  const onInputChange = useCallback(() => {
    window.parent.postMessage("createTingDocStart", "*");
  }, []);

  const createTingDocBtnClick = useCallback((e: any) => {
    e.preventDefault();
    logger?.telemetry("ClickCreateFromLocalBtn", { clickType: "FromCreate" });
    console.log("createTingDocBtnClick");
    setUploadType("tingdoc");
    setFileOrigin("local");
    setHiddenNewModal(false);
    setUploadStart(true);
  }, []);

  const initButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const messageListener = (event: any) => {
      console.log("RCaddEventListener", event.data);

      if (event.data?.type === "showCreateTingdocIframe") {
        const isShow = !!event.data?.showIframe;
        setShow(isShow);
        if (!isShow) {
          setShowLoading(false);
        }
      }
    };
    window.addEventListener("message", messageListener, false);
    return () => window.removeEventListener("message", messageListener, false);
  }, []);

  return (
    <div className={pageContentContainer} style={{ height: "100%", overflow: "hidden" }}>
      {!show && (
        <button
          style={
            show ? { cursor: "pointer" } : { width: "100%", height: "100vh", cursor: "pointer" }
          }
          ref={initButtonRef}
          onClick={createTingDocBtnClick}
        >
          Create TingDoc
        </button>
      )}
      {role === undefined ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <CreateDocDialog
            isAdmin={isAdmin}
            fileOrigin={fileOrigin}
            localFileInputRef={inputRef}
            hidden={hiddenNewModal}
            uploadFileType={uploadType}
            onDismiss={hide}
            onInputChange={onInputChange}
            onFilePickerSubmit={docInfo => {
              setHiddenNewModal(true);
              setTingDocLoadingDialogProps({ docInfo, hidden: false });
            }}
          />
          <TingDocLoadingDialog
            {...tingDocLoadingDialogProps}
            isAdmin={isAdmin}
            onDismiss={() => {
              setTingDocLoadingDialogProps({ hidden: true, docInfo: {} });
            }}
          />
        </>
      )}
      {role && showLoading && (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: "rgb(243, 242, 241)",
            fontSize: "24px",
          }}
        >
          Loading tingdoc video for uploading ...
        </div>
      )}
    </div>
  );
}
