import React, { useCallback, useMemo, useState } from "react";
import { Dialog, DialogFooter, IDialogContentProps, PrimaryButton, Spinner } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import CategoryPicker from "../picker/CategoryPicker";
import { WEB_API } from "../../services/request";
import { CategoryPicker as CategoryPickerNS } from "../../locales";
import { useQueryWeb } from "../../hooks/useQuery";
import { rem } from "../../lib/unit";

export default function CategoryPickerDialog({
  hidden,
  onDismiss,
  onSubmit,
  onCreate,
}: {
  hidden?: boolean;
  onDismiss?: () => void;
  onSubmit?: (selectedId: string) => void;
  onCreate?: () => void;
}) {
  const { data } = useQueryWeb(WEB_API.myTenantCatalog, { enabled: !hidden });
  const [selectedId, setSelectedId] = useState<string>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const isEmpty = data && !data.catalogNodesDetail?.length;

  const onBtnClick = useCallback(() => {
    if (selectedId && onSubmit) {
      setIsloading(true);
      Promise.resolve(onSubmit(selectedId)).finally(() => setIsloading(false));
    }
  }, [onSubmit, selectedId]);
  const { t } = useTranslation();

  const dialogProp = useMemo<IDialogContentProps>(
    () => ({
      styles: isEmpty
        ? {
            inner: {
              height: rem(100),
            },
          }
        : undefined,
      title: t(CategoryPickerNS.dialogTitle, { context: isEmpty ? "empty" : undefined }),
      showCloseButton: true,
    }),
    [isEmpty, t]
  );
  return (
    <Dialog hidden={hidden} onDismiss={onDismiss} dialogContentProps={dialogProp}>
      {data ? (
        isEmpty ? (
          t(CategoryPickerNS.emptyTips)
        ) : (
          <CategoryPicker
            placeholder={t(CategoryPickerNS.pickerTips)}
            catalog={data}
            selectedId={selectedId}
            onSelect={setSelectedId}
          />
        )
      ) : (
        <Spinner />
      )}
      <DialogFooter>
        <PrimaryButton
          disabled={!isEmpty && (isLoading || !selectedId)}
          onClick={isEmpty ? onCreate : onBtnClick}
          text={t(CategoryPickerNS.done)}
        />
      </DialogFooter>
    </Dialog>
  );
}
