/**
 * APP 启动时的Query参数
 */
const env = process.env;

export const INIT_QUERY = new URLSearchParams(window.location.search);

/**
 * 是否允许从QueryString中获取配置
 */
const custom_enabled = env.REACT_APP_ENABLE_CUSTOM_ENDPOINT === "true";
function getConfig(key: string, defaultValue?: string) {
  if (custom_enabled) {
    return (
      INIT_QUERY.get(key) || (window[`__$${key}` as any as keyof Window] as string) || defaultValue
    );
  }
  return defaultValue;
}

/**
 * APP 运行环境
 */
export const APP_ENV = env.NODE_ENV;

/**
 * graph API 默认地址
 */
export const DEFAULT_GRAPH_ENDPOINT = "https://graph.microsoft.com";

/**
 * Team相关API
 */
export const API_ROOT_WEB = getConfig("api_root_web", env.REACT_APP_API_ROOT_WEB)!;

/**
 * 小程序API
 */
// export const API_ROOT_MP = getConfig("api_root_mp", env.REACT_APP_API_ROOT_MP)!;

/**
 * Graph API endpoint
 */
export const API_ROOT_GRAPH =
  getConfig("api_root_graph", env.REACT_APP_API_ROOT_GRAPH) || DEFAULT_GRAPH_ENDPOINT;

/**
 * 注入调试的 MPF TOKEN (开发调试)
 */
export const MPF_TOKEN = getConfig("mpf_token", env.REACT_APP_MPF_TOKEN);

/**
 * 注入 ID Token (开发调试)
 */
export const ID_TOKEN = getConfig("id_token", env.REACT_APP_ID_TOKEN);

/**
 * 注入 Graph Access Token (开发调试)
 */
export const ACCESS_TOKEN = getConfig("access_token", env.REACT_APP_ACCESS_TOKEN);

/**
 * 听听文档小程序ID
 */
export const TINGP_APPID = getConfig("tingp_appid", env.REACT_APP_TINGP_APPID);

/**
 * 是否启用Vconsole
 */
export const ENABLE_VCONSOLE =
  getConfig("enable_vconsole", env.REACT_APP_ENABLE_VCONSOLE) === "true";

/**
 * AriaLog token
 */
export const ARIA_TOKEN = getConfig("aria_token", env.REACT_APP_ARIA_TOKEN);

/**
 * add_client_id
 */
export const AAD_CLIENT_ID = getConfig("aad_client_id", env.REACT_APP_AAD_CLIENT_ID);

/**
 * 字体设置
 * JSON.parse workaround for https://github.com/storybookjs/storybook/issues/16381
 */
export const BASE_FONT_SIZE = parseFloat(env.REACT_APP_BASE_FONT_SIZE || "20");

export const RC_APPID = env.REACT_APP_RC_APPID || "";

export const RC_ENTITYID = env.REACT_APP_RC_ENTITYID || "";

export const PUBLIC_URL = env.PUBLIC_URL;
/**
 * 当前版本号
 */
export const VERSION = env.REACT_APP_VERSION || "0.0.0";
