function encode(val: string) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ":")
    .replace(/%24/g, "$")
    .replace(/%2C/gi, ",")
    .replace(/%20/g, "+")
    .replace(/%5B/gi, "[")
    .replace(/%5D/gi, "]");
}

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
function isArray<T = any>(val: any): val is T[] {
  return Object.prototype.toString.call(val) === "[object Array]";
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function isObject(val: any): val is {} {
  return val !== null && typeof val === "object";
}

/**
 * 参数序列化
 * @param params
 */
export function paramsSerializer(params: any) {
  if (!isObject(params)) {
    return params ? `${params}` : "";
  }
  const parts: string[] = [];
  Object.keys(params).forEach(key => {
    let v = (params as any)[key];
    if (isArray(v)) {
      v = v.join(",");
    } else if (Object.prototype.toString() === "[object Date]") {
      v = v.toISOString();
    } else if (isObject(v)) {
      v = JSON.stringify(v);
    }

    if (v === null) {
      parts.push(`${encode(key)}`);
    } else {
      v !== undefined && parts.push(`${encode(key)}=${encode(v)}`);
    }
  });

  return parts.join("&");
}
