import React, { useEffect, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { setLanguage, ThemeProvider } from "@fluentui/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";

import { routes } from "./config/routes";
import { initIcons } from "./config/icons";
import { setLng } from "./config/i18n";
import { useTeamsContext } from "./hooks/useTeamsContext";
import { initVConsole } from "./services/vconsole";
import { logEnterAppTelemetry, logger } from "./services/logger";
import { ENABLE_VCONSOLE, VERSION } from "./config/config";
import { isOpt, isTeamsApp } from "./config/app";
import { useTeamsTheme } from "./hooks/useTeamsTheme";
import {
  history,
  dispatchDeepLink,
  recoverLastHistory,
  saveCurrentHistory,
} from "./services/history";
import { isPrerender, isReload } from "./config/browser";
import { WindowSizeProvider } from "./hooks/useWindowSize";
import RootFragment from "./components/RootFragment";
import { UserProvider } from "./hooks/rc/useUser";
import { SharedDeleteDialog } from "./components/dialog/DeleteDialog";
import { PagePath } from "./config/routes";
import { queryGraph } from "./hooks/useQuery";
import { PUBLIC_URL } from "./config/config";
import BrowserRouter from "./components/router/BrowserRouter";
import { registerBeforeUnloadHandler } from "./services/teams";
import { withErrorBoundary } from "./pages/rc/ErrorBoundary";
import { setStorage, getStorage, delStorage } from "./services/storage";
import CryptoJS from "crypto-js";

initIcons();

if (ENABLE_VCONSOLE && isTeamsApp) {
  initVConsole();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 6000_000,
      staleTime: 1500,
      refetchInterval: 120_000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      retryDelay(retry: number) {
        const delayTime = 1500;
        return retry * delayTime;
      },
    },
  },
});

persistQueryClient({
  queryClient,
  maxAge: 172800_000, // 48 * 60*60*1000
  buster: VERSION,
  persistor: createWebStoragePersistor({ storage: localStorage }),
  dehydrateOptions: {
    dehydrateMutations: false,
    shouldDehydrateQuery: query =>
      query.state.status === "success" && query.options.queryFn !== queryGraph,
  },
});
if (isTeamsApp) {
  // teams 添加class标识
  document.body.className = "is-teams";
} else {
  logEnterAppTelemetry();
}

if (isOpt) {
  console.log(`isOpt:${isOpt}`);
}

function App() {
  const { locale, userObjectId, groupId, tid, subEntityId } = useTeamsContext() || {};
  const theme = useTeamsTheme();

  useEffect(() => {
    if (userObjectId) {
      // 不同用户时，清理缓存，重新加载
      const queries = queryClient.getQueryCache().findAll();
      const lastUserId = getStorage("userId");
      if (queries.length && lastUserId !== null && lastUserId !== userObjectId) {
        queryClient.clear();
        delStorage("editDocAutoSave", "me");
        delStorage("editDocAutoSave", "admin");
        delStorage("scrollNum", "adminScrollTop");
        delStorage("scrollNum", "homeScrollTop");
      }

      setStorage("userId", userObjectId);
    }
  }, [userObjectId]);

  const routeList = useMemo(
    () =>
      routes.map(({ path, Component }, i) => (
        <Route key={i} path={path} element={<Component key={i} />} />
      )),
    []
  );

  useEffect(() => {
    if (locale) {
      setLanguage(locale);
      setLng(locale);
      logger?.setContext("UserInfo.Language", locale);
    }
  }, [locale]);
  useEffect(() => {
    if (userObjectId && tid && logger) {
      logger.setContext("TenantId", tid);
      logger.setContext("UserId", CryptoJS.SHA256(userObjectId).toString());
      logger.setContext("GroupId", groupId!);
      logEnterAppTelemetry(subEntityId);
    }
  }, [userObjectId, tid, groupId, subEntityId]);

  useEffect(() => {
    if (subEntityId && !isReload) {
      // 首次加载带subEntityId
      dispatchDeepLink(subEntityId);
    } else if (!subEntityId && history.location.pathname.includes(PagePath.home)) {
      // 恢复最后一次位置
      // 可能先跳转又dispatch
      recoverLastHistory();
    }
  }, [subEntityId]);

  useEffect(() => {
    if (history.location.pathname.includes(PagePath.home)) {
      // 只在teams中进行缓存历史记录，直接用浏览器打开不会进行历史记录缓存
      registerBeforeUnloadHandler(() => {
        saveCurrentHistory();
        return true;
      });
    }
  }, []);

  useEffect(() => {
    logger?.time("rendered", Math.ceil(performance.now()));
  }, []);

  useEffect(() => {
    // 浏览器中设置背景
    if (isTeamsApp) {
      document.body.style.backgroundColor = "transparent";
    } else if (!isPrerender && !theme.isInverted) {
      // open in browser
      document.body.style.backgroundColor = "#F3F2F1";
    }
  }, [theme.isInverted]);

  return (
    <ThemeProvider as={RootFragment} theme={theme} applyTo={isPrerender ? "none" : "body"}>
      <QueryClientProvider client={queryClient}>
        <WindowSizeProvider>
          <UserProvider>
            <BrowserRouter basename={PUBLIC_URL} history={history}>
              <Routes>{routeList}</Routes>
            </BrowserRouter>
          </UserProvider>
        </WindowSizeProvider>
        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
      <SharedDeleteDialog />
    </ThemeProvider>
  );
}

export default withErrorBoundary(App);
