import { makeStyles, Shimmer } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { PureImg } from "./Img";

const useShimmerCss = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
  dataWrapper: {
    height: "100%",
    width: "100%",
  },
  shimmerWrapper: {
    height: "100%",
  },
});
const useImgCss = makeStyles(theme => ({
  img: [
    {
      height: "100%",
      width: "100%",
      objectFit: "contain",
      display: "block",
      backgroundColor: theme.palette.neutralLighter,
    },
    ".ms-Image-image is-fadeIn",
  ],
}));

export interface ShimmerImageProps {
  src?: string;
  className?: string;
  alt?: string;
  loading?: "eager" | "lazy";
}
export default function ShimmerImage({ src, ...props }: ShimmerImageProps) {
  const shimmerCss = useShimmerCss();
  const imgCss = useImgCss();
  const [isImgLoaded, { setTrue }] = useBoolean(false);
  return (
    <Shimmer
      className={props.className}
      onLoad={setTrue}
      styles={shimmerCss}
      isDataLoaded={isImgLoaded}
    >
      <PureImg loading={props.loading} className={imgCss.img} src={src} alt={props.alt} />
    </Shimmer>
  );
}
