import { BUILDTIN_CATEGROY_MAPPING } from "../config/constant";
import { UserRole, WEB_API } from "../services/request";
import { useUser } from "./rc/useUser";
import { useFeedsLanguage } from "./useFeedsLanguage";
import { useQueryWeb } from "./useQuery";

export function useDimensionAndTags(categoryId?: string, isAdmin?: boolean) {
  const { role } = useUser();
  const { feedsLanguage } = useFeedsLanguage(isAdmin);
  const isGuest = !!role && role === UserRole.guest;
  const { data } = useQueryWeb(
    isGuest ? WEB_API.feedsPublicDimensionsAndTags : WEB_API.feedsDimensionsAndTags,
    {
      skipAuth: isGuest,
      params: {
        $type: BUILDTIN_CATEGROY_MAPPING[categoryId!],
      },
      enabled: !!categoryId && !!BUILDTIN_CATEGROY_MAPPING[categoryId],
    }
  );
  return data?.dimensions
    .filter(i => i.locale?.toLowerCase() === feedsLanguage.toLowerCase())
    .map(item => {
      item.tags = item.tags.filter(t => t.locale.toLowerCase() === feedsLanguage.toLowerCase());
      return item;
    })
    .filter(d => d.tags?.length > 0);
}
