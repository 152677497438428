import React, { useCallback, useEffect, useState, ReactElement } from "react";
import {
  ActionButton,
  Dialog,
  DialogFooter,
  makeStyles,
  MessageBarType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { getFileSize } from "../../lib/fileSize";

import { FilePicker as FilePickerNS, BundlesEditorI18n } from "../../locales";
import DocumentMetaEditor, { DocumentMetaEditorProps } from "../rc/DocumentMetaEditor";
import { IconName } from "../../config/icons";
import { preFetchUploadToken, uploadBlob } from "../../services/request/";

import emptyCover from "../../img/empty_cover.png";
import { MAX_COVER_SIZE, MAX_BUNDLE_DESC_LEN } from "../../config/constant";
import { useFileToken } from "../../hooks/useFileToken";
import BundleDropDown from "../dropdown/BundleDropDown";
import { rem } from "../../lib/unit";
import { toAbsURL } from "../../lib/absUrl";

export const emptyCoverImg = toAbsURL(emptyCover);
export interface MetaEditorDialogProps {
  /**
   * dialog title
   */
  title?: string;
  onBack?: () => void;
  onDismiss?: () => void;
  onUpdate?: (item: {
    name?: string;
    cover?: string;
    description?: string;
    bundle?: CDS.Bundle;
  }) => boolean;
  onSubmit?: () => void;
  hidden?: boolean;
  /**
   * document name or title
   */
  name?: string;
  cover?: string;
  hasDesc?: boolean;
  /**
   * documentId or itemId for cache localImage
   */
  cacheKey?: string;
  /**
   * Dropdown bundleOptions
   */
  bundleOptions?: CDS.Bundle[];
  /**
   * Default need upload cover
   */
  defaultUploadCover?: string;
  /**
   * Viva-Engage嵌入页面功能,loading状态下显示的样式
   */
  loadingElement?: ReactElement;
  typeErr?: { message: string; type: MessageBarType; onDismiss?: () => void };
}

const useStyles = makeStyles(theme => ({
  textTitle: {
    lineHeight: rem(16),
    paddingBottom: rem(8),
    paddingTop: rem(14),
    color: theme.palette.neutralDark,
  },
  textContent: {
    ".ms-TextField-field": {
      minHeight: rem(36),
      lineHeight: rem(18),
      maxHeight: rem(90),
      overflowY: "auto",
    },
  },
  loading: {
    fontSize: rem(18),
    lineHeight: rem(40),
    height: rem(40),
  },
}));

const useFooterStyles = makeStyles(() => ({
  actionsRight: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
}));

export default function MetaEditorDialog({
  title,
  name,
  cover,
  hasDesc,
  hidden,
  cacheKey,
  bundleOptions,
  defaultUploadCover,
  loadingElement,
  onBack,
  onSubmit,
  onUpdate,
  onDismiss,
  typeErr,
}: MetaEditorDialogProps) {
  const token = useFileToken();
  const [titleErr, setTitleErr] = useState<boolean>();
  const [isLoading, setIsloading] = useState(false);
  const [localCover, setLocalCover] = useState("");
  const [coverErr, setCoverError] = useState<DocumentMetaEditorProps["coverErr"]>();
  const [progress, setProgress] = useState<number>();
  const { t } = useTranslation();
  const onSubmitClick = useCallback(() => {
    if (onSubmit) {
      setIsloading(true);
      Promise.resolve(onSubmit()).finally(() => {
        setIsloading(false);
        setLocalCover("");
      });
    }
  }, [onSubmit]);

  useEffect(() => {
    setCoverError(undefined);
  }, [cover]);
  // 清空信息
  useEffect(() => {
    setLocalCover("");
  }, [cacheKey]);

  useEffect(() => {
    if (!hidden) {
      preFetchUploadToken();
    }
  }, [hidden]);

  const setTitle = useCallback(
    name => {
      if (onUpdate) {
        onUpdate({ name });
      }
    },
    [onUpdate]
  );
  const onUpload = useCallback(
    (file: File) => {
      const isSizeError = (file.size || 0) > MAX_COVER_SIZE;
      const isTypeError = !/['image/png'|'image/jpeg']$/.test(file.type);
      if (isSizeError || isTypeError) {
        setCoverError({
          message: isTypeError
            ? t(FilePickerNS.coverTypeError)
            : t(FilePickerNS.coverSizeError, { size: getFileSize(MAX_COVER_SIZE) }),
          type: MessageBarType.error,
          onDismiss: () => setCoverError(undefined),
        });
        return;
      }

      setCoverError(undefined);
      setLocalCover(URL.createObjectURL(file));
      setIsloading(true);
      setProgress(0);
      uploadBlob(file, {
        token: token.writable
          ? { token: token.token!, baseURL: token.baseUrl ? `${token.baseUrl}/temp` : "" }
          : undefined,
        onProgress: p => setProgress(p.rate),
      })
        .then(
          res => onUpdate && onUpdate({ cover: res.file! }),
          () => {
            setLocalCover("");
            setProgress(1);
            setCoverError({
              message: t(FilePickerNS.coverUploadError),
              type: MessageBarType.severeWarning,
            });
          }
        )
        .finally(() => {
          setIsloading(false);
          setProgress(undefined);
        });
    },
    [onUpdate, t, token.baseUrl, token.token, token.writable]
  );

  useEffect(() => {
    if (defaultUploadCover && !cover) {
      setLocalCover(defaultUploadCover);
      onUpdate && onUpdate({ cover: defaultUploadCover });
    }
  }, [cover, defaultUploadCover, onUpdate, onUpload]);

  const setDescription = useCallback(
    (_event, description) => onUpdate && onUpdate({ description }),
    [onUpdate]
  );
  const onCoverError = useCallback(() => {
    if (cover && cover !== emptyCoverImg) {
      onUpdate?.({ cover: emptyCoverImg });
    }
  }, [cover, onUpdate]);

  const dropDownOnchange = useCallback(
    (_ev, bundle) => bundle && onUpdate && onUpdate({ bundle }),
    [onUpdate]
  );

  const styles = useStyles();
  const footerStyles = useFooterStyles();

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{ showCloseButton: true, title }}
      onDismiss={onDismiss}
    >
      <DocumentMetaEditor
        title={name}
        cover={localCover || cover}
        typeErr={typeErr}
        onCoverError={onCoverError}
        onTitleChanged={setTitle}
        setTitleErr={setTitleErr}
        onUpload={onUpload}
        coverErr={coverErr}
        progress={progress}
      />
      {bundleOptions && (
        <BundleDropDown
          label={t(BundlesEditorI18n.joinCollection)}
          placeholder={t(BundlesEditorI18n.chooseCollection)}
          onChange={dropDownOnchange}
          bundleOptions={bundleOptions}
          styles={{ subComponentStyles: { label: { root: styles.textTitle } } }}
        />
      )}

      {hasDesc && (
        <TextField
          label={t(BundlesEditorI18n.description)}
          placeholder={t(BundlesEditorI18n.descPlaceholder)}
          className={styles.textContent}
          resizable={false}
          maxLength={MAX_BUNDLE_DESC_LEN}
          multiline
          autoAdjustHeight
          onChange={setDescription}
          styles={{ subComponentStyles: { label: { root: styles.textTitle } } }}
        />
      )}

      <DialogFooter styles={footerStyles}>
        {onBack ? (
          <ActionButton
            iconProps={{ iconName: IconName.ChevronLeft }}
            text={t(FilePickerNS.back)}
            onClick={onBack}
          />
        ) : (
          <div></div>
        )}
        {loadingElement && isLoading ? (
          <div className={styles.loading}>{loadingElement}</div>
        ) : (
          <PrimaryButton
            disabled={titleErr || !name || !cover || isLoading}
            onClick={onSubmitClick}
            text={t(FilePickerNS.done)}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}
