import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles, Shimmer, TextField, CommandButton, ImageFit } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { rem } from "../../lib/unit";

import { EditorI18n } from "../../locales";
import { IconName } from "../../config/icons";

import { useBoolean } from "@fluentui/react-hooks";
import type { TTS } from "../../services/request";

import { Audio } from "../../models/TingDoc";
import { getStorage, setStorage } from "../../services/storage";
import i18n from "../../config/i18n";
import SelectVoiceDialog from "../dialog/SelectVoiceDialog";
import RecorderButton from "./RecorderButton";
import TTSButton from "./TTSButton";
import SwitchRecorderButton from "./SwitchRecorderButton";

const useEditorCss = makeStyles(theme => ({
  note: {
    width: rem(440),
    height: "100%",
    minWidth: "20%",
    maxWidth: "60%",
    padding: rem(8),
    boxSizing: "border-box",
    ".ms-TextField": {
      height: `calc(100% - ${rem(36)})`,
      ".ms-TextField-wrapper,.ms-TextField-fieldGroup,.ms-TextField-field": {
        height: "100%",
      },
    },
  },
  noteShimmer: {
    height: `calc(100% - ${rem(56)})`,
  },
  noteShimmerWrapper: {
    height: "100%",
  },
  avatar: {
    "&.ms-Icon": {
      width: rem(30),
      height: rem(30),
      borderRadius: "50%",
    },
  },
  recordContent: {
    paddingTop: rem(24),
    height: rem(32),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnShimmer: {
    minWidth: rem(80),
    ".ms-Shimmer-shimmerWrapper": {
      height: rem(32),
      width: rem(80),
      borderRadius: rem(3),
    },
    "&.ai-shimmer": {
      minWidth: rem(80),
      ".ms-Shimmer-shimmerWrapper": {
        width: rem(80),
      },
    },
    "&.right": {
      textAlign: "right",
    },
  },
}));

const MAX_NOTE_LENGTH = 1500;
export interface RecorderNoteProps {
  voices: TTS.Voice[];
  text?: string;
  maxAudioMilliSecond?: number;
  baseUrl: string;
  token?: string;
  disabled?: boolean;
  audios?: Audio[];
  ttsOnClick?: () => void;
  isDataLoaded?: boolean;
  onNoteChange?: (_ev: any, value?: string) => void;
  onComplete?: (res: TTS.Audio.Response) => void;
  onRecodingStatusChanged?: (isRecording: boolean) => void;
  onRecordEnd?: (file: Blob, duration: number) => void;
  onRecordingTypeChanged?: (type: "ai" | "human") => void;
  onErrorMsgChange?: (errorMsg: string) => void;
}
export default function RecorderNote({
  voices,
  text,
  maxAudioMilliSecond,
  baseUrl,
  token,
  disabled,
  audios,
  ttsOnClick,
  isDataLoaded,
  onNoteChange,
  onComplete,
  onRecordEnd,
  onRecodingStatusChanged,
  onRecordingTypeChanged,
  onErrorMsgChange,
}: RecorderNoteProps) {
  const { t } = useTranslation();
  const css = useEditorCss();
  const [selectedVoice, setSelectedVoice] = useState<TTS.Voice | undefined>(() => {
    // get from storage
    const voiceName = getStorage("userSelectedTTSVoiceName");
    const userSelectedVoice = voices?.find(v => v.voiceName === voiceName);
    if (userSelectedVoice) {
      return userSelectedVoice;
    }
    // get from teams language
    const lng = i18n.language.startsWith("zh") ? "zh-cn" : "en";
    const filteredVoices = voices && voices.filter(v => lng === v.locale);
    return filteredVoices && filteredVoices.length
      ? filteredVoices[0]
      : voices && voices.length
      ? voices[0]
      : undefined;
  });
  const [isRecording, setIsRecording] = useState(false);
  const [selectVoiceDialogHidden, { setTrue: hideVioceDialog, setFalse: showVoiceDialog }] =
    useBoolean(true);

  const [isAI, setIsAI] = useState(
    !audios || audios.length < 1 || !audios.some(a => !!a.captions?.length)
  );

  const remainDuration = useMemo(
    () =>
      maxAudioMilliSecond
        ? maxAudioMilliSecond - (audios?.reduce((p, a) => a.duration! + p, 0) || 0)
        : undefined,
    [audios, maxAudioMilliSecond]
  );

  const setRecordingType = useCallback(
    (type: "ai" | "human") => {
      if (!disabled && !isRecording) {
        setIsAI(type === "ai");
        onRecordingTypeChanged && onRecordingTypeChanged(type);
        onErrorMsgChange && onErrorMsgChange("");
      }
    },
    [disabled, isRecording, onErrorMsgChange, onRecordingTypeChanged]
  );
  useEffect(() => {
    if (audios && audios.length > 0) {
      audios.some(a => !!a.captions?.length) ? setRecordingType("ai") : setRecordingType("human");
    }
  }, [audios, setRecordingType]);

  const onRecordingStatusChanged = useCallback(
    (isRecording: boolean) => {
      setIsRecording(isRecording);
      onRecodingStatusChanged && onRecodingStatusChanged(isRecording);
    },
    [onRecodingStatusChanged]
  );

  const isAudioLoaded = audios && !!token;
  return (
    <div className={css.note}>
      <Shimmer
        className={css.noteShimmer}
        styles={{ dataWrapper: css.noteShimmerWrapper, shimmerWrapper: css.noteShimmerWrapper }}
        isDataLoaded={isDataLoaded}
      >
        <SwitchRecorderButton
          selectedRecorder={isAI ? "ai" : "human"}
          isLocked={!!audios?.length}
          disabled={disabled || isRecording}
          onRecorderStatusChange={setRecordingType}
        />
        <TextField
          className="audioAIUserGuideText"
          placeholder={t(EditorI18n.notePlaceholder)}
          multiline
          resizable={false}
          value={text}
          onChange={onNoteChange}
          disabled={isRecording}
          maxLength={MAX_NOTE_LENGTH + 1}
        />
      </Shimmer>
      <div className={css.recordContent}>
        <Shimmer className={`${css.btnShimmer} ai-shimmer`} isDataLoaded={isAudioLoaded}>
          {isAI && (
            <CommandButton
              onClick={showVoiceDialog}
              iconProps={{
                className: css.avatar,
                imageProps: {
                  src: selectedVoice?.avatar,
                  imageFit: ImageFit.cover,
                },
              }}
              disabled={disabled || isRecording}
              text={selectedVoice?.displayName}
              menuIconProps={{ iconName: IconName.ChevronDown }}
            />
          )}
        </Shimmer>

        <Shimmer className={`${css.btnShimmer} right`} isDataLoaded={isAudioLoaded}>
          {isAI ? (
            <TTSButton
              audioRequest={{
                voice: selectedVoice?.voiceName!,
                text: text || "",
                sasToken: { baseUrl, token: token! },
              }}
              disabled={disabled || !(selectedVoice && token) || !!audios?.length}
              onClick={ttsOnClick}
              onComplete={onComplete}
              onLoadingStateChange={onRecordingStatusChanged}
              onErrorMsgChange={onErrorMsgChange}
            />
          ) : (
            <RecorderButton
              maxDuration={remainDuration}
              disabled={disabled}
              onRecordEnd={onRecordEnd}
              onRecodingStatusChanged={onRecordingStatusChanged}
              onErrorMsgChange={onErrorMsgChange}
            />
          )}
        </Shimmer>
      </div>
      <SelectVoiceDialog
        voices={voices}
        defaultVoice={selectedVoice}
        hidden={selectVoiceDialogHidden}
        onDismiss={hideVioceDialog}
        onSubmit={useCallback(
          voice => {
            setSelectedVoice(voice);
            hideVioceDialog();
            voice && setStorage("userSelectedTTSVoiceName", voice.voiceName);
          },
          [hideVioceDialog]
        )}
      />
    </div>
  );
}
