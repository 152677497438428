import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useCallback, useEffect, useMemo } from "react";
import {
  UseQueryOptions,
  QueryFunctionContext,
  UseInfiniteQueryResult,
  QueryKey,
} from "react-query";
import { RequestConfig } from "../../services/request";

export interface RequestQueryOptions<TParam, TVar, TResponse, TKey extends QueryKey, TBody = any>
  extends RequestConfig<TParam, TVar, TBody> {
  // use Query 的参数配置
  config?: UseQueryOptions<TResponse, AxiosError, TResponse, TKey>;
  /**
   * enable query,`undefined` as `true`
   */
  enabled?: boolean;
}

export function getQueryConfig<TP, TV, TR, TK extends QueryKey>(
  options: RequestQueryOptions<TP, TV, TR, TK>
): [RequestConfig<TP, TV, any>, UseQueryOptions<TR, AxiosError, TR, TK>] {
  const { config = {}, enabled, ...requestConfig } = options || {};
  if (enabled !== undefined) {
    config.enabled = enabled;
  }
  return [requestConfig, config];
}

export function getRequestParams<TKey, TConfig extends AxiosRequestConfig>(
  context: QueryFunctionContext<[TKey, TConfig?], TKey>
): [TKey, TConfig, () => void] {
  const api = context.queryKey[0];
  let config: TConfig = context.queryKey[1]! || {};
  const paginateUrl = context.pageParam;
  if (paginateUrl) {
    config = { ...config, params: undefined };
    delete config.url;
  }
  const source = axios.CancelToken.source();
  (config as AxiosRequestConfig).cancelToken = source.token;
  return [paginateUrl || api, config, () => source.cancel("cancel react query")];
}

export function makeCancelPromise<T>(
  p: Promise<T>,
  cancel: () => void
): Promise<T> & { cancel: () => void } {
  return Object.assign(p, { cancel });
}

/**
 * 包装 `values`, loadmore
 * @param res
 */
export function usePageValues<TR extends { value: any }>(
  res: UseInfiniteQueryResult<TR, AxiosError>
) {
  const fetchMore = res.fetchNextPage;
  const loadMore = useCallback(() => fetchMore(), [fetchMore]);
  const values = useMemo(
    () => res.data?.pages?.reduce<TR["value"]>((pre, item) => pre.concat(item.value), []),
    [res.data?.pages]
  );

  return useMemo(
    () =>
      Object.assign(res, {
        values,
        loadMore,
        /**
         * 是否可以加载下一页，(当前已加载，且下一页未加载)
         */
        canLoadMore: res.isFetched && res.hasNextPage,
        /**
         * 是否显示正在加载(首次或者正在加载下一页)
         */
        indicatorVisible: res.isLoading || res.isFetchingNextPage,
      }),
    [res, values, loadMore]
  );
}

/**
 * 包装自动加载所有分页
 * @param res
 * @param filter
 * @returns
 */
export function useFilterValues<TR extends { value: any }>(
  res: UseInfiniteQueryResult<TR, AxiosError>,
  filter?: (value: any) => boolean
) {
  useEffect(() => {
    if (res.hasNextPage && !res.isFetchingNextPage) {
      res.fetchNextPage();
    }
  }, [res]);
  const values = useMemo<TR["value"] | undefined>(() => {
    const items = res.data?.pages?.reduce<TR["value"]>((pre, item) => pre.concat(item.value), []);
    return filter && items ? items.filter(filter) : items;
  }, [filter, res.data?.pages]);

  return useMemo(
    () =>
      Object.assign(res, {
        values,
        /**
         * 是否显示正在加载(首次或者正在加载下一页)
         */
        indicatorVisible: res.isLoading || res.isFetchingNextPage,
      }),
    [res, values]
  );
}
