import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputTips } from "../locales";

// eslint-disable-next-line no-control-regex
const noEngCharReg = /[^\x00-\xff]/g;
export function useInputLengthValidation(maxLen = 32, isRequired?: boolean) {
  const { t } = useTranslation();
  const [isOk, setIsOk] = useState<boolean>();
  const onGetErrorMessage: (value: string) => string | undefined = useCallback(
    (value: string) => {
      const newTitle = value?.trim() || "";
      if (isRequired && !newTitle) {
        setIsOk(false);
        return t(InputTips.emptyError);
      }
      if (newTitle.replace(noEngCharReg, "00").length > maxLen) {
        setIsOk(false);
        return t(InputTips.lenError, { count: maxLen });
      }
      setIsOk(true);
    },
    [isRequired, maxLen, t]
  );
  return useMemo(() => ({ onGetErrorMessage, isOk }), [isOk, onGetErrorMessage]);
}
