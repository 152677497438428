import { FontSizes, FontWeights, ILabelStyleProps, ILabelStyles } from "@fluentui/react";

export function getLabelStyles(themeName: "dark" | "default" | "contrast") {
  return (props: ILabelStyleProps): ILabelStyles => ({
    root: {
      color: props.theme.palette.neutralDark,
      paddingTop: "6px",
      paddingBottom: "5px",
      fontSize: FontSizes.small,
      fontWeight: FontWeights.regular,
    },
  });
}
