import { BaseDocumentModel } from "./BaseDocumentModel";

export class VideoDoc extends BaseDocumentModel {
  /**
   * 视频地址
   */
  public readonly video: string;

  public static isVideoDoc(doc: CDS.Document) {
    return doc.metadata?.type?.includes("video");
  }

  constructor(doc: CDS.Document) {
    if (!VideoDoc.isVideoDoc(doc)) {
      throw new Error(`invalid VideoDoc type ${doc.metadata?.type} ${doc.id}`);
    }

    super(doc);
    const content = doc.content as CDS.VideoContent;
    this.video = this.getMediaUrl(content.videos[0].mediaId);
  }
}
