/* eslint-disable no-bitwise */
import { GroupType } from "./graphGroups";

export function usersQueryBuilder(query: string, top = 10, count = false) {
  return {
    $top: top,
    $filter: `(startswith(displayName,'${query}') or startswith(mailNickname,'${query}') or startswith(mail,'${query}'))`,
    $count: count,
  };
}

export function batchQueryBuilder(ids: string[]) {
  if (Array.isArray(ids)) {
    return {
      $filter: `id in [${ids.map(item => `'${item}'`)}]`,
    };
  }
  return;
}

export function groupsQueryBuilder(query: string, top = 10, groupTypes: GroupType = GroupType.any) {
  let filterQuery = "";
  if (query !== "") {
    filterQuery = `(startswith(displayName,'${query}') or startswith(mailNickname,'${query}') or startswith(mail,'${query}'))`;
  }

  if (groupTypes !== GroupType.any) {
    const filterGroups = [];

    if (GroupType.unified === (groupTypes & GroupType.unified)) {
      filterGroups.push("groupTypes/any(c:c+eq+'Unified')");
    }

    if (GroupType.security === (groupTypes & GroupType.security)) {
      filterGroups.push("(mailEnabled eq false and securityEnabled eq true)");
    }

    if (GroupType.mailenabledsecurity === (groupTypes & GroupType.mailenabledsecurity)) {
      filterGroups.push("(mailEnabled eq true and securityEnabled eq true)");
    }

    if (GroupType.distribution === (groupTypes & GroupType.distribution)) {
      filterGroups.push("(mailEnabled eq true and securityEnabled eq false)");
    }

    filterQuery += (query !== "" ? " and " : "") + filterGroups.join(" or ");
  }
  return {
    $filter: filterQuery,
    $top: top,
  };
}
