import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionButton,
  Dialog,
  DialogFooter,
  IDialogContentProps,
  Label,
  makeStyles,
  mergeStyles,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { EditNotificationDialogI18n } from "../../locales";
import { rem } from "../../lib/unit";
import SortableCardList from "../list/SortableCardList";
import { IconName } from "../../config/icons";
import VisibilityScopeTag from "../tag/VisibilityScopeTag";
import { useInputLengthValidation } from "../../hooks/useInputLengthValidation";
import { SortableData } from "../cards/SortableCard";

const MAX_LEN = 64;

const contentClassName = mergeStyles({ height: rem(234), overflow: "auto" });
const useFooterStyles = makeStyles({
  actionsRight: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
});
const useTextFieldStyles = makeStyles(theme => ({
  errorMessage: { position: "absolute" },
  icon: {
    alignItems: "flex-start",
    padding: `${rem(8)} ${rem(8)} 0`,
    position: "relative",
    bottom: 0,
    right: 0,
    color: theme.palette.redDark,
  },
}));

export default function EditNotificationDialog({
  dataList,
  hidden,
  onDismiss,
  onSubmit,
  onBack,
  categoryId,
}: {
  dataList: SortableData[];
  hidden?: boolean;
  categoryId?: string;
  onDismiss?: () => void;
  onBack?: (docs: SortableData[]) => void;
  onSubmit?: (docs: SortableData[], description?: string) => void;
}) {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [orderedDataList, setOrderedDocs] = useState<SortableData[]>(dataList);
  const { isOk, onGetErrorMessage } = useInputLengthValidation(MAX_LEN);

  const footerStyles = useFooterStyles();
  const textFieldStyles = useTextFieldStyles();

  const dialogProp = useMemo<IDialogContentProps>(
    () => ({
      title: t(EditNotificationDialogI18n.dialogTitle),
      showCloseButton: true,
    }),
    [t]
  );

  useEffect(() => {
    setOrderedDocs(dataList);
  }, [dataList]);

  useEffect(() => {
    setDescription(undefined);
  }, [categoryId]);

  const onSubmitBtnClick = useCallback(() => {
    if (orderedDataList && onSubmit) {
      onSubmit(orderedDataList, description);
    }
  }, [description, onSubmit, orderedDataList]);

  const onBackBtnClick = useCallback(() => {
    if (orderedDataList && onBack) {
      onBack(orderedDataList);
    }
  }, [onBack, orderedDataList]);

  return (
    <Dialog hidden={hidden} onDismiss={onDismiss} dialogContentProps={dialogProp}>
      <Label>{t(EditNotificationDialogI18n.visibilityScopeLabel)}</Label>
      <VisibilityScopeTag categoryId={categoryId} />
      <Label>{t(EditNotificationDialogI18n.contentsLabel)}</Label>
      <SortableCardList
        className={contentClassName}
        dataList={orderedDataList}
        onChanged={setOrderedDocs}
      />
      <Label>{t(EditNotificationDialogI18n.descriptionLabel)}</Label>
      <TextField
        placeholder={t(EditNotificationDialogI18n.descriptionPlaceholder, { count: MAX_LEN })}
        onChange={useCallback((_event, value) => {
          setDescription(value);
        }, [])}
        maxLength={MAX_LEN}
        value={description}
        height={rem(60)}
        multiline
        resizable={false}
        onGetErrorMessage={onGetErrorMessage}
        iconProps={{
          iconName: IconName.Info,
          style: { display: isOk ? "none" : "unset" },
        }}
        styles={textFieldStyles}
      />
      <DialogFooter styles={footerStyles}>
        <ActionButton
          styles={{ icon: { marginLeft: 0 }, root: { padding: 0 } }}
          iconProps={{ iconName: IconName.ChevronLeft }}
          text={t(EditNotificationDialogI18n.back)}
          onClick={onBackBtnClick}
        />
        <PrimaryButton
          disabled={!isOk && orderedDataList.length > 0}
          onClick={onSubmitBtnClick}
          text={t(EditNotificationDialogI18n.send)}
        />
      </DialogFooter>
    </Dialog>
  );
}
