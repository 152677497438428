/* eslint-disable @dragongate/no-magic-numbers */

/**
 * 毫秒时间格式化
 * @param ms
 * @returns
 */
export function timeFormat(ms: number): string {
  // const dms = ms % 1000;
  // ms = (ms - dms) / 1000;
  ms = Math.round(ms / 1000);
  const secs = ms % 60;
  const mins = (ms - secs) / 60;
  return `${mins.toFixed(0).padStart(2, "0")}:${secs.toFixed(0).padStart(2, "0")}`;
}
