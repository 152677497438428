import { requestWeb, WEB_API } from "./requestWeb";

let uploadTokenInfo = {
  token: "",
  expiresOn: 0,
  baseURL: "",
};
const RENEW_TIME = 600000; // 10*60*1000 s
let fetchTokenPromise: Promise<typeof uploadTokenInfo> | undefined;

function fetchToken() {
  if (!fetchTokenPromise) {
    fetchTokenPromise = requestWeb(WEB_API.myFileWriteToken, { method: "POST" }).then(
      ({ data }) => {
        fetchTokenPromise = undefined;
        uploadTokenInfo = {
          token: data.token,
          expiresOn: +new Date(data.expiresOn),
          baseURL: data.baseUrl,
        };
        return uploadTokenInfo;
      },
      err => {
        fetchTokenPromise = undefined;
        return Promise.reject(err);
      }
    );
  }
  return fetchTokenPromise;
}

/**
 * 预取Upload token
 */
export function preFetchUploadToken() {
  if (uploadTokenInfo.expiresOn < Date.now() + RENEW_TIME) {
    return fetchToken();
  }
}

/**
 * 获取uploadToken
 */
export function getBlobWriteToken() {
  if (uploadTokenInfo.expiresOn > Date.now() + RENEW_TIME) {
    return Promise.resolve(uploadTokenInfo);
  } else {
    return fetchToken();
  }
}
