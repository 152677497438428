import { useCallback, useEffect, useState } from "react";
import { Spinner, SpinnerSize, Stack, Nav, makeStyles } from "@fluentui/react";
import { isPrerender } from "../../config/browser";
import { pageContentContainer } from "../../components/navigation/TopBar";
import { useLocation, useNavigate } from "react-router-dom";
import Content from "./Content";
import { useNavLinks } from "../../hooks/rc/useNavLinks";
import { rem } from "../../lib/unit";
import PageTitleBar from "../../components/rc/PageTitleBar";
import { MePageI18n } from "../../locales";
import { useTranslation } from "react-i18next";

const useStackStyles = makeStyles(theme => ({
  nav: {
    position: "relative",
    overflow: "hidden",
    borderRight: `1px solid ${theme.palette.neutralLight}`,
    minWidth: rem(300),
    width: rem(300),
  },
  content: {
    overflow: "hidden",
  },
}));

export default function RCMePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const hashId = location.hash;

  const styles = useStackStyles();
  const { t } = useTranslation();
  const { userNavLinks } = useNavLinks({});
  const [selectedKey, setSelectedKey] = useState<string | undefined>();

  useEffect(() => {
    const nav = userNavLinks?.find(item => item.url === hashId);
    if (nav) {
      setSelectedKey(nav.key);
    } else if (userNavLinks?.[0]) {
      navigate(userNavLinks?.[0].url, { replace: true });
    }
  }, [hashId, navigate, userNavLinks]);

  const onCategoryError = useCallback(() => navigate("#"), [navigate]);

  return isPrerender ? (
    <Spinner size={SpinnerSize.large} styles={{ root: { height: "100vh" } }} />
  ) : (
    <>
      <PageTitleBar title={t(MePageI18n.pageName)} />
      <Stack horizontal className={pageContentContainer}>
        <Stack.Item className={`${styles.nav} screen-pc`}>
          <Nav groups={[{ links: userNavLinks ?? [] }]} selectedKey={selectedKey} />
        </Stack.Item>
        <Stack.Item grow={1} className={styles.content}>
          <Content contentHashId={hashId} onCategoryError={onCategoryError} />
        </Stack.Item>
      </Stack>
    </>
  );
}
