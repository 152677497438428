import { ITeachingBubbleStyleProps, ITeachingBubbleStyles } from "@fluentui/react";
import { rem } from "../../lib/unit";
import { FontSizes } from "../fonts";

// const MobileQuery = "@media (orientation: portrait) and (max-width: 719px)";
export function teachingBubbleStyles(
  props: ITeachingBubbleStyleProps
): Partial<ITeachingBubbleStyles> {
  return props.isWide
    ? {}
    : {
        root: {},
        subComponentStyles: {
          callout: {
            root: {
              maxWidth: rem(280),
            },
          },
        },
        headline: {
          fontSize: FontSizes.large,
          lineHeight: FontSizes.xLargePlus,
        },
        closeButton: {
          margin: rem(12, 16, 0, 0),
          fontSize: FontSizes.icon,
          height: rem(24),
          width: rem(24),
        },
        bodyContent: {
          padding: rem(12, 16),
          lineHeight: rem(16),
          fontSize: rem(12),
          whiteSpace: "pre-wrap",
        },
        secondaryButton: {
          borderColor: props.theme.palette.themeTertiary,
        },
        footer: {
          flexDirection: "column",
          alignItems: "stretch",
          ".ms-StackItem>.ms-Button": {
            width: "100%",
          },
        },
      };
}
