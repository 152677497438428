import {
  Dialog,
  DialogFooter,
  Dropdown,
  IDropdownOption,
  Label,
  makeStyles,
  PrimaryButton,
  Stack,
} from "@fluentui/react";

import { FormEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { rem } from "../../lib/unit";
import { SelectVoiceDialogI18n } from "../../locales";
import { TTS } from "../../services/request";
import TTSVoiceCard from "../cards/TTSVoiceCard";

const useDialogStyle = makeStyles(() => ({
  root: { maxHeight: rem(330), overflowY: "overlay" },
  inner: { marginRight: 0, marginBottom: 0 },
}));

export default function SelectVoiceDialog({
  voices,
  defaultVoice,
  hidden,
  onSubmit,
  onDismiss,
}: {
  voices: TTS.Voice[];
  defaultVoice?: TTS.Voice;
  hidden: boolean;
  onSubmit?: (voice?: TTS.Voice) => void;
  onDismiss?: () => void;
}) {
  const style = useDialogStyle();
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      voices
        .map(v => v.language)
        .filter((elem, index, self) => index === self.indexOf(elem))
        .map(l => ({ key: l, text: l } as IDropdownOption)),
    [voices]
  );
  const [selectedLanguage, setSelectedLanguage] = useState(
    () =>
      defaultVoice?.language ??
      (options && options.length > 0 ? options[0].key.toString() : undefined)
  );
  const [selectedVoice, setSelectedVoice] = useState<TTS.Voice | undefined>(defaultVoice);
  const listedVoices = voices.filter(v => v.language === selectedLanguage);

  const onDropDownChange = useCallback(
    (_event: FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
      setSelectedLanguage(option?.key.toString());
    },
    []
  );

  const onSubmitButtonClick = useCallback(() => {
    onSubmit && onSubmit(selectedVoice);
  }, [onSubmit, selectedVoice]);

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{ title: t(SelectVoiceDialogI18n.dialogTitle), showCloseButton: true }}
      onDismiss={() => {
        onDismiss && onDismiss();
        setSelectedVoice(defaultVoice);
      }}
    >
      <Label>{t(SelectVoiceDialogI18n.languageDropDownLabel)}</Label>
      <Dropdown selectedKey={selectedLanguage} options={options} onChange={onDropDownChange} />
      <Label>{t(SelectVoiceDialogI18n.voiceStackLabel)}</Label>
      <Stack styles={style} horizontal wrap tokens={{ childrenGap: `${rem(12)} ${rem(8)}` }}>
        {listedVoices.map((v, id) => (
          <TTSVoiceCard
            voice={v}
            key={id}
            isSelected={selectedVoice?.voiceName === v.voiceName}
            onClick={() => setSelectedVoice(listedVoices[id])}
          />
        ))}
      </Stack>
      <DialogFooter>
        <PrimaryButton
          disabled={!(selectedVoice && listedVoices.includes(selectedVoice))}
          text={t(SelectVoiceDialogI18n.applyBtn)}
          onClick={onSubmitButtonClick}
        />
      </DialogFooter>
    </Dialog>
  );
}
