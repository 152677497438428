export const TING_DOC_RANGE = ["ppt", "pptx"];
export const VIDEO_RANGE = ["mp4"];

export const PPT_RANGE = ["ppt", "pptm", "pptx"];
export const EXCEL_RANGE = ["xls", "xlsb", "xlsm", "xlsx"];
export const PDF_RANGE = ["pdf"];
export const WORD_RANGE = ["doc", "docx"];

export const TING_DOC_CONTENT_TYPE = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const EXCEL_CONTENT_TYPE = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
];

export const PDF_CONTENT_TYPE = ["application/pdf"];

export const PPT_CONTENT_TYPE = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
];

export const WORD_CONTENT_TYPE = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
