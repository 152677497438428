import { useState, useEffect, createContext, useContext } from "react";
import { FileTokenInfo, requestWeb, WEB_API } from "../services/request";

interface Token {
  token: string;
  expiresOn: number;
  baseUrl?: string;
  writable?: boolean;
}
const cachedToken = new Map<string, Token>();
const cachedPromise = new Map<string, Promise<Partial<Token>>>();

const QUREY_BEFORE_EXPIRE = 300000; // 提前5分钟
const MAX_TIMEOUT = 0x7ffffffe; // 最大超时时间
const defaultTimeOut = 60000;

const GUID_LENGTH = 36;

export const isTempUrl = (id: string) => id.startsWith("https://") && id.includes("/temp");

/**
 * 刷新Token, 并合并相同ID
 * @param id
 */
function refreshToken(id: string, isAdmin: boolean, writable?: boolean) {
  let promise = cachedPromise.get(id);
  if (!promise) {
    if (isTempUrl(id)) {
      promise = requestWeb(WEB_API.myTempFilesDirToken, {
        method: "POST",
        data: {
          baseUrl: id.substring(0, id.lastIndexOf("/")), // 只需要到文件夹，不需要到具体文件
          scopes: writable ? ["read", "write"] : ["read"],
        },
      }).then(({ data }) => setFileToken(id, { ...data, writable }) || {});
    } else if (id.length !== GUID_LENGTH) {
      // sessionId
      promise = requestWeb(
        isAdmin ? WEB_API.getTingDocSessionSasToken : WEB_API.getMyTingDocSessionSasToken,
        { vars: { id }, retry: 3 }
      ).then(({ data }) => setFileToken(id, { ...data, writable: true }) || {});
    } else {
      promise = requestWeb(WEB_API.myTenantDocumentSasToken, {
        vars: { id },
        retry: 3,
        params: {
          scopes: writable ? ["read", "write"] : ["read"],
        },
      }).then(({ data }) => setFileToken(id, { ...data, writable }) || {});
    }
    cachedPromise.set(id, promise);
    promise.finally(() => cachedPromise.delete(id));
  }
  return promise;
}

function getCachedToken(id: string, writable: boolean) {
  if (writable) {
    return cachedToken.get(`${id}_write`);
  } else {
    return cachedToken.get(id) || cachedToken.get(`${id}_write`);
  }
}
/**
 * 手动更新Token
 * @param id
 * @param tokeninfo
 */
export function setFileToken(
  id: string,
  tokeninfo: { token: string; expiresOn: string | number; baseUrl?: string; writable?: boolean }
): Token | undefined {
  const now = Date.now();
  const tokenExpire = +new Date(tokeninfo.expiresOn);
  const cache = getCachedToken(id, tokeninfo.writable!);
  const cacheExpire = cache?.expiresOn || 0;
  if (tokenExpire > now && tokenExpire > cacheExpire) {
    const token: Token = {
      token: tokeninfo.token,
      baseUrl: tokeninfo.baseUrl,
      writable: tokeninfo.writable,
      expiresOn: tokenExpire,
    };
    cachedToken.set(`${id}${tokeninfo.writable ? "_write" : ""}`, token);
    return token;
  } else if (cache && cacheExpire <= now) {
    console.debug("sas token expired ", id);
    cachedToken.delete(`${id}${tokeninfo.writable ? "_write" : ""}`);
    return undefined;
  }
  return cache;
}

/**
 * @todo 合并 timer 和 request
 * @param id
 */
export function useFileTokenById(
  id: string,
  defaultToken?: FileTokenInfo,
  writable = false,
  isAdmin = true
) {
  const [token, setToken] = useState<Partial<Token>>(() => {
    const token = defaultToken
      ? setFileToken(id, { ...defaultToken, writable })
      : getCachedToken(id, writable);
    if (token) {
      if (token.expiresOn > Date.now()) {
        return token;
      } else {
        cachedToken.delete(id as string);
      }
    }
    return {};
  });
  // 自动刷新token
  useEffect(() => {
    if (id) {
      let timer = 0;
      const queryToken = () => {
        refreshToken(id, isAdmin, writable).then(t => {
          if (timer >= 0) {
            setToken(t);
            timer = 0;
            const exipreTime = t.expiresOn
              ? t.expiresOn - Date.now() - QUREY_BEFORE_EXPIRE
              : defaultTimeOut;
            if (exipreTime < MAX_TIMEOUT) {
              timer = window.setTimeout(queryToken, exipreTime);
            }
          }
        });
      };
      const expire =
        (getCachedToken(id, writable)?.expiresOn || 0) - Date.now() - QUREY_BEFORE_EXPIRE;
      if (expire <= 0) {
        queryToken();
      } else {
        timer = window.setTimeout(queryToken, expire);
      }

      return () => {
        clearTimeout(timer);
        timer = -1; // 不再刷新
      };
    }
  }, [writable, id, isAdmin]);
  return token;
}

const fileTokenContext = createContext<{ token?: string; baseUrl?: string; writable?: boolean }>(
  {}
);

export function FileTokenProvider({
  id,
  defaultToken,
  write,
  isAdmin = true,
  ...props
}: React.PropsWithChildren<{
  id: string;
  defaultToken?: FileTokenInfo;
  write?: boolean;
  isAdmin?: boolean;
}>) {
  const token = useFileTokenById(id, defaultToken, write, isAdmin);
  return <fileTokenContext.Provider value={token}>{props.children}</fileTokenContext.Provider>;
}

export function useFileToken() {
  return useContext(fileTokenContext);
}
