import { makeStyles, PrimaryButton } from "@fluentui/react";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { useAudioRecorder } from "../../hooks/useAudioRecorder";
import { rem } from "../../lib/unit";
import { Common, RecorderButtonI18n } from "../../locales";
import { logger } from "../../services/logger";
import { showDeleteDialog } from "../dialog/DeleteDialog";

export const useRecordBtn = makeStyles(theme => ({
  root: { padding: rem(0, 12), borderRadius: rem(4) },
  rootHovered: {
    backgroundColor: theme.palette.themePrimary,
  },
  rootPressed: {
    backgroundColor: theme.palette.themeDark,
  },
  rootChecked: {
    backgroundColor: theme.palette.themePrimary,
  },
  rootFocused: {
    backgroundColor: theme.palette.themePrimary,
  },
}));

const MIN_DURATION = 2800; // 约3s
export interface RecorderButtonProps {
  maxDuration?: number;
  disabled?: boolean;
  onRecodingStatusChanged?: (isRecording: boolean) => void;
  onRecordEnd?: (file: Blob, duration: number) => void;
  onErrorMsgChange?: (errorMsg: string) => void;
}
export default function RecorderButton({
  onRecordEnd,
  onRecodingStatusChanged,
  maxDuration,
  disabled,
  onErrorMsgChange,
}: RecorderButtonProps) {
  const { record, isRecording, status, stop } = useAudioRecorder((file, duration) => {
    if (file && duration) {
      stop();
    } else {
      logger?.telemetry("HumanRecordToLimit");
      return;
    }
    if (duration <= MIN_DURATION) {
      onErrorMsgChange && onErrorMsgChange(t(RecorderButtonI18n.error));
    } else if (onRecordEnd) {
      onRecordEnd(file, duration);
    }
    logger?.telemetry("HumanRecordEnd", { Duration: duration });
  });

  const recordBtnCss = useRecordBtn();
  const { t } = useTranslation();
  useEffect(() => {
    if (isRecording) {
      onErrorMsgChange && onErrorMsgChange("");
    }
    onRecodingStatusChanged?.(isRecording);
  }, [isRecording, onErrorMsgChange, onRecodingStatusChanged]);
  const timerRef = useRef({
    maxDuration,
    isStarting: false,
  });
  timerRef.current.maxDuration = maxDuration;
  const start = useCallback(() => {
    if (timerRef.current.isStarting) {
      return;
    }
    timerRef.current.isStarting = true;
    record(timerRef.current.maxDuration).then(
      () => {
        timerRef.current.isStarting = false;
      },
      status => {
        timerRef.current.isStarting = false;
        showDeleteDialog({
          title: t(RecorderButtonI18n.errorTitle, { context: status }),
          subText: t(RecorderButtonI18n.errorDetail, { context: status }),
          cancelButtonText: null,
          confirmButtonText: t(Common.close),
          doDelete: () => {},
        });
      }
    );
  }, [record, t]);
  return (
    <PrimaryButton
      title={t(RecorderButtonI18n.label, { context: status })}
      disabled={disabled || maxDuration! <= MIN_DURATION}
      styles={recordBtnCss}
      text={isRecording ? t(RecorderButtonI18n.pause) : t(RecorderButtonI18n.record)}
      iconProps={{
        iconName: isRecording ? IconName.HumanRecorderPause : IconName.HumanRecorderFill,
      }}
      onClick={isRecording ? stop : start}
    />
  );
}
