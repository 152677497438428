import { PropsWithChildren, useCallback, useEffect, useState, useMemo } from "react";
import { selectAPI, UserRole } from "../../services/request";
import MixedContent from "../../components/rc/MixedContent";
import { ResourceScope } from "../../config/rc";
import { useUser } from "../../hooks/rc/useUser";
import { useQueryWebPages } from "../../hooks/useQuery";
import { delStorage } from "../../services/storage";
import CategoryContentBar from "../navigation/CategoryContentBar";
import TemplateTags from "../tag/TemplateTags";
import { logger } from "../../services/logger";
import { useDimensionAndTags } from "../../hooks/useDimensionAndTags";
import { makeStyles, Separator } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { EmptyContentI18n } from "../../locales";
import { pageContentContainer } from "../navigation/TopBar";
import { useRouteViewBundleCallback, useRouteViewDocCallback } from "../../hooks/rc/useRoute";
import { useMobileCss } from "../../hooks/rc/useCss";
import TemplateMobileTags from "../tag/TemplateMobileTags";
import { useLocation, useNavigate } from "react-router-dom";
import { useRemoveFromCategory } from "../../hooks/useRemoveFromCategory";
import { BUILDTIN_CATEGROY_MAPPING } from "../../config/constant";
import { HASHType } from "../../config/rc";
import { rem } from "../../lib/unit";
import { RCDocumentType } from "../../services/share";

const useSepratorStyles = makeStyles(theme => ({
  root: {
    "::before": {
      background: "transparent",
    },
  },
  content: {
    background: "transparent",
    height: "1px",
    width: "100%",
    borderBottom: `1px solid ${theme?.palette.neutralQuaternaryAlt}`,
  },
}));

export interface CategoryContentProps {
  isAdmin?: boolean;
  scope: ResourceScope;
  categoryId?: string;
  onError?: () => void;
  pushNotification?: (id: string) => void;
}

export default function CategoryContent({
  scope,
  categoryId,
  onError,
  isAdmin,
  children,
  pushNotification,
}: PropsWithChildren<CategoryContentProps>) {
  const { role } = useUser();
  const isGuest = role === UserRole.guest;
  const apis = selectAPI(scope, isGuest);
  const isMobileCss = useMobileCss();
  const dimensionAndTags = useDimensionAndTags(categoryId, isAdmin);
  const [getDocError, setGetDocError] = useState(false);
  const [selectedTagsInfo, setSelectedTagsInfo] = useState<{ [key: string]: string }>();
  const [getBundleError, setGetBundleError] = useState(false);
  const sepratorStyles = useSepratorStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const isFeedsCategory = useMemo(
    () => location?.hash.startsWith(HASHType.feedsCategory),
    [location?.hash]
  );

  const { t } = useTranslation();
  const {
    values: bundles,
    refetch: refetchBundles,
    hasNextPage: hasNextPageBundle,
    loadMore: loadMoreBundle,
    indicatorVisible: bundleIsLoading,
  } = useQueryWebPages(
    apis?.categoryBundlesAPI,
    {
      vars: { categoryId: categoryId! },
      params: {
        $top: 10,
      },
      skipAuth: isGuest,
    },
    {
      enabled: apis && !!categoryId,
      onError: () => {
        setGetBundleError(true);
      },
    }
  );

  const onRemoveBundleFromCategory = useRemoveFromCategory(refetchBundles);
  const clickNotificationBtn = useCallback(() => {
    if (categoryId && pushNotification) {
      pushNotification(categoryId);
      logger?.telemetry("ClickNotificationBtn", { CategoryId: categoryId, Target: "tingdoc" });
    }
  }, [categoryId, pushNotification]);
  const viewBunndle = useRouteViewBundleCallback({ params: { scope, categoryId } });
  const viewDoc = useRouteViewDocCallback({ scope, categoryId });
  const {
    values: documents,
    indicatorVisible: docIsLoading,
    hasNextPage,
    refetch: refetchDocs,
    loadMore,
  } = useQueryWebPages(
    apis.categoryDocumentsAPI,
    {
      vars: { categoryId: categoryId! },
      params: {
        $top: 10,
        $orderBy:
          categoryId && BUILDTIN_CATEGROY_MAPPING[categoryId] ? ["CreateAt DESC"] : undefined,
        tagIds: selectedTagsInfo && Object.values(selectedTagsInfo),
      },
      skipAuth: isGuest,
    },
    {
      enabled: !!categoryId,
      onError: () => {
        setGetDocError(true);
      },
      onSuccess: data => {
        if (
          Object.values(selectedTagsInfo ?? {}).length &&
          data?.pages &&
          data?.pages[0].value.length === 0
        ) {
          logger?.telemetry("NoDocInSelectedTags", {
            SelectedTags: selectedTagsInfo as any,
            CategoryId: categoryId!,
          });
        }
      },
    }
  );

  const onRemoveDocFromCategory = useRemoveFromCategory(refetchDocs);
  useEffect(() => {
    if (getDocError && getBundleError) {
      onError && onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBundleError, getDocError]);

  useEffect(() => {
    const { selectedTags } = window.history.state?.state ?? {};
    if (selectedTags?.categoryId === categoryId) {
      setSelectedTagsInfo(selectedTags.value);
    } else {
      setSelectedTagsInfo(undefined);
    }
    delStorage("justReadId");
  }, [categoryId]);

  const onSelectedTags = useCallback(
    (data?: { [key: string]: string }) => {
      navigate(`${location.pathname}${location.hash}`, {
        replace: true,
        state: {
          ...((location.state as { [key: string]: any }) ?? {}),
          selectedTags: {
            categoryId,
            value: data,
          },
        },
      });
      setSelectedTagsInfo(data);
    },
    [categoryId, location.hash, location.pathname, location.state, navigate]
  );
  const notifable = pushNotification && isAdmin && !!(documents?.length || bundles?.length);
  const emptyContext = dimensionAndTags ? "template" : "category";
  const showSeparator = !isMobileCss && !!dimensionAndTags?.length;

  return (
    <div className={pageContentContainer} style={{ height: "100%" }}>
      {isMobileCss ? (
        <TemplateMobileTags
          dimensions={dimensionAndTags}
          onSelected={onSelectedTags}
          selectedInfo={selectedTagsInfo}
          isAdmin={isAdmin}
        ></TemplateMobileTags>
      ) : (
        <TemplateTags
          dimensions={dimensionAndTags}
          onSelected={onSelectedTags}
          selectedInfo={selectedTagsInfo ?? {}}
          isAdmin={isAdmin}
        ></TemplateTags>
      )}
      {showSeparator && <Separator styles={sepratorStyles}></Separator>}
      <MixedContent
        categoryId={categoryId}
        scope={scope}
        loadMoreBundle={loadMoreBundle}
        isFetchingBundle={bundleIsLoading}
        hasNextPageBundle={hasNextPageBundle}
        bundles={bundles}
        tapBundle={viewBunndle}
        hasNextPageDoc={hasNextPage}
        loadMoreDoc={loadMore}
        isFetchingDoc={docIsLoading}
        documents={documents}
        allowLinkType={[
          RCDocumentType.TingDoc,
          RCDocumentType.Video,
          RCDocumentType.PPTTemplate,
          RCDocumentType.WordTemplate,
        ]}
        tapDoc={doc => {
          dimensionAndTags &&
            logger?.telemetry("ClickTagFilteredDoc", {
              DocumentType: doc.metadata?.type!,
              DocumentId: doc.id!,
              DocumentName: doc.metadata?.name!,
              BundleId: doc.extended?.bundleId!,
              CategoryId: doc.extended?.categoryId!,
              SelectedTags: selectedTagsInfo as any,
              Tags: doc.tags! as any,
              Permission:
                doc.metadata?.status === 0 ? "public" : doc.metadata?.status === 1 ? "private" : "",
            });
          viewDoc(doc);
        }}
        emptyTitle={t(EmptyContentI18n.emptyTitle, { context: emptyContext })}
        emptySubTitle={t(EmptyContentI18n.emptySubTitle, { context: emptyContext })}
        onRemoveDocFromCategory={isAdmin && !isFeedsCategory ? onRemoveDocFromCategory : undefined}
        onRemoveBundleFromCategory={
          isAdmin && !isFeedsCategory ? onRemoveBundleFromCategory : undefined
        }
        contentContainerStyle={{
          height: `calc(100% - ${isAdmin ? rem(50) : "0px"})`,
          overflow: "scroll",
        }}
      >
        {notifable && <CategoryContentBar onClickNotification={clickNotificationBtn} />}
      </MixedContent>
    </div>
  );
}
