import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogFooter,
  DefaultButton,
  PrimaryButton,
  IDialogProps,
  DialogType,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { Common } from "../../locales";
import { rem } from "../../lib/unit";
import { Subject } from "rxjs";

export interface DeleteDialogProps {
  hidden?: boolean;
  doDelete: () => void;
  onDismiss?: () => void;
  subText?: string;
  title: string;
  cancelButtonText?: string | null;
  confirmButtonText?: string | null;
  showCloseButton?: boolean;
}

export default function DeleteDialog({
  hidden,
  doDelete,
  onDismiss,
  title,
  subText = "",
  cancelButtonText,
  confirmButtonText,
  showCloseButton,
}: React.PropsWithChildren<DeleteDialogProps>) {
  const { t } = useTranslation();
  const dialogProps = useMemo<IDialogProps>(
    () => ({
      styles: {
        main: {
          width: `${rem(450)} !important`,
        },
      },
      dialogContentProps: {
        type: DialogType.normal,
        title,
        styles: {
          inner: {
            minHeight: rem(100),
            height: "conent-height",
          },
          topButton: {
            visibility: showCloseButton ? "inherit" : "hidden",
          },
        },
      },
      modalProps: {
        isBlocking: true,
      },
    }),
    [showCloseButton, title]
  );
  return (
    <Dialog hidden={hidden} onDismiss={onDismiss} {...dialogProps}>
      {subText}
      <DialogFooter>
        {cancelButtonText === null ? null : (
          <DefaultButton onClick={doDelete} text={cancelButtonText || t(Common.confirm)} />
        )}
        {confirmButtonText === null ? null : (
          <PrimaryButton onClick={onDismiss} text={confirmButtonText || t(Common.cancel)} />
        )}
      </DialogFooter>
    </Dialog>
  );
}

const subject = new Subject<Omit<DeleteDialogProps, "hidden"> | null>();

export const showDeleteDialog = subject.next.bind(subject);

export const SharedDeleteDialog = () => {
  const [hidden, setHidden] = useState<boolean>(true);
  const [props, setProps] = useState<DeleteDialogProps>(() => ({
    title: "",
    doDelete: () => setHidden(true),
    onDismiss: () => {
      setHidden(true);
      setProps(p => ({ ...p, doDelete: () => setHidden(true) }));
    },
  }));
  useEffect(() => {
    const s = subject.subscribe(prop => {
      if (!prop) {
        return setHidden(true);
      }
      const beforeDismiss = prop.onDismiss;
      setProps(p => ({
        // ...p,
        ...prop,
        doDelete: () => {
          prop.doDelete();
          setHidden(true);
        },
        onDismiss: beforeDismiss
          ? () => {
              setHidden(true);
              Promise.resolve(beforeDismiss()).finally(() => {
                setProps(p => ({ ...p, doDelete: () => setHidden(true), onDismiss: undefined }));
              });
            }
          : () => {
              setHidden(true);
              setProps(p => ({ ...p, doDelete: () => setHidden(true) }));
            },
      }));
      setHidden(false);
    });
    return () => s.unsubscribe();
  }, []);
  return <DeleteDialog {...props} hidden={hidden} />;
};
