import React from "react";
import { ResourceScope } from "../../../config/rc";
import { Template } from "../../../models/Template";

import { TemplateContentView } from "./TemplateContentView";
import TemplateViewTop from "./TemplateViewTop";
import "./TemplatePlayer.scss";
import { ShareInfo } from "../../../services/share";

export interface TemplatePlayerProps {
  scope: ResourceScope;
  template: Template;
  category?: { id: string; name: string };
  hideTeamsShare?: boolean;
  rcShareInfo?: ShareInfo;
  onTapBack?: () => void;
}

export default function TemplatePlayer(props: TemplatePlayerProps): React.ReactElement {
  return (
    <>
      <TemplateViewTop {...props}></TemplateViewTop>
      <div className="TemplatePlayer-container">
        <TemplateContentView template={props.template}></TemplateContentView>
      </div>
    </>
  );
}
