import React, { useCallback, useMemo, useState } from "react";
import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  ICommandBarItemProps,
  CommandBar,
  SelectionMode,
  PersonaSize,
  ActionButton,
  IColumn,
  FontWeights,
  Spinner,
  makeStyles,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { AssignmentCenterI18n } from "../../locales";
import AssignmentDialog, { AssignmentActionType, AssignmentInfo } from "../dialog/AssignmentDialog";
import { AssignmentEntity, UserRole, WEB_API } from "../../services/request";
import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";
import { RCPersona } from "./RCPersona";
import { contentHeight } from "../navigation/TopBar";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { usePrincipalsDetail } from "../../hooks/rc/usePrincipalsDetail";
import { useQueryWebPages } from "../../hooks/useQuery";
import { FontSizes } from "../../themes/fonts";

enum columnKeys {
  Name = "name",
  Title = "title",
  Location = "location",
  Role = "role",
  Action = "action",
}

const columnCellEmpty = "--";

const useColumnTitleStyles = makeStyles(theme => ({
  cellName: {
    color: theme.palette.neutralDark,
    fontSize: FontSizes.small,
    fontWeight: FontWeights.regular,
  },
}));

const useStyles = makeStyles(theme => ({
  root: { padding: rem(20, 0, 20, 20), height: `calc(${contentHeight} - ${rem(40)})` },
  column: {
    display: "flex",
    alignItems: "center",
    height: rem(48),
    color: theme.palette.neutralPrimary,
  },
  assignmentsInfo: {
    maxHeight: `calc(100% - ${rem(40)})`,
    paddingRight: rem(20),
    overflowY: "auto",
  },
}));

function useAssignmentsInfoWithPrincipalDetail() {
  let assignmentsInfo: AssignmentInfo[] | undefined;
  const {
    values: assignments,
    refetch,
    loadMore,
    hasNextPage,
    isFetching,
  } = useQueryWebPages(WEB_API.assignments, {
    params: {
      $top: 15,
    },
  });
  const principals = assignments?.map(item => ({
    principalId: item.principalId,
    principalType: item.principalType,
  }));
  const { users } = usePrincipalsDetail(principals);
  if (assignments && users) {
    assignmentsInfo = assignments.map((item, index) => ({
      ...item,
      principalDetail: users.find(u => u.id === item.principalId),
    }));
  }

  return {
    assignmentsInfo,
    refetch,
    loadMore,
    hasNextPage,
    isFetching,
  };
}

export default function AssignmentCenter() {
  const { t } = useTranslation();
  const [hiddenDialog, setHiddenDialog] = useState(true);
  const [actionType, setActionType] = useState<AssignmentActionType>(AssignmentActionType.add);
  const [actionData, setActionData] = useState<AssignmentInfo>();
  const columnTitleStyles = useColumnTitleStyles();
  const styles = useStyles();
  const { assignmentsInfo, refetch, loadMore, hasNextPage, isFetching } =
    useAssignmentsInfoWithPrincipalDetail();
  const columns = useMemo<IColumn[]>(
    () => [
      {
        key: columnKeys.Name,
        name: t(AssignmentCenterI18n.columnHeadName),
        minWidth: 100,
        maxWidth: 250,
        styles: columnTitleStyles,
        fieldName: "principalId",
        onRender: (item: AssignmentInfo) => (
          <div className={styles.column}>
            <RCPersona
              size={PersonaSize.size32}
              principalType={item.principalType}
              principalData={item.principalDetail}
              showSecondaryText={false}
            ></RCPersona>
          </div>
        ),
      },
      {
        key: columnKeys.Title,
        name: t(AssignmentCenterI18n.columnHeadTitle),
        minWidth: 200,
        maxWidth: 250,
        styles: columnTitleStyles,
        fieldName: "principalDetail.jobTitle",
        onRender: (item: AssignmentInfo) => (
          <div className={styles.column}>{item.principalDetail?.jobTitle ?? columnCellEmpty}</div>
        ),
      },
      {
        key: columnKeys.Location,
        name: t(AssignmentCenterI18n.columnHeadLocation),
        minWidth: 100,
        styles: columnTitleStyles,
        fieldName: "principalDetail.officeLocation",
        onRender: (item: AssignmentInfo) => (
          <div className={styles.column}>
            {item.principalDetail?.officeLocation ?? columnCellEmpty}
          </div>
        ),
      },
      {
        key: columnKeys.Role,
        name: t(AssignmentCenterI18n.columnHeadRole),
        minWidth: 100,
        maxWidth: 150,
        styles: columnTitleStyles,
        fieldName: "roleType",
        onRender: (item: AssignmentEntity) => (
          <div className={styles.column}>
            {t(AssignmentCenterI18n.roleName, {
              context: item.roleType === UserRole.resourceAdmin ? "resourceAdmin" : "",
            })}
          </div>
        ),
      },
      {
        key: columnKeys.Action,
        name: "",
        styles: columnTitleStyles,
        minWidth: 50,
        onRender: (item: AssignmentInfo) => (
          <div className={styles.column}>
            <ActionButton
              iconProps={{
                iconName: IconName.Cancel,
                styles: {
                  root: {
                    fontSize: FontSizes.small,
                  },
                },
              }}
              onClick={() => {
                setActionType(AssignmentActionType.delete);
                setActionData(item);
                setHiddenDialog(false);
              }}
            ></ActionButton>
          </div>
        ),
      },
    ],
    [t, columnTitleStyles, styles.column]
  );

  const items = useMemo<ICommandBarItemProps[]>(
    () => [
      {
        key: "newAssignment",
        text: t(AssignmentCenterI18n.createButtonTitle),
        cacheKey: "createNewAssignment", // changing this key will invalidate this item's cache
        iconProps: { iconName: IconName.Add },
        onClick: () => {
          setActionType(AssignmentActionType.add);
          setHiddenDialog(false);
        },
      },
    ],
    [t]
  );

  const onAssignmentDialogConfirm = useCallback(() => {
    setHiddenDialog(true);
    setActionData(undefined);
    setActionType(AssignmentActionType.blank);
    refetch();
  }, [refetch]);
  const onAssignmentDialogDismiss = useCallback(() => {
    setHiddenDialog(true);
    setActionData(undefined);
  }, []);

  const [ref] = useInfiniteScroll({
    loading: isFetching,
    onLoadMore: loadMore,
    hasNextPage: !!hasNextPage,
  });

  return (
    <div className={`${styles.root} Container`}>
      <CommandBar
        items={items}
        styles={{
          root: {
            padding: 0,
          },
        }}
      />
      <div className={styles.assignmentsInfo}>
        <ShimmeredDetailsList
          items={assignmentsInfo || []}
          enableShimmer={!assignmentsInfo}
          columns={columns}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selectionMode={SelectionMode.none}
        />
        <div ref={ref} />
        {isFetching && <Spinner />}
      </div>

      <AssignmentDialog
        assignmentInfos={assignmentsInfo}
        assignment={actionData}
        hidden={hiddenDialog}
        actionType={actionType}
        roleType={UserRole.resourceAdmin}
        onConfirm={onAssignmentDialogConfirm}
        onDismiss={onAssignmentDialogDismiss}
      ></AssignmentDialog>
    </div>
  );
}
