import {
  IButtonProps,
  IButtonStyles,
  ICommandBarStyleProps,
  ICommandBarStyles,
} from "@fluentui/react";
import { rem } from "../../lib/unit";
export function getCommandBarStyles(themeName: string) {
  return (props: ICommandBarStyleProps) => {
    const { palette, semanticColors } = props.theme;

    const styles: Partial<ICommandBarStyles> = {
      root: {
        borderBottom: `1px solid ${semanticColors.bodyFrameDivider}`,
        boxSizing: "border-box",
        height: rem(40),
        padding: rem(0, 32),
        background: "transparent",
        ".ms-Button-icon": {
          color: palette.neutralPrimary,
          display: "flex",
        },
        "a, a:link, a:visited, a:hover, button, button:active, button:hover": {
          background: "transparent",
        },
      },
    };
    return styles;
  };
}

export function getCommandBarButtonStyles(themeName: string) {
  return (props: IButtonProps) => {
    const theme = props.theme;
    const colorType = {
      backgroundColor: "transparent",
      color: theme?.palette.themePrimary,
    };

    const styles: IButtonStyles = {
      rootHovered: colorType,
      rootPressed: colorType,
      rootExpandedHovered: colorType,
      rootExpanded: colorType,

      menuIconHovered: colorType,
      menuIconPressed: colorType,
      root: { "-webkit-tap-highlight-color": "transparent" },
    };
    return styles;
  };
}
