import { useEffect, useState } from "react";
import axios from "axios";

const message = "hook unmounted";
export function useCancelToken() {
  const [cancelTokenSource, setCancelTokenSource] = useState(() => axios.CancelToken.source());
  useEffect(() => {
    cancelTokenSource.token.promise.then(v => {
      if (v.message !== message) {
        setCancelTokenSource(axios.CancelToken.source());
      }
    });
    return () => cancelTokenSource.cancel(message);
  }, [cancelTokenSource]);
  return cancelTokenSource;
}
