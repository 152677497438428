import { useCallback, useMemo } from "react";
import { List, mergeStyles } from "@fluentui/react";
import SelectableCard, { SelectableCardProps } from "../cards/SelectableCard";
import { SortableData } from "../cards/SortableCard";
import { rem } from "../../lib/unit";
import EmptyContent from "../empty/EmptyContent";

type ItemType = SortableData & { itemStatus?: SelectableCardProps["status"] };

export default function DocumentPicker({
  dataList,
  selectedItems,
  onSelect,
  className,
  enableShimmer,
  maxItems,
  emptyMessage,
}: {
  dataList?: SortableData[];
  selectedItems?: SortableData[];
  className?: string;
  onSelect?: (item: SortableData) => void;
  enableShimmer?: boolean;
  maxItems: number;
  emptyMessage?: string;
}) {
  const items = useMemo<ItemType[]>(() => {
    const shimmerCount = 3;
    if (!dataList) {
      return Array(shimmerCount);
    }
    return dataList
      .map<ItemType>(data =>
        !selectedItems
          ? data
          : {
              ...data,
              itemStatus: selectedItems.some(i => i.id === data.id)
                ? "checked"
                : selectedItems.length >= maxItems
                ? "disabled"
                : "unchecked",
            }
      )
      .concat(enableShimmer ? Array(shimmerCount) : []);
  }, [dataList, enableShimmer, maxItems, selectedItems]);
  const renderCell = useCallback(
    (item?: ItemType) => {
      const onTap = onSelect && item && (() => item.itemStatus !== "disabled" && onSelect(item));
      return <SelectableCard data={item} onTap={onTap} status={item?.itemStatus} />;
    },
    [onSelect]
  );
  const contentClassName = mergeStyles({ height: rem(380), overflow: "auto" }, className);

  return !enableShimmer && items?.length === 0 ? (
    <EmptyContent className={contentClassName} message={emptyMessage} />
  ) : (
    <List data-is-scrollable className={contentClassName} items={items} onRenderCell={renderCell} />
  );
}
