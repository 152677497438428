import { useState, useEffect } from "react";
import { TingPage } from "../../models/TingDoc";
import { EditableTingDoc } from "../../models/EditableTingDoc";

/**
 * 使用doc 页面数据
 * @param editorDoc
 * @param pageIndex
 * @param key
 * @param debounceDuartion
 * @returns
 */
export function useEditableTingDocPageData<TKey extends keyof TingPage>(
  editorDoc: EditableTingDoc | undefined,
  pageIndex: number,
  key: TKey,
  debounceDuartion?: number
) {
  const [data, setData] = useState(() => editorDoc?.pages[pageIndex]?.[key]);
  const currentPage = editorDoc?.pages[pageIndex];
  useEffect(() => setData(currentPage?.[key]), [currentPage, key]);
  useEffect(
    () => editorDoc?.subscribePageData(pageIndex, key, setData, debounceDuartion),
    [debounceDuartion, editorDoc, key, pageIndex]
  );
  return data;
}

/**
 * 页面数据
 * @param editorDoc
 * @returns
 */
export function useEditableTingDocPages(editorDoc?: EditableTingDoc) {
  const [data, setData] = useState(editorDoc?.pages);
  useEffect(() => setData(editorDoc?.pages), [editorDoc?.pages]);
  useEffect(() => editorDoc?.subscribePages(setData), [editorDoc]);
  return data;
}

/**
 * meta Data数据
 * @param editorDoc
 * @returns
 */
export function useEditableTingDocMetaData(editorDoc?: EditableTingDoc) {
  const [data, setData] = useState<Partial<CDS.DocumentMeta>>(editorDoc?.metadata || {});
  useEffect(() => setData(editorDoc?.metadata || {}), [editorDoc?.metadata]);
  useEffect(() => {
    const unSub = editorDoc?.metaSubject.subscribe(meta => setData(s => ({ ...s, ...meta })));
    return () => unSub?.unsubscribe();
  }, [editorDoc]);
  return data;
}
