import { useEffect, useState } from "react";
import { ITheme } from "@fluentui/react";
import { getTeamsTheme } from "../themes/teamsCustomization";
import { isMobliePhone } from "../config/browser";
import { ThemeType } from "../themes/teamsTheme";
import { INIT_QUERY } from "../config/config";
import {
  getCachedTeamsContext,
  getTeamsContext,
  registerOnThemeChangeHandler,
} from "../services/teams";

function getInitTheme(): ThemeType {
  const defaultTheme: ThemeType = (INIT_QUERY.get("theme") as ThemeType) || ThemeType.default;
  const initContext = getCachedTeamsContext();
  const cachedTheme = initContext && initContext.theme;
  return (cachedTheme as ThemeType) || defaultTheme;
}

export function useTeamsTheme() {
  const [theme, setTheme] = useState<ITheme>(() => getTeamsTheme(getInitTheme(), isMobliePhone));
  useEffect(() => {
    function updateTheme(themeName?: string) {
      if (themeName) {
        setTheme(getTeamsTheme(themeName as ThemeType, isMobliePhone));
      }
    }
    getTeamsContext().then(
      context => updateTheme(context.theme),
      () => {}
    );
    registerOnThemeChangeHandler(updateTheme);
  }, []);
  return theme;
}
