import {
  DirectionalHint,
  TeachingBubble,
  Icon,
  Separator,
  Text,
  makeStyles,
  IStyleFunctionOrObject,
  ITeachingBubbleStyleProps,
  ITeachingBubbleStyles,
} from "@fluentui/react";
import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppDeployment } from "../../locales";
import { IconName } from "../../config/icons";
import { rem } from "../../lib/unit";

const useUserGuidesStyles = makeStyles(theme => ({
  text: {
    color: "inherit",
    whiteSpace: "pre-wrap",
  },
  control: {
    display: "flex",
    justifyContent: "space-between",
  },
  turnPage: {
    " i": {
      justifyContent: "center",
      width: rem(20),
      cursor: "pointer",
    },
    "&.is-hidden": {
      visibility: "hidden",
    },
  },
  stepper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "maxContent",
    ".gotIt": {
      cursor: "pointer",
    },
  },
  selectOption: {
    float: "left",
    width: rem(4),
    height: rem(4),
    marginRight: rem(4),
    borderRadius: "50%",
    backgroundColor: theme.palette.whiteTranslucent40,
    "&.is-active": {
      backgroundColor: theme.palette.white,
    },
    ":last-child": {
      marginRight: 0,
    },
  },
  separator: {
    height: rem(18),
    padding: 0,
    "::before": {
      background: theme.palette.whiteTranslucent40,
      top: rem(12),
    },
  },
}));

export interface UserGuide {
  target?: string | Element;
  directionalHint?: DirectionalHint;
  directionalHintFixed?: true;
  text: string;
  styles?: IStyleFunctionOrObject<ITeachingBubbleStyleProps, ITeachingBubbleStyles>;
  headline?: string;
}

export default function UserGuidesBubble({
  items,
  onDismiss,
}: {
  items: UserGuide[];
  onDismiss?: () => void;
}): React.ReactElement {
  const userGuidesStyles = useUserGuidesStyles();
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const hiddenBtn = useCallback(() => {
    setHidden(true);
    if (onDismiss) onDismiss();
  }, [onDismiss]);
  const chevronLeftBtn = useCallback(() => setCurrentStep(s => s - 1), []);
  const chevronRightBtn = useCallback(() => setCurrentStep(s => s + 1), []);
  const currentData = items[currentStep];
  const dismiss = useRef(onDismiss);
  if (dismiss.current !== onDismiss) dismiss.current = onDismiss;
  useEffect(() => () => dismiss.current && dismiss.current(), []);

  const styles = useMemo(
    () => ({ headline: { marginBottom: rem(12) }, ...currentData.styles }),
    [currentData.styles]
  );

  return hidden ? (
    <></>
  ) : (
    <TeachingBubble
      target={currentData.target}
      styles={styles}
      calloutProps={{
        directionalHint: currentData.directionalHint,
        preventDismissOnLostFocus: true,
        directionalHintFixed: currentData.directionalHintFixed,
      }}
      headline={currentData.headline}
    >
      <Text block className={userGuidesStyles.text} variant="small">
        {currentData.text}
      </Text>
      <Separator className={userGuidesStyles.separator} />
      <div className={userGuidesStyles.control}>
        <div className={`${userGuidesStyles.turnPage} ${currentStep > 0 ? "" : "is-hidden"}`}>
          <Icon iconName={IconName.ChevronLeft} onClick={chevronLeftBtn} />
        </div>
        <div className={`${userGuidesStyles.stepper}`}>
          {currentStep >= items.length - 1 ? (
            <div className="gotIt" onClick={hiddenBtn}>
              {t(AppDeployment.bubbleBtn)}
            </div>
          ) : (
            <div>
              {items.map((_item, index) => (
                <i
                  className={`selectOption ${currentStep === index ? "is-active" : ""} ${
                    userGuidesStyles.selectOption
                  }`}
                  key={`stepper${index}`}
                />
              ))}
            </div>
          )}
        </div>
        <div
          className={`${userGuidesStyles.turnPage} ${
            currentStep < items.length - 1 ? "" : "is-hidden"
          }`}
        >
          <Icon iconName={IconName.ChevronRight} onClick={chevronRightBtn} />
        </div>
      </div>
    </TeachingBubble>
  );
}
