import { getIdToken } from "../services/request/";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { guestCancelled } from "../services/teams";
import { useBoolean } from "@fluentui/react-hooks";

export function useIdToken() {
  const [isErr, { setFalse, setTrue }] = useBoolean(false);

  const { data, isError } = useQuery(
    "#IDTOKEN",
    () => {
      const result = getIdToken();
      result.catch(err => {
        // 判断用户为guest
        if (err === guestCancelled) {
          setTrue();
        }
      });
      return result;
    },
    {
      staleTime: 30000, // 30s
      refetchIntervalInBackground: false,
      placeholderData: "",
      refetchInterval: Infinity,
    }
  );

  useEffect(() => {
    if (!isError && !!data) {
      setFalse();
    }
  }, [data, isError, setFalse]);

  return { data, isError: isErr };
}
