import { getDocExtension, RCDocumentType } from "../services/share";
import { BaseDocumentModel } from "./BaseDocumentModel";

export class Template extends BaseDocumentModel {
  /**
   * 页面预览
   */
  public readonly pages: string[];
  /**
   * 下载地址
   */
  public readonly downloadUrl: string;
  /**
   * 扩展名
   */
  public readonly extension?: string;
  /**
   * 跳转网页office url
   */
  public readonly editUrl?: string;
  /**
   * tags
   */
  public readonly tags?: string[];

  /**
   * 检查是否为template
   * @param doc
   */
  public static isTemplate(doc: CDS.Document) {
    return [
      RCDocumentType.PPTTemplate,
      RCDocumentType.WordTemplate,
      RCDocumentType.ExcelTemplate,
    ].includes(doc.metadata?.type as unknown as RCDocumentType);
  }

  constructor(doc: CDS.Document) {
    if (!Template.isTemplate(doc)) {
      throw new Error(`invalid Template type ${doc.metadata?.type} ${doc.id}`);
    }

    super(doc);
    const content = doc.content as CDS.TemplateDocumentContent;
    this.downloadUrl = content.downloadUrl;
    this.pages = content.template.previews.map(p => this.getMediaUrl(p.mediaId));
    this.extension = getDocExtension(doc.metadata?.type);
    this.tags = doc.tags;
    // https://docs.microsoft.com/zh-cn/office/client-developer/office-uri-schemes
    switch (doc.metadata?.type) {
      case RCDocumentType.PPTTemplate:
        this.editUrl = `ms-powerpoint:nft|u|${content?.downloadUrl}`;
        break;
      case RCDocumentType.WordTemplate:
        this.editUrl = `ms-word:nft|u|${content?.downloadUrl}`;
        break;
      case RCDocumentType.ExcelTemplate:
        this.editUrl = `ms-excel:nft|u|${content?.downloadUrl}`;
        break;
    }
  }

  public isPPT() {
    return this.metadata.type === RCDocumentType.PPTTemplate;
  }

  public isWord() {
    return this.metadata.type === RCDocumentType.WordTemplate;
  }

  public isExcel() {
    return this.metadata.type === RCDocumentType.ExcelTemplate;
  }
}
