/* eslint-disable @dragongate/no-magic-numbers */
/* eslint-disable no-bitwise */
import { Graph_API, requestGraph } from "../request/requestGraph";

/**
 * Group Type enumeration
 *
 * @export
 * @enum {number}
 */
export enum GroupType {
  /**
   * Any group Type
   */
  any = 0,

  /**
   * Office 365 group
   */
  unified = 1 << 0,

  /**
   * Security group
   */
  security = 1 << 1,

  /**
   * Mail Enabled Security group
   */
  mailenabledsecurity = 1 << 2,

  /**
   * Distribution Group
   */
  distribution = 1 << 3,
}

export function findGroups(query: string, top = 10, groupTypes: GroupType = GroupType.any) {
  let filterQuery = "";
  if (query !== "") {
    filterQuery = `(startswith(displayName,'${query}') or startswith(mailNickname,'${query}') or startswith(mail,'${query}'))`;
  }

  if (groupTypes !== GroupType.any) {
    const filterGroups = [];

    if (GroupType.unified === (groupTypes & GroupType.unified)) {
      filterGroups.push("groupTypes/any(c:c+eq+'Unified')");
    }

    if (GroupType.security === (groupTypes & GroupType.security)) {
      filterGroups.push("(mailEnabled eq false and securityEnabled eq true)");
    }

    if (GroupType.mailenabledsecurity === (groupTypes & GroupType.mailenabledsecurity)) {
      filterGroups.push("(mailEnabled eq true and securityEnabled eq true)");
    }

    if (GroupType.distribution === (groupTypes & GroupType.distribution)) {
      filterGroups.push("(mailEnabled eq true and securityEnabled eq false)");
    }

    filterQuery += (query !== "" ? " and " : "") + filterGroups.join(" or ");
  }
  const result = requestGraph(Graph_API.groups, {
    params: {
      $filter: filterQuery,
      $top: top,
    },
  });

  return result;
}

export function findGroupsFromGroup(
  query: string,
  groupId: string,
  top = 10,
  transitive = false,
  groupTypes: GroupType = GroupType.any,
  memberType = "microsoft.graph.group"
) {
  let filterQuery = "";
  if (query !== "") {
    filterQuery = `(startswith(displayName,'${query}') or startswith(mailNickname,'${query}') or startswith(mail,'${query}'))`;
  }

  if (groupTypes !== GroupType.any) {
    const filterGroups = [];

    if (GroupType.unified === (groupTypes & GroupType.unified)) {
      filterGroups.push("groupTypes/any(c:c+eq+'Unified')");
    }

    if (GroupType.security === (groupTypes & GroupType.security)) {
      filterGroups.push("(mailEnabled eq false and securityEnabled eq true)");
    }

    if (GroupType.mailenabledsecurity === (groupTypes & GroupType.mailenabledsecurity)) {
      filterGroups.push("(mailEnabled eq true and securityEnabled eq true)");
    }

    if (GroupType.distribution === (groupTypes & GroupType.distribution)) {
      filterGroups.push("(mailEnabled eq true and securityEnabled eq false)");
    }

    filterQuery += (query !== "" ? " and " : "") + filterGroups.join(" or ");
  }

  const result = requestGraph(Graph_API.groupGroupMembers, {
    vars: {
      groupId,
      transiveType: transitive ? "transitiveMembers" : "members",
    },
    params: {
      $filter: filterQuery,
      $count: true,
      $top: top,
    },
  });

  return result;
}

/**
 * @param {string} groupId
 */
export function getGroup(groupId: string) {
  const response = requestGraph(Graph_API.group, {
    vars: {
      groupId,
    },
  });

  return response;
}
