import { TFunction } from "i18next";
import { TTSVoiceI18n } from "../locales";
import { TTS } from "../services/request";

import GuyNeuralAvatar from "../img/tts/guyneural.svg";
import GuyNeuralNewscastAvatar from "../img/tts/guyneuralnewscast.svg";
import JennyNeuralAvatar from "../img/tts/jennyneural.svg";
import JennyNeuralNewscastAvatar from "../img/tts/jennyneuralnewscast.svg";
import XiaoxiaoNeuralAvatar from "../img/tts/xiaoxiaoneural.svg";
import XiaoxiaoNeuralCustomerServiceAvatar from "../img/tts/xiaoxiaoneuralcustomerservice.svg";
import XiaoxiaoNeuralNewscastAvatar from "../img/tts/xiaoxiaoneuralnewscast.svg";
import YunxiNeuralCheerfulAvatar from "../img/tts/yunxineuralcheerful.svg";
import YunyangNeuralAvatar from "../img/tts/yunyangneural.svg";

const audioHost = "https://rc-prod-verizon.o365cn.com/public/files";

export function getVoices(t: TFunction): TTS.Voice[] {
  const voices: TTS.Voice[] = [
    {
      voiceName: "XiaoxiaoNeural",
      locale: "zh-cn",
      displayName: t(TTSVoiceI18n.displayName, { context: "XiaoxiaoNeural" }),
      description: t(TTSVoiceI18n.description, { context: "XiaoxiaoNeural" }),
      avatar: XiaoxiaoNeuralAvatar,
      language: t(TTSVoiceI18n.language, { context: "Chinese" }),
      auditionSrc: `${audioHost}/xiaoxiaoneural.mp3`,
    },
    {
      voiceName: "YunyangNeural",
      locale: "zh-cn",
      displayName: t(TTSVoiceI18n.displayName, { context: "YunyangNeural" }),
      description: t(TTSVoiceI18n.description, { context: "YunyangNeural" }),
      avatar: YunyangNeuralAvatar,
      language: t(TTSVoiceI18n.language, { context: "Chinese" }),
      auditionSrc: `${audioHost}/yunyangneural.mp3`,
    },
    {
      voiceName: "XiaoxiaoNeuralCustomerService",
      locale: "zh-cn",
      displayName: t(TTSVoiceI18n.displayName, { context: "XiaoxiaoNeuralCustomerService" }),
      description: t(TTSVoiceI18n.description, { context: "XiaoxiaoNeuralCustomerService" }),
      avatar: XiaoxiaoNeuralCustomerServiceAvatar,
      language: t(TTSVoiceI18n.language, { context: "Chinese" }),
      auditionSrc: `${audioHost}/xiaoxiaoneuralcustomerservice.mp3`,
    },
    {
      voiceName: "YunxiNeuralCheerful",
      locale: "zh-cn",
      displayName: t(TTSVoiceI18n.displayName, { context: "YunxiNeuralCheerful" }),
      description: t(TTSVoiceI18n.description, { context: "YunxiNeuralCheerful" }),
      avatar: YunxiNeuralCheerfulAvatar,
      language: t(TTSVoiceI18n.language, { context: "Chinese" }),
      auditionSrc: `${audioHost}/yunxineuralcheerful.mp3`,
    },
    {
      voiceName: "XiaoxiaoNeuralNewscast",
      locale: "zh-cn",
      displayName: t(TTSVoiceI18n.displayName, { context: "XiaoxiaoNeuralNewscast" }),
      description: t(TTSVoiceI18n.description, { context: "XiaoxiaoNeuralNewscast" }),
      avatar: XiaoxiaoNeuralNewscastAvatar,
      language: t(TTSVoiceI18n.language, { context: "Chinese" }),
      auditionSrc: `${audioHost}/xiaoxiaoneuralnewscast.mp3`,
    },
    {
      voiceName: "JennyNeural",
      locale: "en",
      displayName: t(TTSVoiceI18n.displayName, { context: "JennyNeural" }),
      description: t(TTSVoiceI18n.description, { context: "JennyNeural" }),
      avatar: JennyNeuralAvatar,
      language: t(TTSVoiceI18n.language, { context: "English" }),
      auditionSrc: `${audioHost}/jennyneural.mp3`,
    },
    {
      voiceName: "GuyNeural",
      locale: "en",
      displayName: t(TTSVoiceI18n.displayName, { context: "GuyNeural" }),
      description: t(TTSVoiceI18n.description, { context: "GuyNeural" }),
      avatar: GuyNeuralAvatar,
      language: t(TTSVoiceI18n.language, { context: "English" }),
      auditionSrc: `${audioHost}/guyneural.mp3`,
    },
    {
      voiceName: "JennyNeuralNewscast",
      locale: "en",
      displayName: t(TTSVoiceI18n.displayName, { context: "JennyNeuralNewscast" }),
      description: t(TTSVoiceI18n.description, { context: "JennyNeuralNewscast" }),
      avatar: JennyNeuralNewscastAvatar,
      language: t(TTSVoiceI18n.language, { context: "English" }),
      auditionSrc: `${audioHost}/jennyneuralnewscast.mp3`,
    },
    {
      voiceName: "GuyNeuralNewscast",
      locale: "en",
      displayName: t(TTSVoiceI18n.displayName, { context: "GuyNeuralNewscast" }),
      description: t(TTSVoiceI18n.description, { context: "GuyNeuralNewscast" }),
      avatar: GuyNeuralNewscastAvatar,
      language: t(TTSVoiceI18n.language, { context: "English" }),
      auditionSrc: `${audioHost}/guyneuralnewscast.mp3`,
    },
  ];
  return voices;
}
