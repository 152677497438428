import React, { useEffect } from "react";
import {
  Callout,
  Icon,
  IconButton,
  Target,
  DirectionalHint,
  Layer,
  mergeStyles,
  useTheme,
} from "@fluentui/react";
import "./CopyCallout.scss";
import { copyToClip, ShareInfo } from "../../services/share";
import { useTranslation } from "react-i18next";
import { CopyCalloutI18n } from "../../locales";
import { isMobliePhone } from "../../config/browser";
import { IconName } from "../../config/icons";
import CopyMessageBar from "./CopyMessageBar";

export interface CopyCalloutProps {
  target?: Target;
  item?: ShareInfo | string;
  directionalHint?: DirectionalHint;
  onDismiss: () => void;
}

function CopyComponent({ target, item, directionalHint, onDismiss }: CopyCalloutProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const isShareInfo = !!(item && typeof item === "object");
  // 立即复制
  useEffect(() => {
    if (item) {
      if (isShareInfo) {
        item.shareLink && copyToClip(item);
      } else {
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(item);
        } else {
          const copyInput = document.createElement("textarea");
          copyInput.value = item;
          document.body.appendChild(copyInput);
          copyInput.select();
          document.execCommand("copy");
          document.body.removeChild(copyInput);
        }
      }
      if (isMobliePhone && onDismiss) {
        const t = 2000;
        const timer = setTimeout(onDismiss, t);
        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, isShareInfo]);

  if (isMobliePhone) {
    const content = <CopyMessageBar hidden={!item}></CopyMessageBar>;
    return item ? <Layer>{content}</Layer> : null;
  }
  const titleLimit = 10;
  const title = isShareInfo ? item?.title : undefined;

  return !item ? null : (
    <Callout
      onDismiss={onDismiss}
      className={mergeStyles("CopyCallout", {
        boxShadow: theme.effects.elevation8,
      })}
      target={target}
      isBeakVisible={false}
      gapSpace={1}
      directionalHint={directionalHint ? directionalHint : DirectionalHint.bottomAutoEdge}
    >
      <div className="CopyCallout-body">
        <IconButton
          iconProps={{ iconName: IconName.Cancel }}
          title="Cancel"
          ariaLabel="Cancel"
          onClick={onDismiss}
          className="CopyCallout-body_cancel"
        />
        <Icon
          className="CopyCallout-body_success"
          styles={{ root: { color: theme.semanticColors.successIcon } }}
          iconName={IconName.CompletedSolid}
        />
        <span className="CopyCallout-body_describe">
          {t(
            CopyCalloutI18n.description,
            !!(item && !isShareInfo)
              ? { context: "error" }
              : {
                  title: `${
                    title && title.length > titleLimit ? `${title.slice(0, titleLimit)}...` : title
                  }`,
                }
          )}
        </span>
      </div>
    </Callout>
  );
}
const CopyCallout = React.memo(CopyComponent, (pre, next) => {
  if (pre?.item && typeof pre.item === "object" && next?.item && typeof next?.item === "object") {
    return pre.item?.shareLink === next.item?.shareLink;
  }
  return pre.item === next?.item;
});

export default CopyCallout;
