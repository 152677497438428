import { Dialog, DialogFooter, PrimaryButton, Text, Image, makeStyles } from "@fluentui/react";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import voiceAI from "../../img/voice_ai.png";
import { rem } from "../../lib/unit";
import { AdministratorGuide } from "../../locales";

const useCurrentStyles = makeStyles({
  textTitle: { margin: `${rem(27)} 0 ${rem(19)}`, fontWeight: "bold" },
  textContent: { whiteSpace: "pre-wrap" },
  textFooter: { whiteSpace: "pre-wrap", paddingBottom: rem(16) },
  img: { width: "100%", "& img": { width: "100%" } },
});

export default function AdministratorGuideAIAudioDialog({
  onDismiss,
}: {
  onDismiss?: () => void;
}): React.ReactElement {
  const [hidden, setHidden] = useState(false);
  const clickHidden = useCallback(() => {
    setHidden(true);
    if (onDismiss) onDismiss();
  }, [setHidden, onDismiss]);
  const { t } = useTranslation();
  const currentStyles = useCurrentStyles();

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        title: <Image src={voiceAI} className={currentStyles.img} />,
        titleProps: { style: { padding: 0 } },
        styles: {
          inner: {
            height: `${rem(288)}`,
          },
        },
        showCloseButton: true,
      }}
      modalProps={{ isBlocking: true }}
    >
      <Text variant="large" block className={currentStyles.textTitle}>
        {t(AdministratorGuide.voiceAITitle)}
      </Text>
      <Text className={currentStyles.textContent}>{t(AdministratorGuide.voiceAITipText)}</Text>
      <Text block className={currentStyles.textFooter}>
        {t(AdministratorGuide.voiceAIFooter)}
      </Text>
      <DialogFooter>
        <PrimaryButton text={t(AdministratorGuide.confirmBtn)} onClick={clickHidden} />
      </DialogFooter>
    </Dialog>
  );
}
