/* eslint-disable no-bitwise */
/**
 * 文档所有权限定义
 */
export enum DocumentPermission {
  /**
   * 允许查看
   */
  view = 0b0001,

  /**
   * 评论文档
   */
  comment = 0b0010,

  /**
   * 允许下载
   */
  save = 0b0100,

  /**
   * 允许转发/分享
   */
  forward = 0b1000,

  /**
   * 允许获取文档路径
   */
  path = 0b0001_0000,

  /**
   * 允许复制
   */
  copy = 0b0010_0000,

  /// /////////////////////////////////////
  /// 特殊定义
  /// ////////////////////////////////////

  /**
   * 无权限
   */
  None = 0,

  /**
   * 完全公开权限？拥有所有
   */
  Public = view | comment | save | forward | path,

  /**
   * 文档可分享
   */
  Shareable = forward | path | save,
  /**
   * 部分人可见
   */
  // Part = comment | save | view,
}

export enum DocumentStatus {
  Public = 0,
  Private = 1,
}

export function isShareable(permission: DocumentPermission | undefined | null) {
  return (permission! & DocumentPermission.Shareable) === DocumentPermission.Shareable;
}

export function isPublicDoc(
  permission: DocumentPermission | undefined | null,
  status: DocumentStatus | undefined | null
) {
  return permission === DocumentPermission.Public && status === DocumentStatus.Public;
}

export enum ResourcePermission {
  /**
   * 查看folder内容
   */
  read = 0b0001,

  /**
   * 更新folder内容
   */
  write = 0b0010,

  /**
   * 删除folder内容
   */
  delete = 0b0100,

  /**
   * 授权其他人
   */
  grant = 0b1000,

  /**
   * 导出报告
   */
  export = 0b10000,

  /**
   * 所有权限
   */
  all = 0b11111,
}
