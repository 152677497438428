import React, { useState, useEffect, createContext, useContext } from "react";

function getSize() {
  return { height: window.innerHeight, width: window.innerWidth };
}

const sizeContext = createContext(getSize());

export function WindowSizeProvider({
  children,
  throttle = 250,
}: React.PropsWithChildren<{ throttle?: number }>) {
  const [value, setValue] = useState(getSize());

  useEffect(() => {
    const update = () => setValue(getSize());
    let startTime = performance.now();
    let id = 0;
    let timer = 0;
    const delayUpdate = () => {
      clearTimeout(timer);
      const now = performance.now();
      if (now > startTime + throttle) {
        startTime = now;

        id = window.requestAnimationFrame(update);
      } else {
        timer = window.setTimeout(update, throttle);
      }
    };
    window.addEventListener("resize", delayUpdate);
    return () => {
      window.removeEventListener("resize", delayUpdate);
      window.cancelAnimationFrame(id);
      clearTimeout(timer);
    };
  }, [throttle]);
  return <sizeContext.Provider value={value}>{children}</sizeContext.Provider>;
}

/**
 * 获取当前窗口大小
 */
export function useWindowSize() {
  return useContext(sizeContext);
}
