import { useMemo, useState, useEffect } from "react";
import { getStorage, setStorage } from "../services/storage";
import { Flag, flagDependency } from "../config/flag";
import { Subject } from "rxjs";

type FlagState = true | null;

/**
 * useFlagStatus状态，true是可以显示，null是前提未满足，false是满足条件但是已经触发过
 */
type FlagStatus = true | null | false;

const storageKey = "flag";

const states: { [key in Flag]?: FlagState } = {};

const subject = new Subject<Flag>();

function getFlagTrue(flag: Flag) {
  if (!(flag in states)) states[flag] = getStorage(storageKey, flag);
  return states[flag] === null;
}

function getFlagState(flag: Flag): FlagStatus {
  getFlagTrue(flag);
  if (flagDependency[flag]) {
    const isComplete = !flagDependency[flag]?.some(flag => getFlagTrue(flag));
    return isComplete ? !states[flag] : false;
  }
  return !states[flag];
}

function setFlagState(flag: Flag) {
  states[flag] = true;
  setStorage(storageKey, true, flag);
  subject.next(flag);
}

/**
 * set
 * @param flag Flag
 * @returns [value, setValue]
 */
export function useFlagStatus(flag: Flag) {
  const [value, setValue] = useState<FlagStatus>(() => getFlagState(flag));

  useEffect(() => {
    const subscriber = subject.subscribe(updateFlag => {
      if (flagDependency[flag]?.includes(updateFlag)) {
        setValue(getFlagState(flag));
      }
    });
    return () => subscriber.unsubscribe();
  }, [flag]);

  return useMemo<[isEnabled: FlagStatus, disable: () => void]>(
    () => [
      value,
      () => {
        setValue(false);
        setFlagState(flag);
      },
    ],
    [flag, value]
  );
}
