import { useEffect, useMemo, useState } from "react";
import { Shimmer, makeStyles } from "@fluentui/react";

const useStyles = makeStyles({
  root: {
    height: "20px",
    padding: "10px 0",
  },
  dataWrapper: {
    height: "20px",
  },
  shimmerWrapper: {
    height: "20px",
  },
});

const lineHeight = 40;

const widthTypeNum = 3;

const showShimmer = 0.8;

export default function HomeNavShimmer({ className }: { className?: string }) {
  const styles = useStyles();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [num, setNum] = useState(0);

  const shimmers = useMemo(() => {
    const result = new Array<JSX.Element>(num);
    for (let i = 0; i < num; i++) {
      const type = i % widthTypeNum;
      result[i] = (
        <Shimmer key={i} styles={styles} width={type === 0 ? "100%" : type === 1 ? "75%" : "50%"} />
      );
    }
    return result;
  }, [num, styles]);

  useEffect(() => {
    const listener = () => ref && setNum(Math.floor((ref.offsetHeight * showShimmer) / lineHeight));
    listener();
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [ref]);

  return (
    <div className={className} ref={ref => setRef(ref)}>
      {shimmers}
    </div>
  );
}
