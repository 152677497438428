/* eslint-disable @dragongate/no-magic-numbers */
import React, { useMemo } from "react";
import {
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Selection,
  ShimmeredDetailsList,
  Link,
  CheckboxVisibility,
} from "@fluentui/react";
import { FileIconType } from "@fluentui/react-file-type-icons";
import { DriveItem } from "@microsoft/microsoft-graph-types";
import FileTypeIcon from "../common/FileTypeIcon";
import { getFileSize } from "../../lib/fileSize";
import { useTranslation } from "react-i18next";
import { FilePicker } from "../../locales";
import { useCustomizeTimeCallback } from "../../hooks/useCustomizeTime";

function useColumns(onItemInvoked?: (item?: DriveItem, index?: number, ev?: Event) => void) {
  const { t } = useTranslation();
  const customizeTime = useCustomizeTimeCallback();
  return useMemo<IColumn[]>(
    () => [
      {
        key: "fileType",
        name: t(FilePicker.type),
        ariaLabel: "Column operations for File type, Press to sort on File type",
        fieldName: "name",
        minWidth: 16,
        maxWidth: 40,
        isCollapsible: false,
        onRender: ({ name, folder }: DriveItem) => (
          <FileTypeIcon fileName={name!} type={folder! && FileIconType.folder} />
        ),
      },
      {
        key: "fileName",
        name: t(FilePicker.name),
        fieldName: "name",
        minWidth: 90,
        maxWidth: 400,
        // isRowHeader: true,
        // isPadded: true,
        isResizable: false,
        isCollapsible: true,
        data: "string",
        onRender(item: any, index) {
          const name = item.name;
          if (item.folder) {
            return (
              <Link
                title={name}
                onClick={onItemInvoked && (ev => onItemInvoked(item, index, ev as any as Event))}
              >
                {item.name}
              </Link>
            );
          }
          return <span title={name}>{name}</span>;
        },
      },
      {
        key: "lastModifiedDateTime",
        name: t(FilePicker.modified),
        fieldName: "lastModifiedDateTime",
        minWidth: 70,
        maxWidth: 90,
        data: "string",
        isCollapsible: true,
        onRender: (item: DriveItem) => customizeTime(item.lastModifiedDateTime),
      },
      {
        key: "modifiedUser",
        name: t(FilePicker.modifiedBy),
        fieldName: "lastModifiedBy",
        minWidth: 60,
        maxWidth: 150,
        isResizable: false,
        isCollapsible: true,
        data: "object",
        onRender: (item: DriveItem) => {
          const name = item.lastModifiedBy?.user?.displayName;
          return <span title={name!}> {name}</span>;
        },
        isPadded: true,
      },
      {
        key: "size",
        name: t(FilePicker.size),
        fieldName: "size",
        minWidth: 50,
        maxWidth: 100,
        isCollapsible: false,
        data: "number",
        onRender: ({ size }: DriveItem) => size && getFileSize(size, { round: 1 }),
      },
    ],
    [customizeTime, onItemInvoked, t]
  );
}
export interface FileListProps {
  className?: string;
  items?: DriveItem[];
  onItemInvoked?: (item?: DriveItem, index?: number, ev?: Event) => void;
  onSelectedChange?: (items?: DriveItem[]) => void;
  enableShimmer?: boolean;
}

export default function FileList({
  className,
  items,
  onItemInvoked,
  onSelectedChange,
  enableShimmer,
}: FileListProps) {
  const columns = useColumns(onItemInvoked);
  const selection = useMemo(
    () =>
      new Selection({
        canSelectItem: (item: any) => !item.folder,
        selectionMode: SelectionMode.single,
        onSelectionChanged: onSelectedChange
          ? function onSelectionChanged(this: Selection<DriveItem>) {
              onSelectedChange(this.getSelection());
            }
          : undefined,
      }),
    [onSelectedChange]
  );
  return (
    <ShimmeredDetailsList
      className={className}
      items={items || []}
      onItemInvoked={onItemInvoked}
      selectionPreservedOnEmptyClick={true}
      enableShimmer={!items || enableShimmer}
      columns={columns}
      selection={selection}
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
      checkboxVisibility={CheckboxVisibility.hidden}
    />
  );
}
