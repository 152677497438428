import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdministratorGuide } from "../../locales";
import { DirectionalHint } from "@fluentui/react";
import UserGuidesBubble, { UserGuide } from "../bubble/UserGuidesBubble";
import { AdminUserGuideIds } from "../../config/rc";

export default function UploadOfficeFileBubble({ onDismiss }: { onDismiss?: () => void }) {
  const { t } = useTranslation();
  const items = useMemo<UserGuide[]>(
    () => [
      {
        target: `#${AdminUserGuideIds.uploadFile}`,
        directionalHint: DirectionalHint.bottomCenter,
        text: t(AdministratorGuide.officeUploadGuideContent),
        headline: t(AdministratorGuide.officeUploadGuideTitle),
      },
    ],
    [t]
  );

  return <UserGuidesBubble items={items} onDismiss={onDismiss} />;
}
