import {
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogProps,
  makeStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  TextField,
  Text,
} from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrincipalsDetail } from "../../hooks/rc/usePrincipalsDetail";
import { rem } from "../../lib/unit";
import { CategoryDialogI18n } from "../../locales";
import {
  addCategory,
  deleteCategory,
  patchCategory,
  putFeedsCategoryConfig,
} from "../../services/rc/category";
import { Category, Principal, PrincipalType } from "../../services/request";
import { FontSizes } from "../../themes/fonts";
import RCPeoplePicker from "../picker/RCPeoplePicker";
import { createPersonasFromGroups, createPersonasFromUsers, RCPersonaProps } from "../rc/RCPersona";
import DeleteDialog from "./DeleteDialog";
// import "./Dialog.scss";

enum DialogSize {
  large = "LargeDialog",
  small = "SmallDialog",
}

enum VisibleScope {
  everyone = "everyone",
  part = "part",
  admin = "admin",
}

const everyOnePrincipal = {
  principalType: PrincipalType.everyone,
  principalId: "00000000-0000-0000-0000-000000000000",
};

const useCategoryDialogTextStyle = makeStyles(theme => ({
  root: {
    overflowX: "hidden",
    border: "0",
    selectors: {
      ".ms-TextField-fieldGroup": {
        fontSize: FontSizes.xLargePlus,
        height: rem(42),
        borderRadius: "0",
        background: "transparent",
        selectors: {
          ".ms-TextField-field": {
            border: "0",
            fontWeight: "bold",
            fontSize: "inherit",
            padding: "0",
            height: "100%",
          },
          ".ms-TextField-field::placeholder": {
            fontWeight: "normal",
            fontSize: "inherit",
            lineHeight: rem(32),
            color: theme.palette.neutralTertiaryAlt,
          },
        },
      },
      "&.is-active": {
        selectors: {
          ".ms-TextField-fieldGroup": {
            borderWidth: "0 0 2px 0",
            borderBottomColor: theme.palette.themePrimary,
          },
        },
      },
    },
  },
  fieldGroup: [
    {
      borderWidth: "0 0 2px 0",
    },
  ],
}));

interface CatagoryDialogSettings {
  dialogSize?: DialogSize;
  dialogTitleDisabled?: boolean;
  dialogTitle?: string;
  showTitlePart?: boolean;
  showPermissionPart?: boolean;
  showDeletePart?: boolean;
  showTitleTip?: boolean;
}

export enum CategoryActionType {
  editSubCategory = "editSubCategory",
  editCategory = "editCategory",
  addCategory = "addCategory",
  delete = "delete",
  addSubCategory = "addSubCategory",
  blank = "blank",
  editFeedsConfig = "editFeedsConfig",
}
export interface CategoryDialogProps {
  category?: Category;
  onDismiss?: () => void;
  onConfirm?: (actionType: CategoryActionType) => void;
  onActionSuccess?: (actionType: CategoryActionType, data: unknown, categoryId?: string) => void;
  onActionFailed?: (actionType: CategoryActionType, categoryId?: string) => void;
  actionType: CategoryActionType;
  hidden: boolean;
}

function checkInputStatus(
  actionType: CategoryActionType,
  title?: string,
  scope?: VisibleScope,
  visibilityScopes?: Principal[]
): boolean {
  switch (actionType) {
    case CategoryActionType.addCategory:
    case CategoryActionType.addSubCategory:
    case CategoryActionType.editCategory:
    case CategoryActionType.editFeedsConfig:
    case CategoryActionType.editSubCategory:
      return (
        !!title &&
        !!(scope === VisibleScope.part
          ? visibilityScopes && visibilityScopes.length !== 0
          : visibilityScopes)
      );
    default:
      return false;
  }
}

export default function CategoryDialog({
  category,
  onDismiss,
  onConfirm,
  onActionSuccess,
  onActionFailed,
  hidden,
  actionType,
}: CategoryDialogProps) {
  const { t } = useTranslation();
  const [scope, setScope] = useState<VisibleScope>();
  const [visibilityScopes, setVisibilityScopes] = useState<Principal[]>([]);
  const [currentSelectedItems, setCurrentSelectedItems] = useState<RCPersonaProps[]>([]);
  const [title, setTitle] = useState("");
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const textStyles = useCategoryDialogTextStyle();
  const toResolvePrincipals = useMemo(
    () =>
      visibilityScopes.filter(
        item => currentSelectedItems.filter(i => i.id === item.principalId).length === 0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibilityScopes]
  );
  const { users, groups } = usePrincipalsDetail(toResolvePrincipals);
  const prePersonasList = useMemo(
    () => [...createPersonasFromUsers(users), ...createPersonasFromGroups(groups)],
    [users, groups]
  );
  const {
    dialogSize,
    dialogTitle,
    showTitlePart,
    showPermissionPart,
    dialogTitleDisabled,
    showDeletePart,
    showTitleTip,
  } = useMemo(() => {
    let dialogSettings: CatagoryDialogSettings = {};
    switch (actionType) {
      case CategoryActionType.addCategory:
      case CategoryActionType.editCategory:
        dialogSettings = {
          dialogTitle: t(CategoryDialogI18n.dialogTitle, { context: actionType }),
          dialogSize: DialogSize.small,
          showTitlePart: true,
        };
        break;
      case CategoryActionType.addSubCategory:
      case CategoryActionType.editSubCategory:
        dialogSettings = {
          dialogTitle: t(CategoryDialogI18n.dialogTitle, { context: actionType }),
          dialogSize: DialogSize.large,
          showTitlePart: true,
          showPermissionPart: true,
        };
        break;
      case CategoryActionType.delete:
        dialogSettings = {
          dialogTitle: t(CategoryDialogI18n.dialogTitle, { context: actionType }),
          showDeletePart: true,
        };
        break;
      case CategoryActionType.editFeedsConfig:
        dialogSettings = {
          dialogSize: DialogSize.large,
          dialogTitle: t(CategoryDialogI18n.dialogTitle, { context: actionType }),
          dialogTitleDisabled: true,
          showTitlePart: true,
          showPermissionPart: true,
          showTitleTip: true,
        };
        break;
    }
    return dialogSettings;
  }, [actionType, t]);

  useEffect(() => {
    switch (actionType) {
      case CategoryActionType.editSubCategory:
      case CategoryActionType.editFeedsConfig:
      case CategoryActionType.editCategory:
      case CategoryActionType.delete:
        setCurrentSelectedItems([]);
        setTitle(category?.title ?? "");
        if (category?.visibilityScopes?.length === 0) {
          setScope(VisibleScope.admin);
          setVisibilityScopes([]);
        } else if (
          category?.visibilityScopes?.findIndex(
            item => item.principalType === PrincipalType.everyone
          ) !== -1
        ) {
          setScope(VisibleScope.everyone);
          setVisibilityScopes([everyOnePrincipal]);
        } else {
          setScope(VisibleScope.part);
          setVisibilityScopes([
            ...category.visibilityScopes.filter(
              i => i.principalId !== everyOnePrincipal.principalId
            ),
          ]);
        }
        break;
      default:
        setTitle("");
        setScope(VisibleScope.everyone);
        setVisibilityScopes([everyOnePrincipal]);
        setCurrentSelectedItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, actionType]);

  useEffect(() => {
    setConfirmDisabled(!checkInputStatus(actionType, title, scope, visibilityScopes));
  }, [actionType, title, scope, visibilityScopes]);

  const dialogProps: IDialogProps = {
    hidden,
    onDismiss,
    dialogContentProps: {
      type: DialogType.normal,
      title: dialogTitle,
      styles: {
        inner: {
          height: dialogSize === DialogSize.small ? rem(200) : rem(500),
        },
      },
    },
    modalProps: {
      isBlocking: true,
    },
  };
  return showDeletePart ? (
    <DeleteDialog
      hidden={hidden}
      title={dialogTitle!}
      subText={t(CategoryDialogI18n.deleteAlert)}
      onDismiss={onDismiss}
      doDelete={() => {
        onDismiss && onDismiss();
        category &&
          deleteCategory(category.id)
            .then(res => {
              onActionSuccess && onActionSuccess(actionType, res.data, category.id);
            })
            .catch(() => {
              onActionFailed && onActionFailed(actionType, category.id);
            })
            .finally(() => {
              onConfirm && onConfirm(actionType);
            });
      }}
    ></DeleteDialog>
  ) : (
    <Dialog {...dialogProps}>
      <Stack>
        {showTitlePart && (
          <>
            <TextField
              required
              disabled={!!dialogTitleDisabled}
              placeholder={t(CategoryDialogI18n.namePlaceholder)}
              label={t(CategoryDialogI18n.nameLabel)}
              onChange={(e, v) => {
                setTitle(v ?? "");
              }}
              styles={textStyles}
              value={title}
            />
            {showTitleTip && (
              <Text
                className={mergeStyles({
                  fontSize: FontSizes.small,
                  lineHeight: rem(16),
                  marginTop: rem(2),
                })}
              >
                {t(CategoryDialogI18n.nameAlert)}
              </Text>
            )}
          </>
        )}
        {showPermissionPart && (
          <>
            <Dropdown
              styles={{
                root: {
                  marginTop: rem(30),
                },
                title: {
                  height: rem(34),
                },
              }}
              label={t(CategoryDialogI18n.scopeLabel)}
              selectedKey={scope}
              onChange={(event, option) => {
                const scope = `${option?.key}` as VisibleScope;
                switch (scope) {
                  case VisibleScope.everyone:
                    setScope(scope);
                    setVisibilityScopes([everyOnePrincipal]);
                    break;
                  case VisibleScope.admin:
                    setScope(scope);
                    setVisibilityScopes([]);
                    break;
                  case VisibleScope.part:
                    setScope(scope);
                    if (category?.visibilityScopes) {
                      setVisibilityScopes([
                        ...category.visibilityScopes.filter(
                          i => i.principalId !== everyOnePrincipal.principalId
                        ),
                      ]);
                    } else {
                      setVisibilityScopes([]);
                    }
                }
              }}
              options={
                actionType === CategoryActionType.editFeedsConfig
                  ? [
                      {
                        key: VisibleScope.everyone,
                        text: t(CategoryDialogI18n.visibleToAll),
                      },
                      {
                        key: VisibleScope.admin,
                        text: t(CategoryDialogI18n.visibleToAdmin),
                      },
                    ]
                  : [
                      {
                        key: VisibleScope.everyone,
                        text: t(CategoryDialogI18n.visibleToAll),
                      },
                      {
                        key: VisibleScope.part,
                        text: t(CategoryDialogI18n.visibleToScope),
                      },
                      {
                        key: VisibleScope.admin,
                        text: t(CategoryDialogI18n.visibleToAdmin),
                      },
                    ]
              }
            ></Dropdown>
            {scope === VisibleScope.part && (
              <RCPeoplePicker
                className={mergeStyles({
                  marginTop: rem(16),
                })}
                label={t(CategoryDialogI18n.peoplePickerLabel)}
                currentSelectedItems={currentSelectedItems.concat(prePersonasList)}
                onChanged={personas => {
                  setCurrentSelectedItems(personas ?? []);
                  setVisibilityScopes(
                    personas?.map(item => ({
                      principalId: item.id!,
                      principalType: item.principalType!,
                    })) ?? []
                  );
                }}
              ></RCPeoplePicker>
            )}
          </>
        )}
      </Stack>
      <DialogFooter>
        <PrimaryButton
          className="content-button"
          disabled={confirmDisabled}
          onClick={() => {
            onDismiss && onDismiss();
            let promise;
            switch (actionType) {
              case CategoryActionType.addCategory:
                promise = addCategory(title, [everyOnePrincipal]);
                break;
              case CategoryActionType.addSubCategory:
                promise = addCategory(title, [...visibilityScopes], category?.id);
                break;
              case CategoryActionType.editSubCategory:
              case CategoryActionType.editCategory:
                promise =
                  category &&
                  patchCategory(category.id, {
                    nodeETag: category.nodeETag,
                    title,
                    visibilityScopes,
                  });
                break;
              case CategoryActionType.editFeedsConfig:
                promise =
                  category &&
                  putFeedsCategoryConfig(category.id, {
                    visibilityScopes,
                  });
                break;
            }
            promise &&
              promise
                .then(res => {
                  onActionSuccess && onActionSuccess(actionType, res.data, category?.id);
                })
                .catch(() => {
                  onActionFailed && onActionFailed(actionType, category?.id);
                })
                .finally(() => {
                  onConfirm && onConfirm(actionType);
                });
          }}
          text={t(CategoryDialogI18n.confirmButton)}
        />
      </DialogFooter>
    </Dialog>
  );
}
