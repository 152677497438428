/* eslint-disable @dragongate/no-magic-numbers */
import { Image, ImageFit, PrimaryButton } from "@fluentui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import aboutPart1Img from "../img/about_part1.png";
import aboutPart3Img from "../img/about_part3.png";
import microsoftIcon from "../img/icons/microsoft.svg";
import icon1 from "../img/icons/about_icon1.svg";
import icon2 from "../img/icons/about_icon2.svg";
import rclogo from "../img/icons/rclogo.svg";
import verticalImg from "../img/vertical_bar.png";
import { AboutPageI18n, AppDeployment } from "../locales/namespace";
// import { ADMINCENTER_DOC_URL } from "../config/constant";
import "./About.scss";

export const AboutPage: React.FC = () => {
  const { t } = useTranslation();
  const designWidth = 1366;
  const fullWidth = 100;
  const vw = (pix: number) => `${(pix / designWidth) * fullWidth}vw`;
  return (
    <div className="About">
      <div className="About-org">
        <div className="About-org_microsoft">
          <Image src={microsoftIcon} width={vw(107)} height={vw(20)} />
        </div>
        <Image src={verticalImg} className="About-org_vertical" alt=""></Image>
        <span className="About-org_title">{t(AboutPageI18n.title)}</span>
      </div>
      <div className="About-logo">
        <div className="About-logo_icon">
          <Image src={rclogo} width={vw(64)} height={vw(64)} />
        </div>
        <div className="About-logo_text">
          <span className="About-logo_title">{t(AboutPageI18n.title)}</span>
          <span className="About-logo_description"> {t(AboutPageI18n.description)}</span>
        </div>
      </div>
      <div className="About-part1">
        <div className="About-part1_text">
          <Trans
            i18nKey={AboutPageI18n.welcomeDescription}
            components={[
              <span className="About-part1_pre">Welcome to</span>,
              <span className="About-part1_mid">Resource Center</span>,
              <span className="About-part1_end"></span>,
            ]}
          ></Trans>
          <PrimaryButton
            onClick={() => {
              window.location.href = "#";
            }}
          >
            {t(AppDeployment.messageBarBtn)}
          </PrimaryButton>
        </div>
        <Image
          src={aboutPart1Img}
          className="About-part1_img"
          alt=""
          imageFit={ImageFit.centerContain}
        ></Image>
      </div>
      <div className="About-part2">
        <div className="About-part2_container">
          <Image src={icon1} width={vw(64)} height={vw(64)} />
          <span className="About-part2_description"> {t(AboutPageI18n.icon1Description)}</span>
        </div>
        <div className="About-part2_container">
          <Image src={icon2} width={vw(64)} height={vw(64)} />
          <span className="About-part2_description">{t(AboutPageI18n.icon2Description)}</span>
        </div>
      </div>
      <div className="About-part3">
        <Image
          src={aboutPart3Img}
          className="About-part3_img"
          alt=""
          imageFit={ImageFit.centerContain}
        ></Image>
        <div className="About-part3_container">
          <Trans
            i18nKey={AboutPageI18n.contactHint}
            components={[
              <a
                href="mailto:dgmkt@microsoft.com?subject=[ResourceCenter]"
                className="About-part3_mail"
              >
                email
              </a>,
            ]}
          ></Trans>
        </div>
      </div>
      <div className="About-foot">
        <span>© Microsoft 2020</span>
      </div>
    </div>
  );
};
