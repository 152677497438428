export interface MediaMap {
  [index: string]: CDS.Medium;
}

function parseMediaMap(media: CDS.Medium[]) {
  return media.reduce<MediaMap>((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
}
/**
 * 文档基本信息
 */
export class BaseDocumentModel {
  public readonly id?: string;

  /**
   * 文档信息
   */
  public readonly metadata: CDS.DocumentMeta;

  /**
   * 文档扩展属性
   */
  public readonly extended: CDS.DocumentExtended;

  /**
   * 统计信息
   */
  public readonly stats?: CDS.DocumentStats;

  /**
   * 私有文件得访问Token
   */
  // public readonly readToken?: {
  //   token: string;
  //   expiresOn: number;
  // };

  public readonly author?: CDS.User;

  /**
   * 所属bundle
   */
  public readonly bundle?: CDS.Bundle;

  protected readonly mediaMap: MediaMap;

  constructor(doc: CDS.Document) {
    this.id = doc.id;
    this.metadata = doc.metadata! || {};
    this.extended = doc.extended || {};
    this.stats = doc.stats;
    this.author = doc.author || doc.owner || doc.creator;
    this.bundle = doc.bundle;
    const media = doc.content?.media;
    this.mediaMap = media ? parseMediaMap(media) : {};
  }

  /**
   * 查询资源默认URL
   * @param mediaId
   */
  public getMediaUrl(mediaId: string) {
    const m = this.mediaMap[mediaId];
    console.assert(m, `${mediaId} should not be empty media`);
    return m && (m.urls?.[0] || m.data);
  }
}
