import AuthenticationContext from "adal-angular";
import { isLimited, isTeamsApp } from "../../config/app";
import { AAD_CLIENT_ID, ID_TOKEN, PUBLIC_URL } from "../../config/config";
import { guestCancelled, ssoAuth } from "../teams";

/**
 * 获取ID token 自动维护缓存
 */
export function getIdToken(silent?: boolean): Promise<string> {
  if (!isTeamsApp && ID_TOKEN) {
    console.debug("using mock id token", ID_TOKEN);
    return Promise.resolve(ID_TOKEN);
  }
  if (isLimited) {
    return Promise.reject(guestCancelled);
  }

  return ssoAuth(silent);
}

let idTokenADALPromise: Promise<string> | undefined;

/**
 * 通过ADAL 获取ID token
 */
export function getIdTokenADAL(
  userPrincipalName: string,
  tenant?: string,
  scope?: string
): Promise<string> {
  if (!idTokenADALPromise) {
    idTokenADALPromise = new Promise((resolve, reject) => {
      const config: AuthenticationContext.Options = {
        clientId: AAD_CLIENT_ID!,
        tenant: tenant || "common",
        redirectUri: `${window.location.origin}${PUBLIC_URL}/auth/silent-end.html`, // This should be in the list of redirect uris for the AAD app
        cacheLocation: "localStorage" as "localStorage",
        navigateToLoginRequestUrl: true,
        extraQueryParameter: `scope=${scope ?? "OpenId"}&login_hint=${userPrincipalName}`,
      };
      const authContext = new AuthenticationContext(config);
      sessionStorage.setItem("adal_config", JSON.stringify(config));
      // See if there's a cached user and it matches the expected user
      const user = authContext.getCachedUser();
      if (user) {
        if (user.userName !== userPrincipalName) {
          // User doesn't match, clear the cache
          authContext.clearCache();
        }
      }
      // Get the id token (which is the access token for resource = clientId)
      const token = authContext.getCachedToken(config.clientId);
      if (token) {
        idTokenADALPromise = undefined;
        return resolve(token);
      } else {
        (authContext as any)._renewIdToken((err: any, idToken: string) => {
          idTokenADALPromise = undefined;
          if (err) {
            console.error(`Renewal failed: ${err}`);
            reject(err);
          } else {
            resolve(idToken);
          }
        });
      }
    });
  }
  return idTokenADALPromise;
}
