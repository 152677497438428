import { DirectionalHint, TeachingBubble } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import deployImg from "../../img/teams/deploymentbubble.png";

import { AppDeployment } from "../../locales";

export default function AppDeploymentBubble({
  hidden,
  target,
  onDismiss,
}: {
  hidden: boolean;
  target: string | Element;
  onDismiss?: () => void;
}): React.ReactElement {
  const { t } = useTranslation();

  return hidden ? (
    <></>
  ) : (
    <TeachingBubble
      target={target}
      illustrationImage={{
        src: deployImg,
        shouldStartVisible: true,
        styles: { image: { width: "100%" } },
      }}
      onDismiss={onDismiss}
      calloutProps={{
        directionalHint: DirectionalHint.topCenter,
        preventDismissOnLostFocus: true,
      }}
      secondaryButtonProps={{ text: t(AppDeployment.bubbleBtn), onClick: onDismiss }}
    >
      {t(AppDeployment.bubbleContent)}
    </TeachingBubble>
  );
}
