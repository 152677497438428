import { Icon, IconButton, makeStyles, Shimmer, Text } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconName } from "../../config/icons";
import { timeFormat } from "../../lib/timeFormat";
import { rem } from "../../lib/unit";
import { TingPage } from "../../models/TingDoc";
import { useAudio } from "../../hooks/useAudio";
import { FontSizes } from "../../themes/fonts";
import { useFileToken } from "../../hooks/useFileToken";
import { logger } from "../../services/logger";
import AudioProgressBar from "./AudioProgressBar";
import { useTranslation } from "react-i18next";
import { AudioBarI18n } from "../../locales";

const useCss = makeStyles(theme => ({
  root: {
    width: "100%",
    // maxWidth: "90%",
    height: rem(50),
    borderRadius: rem(4),
    padding: rem(12),
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.neutralLight}`,
    backgroundColor: theme.palette.neutralLighterAlt,
  },
  warpper: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignContent: "space-around",
    alignItems: "center",
    columnGap: rem(5),
  },
  icon: {
    width: rem(20),
    maxWidth: "10%",
    fontSize: FontSizes.large,
    color: theme.palette.black,
  },
  indicator: {
    width: rem(20),
    maxWidth: "10%",
    color: theme.palette.neutralTertiary,
    "&.is-recording": {
      color: theme.palette.redDark,
    },
  },
  placeholder: {
    color: theme.palette.neutralTertiary,
  },
  duration: {
    maxWidth: "12%",
    margin: rem(5),
    "&.is-highlight": {
      color: theme.palette.redDark,
    },
  },
  tip: {
    color: theme.palette.neutralSecondary,
    display: "block",
    textAlign: "right",
    minHeight: rem(32),
    lineHeight: rem(32),
  },
}));

export interface AudioBarProps {
  maxAudioMilliSecond: number;
  audios?: TingPage["audios"];
  placeholder?: string;
  isRecording?: boolean;
  onRemove?: (id: string) => void;
  onPlayingStatusChange?: (isPlaying: boolean) => void;
}
export default function AudioBar({
  audios,
  placeholder,
  isRecording,
  maxAudioMilliSecond,
  onRemove,
  onPlayingStatusChange,
}: AudioBarProps) {
  const { t } = useTranslation();
  const { token } = useFileToken();
  const css = useCss();
  const [recordingDuration, setRecordingDuration] = useState(0);

  const { audioTicks, audioDuration, progressBarDuration } = useMemo(() => {
    let sum = 0;
    const sumArray = audios ? audios.map(val => (sum += val.duration)) : [];
    const lastSum = sumArray.slice(-1)[0];
    return {
      audioTicks: [0, ...sumArray.slice(0, -1)],
      audioDuration: lastSum ?? 0,
      progressBarDuration: lastSum > maxAudioMilliSecond ? lastSum : maxAudioMilliSecond,
    };
  }, [audios, maxAudioMilliSecond]);

  const audioSrcs = useMemo(
    () => (audios ? audios.map(a => a._localPath || `${a.data!}?${token}`) : ""),
    [audios, token]
  );
  const { audioIndex, isPlaying, play, pause, seek, currentTime } = useAudio(audioSrcs, {
    timeupdate: true,
    autoNext: true,
  });

  useEffect(() => {
    onPlayingStatusChange && onPlayingStatusChange(isPlaying);
  }, [isPlaying, onPlayingStatusChange]);

  // recording
  useEffect(() => {
    if (isRecording) {
      const oneMilliSecond = 1000;
      const timer = setInterval(() => {
        setRecordingDuration(pre => pre + oneMilliSecond);
      }, oneMilliSecond);
      return () => {
        setRecordingDuration(0);
        clearInterval(timer);
      };
    }
  }, [audioDuration, isRecording, maxAudioMilliSecond]);

  const progress = useMemo(
    // eslint-disable-next-line @dragongate/no-magic-numbers
    () => audioTicks[audioIndex] + currentTime * 1000,
    [audioIndex, audioTicks, currentTime]
  );

  const onClick = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      logger?.telemetry("ClickAudioBarPlayBtn", {
        AudioId: audios?.[audioIndex]?.id!,
        Duration: audios?.[audioIndex]?.duration!,
      });
      play();
    }
  }, [audioIndex, audios, isPlaying, pause, play]);

  const onRemoveClick = useCallback(() => {
    onRemove?.(audios?.slice(-1)[0]?.id!);
  }, [audios, onRemove]);

  return (
    <>
      <Text className={css.tip}>
        {
          // eslint-disable-next-line @dragongate/no-magic-numbers
          maxAudioMilliSecond - audioDuration <= 1000
            ? t(AudioBarI18n.exceedlimitTip)
            : isRecording
            ? t(AudioBarI18n.limitTip)
            : ""
        }
      </Text>
      <Shimmer
        className={css.root}
        styles={{ shimmerWrapper: css.warpper, dataWrapper: css.warpper }}
        isDataLoaded={!!audios}
      >
        {isRecording ? (
          <>
            <Icon className={`${css.indicator} is-recording`} iconName={IconName.LocationDot} />
            <AudioProgressBar
              audioTicks={audioDuration > 0 ? [...audioTicks, audioDuration] : audioTicks}
              audioDuration={recordingDuration + audioDuration}
              totalDuration={progressBarDuration}
              milliSecondComplete={0}
              disableSeek={true}
            />
            <Text
              className={`${css.duration} ${
                // eslint-disable-next-line @dragongate/no-magic-numbers
                maxAudioMilliSecond - recordingDuration - audioDuration <= 20000
                  ? "is-highlight"
                  : ""
              }`}
              variant="medium"
            >
              {timeFormat(recordingDuration + audioDuration)}
            </Text>
          </>
        ) : audios && audios.length ? (
          <>
            <IconButton
              className={css.icon}
              onClick={onClick}
              iconProps={{
                iconName: isPlaying ? IconName.CirclePauseSolid : IconName.MSNVideosSolid,
              }}
            />
            <AudioProgressBar
              audioTicks={audioTicks}
              audioDuration={audioDuration}
              totalDuration={progressBarDuration}
              milliSecondComplete={progress}
              onSeek={seek}
            />
            <Text className={css.duration} variant="medium">
              {`${timeFormat(progress)} / ${timeFormat(audioDuration)}`}
            </Text>
            <IconButton
              className={css.icon}
              iconProps={{ iconName: IconName.Backspace }}
              onClick={onRemoveClick}
            />
          </>
        ) : (
          <>
            <Icon className={css.indicator} iconName={IconName.LocationDot} />
            <Text className={css.placeholder} variant="medium">
              {placeholder}
            </Text>
          </>
        )}
      </Shimmer>
    </>
  );
}
