/**
 * 通知最大个数
 */
export const MAX_NOTIFICATION_NUM_MAX = 5;

/**
 * 合集类文档最多个数
 */
export const MAX_DOCS_NUM_IN_BUNDLE = 120;

/**
 * 封面图最大的体积大小bytes
 */
export const MAX_COVER_SIZE = 614400; // 600*1024

/**
 * 标题最大长度
 */
export const MAX_TITLE_LEN = 64;

/**
 * 合集描述最大长度
 */
export const MAX_BUNDLE_DESC_LEN = 256;

/**
 *
 */
export const NEW_TAG_CRITICAL_TIME = 1209600000; // 14 * 24 * 60 * 60 * 1000

/**
 * Feedback forms
 */
export const FEEDBACK_LINK =
  "https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR2d5RCr5h0lNm6aqtgTsRgdUNEgyVTNPVVQ0TkhZS1BMOUxSQkNTWU1RWi4u";

/**
 * app deployment doc
 */
export const APPDEPLOYMENT_DOC_URL =
  "https://rc-prod-verizon.o365cn.com/public/files/deploymentinstructions.pdf";

export const ADMINCENTER_DOC_URL =
  "https://rc-prod-verizon.o365cn.com/public/files/admincenterinstructions.pdf";

/**
 * Template category types mapping
 */
export const BUILDTIN_CATEGROY_MAPPING: Record<string, string> = {
  "16f5f4a5-f8d8-410f-8801-0ab6f6c9c2ee": "ppt-template",
  // "7b8c126d-9825-4e76-aafc-9b8c919520df": "ppt-template",
  "abf0f3bc-af7d-4a43-85f0-0d8819437dd9": "word-template",
  "2470119c-149c-4848-a6b9-5e002c5a31c7": "excel-template",
};

// 视频文件最大值
export const MAX_VIDEO_FILE_SIZE = 1073741824; // 1024*1024*1024 = 1G;
// 模板文件最大值
export const MAX_TEMPLATE_FILE_SIZE = 104857600; // 100*1024*1024 = 100M;

// EXCEL文件最大值
export const MAX_EXCEL_FILE_SIZE = 26214400; // 25*1024*1024 = 25M;
// PDF文件最大值
export const MAX_PDF_FILE_SIZE = 104857600; // 100*1024*1024 = 100M;
// PPT文件最大值
export const MAX_PPT_FILE_SIZE = 104857600; // 100*1024*1024 = 100M;
// WORD文件最大值
export const MAX_WORD_FILE_SIZE = 104857600; // 100*1024*1024 = 100M;
