import { useCallback, useState } from "react";
import {
  TextField,
  makeStyles,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  IconButton,
} from "@fluentui/react";
import ShimmerImage from "../common/ShimmerImage";
import { FontSizes } from "../../themes/fonts";
import { MediaQuery, rem } from "../../lib/unit";
import { useInputLengthValidation } from "../../hooks/useInputLengthValidation";
import { useFileInput } from "../../hooks/useFileInput";
import { IconName } from "../../config/icons";

import Css from "./BundleDetailCard.module.scss";
import { showDeleteDialog } from "../dialog/DeleteDialog";
import { useTranslation } from "react-i18next";
import { getFileSize } from "../../lib/fileSize";
import { BundlesEditorI18n, FilePicker } from "../../locales";
import { uploadBlob } from "../../services/request";
import { MAX_BUNDLE_DESC_LEN, MAX_COVER_SIZE, MAX_TITLE_LEN } from "../../config/constant";
import { useCancelToken } from "../../hooks/useCancelToken";

const useBundleEditorStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    height: rem(160),
    justifyContent: "center",
    alignItems: "center",
    padding: rem(16, 0),
    background: theme.palette.white,
    [MediaQuery.mobile]: {
      flexWrap: "wrap",
    },
  },
  cover: {
    position: "relative",
    width: rem(275),
    height: rem(154),
    overflow: "hidden",
    borderRadius: rem(4),
    flexShrink: 0,
  },
  coverIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: " translate(-50%, -50%)",
    width: rem(60),
    height: rem(60),
    borderRadius: "50%",
    color: theme.palette.white,
    justifyContent: "center",
    alignItems: "center",
    fontSize: FontSizes.xLargePlus,
    background: theme.palette.blackTranslucent40,
    cursor: "pointer",
    ":hover": {
      background: theme.palette.blackTranslucent40,
    },
  },
  imgShimmer: { width: rem(196.2), height: rem(104), " img": { objectFit: "cover" } },
  text: {
    maxWidth: "100%",
    width: rem(840),
    margin: rem(16),
    boxSizing: "border-box",
    alignItems: "center",
  },
  title: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: rem(16),
    ".ms-TextField-fieldGroup": { height: rem(45) },
    input: [theme.fonts.xLarge, { height: rem(45), lineHeight: rem(45) }],
  },
}));

export interface BundleDetailEditorProps {
  name?: string;
  isLoading?: boolean;
  onNameChange?: (name: string) => void;
  // defaultTitle?: string;
  cover?: string;
  onCoverChanged?: (f: string) => void;
  // defaultCover?: string;
  description?: string;
  onDescChange?: (name: string) => void;
}
export default function BundleDetailEditor({
  name,
  onNameChange,
  description,
  onDescChange,
  cover,
  onCoverChanged,
  isLoading,
}: BundleDetailEditorProps) {
  const styles = useBundleEditorStyles();
  const { t } = useTranslation();
  const [localCover, setLocalCover] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);
  const cancelToken = useCancelToken();
  const { openPicker } = useFileInput(files => {
    const file = files[0];
    const isTypeError = !/['image/png'|'image/jpeg']$/.test(file?.type);
    const isSizeError = (file?.size || 0) > MAX_COVER_SIZE;
    if (file && (isTypeError || isSizeError)) {
      showDeleteDialog({
        title: isTypeError
          ? t(FilePicker.coverTypeError)
          : t(FilePicker.coverSizeError, { size: getFileSize(MAX_COVER_SIZE) }),
        doDelete: openPicker,
        cancelButtonText: t(BundlesEditorI18n.repick),
        confirmButtonText: t(BundlesEditorI18n.ok),
      });
    } else {
      setLocalCover(URL.createObjectURL(file));
      setIsUploading(true);
      uploadBlob(file, { cancelToken: cancelToken.token })
        .then(res => {
          onCoverChanged?.(res.file);
        })
        .finally(() => setIsUploading(false));
    }
  }, "image/png, image/jpeg");
  const { onGetErrorMessage } = useInputLengthValidation(MAX_TITLE_LEN, true);
  return (
    <div className={styles.root}>
      <div className={styles.cover}>
        <ShimmerImage
          className={styles.imgShimmer}
          src={localCover || cover}
          loading="eager"
          alt="cover"
        />
        <IconButton
          disabled={isLoading || isUploading}
          className={styles.coverIcon}
          iconProps={{ iconName: IconName.Camera }}
          onClick={openPicker}
        />
      </div>
      <Shimmer
        className={styles.text}
        isDataLoaded={!isLoading}
        customElementsGroup={
          <ShimmerElementsGroup
            width="100%"
            flexWrap
            shimmerElements={[
              {
                type: ShimmerElementType.line,
                width: "100%",
                height: 32,
              },
              { type: ShimmerElementType.line, width: "100%", height: 60 },
            ]}
          />
        }
      >
        <TextField
          className={styles.title}
          value={name}
          placeholder={t(BundlesEditorI18n.namePlaceholder)}
          onChange={useCallback((_, newValue) => onNameChange?.(newValue || ""), [onNameChange])}
          maxLength={MAX_TITLE_LEN}
          onGetErrorMessage={onGetErrorMessage}
        />
        <TextField
          value={description}
          placeholder={t(BundlesEditorI18n.descPlaceholder)}
          className={Css.Desc}
          resizable={false}
          onChange={useCallback((_, newValue) => onDescChange?.(newValue || ""), [onDescChange])}
          maxLength={MAX_BUNDLE_DESC_LEN}
          multiline
        />
      </Shimmer>
    </div>
  );
}
