import { isOpt, isSnapCall } from "../../config/app";
// import { INIT_QUERY } from "../../config/config";
import { PagePath } from "../../config/routes";
import { navigateTo } from "../../services/history";
import RCActiveHomePage from "./ActiveHome";
import { useEffect } from "react";

export default function RCHomePage() {
  useEffect(() => {
    // 如果是ReactSnap，不要将notice page的静态元素构建到html中
    if (!isSnapCall && isOpt) {
      navigateTo(PagePath.notice);
    }
  }, []);

  return <RCActiveHomePage />;
}
