import { useCallback, useState, useMemo } from "react";
import { ActionButton, makeStyles } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { selectAPI, UserRole } from "../../services/request";
import { Common, BundlesHubI18n, EmptyContentI18n } from "../../locales";
import CopyCallout from "../../components/common/CopyCallout";
import { rem } from "../../lib/unit";
import { EntityType, generateRCShareInfo, ShareInfo, SourcePageType } from "../../services/share";
import { currentApp } from "../../config/app";
import { logger } from "../../services/logger";
import BundleDetailCard from "../../components/cards/BundleDetailCard";
import { FontSizes } from "../../themes/fonts";
import RCDocumentList from "../../components/rc/DocumentList";
import { ResourceScope } from "../../config/rc";
import { pageContentContainer } from "../../components/navigation/TopBar";
import { useMobileCss } from "../../hooks/rc/useCss";
import { useUser } from "../../hooks/rc/useUser";
import { useQueryWeb, useQueryWebPages } from "../../hooks/useQuery";
import { useRouteParams, useRouteViewDocCallback } from "../../hooks/rc/useRoute";
import { PagePath } from "../../config/routes";
import { useLocation } from "react-router-dom";
import PlayerHeader from "../../components/navigation/PlayerHeader";
import { navigateTo, goBack } from "../../services/history";
import EmptyContent, { pageHeightClass } from "../../components/empty/EmptyContent";
import ErrorPage from "../../components/empty/ErrorPage";

const docTargetPrefix = "SamllCard_Button";
const bundleTarget = "Bundle";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  cardList: {
    maxWidth: rem(1200),
    margin: "auto",
  },
  detailsContainer: { width: "100%", backgroundColor: theme.palette.white },
  detailsCardLayout: { maxWidth: rem(1200), margin: "auto" },
}));

export default function RCBundleDetailPage() {
  const location = useLocation();
  const {
    categoryId,
    bundleId = "",
    scope = ResourceScope.feeds,
  } = useRouteParams<PagePath.bundle>();
  const { role } = useUser();
  const isMobileCss = useMobileCss();
  const isGuest = role === UserRole.guest;
  const apis = selectAPI(scope as ResourceScope, role === UserRole.guest);
  const { t } = useTranslation();
  const styles = useStyles();
  const [copyItem, setCopyItem] = useState<ShareInfo | undefined>();
  const { data: bundle, isError } = useQueryWeb(apis.bundleAPI, {
    vars: { bundleId },
    enabled: !!role && !!bundleId,
    skipAuth: isGuest,
  });
  const { values, loadMore, hasNextPage, indicatorVisible } = useQueryWebPages(
    apis.bundleDocumentsAPI,
    {
      vars: { bundleId },
      skipAuth: isGuest,
      params: {
        $top: 10,
      },
    },
    {
      enabled: !!role,
    }
  );

  const isAdmin = useMemo(
    () => ((location.state as { [key: string]: any })?.isAdmin as boolean) || false,
    [location.state]
  );

  const viewDoc = useRouteViewDocCallback({ scope, bundleId, categoryId });
  const onBundleEdit = useCallback(() => {
    navigateTo(PagePath.editBundle, { params: { id: bundle?.id }, state: { bundle } });
  }, [bundle]);
  const copyBundleUrl = useCallback(() => {
    if (bundle) {
      logger?.telemetry("CopyDeepLink", {
        WorkType: EntityType.Bundle,
        WorkId: bundle?.id,
        Name: scope === ResourceScope.feeds ? bundle.name : undefined!,
        Scope: scope,
        CategoryId: categoryId!,
        Location: window.location.pathname,
        Target: "BundleDetail",
      });
      setCopyItem(generateRCShareInfo(scope, EntityType.Bundle, bundle, currentApp.entityId));
    }
  }, [bundle, categoryId, scope]);
  return isError ? (
    <ErrorPage onBack={goBack} />
  ) : (
    <div className={styles.root}>
      <PlayerHeader title={bundle?.name!} type="bundle" onTapBack={goBack}>
        {isAdmin && !isMobileCss ? (
          <ActionButton
            text={t(BundlesHubI18n.edit)}
            iconProps={{ iconName: IconName.Edit }}
            disabled={!bundle}
            onClick={onBundleEdit}
          />
        ) : (
          <ActionButton
            id={bundleTarget}
            text={isMobileCss ? undefined : t(Common.share)}
            styles={{
              icon: { fontSize: FontSizes.icon },
              menuIcon: { display: "none" },
            }}
            iconProps={{ iconName: IconName.Link }}
            onClick={copyBundleUrl}
            disabled={!bundle}
          />
        )}
      </PlayerHeader>
      <div className={pageContentContainer}>
        <div className={styles.detailsContainer}>
          <div className={`CardListLayout ${styles.detailsCardLayout}`}>
            <div className="CardListLayout-row">
              <BundleDetailCard
                title={bundle?.name}
                description={bundle?.description}
                cover={bundle?.cover}
              />
            </div>
          </div>
        </div>
        {values?.length === 0 && (
          <EmptyContent message={t(EmptyContentI18n.emptyTitle)} className={pageHeightClass} />
        )}
        <main className={`CardList ${styles.cardList}`}>
          <RCDocumentList
            loadMore={loadMore}
            hasNextPage={hasNextPage}
            showCreator={isAdmin}
            scope={scope}
            docs={values ? values.filter(v => !v?.extended?.documentStatus) : []}
            tapDoc={viewDoc}
            sourcePageType={SourcePageType.Bundle}
            isLoading={indicatorVisible}
          />
        </main>
      </div>
      <CopyCallout
        item={copyItem}
        target={
          copyItem && copyItem.entityType === EntityType.Document
            ? `#${docTargetPrefix}_${copyItem?.id}`
            : `#${bundleTarget}`
        }
        onDismiss={() => setCopyItem(undefined)}
      />
    </div>
  );
}
