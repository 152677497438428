import { AxiosError } from "axios";
import {
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
} from "react-query";
import {
  GraphPagination,
  GraphParamTypeOfKey,
  GraphResponseOfKey,
  GraphVarTypeOfKey,
  Graph_API,
  requestGraph,
  RequestConfig,
} from "../../services/request";
import {
  getQueryConfig,
  getRequestParams,
  makeCancelPromise,
  RequestQueryOptions,
  usePageValues,
} from "./base";

type GraphQueryKey<TAPI extends Graph_API> = [
  TAPI,
  RequestConfig<GraphParamTypeOfKey<TAPI>, GraphVarTypeOfKey<TAPI>>?
];

export function queryGraph<TAPI extends Graph_API>(
  context: QueryFunctionContext<GraphQueryKey<TAPI>, TAPI>
) {
  const [api, config, cancel] = getRequestParams(context);
  const promise = requestGraph(api, config).then(res => res.data);
  return makeCancelPromise(promise, cancel);
}

/**
 * 查询 Graph API 数据
 * @param api
 * @param options
 */
export function useQueryGraph<TAPI extends Graph_API>(
  api: TAPI,
  options?: RequestQueryOptions<
    GraphParamTypeOfKey<TAPI>,
    GraphVarTypeOfKey<TAPI>,
    GraphResponseOfKey<TAPI>,
    GraphQueryKey<TAPI>
  >
) {
  const [requestConfig, config] = getQueryConfig(options || {});
  return useQuery([api, requestConfig], queryGraph, config);
}

function getNextPageParam(p: GraphPagination<any>) {
  return p["@odata.nextLink"] || false;
}

type PaginationGraphAPIs<T> = T extends infer U
  ? GraphResponseOfKey<U> extends GraphPagination<any>
    ? U
    : never
  : never;

/**
 * 分页查询Garph API
 * @param key
 * @param config
 * @param queryConfig
 */
export function useQueryGraphPages<Tkey extends PaginationGraphAPIs<Graph_API>>(
  key: Tkey,
  config?: RequestConfig<GraphParamTypeOfKey<Tkey>, GraphVarTypeOfKey<Tkey>>,
  queryConfig?: UseInfiniteQueryOptions<
    GraphResponseOfKey<Tkey>,
    AxiosError,
    GraphResponseOfKey<Tkey>,
    GraphResponseOfKey<Tkey>,
    GraphQueryKey<Tkey>
  >
) {
  const res = useInfiniteQuery([key, config], queryGraph, {
    enabled: !!key,
    getNextPageParam,
    ...queryConfig,
  });
  return usePageValues(res);
}
