/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

// Default theme colors
export const CoreColors = {
  selectionColor: "#3399FF",
  appBlack: "#252424",
  appBlack02: "#201F1D",
  appBlack03: "#0F0F0D",
  appBlack04: "#1B1A18",
  appBlack05: "#201F1D",
  appBlackAlpha10: "rgba(37, 36, 36, 0.1)",
  appWhite: "#fff",
  appWhiteAlpha10: "rgba(255, 255, 255, 0.1)",
  appBlackAlpha05: "rgba(0, 0, 0, 0.5)",

  appBrand02: "#33344a",
  appBrand04: "#464775",
  appBrand06: "#6264a7",
  appBrand08: "#8b8cc7",
  appBrand12: "#bdbde6",
  appBrand14: "#e2e2f6",
  appBrand16: "#f4f4fc",
  appBrand18: "#323348",

  appGray02: "#484644",
  appGray03: "#605e5c",
  appGray04: "#979593",
  appGray06: "#c8c6c4",
  appGray08: "#e1dfdd",
  appGray09: "#edebe9",
  appGray10: "#f3f2f1",
  appGray12: "rgba(37, 36, 36, 0.05)",
  appGray14: "#f9f8f7",
  appGray16: "#6e6e6e",

  overlayBackground: "rgba(37,36,35,.75)",
  appRed: "#c4314b",
  appRed08: "#f3d6d8",

  // Approvals colors
  requestedDark: "#6264a7",
  requestedLight: "#f4f4fc",
  approvedDark: "#2b7550",
  approvedLight: "#e5f5ed",
  rejectedDark: "#c4314b",
  rejectedLight: "#fcf4f6",
  customRespDark: "#8F6200",
  customRespLight: "#FFF8DF",
  pickerPersonaContainerBackground: "#e9eaf6",

  mobileTextColor: "#252443",
  placeHolderTextColor: "#616161",
  secondaryTextColor: "#424242",

  sidebarLinkSelectedColor: "#ebebeb",
} as const;

// Dark theme colors
export const DarkColors = {
  appBlack: "#fff",
  appBlack03: "#0f0f0d",
  appBlackAlpha10: "rgba(255, 255, 255, 0.1)",
  appWhite: "#201f1f",
  appWhiteAlpha10: "rgba(255, 255, 255, 0.1)",
  appBlackAlpha05: "rgba(0, 0, 0, 0.5)",

  appBrand02: "#e2e2f6",
  appBrand04: "#bdbde6",
  appBrand06: "#a6a7dc",
  appBrand08: "#8b8cc7",
  appBrand12: "#6264a7",
  appBrand14: "#464775",
  appBrand16: "#3b3a39",

  appGray02: "rgba(255, 255, 255, 0.75)",
  appGray03: "rgba(255, 255, 255, 0.65)",
  appGray04: "rgba(255, 255, 255, 0.5)",
  appGray06: "rgba(255, 255, 255, 0.3)",
  appGray08: "rgba(255, 255, 255, 0.15)",
  appGray09: "#323131",
  appGray10: "#2d2c2c",
  appGray12: "rgba(255, 255, 255, 0.05)",
  appGray14: "#292828",
  appGray16: "#8e8e8e",
  overlayBackground: "rgba(37,36,35,.75)",
  appRed: "#d74654",
  appRed08: "#4f232b",
  appPersonaBackground: "#3B3A3A",
  pickerPersonaContainerBackground: "#323348",
  mobileButtonPrimary: "#7A7FEA",

  // Approvals colors
  requestedDark: "#a6a7dc",
  requestedLight: "#33344a",
  approvedDark: "#82cda8",
  approvedLight: "#29553e",
  rejectedDark: "#df9299",
  rejectedLight: "#3e1f25",
  redTextDark: "#f9526b",
  darkThemeLinkColor: "#9EA2FF",

  customRespDark: "#8F6200",
  customRespLight: "#FFF8DF",
  secondaryTextColor: "#d6d6d6",
} as const;

// Contrast theme colors
export const ContrastColors = {
  appBlack: "#fff",
  appBlackAlpha10: "rgba(255, 255, 255, 0.1)",
  appWhite: "#000",
  appWhiteAlpha10: "rgba(255, 255, 255, 0.1)",
  appBlackAlpha05: "rgba(0, 0, 0, 0.5)",

  appBrand02: "#ffff00",
  appBrand04: "#ffff00",
  appBrand06: "#00ebff",
  appBrand08: "#ffff00",
  appBrand12: "#ffff00",
  appBrand14: "#fff000",
  appBrand16: "#fff000",

  appGray02: "#fff",
  appGray03: "#000",
  appGray04: "#fff",
  appGray06: "#000",
  appGray08: "#fff",
  appGray09: "#000",
  appGray10: "#000",
  appGray12: "#fff",
  appGray14: "#000",
  appGray16: "#000",
  appGreenContrast: "#30f42c",
  overlayBackground: "rgba(0,0,0,.75)",
  appRed: "#fff",
  appRed08: "#fff",
  pickerPersonaContainerBackground: "#ffff00",

  // Approvals colors
  requestedDark: "#fff",
  requestedLight: "#000",
  approvedDark: "#fff",
  approvedLight: "#000",
  rejectedDark: "#fff",
  rejectedLight: "#000",
  customRespDark: "#fff",
  customRespLight: "#000",
  secondaryTextColor: "#fff",
} as const;

export const SecondaryColors = {
  appOrange01: "#833122",
  appOrange02: "#a33d2a",
  appOrange03: "#bd432c",
  appOrange04: "#cc4a31",
  appOrange: "#e97548",
  appOrange14: "#efdbd3",
  appOrange16: "#f9ecea",

  appMagenta: "#b24782",
  appOrchid: "#943670",
  appRed10: "#fcf4f6",
  appYellow: "#f8d22a",
  appGreen: "#92C353",
  appGreen04: "#237b4b",
} as const;

export const BoxShadowColors = {
  boxShadowColor: "rgba(0,0,0,.1)",
  boxShadowColorDark: "rgba(0,0,0,.25)",
  boxShadowColorDarker: "rgba(0,0,0,.5)",
} as const;
export const appWhiteAlpha10 = "rgba(37, 36, 36, 0.1)";
