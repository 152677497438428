import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  ActionButton,
  Dialog,
  DialogFooter,
  IDialogProps,
  makeStyles,
  PrimaryButton,
  ProgressIndicator,
  Text,
  Image,
} from "@fluentui/react";
import { DocInfo, uploadBlob } from "../../services/request";
import { CreateDocFromLocalFileI18n, View } from "../../locales";
import { useTranslation } from "react-i18next";
import { IconName } from "../../config/icons";
import { FontSizes } from "../../themes/fonts";
import loadingErrorImg from "../../img/teams/loadingerror.png";
import { rem } from "../../lib/unit";
import { getFileSize } from "../../lib/fileSize";
import { getVideoCover } from "../../lib/file";
import { useCancelToken } from "../../hooks/useCancelToken";
import { CancelToken } from "axios";
import {
  MAX_VIDEO_FILE_SIZE,
  MAX_TEMPLATE_FILE_SIZE,
  MAX_EXCEL_FILE_SIZE,
  MAX_PDF_FILE_SIZE,
  MAX_PPT_FILE_SIZE,
  MAX_WORD_FILE_SIZE,
} from "../../config/constant";
import {
  TING_DOC_CONTENT_TYPE,
  PPT_CONTENT_TYPE,
  PDF_CONTENT_TYPE,
  WORD_CONTENT_TYPE,
  EXCEL_CONTENT_TYPE,
} from "../../config/uploadType";
const MP4Box = require("mp4box");

const useDialogStyle = makeStyles(() => ({
  innerContent: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  actionsRight: {
    justifyContent: "space-between",
    alignItems: "baseline",
    display: "flex",
  },
  img: {
    width: rem(200),
    height: rem(200),
  },
  text: {
    fontSize: FontSizes.medium,
    textAlign: "center",
    whiteSpace: "pre-line",
    maxWidth: rem(500),
    "&.is-error": {
      paddingTop: rem(40),
    },
    "&.is-uploading": {
      position: "absolute",
      bottom: rem(50),
    },
  },
  progress: {
    width: rem(500),
    marginTop: rem(20),
    marginBottom: rem(20),
  },
}));

async function getAndUploadCover(file: File, cancelToken: CancelToken) {
  const isVideoFile = file.type.startsWith("video");
  if (!isVideoFile) {
    return;
  }
  const coverBlob = await getVideoCover(file);
  const coverBlobUrl = coverBlob && (await (await uploadBlob(coverBlob, { cancelToken })).file);
  const coverObjUrl = coverBlob ? URL.createObjectURL(coverBlob) : undefined;
  return { coverObjUrl, coverBlobUrl };
}

enum FileType {
  excel,
  tingdoc,
  ppt,
  pdf,
  word,
  video,
}

export type UploadDocFileType = "office" | "tingdoc" | "video";

export default function UploadLocalFile({
  fileInputRef,
  fileAccept,
  uploadFileType,
  onInputChange,
  onFilePickSuccess,
  onBack,
}: {
  fileInputRef?: any;
  fileAccept?: string;
  uploadFileType?: UploadDocFileType;
  onInputChange?: () => void;
  onDismiss?: () => void;
  onBack?: () => void;
  onFilePickSuccess?: (docInfo: DocInfo) => void;
}) {
  const { t } = useTranslation();
  const style = useDialogStyle();
  const [dialogHidden, setDialogHidden] = useState(true);
  const [enableretry, setEnableretry] = useState(false);
  const [err, setErr] = useState<string>();
  const [progress, setProgress] = useState<number>();
  const [docInfo, setDocInfo] = useState<DocInfo>();
  const { token: cancelToken, cancel } = useCancelToken();

  const MAX_PPT_SIZE = useMemo(
    () => (uploadFileType === "office" ? MAX_PPT_FILE_SIZE : MAX_TEMPLATE_FILE_SIZE),
    [uploadFileType]
  );
  const pptContentType = useMemo(
    () => (uploadFileType === "office" ? PPT_CONTENT_TYPE : TING_DOC_CONTENT_TYPE),
    [uploadFileType]
  );

  const getFileType = useCallback(
    (fileExt: string, blobType: string) => {
      const isPPTFile = fileExt.startsWith("ppt") && pptContentType.includes(blobType);
      const isVideoFile = blobType.startsWith("video") && blobType.endsWith("mp4"); // only
      const isPDFFile = PDF_CONTENT_TYPE.includes(blobType);
      const isExcelFile = EXCEL_CONTENT_TYPE.includes(blobType);
      const isWordFile = WORD_CONTENT_TYPE.includes(blobType);
      let resultType;
      switch (uploadFileType) {
        case "office":
          isPPTFile && (resultType = FileType.ppt);
          isVideoFile && (resultType = FileType.video);
          isExcelFile && (resultType = FileType.excel);
          isPDFFile && (resultType = FileType.pdf);
          isWordFile && (resultType = FileType.word);
          break;
        case "video":
          isVideoFile && (resultType = FileType.video);
          break;
        case "tingdoc":
          isPPTFile && (resultType = FileType.tingdoc);
          break;
      }
      if (resultType === undefined) {
        setErr(t(CreateDocFromLocalFileI18n.errorTip));
      }
      return resultType;
    },
    [pptContentType, t, uploadFileType]
  );
  const isAllowFileSize = useCallback(
    (fileType: FileType, size: number) => {
      let isAllowSize;
      switch (fileType) {
        case FileType.tingdoc:
          isAllowSize = size <= MAX_TEMPLATE_FILE_SIZE;
          break;
        case FileType.video:
          isAllowSize = size <= MAX_VIDEO_FILE_SIZE;
          break;
        case FileType.excel:
          isAllowSize = size <= MAX_EXCEL_FILE_SIZE;
          break;
        case FileType.pdf:
          isAllowSize = size <= MAX_PDF_FILE_SIZE;
          break;
        case FileType.ppt:
          isAllowSize = size <= MAX_PPT_FILE_SIZE;
          break;
        case FileType.word:
          isAllowSize = size <= MAX_WORD_FILE_SIZE;
          break;
      }
      if (!isAllowSize) {
        setEnableretry(false);
        setErr(
          t(
            CreateDocFromLocalFileI18n.errorTip,
            uploadFileType === "office"
              ? {
                  context: "office",
                  size: getFileSize(MAX_PPT_SIZE),
                  videoSize: getFileSize(MAX_VIDEO_FILE_SIZE),
                  pdfSize: getFileSize(MAX_PDF_FILE_SIZE),
                  excelSize: getFileSize(MAX_EXCEL_FILE_SIZE),
                  wordSize: getFileSize(MAX_WORD_FILE_SIZE),
                }
              : uploadFileType === "tingdoc"
              ? {
                  context: "tingdoc",
                  size: getFileSize(MAX_PPT_SIZE),
                }
              : {
                  context: "video",
                  size: getFileSize(MAX_VIDEO_FILE_SIZE),
                }
          )
        );
      }
      return isAllowSize;
    },
    [MAX_PPT_SIZE, t, uploadFileType]
  );

  const uploadFileToBlob = useCallback(async () => {
    const file = fileInputRef.current.files?.[0];
    if (!file) {
      return;
    }

    console.log(`file type: ${file.type}`);

    setErr(undefined);
    setProgress(0);
    const fileExt = file.name?.split(".").pop();
    const fileName = (file.name || "").replace(/\.[^/.]+$/, "");
    const fileType = getFileType(fileExt, file.type);
    if (fileType === undefined) {
      return;
    }

    const size = file.size;
    setDocInfo({
      name: fileName,
      ext: fileExt,
      size,
    });
    // check file size
    if (!isAllowFileSize(fileType, size)) {
      fileInputRef.current.value = "";
      return;
    }

    const uploadFile = async (file: any) => {
      // upload file
      let cover: string | undefined;
      let coverBlobUrl: string | undefined;
      const [fileResult, coverResult] = await Promise.allSettled([
        uploadBlob(file, {
          onProgress: p => setProgress(p.rate),
          cancelToken,
        }),
        getAndUploadCover(file, cancelToken),
      ]);
      if (fileResult.status === "rejected") {
        setEnableretry(true);
        return setErr(t(CreateDocFromLocalFileI18n.errorTip, { context: "network" }));
      }
      const downloadUrl = fileResult.value.file;
      if (coverResult.status === "fulfilled") {
        cover = coverResult.value?.coverObjUrl;
        coverBlobUrl = coverResult.value?.coverBlobUrl;
      }
      setDocInfo(doc => {
        const updatedDoc = {
          ...doc,
          downloadUrl,
          cover,
          coverBlobUrl,
        };
        onFilePickSuccess && onFilePickSuccess(updatedDoc);
        return updatedDoc;
      });

      fileInputRef.current.value = "";
      setDialogHidden(true);
      setProgress(undefined);
      setErr(undefined);
    };

    if (fileType === FileType.video) {
      // check file codec: only support H.264
      const mp4boxFile = MP4Box.createFile();
      mp4boxFile.onReady = async (info: any) => {
        const mime = info.mime;
        const codec = mime.match(/codecs="(\S*),/)[1];
        console.log(`codecs:${codec}`);
        if (codec.indexOf("avc") === -1) {
          // 非H.264
          setErr(t(View.errContent));
          return;
        }

        await uploadFile(file);
      };

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;
        arrayBuffer.fileStart = 0;
        mp4boxFile.appendBuffer(arrayBuffer);
      };
    } else {
      await uploadFile(file);
    }
  }, [cancelToken, fileInputRef, getFileType, isAllowFileSize, onFilePickSuccess, t]);

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDialogHidden(false);
      onInputChange && onInputChange();
      uploadFileToBlob();
    },
    [onInputChange, uploadFileToBlob]
  );

  const dialogContentProps = useMemo<IDialogProps["dialogContentProps"]>(
    () => ({
      title: t(CreateDocFromLocalFileI18n.title),
      showCloseButton: true,
      styles: { innerContent: style.innerContent },
    }),
    [style.innerContent, t]
  );

  return (
    <>
      <Dialog
        isBlocking
        hidden={dialogHidden}
        dialogContentProps={dialogContentProps}
        onDismiss={() => {
          setDialogHidden(true);
          cancel();
        }}
      >
        {err ? (
          <>
            <Image src={loadingErrorImg} className={style.img} />
            <Text className={`${style.text} is-error`}>{err}</Text>
          </>
        ) : (
          <>
            <Text className={style.text}>{`${docInfo?.name}.${docInfo?.ext}`}</Text>
            <ProgressIndicator percentComplete={progress} className={style.progress} />
            <Text className={style.text}>
              {t(CreateDocFromLocalFileI18n.uploadingInfo, {
                uploadedSize: getFileSize((progress ?? 0) * (docInfo?.size ?? 0), {
                  exponent: 1,
                  round: 0,
                }),
                fileSize: getFileSize(docInfo?.size ?? 0, { exponent: 1, round: 0 }),
              })}
            </Text>

            <Text className={`${style.text} is-uploading`}>
              {t(CreateDocFromLocalFileI18n.uploadingTip)}
            </Text>
          </>
        )}
        <DialogFooter styles={{ actionsRight: style.actionsRight }}>
          <ActionButton
            iconProps={{ iconName: IconName.ChevronLeft }}
            text={t(CreateDocFromLocalFileI18n.back)}
            onClick={() => {
              setDialogHidden(true);
              setProgress(undefined);
              setErr(undefined);
              cancel();
              onBack && onBack();
            }}
          />
          <PrimaryButton
            onClick={uploadFileToBlob}
            text={t(CreateDocFromLocalFileI18n.tryAgain)}
            style={{ visibility: enableretry && err ? "visible" : "hidden" }}
          />
        </DialogFooter>
      </Dialog>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onFileChange}
        hidden
        accept={fileAccept ?? "*"}
      />
    </>
  );
}
