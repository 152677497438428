/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

import { ITheme, IDropdownStyleProps, FontWeights } from "@fluentui/react";
import { FontSizes } from "../fonts";
import { ContrastColors } from "../teamsColors";

export function getDropdownStyles(themeName: "dark" | "default" | "contrast", theme: ITheme) {
  // tslint:disable-next-line:max-func-body-length
  return (props: IDropdownStyleProps) => {
    const { disabled } = props;
    const { palette } = theme;

    let focusBottomBorderWidth = "2px";
    let opacity = 1;
    const borderWidth = "1px";
    let borderColor = {
      default: palette.neutralLighterAlt,
      dark: palette.neutralLighterAlt,
      contrast: palette.black,
    }[themeName];
    let color = themeName === "dark" ? palette.neutralPrimary : palette.neutralDark;

    if (disabled) {
      focusBottomBorderWidth = "0px";
      color = {
        default: palette.neutralPrimary,
        dark: palette.neutralPrimary,
        contrast: ContrastColors.appGreenContrast,
      }[themeName];
      borderColor = {
        default: "transparent",
        dark: "transparent",
        contrast: ContrastColors.appGreenContrast,
      }[themeName];
      opacity = {
        default: 0.64,
        dark: 0.64,
        contrast: 1.0,
      }[themeName];
    }

    const titleBackground = {
      default: palette.neutralLight,
      dark: palette.white,
      contrast: palette.white,
    }[themeName];

    const dropdownItemBackground = {
      default: palette.white,
      dark: palette.neutralLighter,
      contrast: palette.white,
    }[themeName];

    let selectedColor = palette.black;
    let selectedBackground = palette.neutralQuaternary;
    let focusBackground = palette.neutralQuaternary;
    let activeBackground = `${palette.neutralTertiaryAlt} !important`;
    let hoverBackground = palette.neutralLight;
    let labelColor = palette.neutralPrimary;
    if (themeName === "contrast") {
      selectedColor = palette.white;
      selectedBackground = palette.themePrimary;
      focusBackground = palette.themeSecondary;
      activeBackground = palette.themeSecondary;
      hoverBackground = palette.themeSecondary;
      labelColor = palette.black;
    }

    const backgroundColor = palette.neutralLighterAlt;
    let hoverColor = palette.black;
    if (themeName === "contrast") {
      hoverColor = palette.white;
    }
    let focusBorderColor = {
      default: palette.themePrimary,
      dark: palette.themePrimary,
      contrast: palette.themeDark,
    }[themeName];

    if (disabled) {
      focusBorderColor = "transparent";
    }

    const styles = {
      callout: {
        gapSpace: 3,
      },

      title: {
        border: "0px",
        borderRadius: "2px",
        background: titleBackground,
        color,
      },

      dropdown: {
        background: palette.neutralLight,
        backgroundColor: `${backgroundColor}`,
        borderStyle: "solid",
        borderColor: `${borderColor}`,
        borderWidth,
        borderRadius: "2px",
        opacity,
        selectors: {
          ".ms-Dropdown-title span": {
            color,
          },
          "::after": {
            display: "none",
          },
          ":focus .ms-Dropdown-title": {
            borderBottomStyle: "solid",
            borderBottomWidth: focusBottomBorderWidth,
            borderBottomColor: focusBorderColor,
          },
        },
        " .ms-Dropdown-caretDownWrapper i": {
          display: "inline-block",
        },
        " .ms-Dropdown-title i": {
          display: "inline-block",
        },
      },

      caretDown: {
        color: palette.black,
      },

      dropdownItem: {
        background: dropdownItemBackground,
        color,
        selectors: {
          ".ms-Dropdown-item span": {
            color,
          },
          ":hover": { color: hoverColor, background: hoverBackground },
          ":focus": { color: hoverColor, background: focusBackground },
          ":focus:hover": { color: hoverColor, background: hoverBackground },
          ":active": { color: hoverColor, background: activeBackground },
        },
      },

      dropdownItemSelected: {
        color: selectedColor,
        background: selectedBackground,
        selectors: {
          ":hover": { color: hoverColor, background: hoverBackground },
          ":focus": { color: hoverColor, background: focusBackground },
          ":focus:hover": { color: hoverColor, background: hoverBackground },
          ":active": { color: hoverColor, background: activeBackground },
        },
      },

      label: {
        color: labelColor,
        fontSize: FontSizes.small,
        fontWeight: FontWeights.regular,
        margin: "0.4375rem 0",
      },
    };
    return styles;
  };
}
