import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Icon, makeStyles, ZIndexes } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { getVoices } from "../../config/tts";
import {
  useEditableTingDocPageData,
  useEditableTingDocPages,
} from "../../hooks/rc/useEditableTingDoc";
import { useFileToken } from "../../hooks/useFileToken";
import { rem } from "../../lib/unit";
import { EditableTingDoc } from "../../models/EditableTingDoc";
import ShimmerImage from "../common/ShimmerImage";
import AudioBar from "./AudioBar";
import SideSwiper from "./SideSwiper";

import { EditorI18n } from "../../locales";
import { IconName } from "../../config/icons";
import { useWindowSize } from "../../hooks/useWindowSize";
import { logger } from "../../services/logger";
import { useOnEvent } from "@fluentui/react-hooks";
import type { TTS } from "../../services/request";
import ShimmerVideo from "../common/ShimmerVideo";
import RecorderNote from "./RecorderNote";

const MIN_WINTH = 1024;
const MAX_AUDIO_MILLISECOND = 180000;

const useEditorCss = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    maxHeight: "100%",
    minHeight: "400px",
    flex: 1,
  },
  side: {
    width: rem(255),
    minWidth: "150px",
    maxWidth: "30%",
    animation: "width 0.6s",
    "&[hidden]": {
      width: 0,
    },
  },
  main: {
    background: theme.palette.white,
    animation: "width 0.4s",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: rem(22, 12, 12, 12),
    position: "relative",
    minHeight: "500px",
  },
  sideToggle: {
    position: "absolute",
    top: "48%",
    left: 0,
    background: theme.palette.neutralLight,
    padding: rem(34, 8),
    borderRadius: "4px",
    cursor: "pointer",
    animation: "transform 0.6s",
    border: "none",
    zIndex: ZIndexes.Nav,
    "&.is-closed": {
      transform: "rotateY(180deg)",
    },
  },
  content: {
    display: "inline-flex",
    height: "70%",
    flex: 1,
  },
  img: {
    padding: rem(8, 8, 8, 20),
    boxSizing: "border-box",
  },
  actions: {
    height: "34%",
    width: "100%",
    boxSizing: "border-box",
    padding: rem(8, 8, 8, 20),
  },
  recordContent: {
    paddingTop: rem(24),
    height: rem(32),
    display: "flex",
    alignContent: "space-between",
  },
  error: {
    textAlign: "right",
    lineHeight: rem(20),
    fontSize: rem(14),
    color: theme.palette.redDark,
    padding: rem(20, 0, 6, 0),
    height: rem(20),
  },
}));

const MAX_NOTE_LENGTH = 1500;
export interface TingDocEditorProps {
  doc?: EditableTingDoc;
  defaultIndex?: number;
}

export default function TingDocEditor({ doc, defaultIndex = 0 }: TingDocEditorProps) {
  const { token, baseUrl } = useFileToken();
  const { t } = useTranslation();
  const css = useEditorCss();
  const [sideHidden, setSideHidden] = useState(() => window.innerWidth < MIN_WINTH);
  const toggleSideBar = useCallback(() => setSideHidden(h => !h), []);
  const voices = useMemo(() => getVoices(t), [t]);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorderDisabled, setIsRecorderDisabled] = useState(false);
  const { width } = useWindowSize();
  useEffect(() => setSideHidden(width < MIN_WINTH), [width]);

  const [textErrorMsg, setTextErrorMsg] = useState<string | undefined>();
  const [pageIndex, setPageIndex] = useState(defaultIndex);
  const pages = useEditableTingDocPages(doc);
  const audios = useEditableTingDocPageData(doc, pageIndex, "audios");
  const pageImg = useEditableTingDocPageData(doc, pageIndex, "img");
  const pageNote = useEditableTingDocPageData(doc, pageIndex, "note", 0);
  const pageVideo = useEditableTingDocPageData(doc, pageIndex, "video");

  const pageIndexChanged = useCallback((index: number) => {
    setPageIndex(index);
    setTextErrorMsg(undefined);
  }, []);

  const onRecordingTypeChanged = useCallback(() => setTextErrorMsg(undefined), []);

  useOnEvent(document, "keyup", (ev: KeyboardEvent) => {
    const target = (ev.target || {}) as Element;
    if (
      (target as any).isContentEditable ||
      target.localName === "textarea" ||
      target.localName === "input"
    ) {
      // skip text input
      return;
    }
    if (ev.code === "ArrowUp") {
      setPageIndex(i => (i > 0 ? i - 1 : 0));
    } else if (ev.code === "ArrowDown") {
      setPageIndex(i => (i + 1 < pages?.length! ? i + 1 : i));
    }
  });
  useEffect(() => {
    if (doc && token) {
      doc.token = { token, baseURL: baseUrl! };
    }
  }, [doc, token, baseUrl]);
  const onNoteChange = useCallback(
    (_ev: any, newValue?: string) => {
      doc?.setPageNote(pageIndex, newValue?.slice(0, MAX_NOTE_LENGTH) || "");
      if (newValue && newValue?.length <= MAX_NOTE_LENGTH) {
        setTextErrorMsg(undefined);
      } else if (newValue && newValue?.length > MAX_NOTE_LENGTH) {
        setTextErrorMsg(t(EditorI18n.tooManyCharactersErrorMsg, { count: MAX_NOTE_LENGTH }));
      }
    },
    [doc, pageIndex, t]
  );
  const removeAudio = useCallback(
    (id: string): void => {
      doc?.deleteAudio(pageIndex, id);
      setTextErrorMsg(undefined);
    },
    [doc, pageIndex]
  );

  const onRecordEnd = useCallback(
    (audioBlob: Blob, duration: number): void => {
      doc?.insertBlobAudio(pageIndex, audioBlob, duration);
      setIsRecording(false);
    },
    [doc, pageIndex]
  );
  const onTTS = useCallback(
    (data: TTS.Audio.Response): void => {
      logger?.telemetry("GetTTSAudioSuccess", {
        VoiceName: data.voice!,
        Duration: data.duration,
        DocId: doc?.id!,
      });
      doc && data?.url && doc.insertTTSAudio(pageIndex, data);
    },
    [doc, pageIndex]
  );
  const onTTSClick = useCallback(
    () => !pageNote?.data && setTextErrorMsg(t(EditorI18n.notFillInErrorMsg)),
    [pageNote?.data, t]
  );
  return (
    <div className={css.root}>
      <aside className={css.side} hidden={sideHidden}>
        <SideSwiper
          pages={token ? pages : undefined}
          index={pageIndex}
          onIndexChange={pageIndexChanged}
          disabled={isRecording}
        />
      </aside>
      <main className={css.main}>
        <button
          className={`${css.sideToggle}${sideHidden ? " is-closed" : ""}`}
          onClick={toggleSideBar}
        >
          <Icon iconName={IconName.ChevronLeft} />
        </button>
        <article className={css.content}>
          {pageVideo ? (
            <ShimmerVideo className={css.img} src={token ? pageVideo.data : ""} />
          ) : (
            <ShimmerImage className={css.img} src={token ? pageImg : ""} loading="eager" />
          )}
          <RecorderNote
            maxAudioMilliSecond={MAX_AUDIO_MILLISECOND}
            text={pages && pages[pageIndex].note?.data}
            voices={voices}
            token={token}
            baseUrl={`${baseUrl}${doc?.extended?.sessionId ? "" : "/temp"}`}
            audios={audios}
            ttsOnClick={onTTSClick}
            disabled={isRecorderDisabled}
            onComplete={onTTS}
            onRecordEnd={onRecordEnd}
            onRecodingStatusChanged={setIsRecording}
            onRecordingTypeChanged={onRecordingTypeChanged}
            onErrorMsgChange={setTextErrorMsg}
            isDataLoaded={!!doc && !!token}
            onNoteChange={onNoteChange}
          />
        </article>
        <footer className={css.actions}>
          <div className={css.error}>{textErrorMsg}</div>
          <AudioBar
            audios={token ? audios : undefined}
            onRemove={removeAudio}
            placeholder={t(EditorI18n.audioPlaceholder)}
            isRecording={isRecording}
            maxAudioMilliSecond={MAX_AUDIO_MILLISECOND}
            onPlayingStatusChange={isPlaying => setIsRecorderDisabled(isPlaying)}
          />
        </footer>
      </main>
    </div>
  );
}
