import React from "react";
import { Shimmer } from "@fluentui/react";
import BundleCard from "./BundleCard";
import SmallCard from "./SmallCard";

export default function ShimmerCard({
  showCreator,
  width = 30,
  type,
  rootRef,
}: {
  showCreator?: boolean;
  width?: number;
  type: "doc" | "bundle";
  rootRef?: React.LegacyRef<HTMLDivElement>;
}) {
  return (
    <>
      {type === "doc" && (
        <SmallCard showCreator={showCreator} rootRef={rootRef}>
          <Shimmer width={width} />
        </SmallCard>
      )}
      {type === "bundle" && (
        <BundleCard showCreator={showCreator} rootRef={rootRef}>
          <Shimmer width={width} />
        </BundleCard>
      )}
    </>
  );
}
