import { Stack, ActionButton, makeStyles, Icon } from "@fluentui/react";
import HorizontalCard from "./HorizontalCard";
import { rem } from "../../lib/unit";
import { IconName } from "../../config/icons";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SortableCardI18n, Mine } from "../../locales";
import { getDocExtension } from "../../services/share";

const useCardStyles = makeStyles(theme => ({
  root: {
    padding: rem(6),
    ":active,:hover,&.is-active": {
      ".ReorderBtn": {
        display: "block",
      },
    },
  },
  footer: [{ display: "none" }, "ReorderBtn"],
}));

export type SortableData = CDS.Document | CDS.Bundle;

export interface SortableCardProps {
  data: SortableData;
  disabled?: boolean;
  isUpBtnDisabled?: boolean;
  isDownBtnDisabled?: boolean;
  onUpBtnTap?: (data: SortableData) => void;
  onDownBtnTap?: (data: SortableData) => void;
  onDeleteBtnTap?: (data: SortableData) => void;
}
export default function SortableCard({
  data,
  disabled,
  isUpBtnDisabled,
  isDownBtnDisabled,
  onUpBtnTap,
  onDownBtnTap,
  onDeleteBtnTap,
}: SortableCardProps) {
  const { t } = useTranslation();
  const cardStyle = useCardStyles();

  const isBundle = data.entityType === "bundle";
  const title = isBundle ? (data as CDS.Bundle).name : (data as CDS.Document).metadata?.name;
  const cover = isBundle ? (data as CDS.Bundle).cover : (data as CDS.Document).metadata?.cover;
  const subTitle = isBundle ? (data as CDS.Bundle).stats?.itemCount : undefined;

  const onUpBtnClick = useCallback(
    e => {
      onUpBtnTap && onUpBtnTap(data);
      e.stopPropagation();
    },
    [data, onUpBtnTap]
  );
  const onDownBtnClick = useCallback(
    e => {
      onDownBtnTap && onDownBtnTap(data);
      e.stopPropagation();
    },
    [data, onDownBtnTap]
  );
  const onDeleteBtnClick = useCallback(
    e => {
      onDeleteBtnTap && onDeleteBtnTap(data);
      e.stopPropagation();
    },
    [data, onDeleteBtnTap]
  );

  return (
    <HorizontalCard
      className={cardStyle.root}
      title={title}
      cover={cover}
      fileExtension={
        !isBundle ? getDocExtension((data as CDS.Document)?.metadata?.type) : undefined
      }
      subTitle={
        subTitle ? (
          <>
            <Icon iconName={IconName.Collection} />
            {t(Mine.content, { count: subTitle })}
          </>
        ) : undefined
      }
      footer={
        <Stack horizontal className={cardStyle.footer}>
          <ActionButton
            iconProps={{ iconName: IconName.Up }}
            onClick={onUpBtnClick}
            disabled={disabled || isUpBtnDisabled}
            title={t(SortableCardI18n.moveUp)}
          />
          <ActionButton
            iconProps={{ iconName: IconName.Down }}
            onClick={onDownBtnClick}
            disabled={disabled || isDownBtnDisabled}
            title={t(SortableCardI18n.moveDown)}
          />
          <ActionButton
            disabled={disabled}
            iconProps={{ iconName: IconName.Cancel }}
            onClick={onDeleteBtnClick}
            title={t(SortableCardI18n.remove)}
          />
        </Stack>
      }
    />
  );
}
