import { EditableTingDoc } from "../models/EditableTingDoc";
import { TingPage, Audio } from "../models/TingDoc";
import { TingDocSessionStatusResponse } from "./request";

export function getEditableTingDoc(
  response: TingDocSessionStatusResponse,
  docName: string,
  docCover?: string
): EditableTingDoc {
  const pages = response.previewTask.data.pages;
  const keys = Object.keys(pages).sort((a, b) => +a - +b);
  let mediaId = 1;
  const mediaList: CDS.Medium[] = [];
  const tingPages: TingPage[] = keys.map(k => {
    const tingPage: TingPage = {
      img: pages[k].type === "video" ? undefined : pages[k].thumbnailUrl,
      video:
        pages[k].type === "video"
          ? { id: (mediaId++).toString(), data: pages[k].thumbnailUrl }
          : undefined,
      audios:
        pages[k].audioList?.map(a => {
          const audio: Audio = {
            id: (mediaId++).toString(),
            data: a.url,
            duration: a.duration || 0,
          };
          mediaList.push({
            id: audio.id,
            data: audio.data,
            type: "audio",
            data_type: "link",
            meta: { voice: "ppt", duration: audio.duration },
          });
          return audio;
        }) || [],
      note: pages[k].textList && {
        data: pages[k].textList?.join("\n"),
        id: (mediaId++).toString(),
        type: "text",
      },
      duration: pages[k].audioList?.reduce((acc, cur) => acc + (cur.duration || 0), 0) || 0,
    };

    mediaList.push({
      id: tingPage.video?.id || (mediaId++).toString(),
      data: pages[k].thumbnailUrl,
      type: pages[k].type || "image",
    });
    if (tingPage.note) {
      mediaList.push({ id: tingPage.note.id, data: tingPage.note.data, type: "text" });
    }

    return tingPage;
  });

  const editableTingDoc = new EditableTingDoc(
    {
      metadata: {
        // first page or onedrive thumbnail or other page
        cover:
          pages?.[0].type !== "video"
            ? pages?.[0].thumbnailUrl
            : docCover || mediaList.find(m => m.type === "image")?.data,
        name: docName,
        status: 1,
        type: "tingdoc",
      },
      extended: { sessionId: response.sessionId },
      content: {
        media: mediaList,
      },
    },
    tingPages
  );

  return editableTingDoc;
}
