import { useEffect, useMemo, useState } from "react";
import { Icon, makeStyles, Shimmer, Text, Stack } from "@fluentui/react";
import { rem } from "../../lib/unit";
import { usePrincipalsDetail } from "../../hooks/rc/usePrincipalsDetail";
import { CatalogNodeDetail, Principal, PrincipalType, WEB_API } from "../../services/request";
import { useQueryWeb } from "../../hooks/useQuery";
import { IconName } from "../../config/icons";
import { TFunction, useTranslation } from "react-i18next";
import { VisibilityScopeTagI18n } from "../../locales";
import { FontSizes } from "../../themes/fonts";

const MAX_DISPLAY_NAME = 4;

const useStackStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    flexFlow: "unset",
    // stylelint-disable-next-line value-keyword-case
    background: theme.palette.neutralLighter,
    borderRadius: rem(3),
    alignItems: "center",
    padding: `${rem(4)} ${rem(8)}`,
    cursor: "pointer",
    boxSizing: "border-box",
  },
}));

export default function VisibilityScopeTag({ categoryId }: { categoryId?: string }) {
  const { t } = useTranslation();
  const stackStyles = useStackStyles();
  const [tipContent, setTipContent] = useState<string>("");
  const [displayContent, setDisplayContent] = useState<string>("");
  useEffect(() => {
    if (!categoryId) {
      setDisplayContent(t(VisibilityScopeTagI18n.visibleToAll));
    }
  }, [categoryId, t]);
  const { data } = useQueryWeb(WEB_API.myTenantCatalog, {
    enabled: !!categoryId,
  });
  const category = useMemo(
    () =>
      data?.catalogNodesDetail
        .reduce(
          (acc: CatalogNodeDetail[], val) => acc.concat(val, ...(val.childrenDetail ?? [])),
          []
        )
        .find(node => node.treeNode.id === categoryId),
    [categoryId, data]
  );
  const { users, groups } = usePrincipalsDetail(category?.treeNode.visibilityScopes);

  useEffect(() => {
    const idNameDict: { [id: string]: string } = {};
    users?.forEach(u => {
      if (u.id && u.displayName) {
        idNameDict[u.id] = u.displayName;
      }
    });
    groups?.forEach(g => {
      if (g.id && g.displayName) {
        idNameDict[g.id] = g.displayName;
      }
    });
    const nameList = category?.treeNode.visibilityScopes
      .map(s => idNameDict[s.principalId])
      .filter(x => x != null);

    if (nameList) {
      setTipContent(nameList.join(", "));
      setDisplayContent(getDisplayContent(category?.treeNode.visibilityScopes!, t, nameList));
    }
  }, [category, groups, t, users]);

  return (
    <Shimmer
      title={tipContent}
      isDataLoaded={!!displayContent}
      styles={{ shimmerWrapper: stackStyles.root }}
    >
      <Stack styles={stackStyles}>
        <Icon
          iconName={IconName.View}
          styles={{ root: { marginRight: rem(7), fontSize: FontSizes.small } }}
        />
        <Text variant="small">{displayContent}</Text>
      </Stack>
    </Shimmer>
  );
}

function getDisplayContent(visibilityScope: Principal[], t: TFunction, nameList: string[]): string {
  if (visibilityScope.length === 0) {
    return t(VisibilityScopeTagI18n.visibleToAdmin);
  }
  if (visibilityScope.find(v => v.principalType === PrincipalType.everyone)) {
    return t(VisibilityScopeTagI18n.visibleToAll);
  }
  if (nameList.length <= MAX_DISPLAY_NAME) {
    return nameList.join(", ");
  }
  return `${nameList.slice(0, MAX_DISPLAY_NAME - 1).join(", ")} ${t(
    VisibilityScopeTagI18n.andOthers
  )}`;
}
