import { FontWeights, Icon, Image, ImageFit, makeStyles, Stack, Text } from "@fluentui/react";
import { useCallback, useEffect } from "react";
import { IconName } from "../../config/icons";
import { useAudio } from "../../hooks/useAudio";
import { rem } from "../../lib/unit";
import { TTS } from "../../services/request";
import { FontSizes } from "../../themes/fonts";

const useCardCss = makeStyles(theme => ({
  root: {
    width: rem(262),
    height: rem(80),
    border: `1px solid ${theme.palette.neutralTertiaryAlt}`,
    borderRadius: rem(4),
    padding: rem(16),
    cursor: "pointer",
    ":hover": {
      background: theme.palette.neutralLighterAlt,
      borderColor: theme.palette.neutralTertiary,
    },
    ":active": {
      backgroundColor: theme.palette.neutralLighter,
      borderColor: theme.palette.neutralTertiary,
    },
    "&.is-selected": {
      backgroundColor: theme.palette.neutralLight,
      borderColor: theme.palette.neutralTertiary,
    },
  },
  avatarStack: {
    marginRight: rem(12),
    position: "relative",
  },
  avatarMask: {
    position: "absolute",
    width: rem(48),
    height: rem(48),
    borderRadius: "50%",
    "&.is-selected": {
      backgroundColor: theme.palette.blackTranslucent40,
    },
  },
  avatarImg: { width: rem(48), height: rem(48), borderRadius: "50%" },
  playIcon: {
    position: "absolute",
    width: rem(48),
    height: rem(48),
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "white",
  },
  displayName: {
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.medium,
    lineHeight: rem(19),
  },
  description: [
    {
      fontSize: FontSizes.small,
      lineHeight: rem(16),
      overflow: "hidden",
    },
    "ellipsis-line2",
  ],
}));
export default function TTSVoiceCard({
  voice,
  isSelected,
  onClick,
}: {
  voice: TTS.Voice;
  isSelected: boolean;
  onClick?: () => void;
}) {
  const { displayName, description, avatar, auditionSrc } = voice;
  const style = useCardCss();
  const { isPlaying, play, stop } = useAudio(auditionSrc);

  const onCardClick = useCallback(() => {
    isPlaying ? stop() : play();
    onClick && onClick();
  }, [isPlaying, onClick, play, stop]);

  useEffect(() => {
    if (!isSelected) {
      stop();
    }
  }, [isSelected, stop]);

  return (
    <Stack
      horizontal
      className={`${style.root} ${isSelected ? "is-selected" : ""}`}
      verticalAlign="center"
      title={`${displayName}\n${description}`}
      onClick={onCardClick}
    >
      <Stack className={style.avatarStack}>
        <Image src={avatar} imageFit={ImageFit.cover} className={style.avatarImg} />
        <div className={`${style.avatarMask} ${isSelected ? "is-selected" : ""}`} />
        <div className={style.playIcon}>
          {isSelected &&
            (isPlaying ? (
              <Icon
                iconName={IconName.ExpandMenu}
                styles={{ root: { transform: "rotate(90deg)" } }}
              />
            ) : (
              <Icon iconName={IconName.TriangleSolidRight12} />
            ))}
        </div>
      </Stack>
      <Stack>
        <Text className={style.displayName}>{displayName}</Text>
        <Text className={style.description}>{description}</Text>
      </Stack>
    </Stack>
  );
}
