import React, { useCallback, useState } from "react";
import { DefaultButton, IconButton, makeStyles } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ResourceScope } from "../../../config/rc";
import { Template } from "../../../models/Template";
import { logger } from "../../../services/logger";
import { EntityType, ShareInfo } from "../../../services/share";
import { IconName } from "../../../config/icons";
import { View } from "../../../locales";
import CopyCallout from "../../common/CopyCallout";
import "./TemplateViewTop.scss";
import { useFileToken } from "../../../hooks/useFileToken";
import PlayerHeader from "../../navigation/PlayerHeader";

const useButtonStyles = makeStyles(theme => ({
  root: { backgroundColor: "transparent", color: theme.palette.neutralPrimary },
  rootHovered: { backgroundColor: theme.palette.neutralTertiaryAlt },
}));

export interface TemplateViewTopProps {
  scope: ResourceScope;
  template: Template;
  category?: { id: string; name: string };
  hideTeamsShare?: boolean;
  rcShareInfo?: ShareInfo;
  onTapBack?: () => void;
}

export default function TemplateViewTop({
  scope,
  template,
  category,
  hideTeamsShare,
  rcShareInfo,
  onTapBack,
}: TemplateViewTopProps): React.ReactElement {
  const { t } = useTranslation();
  const buttonStyles = useButtonStyles();
  const { token } = useFileToken();
  const [copyDeepLinkHidden, setCopyDeepLinkHidden] = useState<boolean>(true);
  const onTemplateEdit = useCallback(() => {
    logger?.telemetry("PPTEdit", {
      DocumentId: template.id!,
      DocumentName: template.metadata?.name!,
      Tags: template.tags! as any,
      Scope: scope,
      Type: template.metadata.type!,
    });
  }, [scope, template]);

  const onTemplateDownload = useCallback(() => {
    logger?.telemetry("PPTDownload", {
      DocumentId: template.id!,
      DocumentName: template.metadata?.name!,
      Tags: template.tags! as any,
      Scope: scope,
      Type: template.metadata.type!,
    });
  }, [scope, template]);

  const onCopyLink = useCallback(() => {
    logger?.telemetry("CopyDeepLink", {
      WorkType: EntityType.Document,
      WorkId: template.id!,
      Name: scope === ResourceScope.feeds ? template.metadata.name : undefined!,
      Scope: scope,
      CategoryId: template.extended?.categoryId!,
      Type: template.metadata.type!,
      Tags: template.tags! as any,
      Target: "TemplatePlayer",
    });
    setCopyDeepLinkHidden(false);
  }, [scope, template]);

  return (
    <PlayerHeader
      title={template.metadata?.name}
      category={category}
      type="template"
      extension={template.extension}
      onTapBack={onTapBack}
    >
      <div className="TemplateViewTop-right">
        <IconButton
          className="TemplateViewTop-download"
          href={`${template?.downloadUrl}?${token}`}
          title={t(View.download)}
          download={template.metadata?.name}
          onClick={onTemplateDownload}
          iconProps={{ iconName: IconName.Download }}
        />
        <IconButton
          className="TemplateViewTop-edit"
          title={t(View.edit)}
          href={`${template?.editUrl}?${token}`}
          target="_blank"
          onClick={onTemplateEdit}
          iconProps={{ iconName: IconName.Edit }}
        />
        {!hideTeamsShare && (
          <IconButton
            iconProps={{ iconName: IconName.Link }}
            onClick={onCopyLink}
            title={t(View.copyLink)}
            className="TemplateViewTop-link"
          />
        )}
        <DefaultButton className="TemplateViewTop-back" onClick={onTapBack} styles={buttonStyles}>
          {t(View.close)}
        </DefaultButton>
        {!copyDeepLinkHidden && (
          <CopyCallout
            item={rcShareInfo}
            target=".TemplateViewTop-link"
            onDismiss={() => setCopyDeepLinkHidden(true)}
          />
        )}
      </div>
    </PlayerHeader>
  );
}
