import { DefaultEffects, IContextualMenuStyleProps, IContextualMenuStyles } from "@fluentui/react";
import { rem } from "../../lib/unit";
import { FontSizes } from "../fonts";

const MobileQuery = "@media (orientation: portrait) and (max-width: 719px)";
export const ContextualMenuStyles: (
  props: IContextualMenuStyleProps
) => Partial<IContextualMenuStyles> = ({ theme, ...props }) => ({
  // container: {},
  root: {
    fontSize: FontSizes.icon,
    boxShadow: DefaultEffects.elevation8,
    [MobileQuery]: {
      bottom: 0,
      left: 0,
      display: "flex",
      flex: "1 1 auto",
      flexFlow: "column nowrap",
      width: "100%",
      paddingBottom: `calc( env(safe-area-inset-bottom) + ${rem(16)} )`,
      borderRadius: rem(16, 16, 0, 0),
    },
    " .ms-ContextualMenu-linkContent i": {
      display: "inline-block",
    },
  },

  list: {
    [MobileQuery]: {
      padding: rem(10, 0),
    },
  },
  header: {
    fontSize: FontSizes.large,
    [MobileQuery]: {
      textAlign: "center",
      ">.ms-ContextualMenu-linkContent>i:empty": {
        display: "none",
      },
    },
  },

  subComponentStyles: {
    callout: {
      boxShadow: DefaultEffects.elevation8,
    },
    menuItem: {
      root: {
        display: "block",
        fontSize: FontSizes.medium,
        height: rem(36),
        lineHeight: rem(36),
        padding: rem(0, 8, 0, 12),
        [MobileQuery]: {
          height: rem(50),
          padding: rem(0, 14, 0),
          // 下划线效果
          boxShadow: rem(40, "2px", 0, "-1px", theme.semanticColors.bodyFrameDivider),
          // ".ms-ContextualMenu-itemText,.ms-ContextualMenu-submenuIcon": {
          //   borderBottom: `1px solid ${theme.semanticColors.bodyFrameDivider}`,
          //   marginBottom: "-4px",
          //   paddingBottom: "4px",
          //   marginRight: 0,
          // },
        },
      },
      icon: {
        color: "inherit",
        fontSize: "inherit",
        maxWidth: "1em",
        maxHeight: rem(36),
        margin: rem(0, 4),
      },
    },
  },
});
