import { INIT_QUERY, RC_APPID, RC_ENTITYID } from "./config";

const APP_NAME_QUERY_KEY = "app";
const TEAMS_QUERY_KEY = "teams";
// const FROM_QUERY_KEY = "from";

export enum AppName {
  It = "InteralTraining",
  Wce = "CustomerEngagement",
  Rc = "ResourceCenter",
}

interface AppInfo {
  appName: AppName;
  appId: string;
  // for deeplink
  entityId?: string;
  /** channel Tab id */
  channelTabId?: string;
  channelTabUrl?: string;
  tabs: string[];
}

export const RCApp: AppInfo = {
  appName: AppName.Rc,
  appId: RC_APPID,
  entityId: RC_ENTITYID,
  tabs: [RC_ENTITYID],
};

export const TraningApp: AppInfo = {
  appName: AppName.It,
  appId: "TRAINING_APPID",
  tabs: ["Training"],
};

export const WCEApp: AppInfo = {
  appName: AppName.Wce,
  appId: "WCE_APPID",
  tabs: ["CustomerEngagement"],
  channelTabId: "CustomerEngagement",
  channelTabUrl: `/teams/groups.html?${APP_NAME_QUERY_KEY}=${AppName.Wce}&${TEAMS_QUERY_KEY}`,
};

const AppDict = {
  [AppName.It]: TraningApp,
  [AppName.Wce]: WCEApp,
  [AppName.Rc]: RCApp,
};

function getCurrentApp() {
  // 如:
  // ?app=CustomerEngagement
  const appName = INIT_QUERY.get(APP_NAME_QUERY_KEY) as AppName;
  if (appName && AppDict[appName]) {
    return AppDict[appName];
  }
  return RCApp;
}

/**
 * 当前TeamsAPP 信息
 */
export const currentApp = getCurrentApp();

/**
 * query string 中包含teams字段
 * session Storage 存储 防止 ios刷新
 */
function inTeams(): boolean {
  try {
    if (sessionStorage.getItem(TEAMS_QUERY_KEY)) {
      return true;
    } else if (INIT_QUERY.has(TEAMS_QUERY_KEY)) {
      sessionStorage.setItem(TEAMS_QUERY_KEY, "true");
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

/**
 * 是否位teamsAPP
 */
export const isTeamsApp = inTeams();

// export enum SourceFrom {
//   Limited = "v3.0.0",
//   Custom = "custom",
// }

// function getSource(useCache: boolean): string | null {
//   try {
//     if (useCache) {
//       let from = sessionStorage.getItem(FROM_QUERY_KEY);
//       if (!from) {
//         from = INIT_QUERY.get(FROM_QUERY_KEY);
//         if (from) {
//           sessionStorage.setItem(FROM_QUERY_KEY, from);
//         }
//       }

//       return from;
//     }

//     return INIT_QUERY.get(FROM_QUERY_KEY);
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// }

// function enableTenant(): boolean {
//   const from = getSource(true);

//   if (from?.toLocaleLowerCase() === SourceFrom.Custom.toLocaleLowerCase()) {
//     return true;
//   }

//   return false;
// }

export const isLimited = false; // !enableTenant();

function checkSnap(): boolean {
  return window.navigator.userAgent.toLocaleLowerCase() === "reactsnap";
}

export const isOpt = false;
export const isSnapCall = checkSnap();
