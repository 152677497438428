import Axios from "axios";
import { API_ROOT_WEB } from "../../config/config";
import { ResourceScope } from "../../config/rc";
import { paramsSerializer } from "../../lib/paramsSerializer";
import {
  axiosRequestIdLogger,
  axiosTimeLogger,
  createAxiosErrorLogger,
  createAxiosResponseLogger,
} from "../logger";
import { RCDocumentType } from "../share";
import type { FeedsLanguage } from "../storage";

import { getIdToken } from "./getIdToken";
import { createRetryErrorInterceptor, replaceVars, RequestConfig } from "./injectors";
import { setBearerToken } from "./token";
import { TTS } from "./types/tts";

/**
 * only use for tests and mock
 */
export const _webRequest = Axios.create({
  baseURL: API_ROOT_WEB,
  paramsSerializer,
  proxy: false,
});

_webRequest.interceptors.request.use(replaceVars);
_webRequest.interceptors.request.use(axiosTimeLogger);
_webRequest.interceptors.request.use(axiosRequestIdLogger);
_webRequest.interceptors.request.use(config =>
  config.skipAuth
    ? config
    : getIdToken()
        .then(token => setBearerToken(config, token))
        .catch(err => {
          console.log(`requestWeb:${err}`);
          return config;
        })
);
_webRequest.interceptors.response.use(
  createAxiosResponseLogger("api"),
  createAxiosErrorLogger("api")
);
_webRequest.interceptors.response.use(undefined, createRetryErrorInterceptor(_webRequest));

/**
 * 调用 web API
 */
export enum WEB_API {
  MPLogin = "/v1.0/wechat/mp/code",
  MPLoginQuery = "/v1.0/wechat/mp/status",
  MPToken = "/v1.0/wechat/mp/token",
  // teams group
  teamsGroupCreate = "/v1.0/teams/groups",
  teamsGroupMPToken = "/v1.0/teams/groups/mp/token",

  // web
  webMPLogin = "/v1.0/web/login/mp",
  webMPLoginQuery = "/v1.0/web/login/mp/status",
  webAnonymousShareDocumentInfo = "/v1.0/web/anonymous/share/documents/{id}",
  webAnonymousShareBundleInfo = "/v1.0/web/anonymous/share/bundles/{id}",

  // rc feeds public
  feedsPublicCatalog = "/v1.0/feeds/public/catalog",
  feedsPublicCategoryDocuments = "/v1.1/feeds/public/categories/{categoryId}/documents",
  feedsPublicCategoryBundles = "/v1.0/feeds/public/categories/{categoryId}/bundles",
  feedsPublicBundleDocuments = "/v1.0/feeds/public/bundles/{bundleId}/documents",
  feedsPublicDocument = "/v1.0/feeds/public/documents/{id}",
  feedsPublicBundle = "/v1.0/feeds/public/bundles/{bundleId}",
  feedsPublicDimensionsAndTags = "/v1.0/feeds/public/dimensionsandtags",

  // rc feeds
  feedsCatalog = "/v1.0/feeds/catalog",
  feedsCategoryDocuments = "/v1.1/feeds/categories/{categoryId}/documents",
  feedsCategoryBundles = "/v1.0/feeds/categories/{categoryId}/bundles",
  feedsBundleDocuments = "/v1.0/feeds/bundles/{bundleId}/documents",
  feedsDocument = "/v1.0/feeds/documents/{id}",
  feedsBundle = "/v1.0/feeds/bundles/{bundleId}",
  feedsDimensionsAndTags = "/v1.0/feeds/dimensionsandtags",

  // rc mytenant
  myTenantInit = "/v1.0/mytenant/init",
  myTenantCatalog = "/v1.0/mytenant/catalog",

  // categories
  myTenantCategories = "/v1.0/mytenant/categories",
  myTenantCategory = "/v1.0/mytenant/categories/{categoryId}",
  myTenantCategoryNotify = "/v2.0/mytenant/categories/{categoryId}/notification",
  myTenantCategoryDocuments = "/v1/mytenant/categories/{categoryId}/documents",
  myTenantCategoryDocument = "/v1/mytenant/categories/{categoryId}/documents/{documentId}",
  myTenantCategoryBundles = "/v1.0/mytenant/categories/{categoryId}/bundles",
  myTenantCategoryBundle = "/v1.0/mytenant/categories/{categoryId}/bundles/{bundleId}",

  // bundles
  myTenantBundles = "/v1/mytenant/bundles",
  myTenantBundleDocuments = "/v1/mytenant/bundles/{bundleId}/documents",
  myTenantBundle = "/v1/mytenant/bundles/{bundleId}",

  // documents
  myTenantDocuments = "/v1/mytenant/documents",
  myTenantDocument = "/v1/mytenant/documents/{id}",
  myTenantDocumentMetadata = "/v1/mytenant/documents/{id}/metadata",
  myTenantDocumentSasToken = "/v1/mytenant/documents/{id}/sastoken",
  myTenantDocumentsVideo = "/v1/mytenant/documents/video",
  myTenantTemplateDocuments = "/v1/mytenant/documents/templates",
  myTenantDocumentsNotify = "/v1/mytenant/documents/notification",
  myTenantTingDocFinishCreation = "/v1/mytenant/documents/tingdoc/sessions/{sessionId}",
  myTenantDocumentsFile = "/v1/mytenant/documents/file",

  // userSetting
  myUserSetting = "/v1/me/settings",

  // dataStatistics
  readingDetails = "/v1/mytenant/documents/{id}/stats/downloadurl",

  // me token
  myAccessToken = "/v1/me/graph/accesstoken",
  myFileWriteToken = "/v1/me/tempfiles/writetoken",

  checkRole = "/v1.0/me/checkroles",
  checkUserCreationPermission = "/v1.0/me/usercreation/permission",
  assignments = "/v1.0/mytenant/roleassignments",
  assignment = "/v1.0/mytenant/roleassignments/{assignmentId}",
  feedsCategoryConfig = "/v1.0/feeds/categories/{categoryId}/config",

  // creation
  initCreateSession = "/v1/mytenant/documents/tingdoc/sessions",
  queryTingDocSessionStatus = "/v1/mytenant/documents/tingdoc/sessions/{sessionId}",
  getTingDocSessionSasToken = "/v1/myTenant/documents/tingdoc/sessions/{id}/sasToken",
  ttsAudios = "/v1.0/tts/audios",
  myTempFilesDirToken = "/v1/me/tempfiles/dirtoken",

  // subscription
  createSubscription = "/v1.0/me/subscriptions",
  deleteFeedsSubscription = "/v1.0/me/subscriptions/feeds/categories/{categoryId}",
  mySubscriptions = "/v1.0/me/subscriptions",

  // my documents
  myDocuments = "/v1/me/documents",
  myDocument = "/v1/me/documents/{id}",
  myDocumentMetadata = "/v1/me/documents/{id}/metadata",
  myDocumentsVideo = "/v1/me/documents/video",
  myTingDocFinishCreation = "/v1/me/documents/tingdoc/sessions/{sessionId}",
  initMyCreateSession = "/v1/me/documents/tingdoc/sessions",
  queryMyTingDocSessionStatus = "/v1/me/documents/tingdoc/sessions/{sessionId}",
  getMyTingDocSessionSasToken = "/v1/me/documents/tingdoc/sessions/{id}/sasToken",
}

/**
 * API 路径参数类型定义
 */
interface WebPathVars {
  [WEB_API.webAnonymousShareDocumentInfo]: {
    id: string;
  };
  [WEB_API.webAnonymousShareBundleInfo]: {
    id: string;
  };
  [WEB_API.feedsPublicCategoryDocuments]: {
    categoryId: string;
  };
  [WEB_API.feedsPublicCategoryBundles]: {
    categoryId: string;
  };
  [WEB_API.feedsPublicDocument]: {
    id: string;
  };
  [WEB_API.feedsPublicBundle]: {
    bundleId: string;
  };

  [WEB_API.feedsPublicBundleDocuments]: {
    categoryId: string;
    bundleId: string;
  };

  [WEB_API.feedsCategoryDocuments]: {
    categoryId: string;
  };
  [WEB_API.feedsCategoryConfig]: {
    categoryId: string;
  };
  [WEB_API.feedsCategoryBundles]: {
    categoryId: string;
  };
  [WEB_API.feedsDocument]: {
    id: string;
  };
  [WEB_API.feedsBundle]: {
    bundleId: string;
  };
  [WEB_API.feedsBundleDocuments]: {
    categoryId: string;
    bundleId: string;
  };

  [WEB_API.myTenantCategoryNotify]: {
    categoryId: string;
  };
  [WEB_API.myTenantCategoryDocuments]: {
    categoryId: string;
  };
  [WEB_API.myTenantCategoryDocument]: {
    categoryId: string;
    documentId: string;
  };
  [WEB_API.myTenantCategory]: {
    categoryId: string;
  };
  [WEB_API.myTenantCategoryBundle]: {
    categoryId: string;
    bundleId: string;
  };
  [WEB_API.myTenantCategoryBundles]: {
    categoryId: string;
  };
  [WEB_API.myTenantDocument]: {
    id: string;
  };
  [WEB_API.myTenantDocumentMetadata]: {
    id: string;
  };
  [WEB_API.myTenantDocumentSasToken]: {
    id: string;
  };
  [WEB_API.myTenantBundle]: {
    bundleId: string;
  };
  [WEB_API.myTenantBundleDocuments]: {
    bundleId: string;
  };
  [WEB_API.assignment]: {
    assignmentId: string;
  };
  [WEB_API.queryTingDocSessionStatus]: {
    sessionId: string;
  };
  [WEB_API.getTingDocSessionSasToken]: {
    id: string;
  };
  [WEB_API.myTenantTingDocFinishCreation]: {
    sessionId: string;
  };
  [WEB_API.readingDetails]: {
    id: string;
  };
  [WEB_API.deleteFeedsSubscription]: {
    categoryId: string;
  };
  [WEB_API.myDocument]: {
    id: string;
  };
  [WEB_API.myDocumentMetadata]: {
    id: string;
  };
  [WEB_API.myTingDocFinishCreation]: {
    sessionId: string;
  };
  [WEB_API.queryMyTingDocSessionStatus]: {
    sessionId: string;
  };
  [WEB_API.getMyTingDocSessionSasToken]: {
    id: string;
  };
}

export interface FileTokenInfo {
  expiresOn: string;
  token: string;
  baseUrl: string;
  scopes?: ("read" | "write")[];
}

/**
 * 参数返回类型定义
 */
interface WebResponse {
  [WEB_API.MPLogin]: MPLoginCodeResponse;
  [WEB_API.MPLoginQuery]: MPLoginQueryResponse;
  [WEB_API.MPToken]: TokenInfo;
  [WEB_API.teamsGroupMPToken]: TokenInfo;
  [WEB_API.teamsGroupCreate]: TeamsGroupCreateResponse;
  [WEB_API.webMPLogin]: MPLoginCodeResponse;
  [WEB_API.webMPLoginQuery]: MPLoginQueryResponse;
  [WEB_API.webAnonymousShareDocumentInfo]: ShareDocumentInfo;
  [WEB_API.webAnonymousShareBundleInfo]: ShareBundleInfo;
  // rc
  [WEB_API.feedsPublicCatalog]: Catalog;
  [WEB_API.feedsPublicCategoryBundles]: CDS.Pagination<CDS.Bundle>;
  [WEB_API.feedsPublicCategoryDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.feedsPublicDocument]: CDS.Document;
  [WEB_API.feedsPublicBundle]: CDS.Bundle;
  [WEB_API.feedsPublicDimensionsAndTags]: {
    dimensions: Dimension[];
  };
  [WEB_API.feedsPublicBundleDocuments]: CDS.Pagination<CDS.Document>;

  [WEB_API.feedsCatalog]: Catalog;
  [WEB_API.feedsCategoryBundles]: CDS.Pagination<CDS.Bundle>;
  [WEB_API.feedsCategoryDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.feedsDocument]: CDS.Document;
  [WEB_API.feedsBundle]: CDS.Bundle;
  [WEB_API.feedsDimensionsAndTags]: {
    dimensions: Dimension[];
  };
  [WEB_API.feedsBundleDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.myTenantTemplateDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.myTenantInit]: Catalog;
  [WEB_API.myTenantCatalog]: Catalog;
  [WEB_API.myTenantCategories]: Category;
  [WEB_API.myTenantCategoryBundles]: CDS.Pagination<CDS.Bundle>;
  [WEB_API.myTenantCategoryDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.myTenantDocument]: CDS.Document;
  [WEB_API.myTenantDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.myTenantBundles]: CDS.Pagination<CDS.Bundle>;
  [WEB_API.myTenantBundle]: CDS.Bundle;
  [WEB_API.myTenantBundleDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.myAccessToken]: AccessTokenResponse;
  [WEB_API.myFileWriteToken]: FileTokenInfo;
  [WEB_API.myTenantDocumentSasToken]: FileTokenInfo;
  [WEB_API.assignments]: CDS.Pagination<AssignmentEntity>;
  [WEB_API.checkRole]: UserRole[];
  [WEB_API.myUserSetting]: UserSetting;
  [WEB_API.readingDetails]: DownloadView;
  [WEB_API.queryTingDocSessionStatus]: TingDocSessionStatusResponse;
  [WEB_API.initCreateSession]: {
    sessionId: string;
  };
  [WEB_API.getTingDocSessionSasToken]: FileTokenInfo;
  [WEB_API.ttsAudios]: TTS.Audio.Response;
  [WEB_API.mySubscriptions]: UserSubscriptionView[];
  [WEB_API.myTempFilesDirToken]: FileTokenInfo;
  [WEB_API.myDocument]: CDS.Document;
  [WEB_API.myDocuments]: CDS.Pagination<CDS.Document>;
  [WEB_API.initMyCreateSession]: {
    sessionId: string;
  };
  [WEB_API.queryMyTingDocSessionStatus]: TingDocSessionStatusResponse;
  [WEB_API.getMyTingDocSessionSasToken]: FileTokenInfo;
  [WEB_API.checkUserCreationPermission]: boolean;
}

export interface UserSetting {
  feedsLanguages?: FeedsLanguage[];
}

export type GraphScopes =
  | "Directory.Read.All"
  | "Sites.Read.All"
  | "Files.ReadWrite.All"
  | "Group.Read.All"
  | "user.read"
  | "mail.read";
export interface AccessTokenResponse {
  tokenType: "Bearer";
  scopes: GraphScopes[];
  expiresOn: string;
  accessToken: string;
}

export interface AccessTokenRequest {
  scopes: GraphScopes[];
}

export interface TingDocSessionStatusResponse {
  sessionId: string;
  previewTask: {
    taskId: string;
    status: FilePreviewTaskStatus;
    data: {
      pages: {
        [key: string]: {
          thumbnailUrl: string;
          type?: "video" | "image";
          audioList: { url: string; duration?: number }[];
          textList: string[];
        };
      };
    };
    workingContainer: string;
    workingPath: string;
  };
  sasToken: FileTokenInfo;
}

export enum FilePreviewTaskStatus {
  NotStart = 0,
  Processing,
  Canceled,
  Finished,
  Failed,
  Timeout,
}

/**
 * API 请求参数类型
 */
interface WebQueryParam {
  [WEB_API.webAnonymousShareDocumentInfo]: {
    originApp?: string;
    originAction?: string;
  };
  [WEB_API.webAnonymousShareBundleInfo]: {
    originApp?: string;
    originAction?: string;
  };
  [WEB_API.feedsPublicCategoryBundles]: {
    $top: number;
  };
  [WEB_API.feedsPublicCategoryDocuments]: {
    $top: number;
    $orderBy: "CreateAt DESC"[];
    tagIds?: string[];
  };
  [WEB_API.feedsCategoryBundles]: {
    $top: number;
  };
  [WEB_API.feedsCategoryDocuments]: {
    $top: number;
    $orderBy: "CreateAt DESC"[];
    tagIds?: string[];
  };
  [WEB_API.myTenantDocuments]: {
    $expand?: ("content" | "category")[];
    $top?: number;
    docTypes?: RCDocumentType[];
  };
  [WEB_API.myTenantBundles]: {
    $expand?: "category"[];
    $top?: number;
  };
  [WEB_API.readingDetails]: {
    reportType?: "UserRead"[];
  };
  [WEB_API.myTenantTemplateDocuments]: {
    $expand?: ("content" | "category")[];
    $top?: number;
  };
  [WEB_API.myTenantDocument]: {
    $expand?: ("category" | "readtoken")[];
  };
  [WEB_API.myTenantCategoryBundles]: {
    $top?: number;
    $orderby?: string;
  };
  [WEB_API.myTenantCategoryDocuments]: {
    $top?: number;
    $orderby?: string;
  };
  [WEB_API.feedsPublicBundleDocuments]: {
    $top: number;
  };
  [WEB_API.feedsPublicDimensionsAndTags]: {
    $type: string;
  };
  [WEB_API.feedsDimensionsAndTags]: {
    $type: string;
  };
  [WEB_API.feedsBundleDocuments]: {
    $top: number;
  };
  [WEB_API.myTenantBundleDocuments]: {
    $top: number;
  };
  [WEB_API.assignments]: {
    $top: number;
  };
  [WEB_API.myTenantDocumentSasToken]: { scopes: ("read" | "write")[] };

  [WEB_API.myDocuments]: {
    $expand?: ("content" | "category")[];
    $top?: number;
    docTypes?: RCDocumentType[];
  };
}

export interface DocInfo {
  downloadUrl?: string;
  name?: string;
  cover?: string;
  itemId?: string;
  driveId?: string;
  driveType?: string;
  ext?: string;
  size?: number;
  coverBlobUrl?: string;
}
export interface VideoInfo extends DocInfo {
  video: string;
}

export enum FileDocType {
  ppt = "ppt-file",
  excel = "excel-file",
  pdf = "pdf-file",
  word = "word-file",
}

export interface FileInfo extends DocInfo {
  type: FileDocType;
  fileUrl: string;
}

export interface TemplateInfo extends DocInfo {
  template: string;
  docType: RCDocumentType;
  templateExt: string;
}

interface WebRequestBody {
  [WEB_API.myTenantDocumentsFile]: FileInfo;
  [WEB_API.myTenantDocumentsVideo]: VideoInfo;
  [WEB_API.myTenantTemplateDocuments]: TemplateInfo;
  [WEB_API.myTenantDocumentMetadata]: Partial<CDS.Document["metadata"]>;
  [WEB_API.myAccessToken]: AccessTokenRequest;
  [WEB_API.checkRole]: string[];
  [WEB_API.myTenantCategoryDocuments]: {
    documentIds: string[];
  };
  [WEB_API.myTenantCategoryBundles]: {
    bundleIds: string[];
  };

  [WEB_API.myTenantCatalog]: CatalogNode[];
  [WEB_API.myTenantCategory]: {
    nodeETag?: string;
    title?: string;
    visibilityScopes?: Principal[];
    notificationSwitch?: boolean;
  };
  [WEB_API.myTenantCategories]: {
    category: {
      title: string;
      visibilityScopes: Principal[];
    };
    parentId?: string;
  };
  [WEB_API.assignments]: {
    roleType: UserRole;
    principalId: string;
    principalType: PrincipalType;
  }[];
  [WEB_API.feedsCategoryConfig]: {
    visibilityScopes: Principal[];
  };
  [WEB_API.myTenantInit]: {
    locale: string;
  };
  [WEB_API.myTenantCategoryNotify]: {
    items: { id: string; type: "doc" | "bundle" }[];
    description?: string;
  };
  [WEB_API.myTenantDocumentsNotify]: {
    documentIds: string[];
    description?: string;
  };
  [WEB_API.myUserSetting]: {
    feedsLanguages?: string[];
  };
  [WEB_API.initCreateSession]: {
    fileUrl: string;
    expands: ("Audios" | "Notes")[];
  };
  [WEB_API.myTenantTingDocFinishCreation]: {
    document: CDS.Document;
  };
  [WEB_API.ttsAudios]: TTS.Audio.Request;
  [WEB_API.myTenantDocument]: CDS.Document;
  [WEB_API.createSubscription]: { feedsCategoryId: string };
  [WEB_API.myTenantBundle]: Partial<CDS.Bundle>;
  [WEB_API.myTenantBundles]: Partial<CDS.Bundle>;
  [WEB_API.myTempFilesDirToken]: { scopes: ("read" | "write")[]; baseUrl: string };
  [WEB_API.myDocumentMetadata]: Partial<CDS.Document["metadata"]>;
  [WEB_API.myDocument]: CDS.Document;
  [WEB_API.myDocumentsVideo]: VideoInfo;
  [WEB_API.myTingDocFinishCreation]: {
    document: CDS.Document;
  };
  [WEB_API.initMyCreateSession]: {
    fileUrl: string;
    expands: ("Audios" | "Notes")[];
  };
}
// 返回类型推断
export type WebResponseOfKey<T> = T extends keyof WebResponse ? WebResponse[T] : unknown;
// 自动推断请求对应的类型
export type WebParamTypeOfKey<T> = T extends keyof WebQueryParam ? WebQueryParam[T] : never;
// 自动推断Key对应路径参数的类型
export type WebVarTypeOfKey<T> = T extends keyof WebPathVars ? WebPathVars[T] : never;

export type WebRequestBodyTypeOfKey<T> = T extends keyof WebRequestBody ? WebRequestBody[T] : never;

interface ShareDocumentInfo {
  wechatMiniCode: string;
  tingdocInfo: {
    title: string;
  };
}

interface ShareBundleInfo {
  wechatMiniCode: string;
  bundleInfo: {
    title: string;
  };
}

export interface TokenInfo {
  tokenStr: string;
  expireOn: string;
  mpfUserId: string;
}

interface MPLoginCodeResponse {
  codeUrl: string;
  expireOn: string;
}
export interface MPLoginQueryResponse {
  /**
   * 状态
   * CodeExpired = 0;
   * NotConfirmed = 1;
   * Confirmed = 2;
   */
  status: 0 | 1 | 2; // eslint-disable-line @dragongate/no-magic-numbers
  tokenInfo: TokenInfo;
}

// export interface FolderInfo {
//   id: string;
//   name: string;
//   weight: number;
//   permission: number;
//   tag: {
//     name: string;
//     weight: number;
//   };
//   tenant: string;
// }

// export enum FolderItemType {
//   document = 0,
//   bundle = 1,
// }

// export interface FolderItemInfo {
//   id: string;
//   type: FolderItemType;
//   cover: string;
//   name: string;
//   createAt: number;
//   description?: string;
//   stats?: { [key: string]: any };
//   documentStatus?: number;
// }

export interface AssignmentEntity {
  id: string;
  entityType: "userRoleAssignment";
  roleType: string;
  principalId: string;
  principalType: PrincipalType;
  tenantId: string;
}

export enum PrincipalType {
  user = "User",
  group = "Group",
  everyone = "Everyone",
}

export interface Principal {
  principalId: string;
  principalType: PrincipalType;
}

export interface TeamsGroupCreateResponse {
  tokenInfo: TokenInfo;
}

export interface TreeNode {
  id: string;
  _etag?: string;
  entityType: string;
  title: string;
  nodeETag?: string;
  visibilityScopes: Principal[];
  languages?: string[];
  createAt?: number;
}

export type Category = TreeNode;

export interface Catalog {
  id: string;
  nodeETag?: string;
  entityType: string;
  catalogNodesDetail: CatalogNodeDetail[];
}

export interface CatalogNode {
  id: string;
  children: string[];
  nodeETag?: string;
}

export interface DownloadView {
  downloadUrl: string;
}

export interface UserSubscriptionView {
  id: string;
  feedsCategoryId: string;
  createAt: number;
}

export interface CatalogNodeDetail {
  childrenDetail?: CatalogNodeDetail[];
  treeNode: TreeNode;
  nodeETag?: string;
}

export enum UserRole {
  resourceAdmin = "ResourceAdministrator",
  tenantAdmin = "TenantAdministrator",
  guest = "guest",
  member = "member",
}

export type RoleType = UserRole.resourceAdmin | UserRole.tenantAdmin;

export function selectAPI(
  scope: ResourceScope,
  isGuest: boolean
): {
  catalogAPI: WEB_API.feedsCatalog | WEB_API.feedsPublicCatalog | WEB_API.myTenantCatalog;
  categoryBundlesAPI:
    | WEB_API.feedsPublicCategoryBundles
    | WEB_API.feedsCategoryBundles
    | WEB_API.myTenantCategoryBundles;
  categoryDocumentsAPI:
    | WEB_API.feedsPublicCategoryDocuments
    | WEB_API.feedsCategoryDocuments
    | WEB_API.myTenantCategoryDocuments;
  bundleDocumentsAPI:
    | WEB_API.feedsPublicBundleDocuments
    | WEB_API.feedsBundleDocuments
    | WEB_API.myTenantBundleDocuments;
  documentAPI: WEB_API.feedsDocument | WEB_API.feedsPublicDocument | WEB_API.myTenantDocument;
  bundleAPI: WEB_API.feedsBundle | WEB_API.feedsPublicBundle | WEB_API.myTenantBundle;
} {
  switch (scope) {
    case ResourceScope.feeds:
      return {
        categoryBundlesAPI: isGuest
          ? WEB_API.feedsPublicCategoryBundles
          : WEB_API.feedsCategoryBundles,
        categoryDocumentsAPI: isGuest
          ? WEB_API.feedsPublicCategoryDocuments
          : WEB_API.feedsCategoryDocuments,
        bundleDocumentsAPI: isGuest
          ? WEB_API.feedsPublicBundleDocuments
          : WEB_API.feedsBundleDocuments,
        documentAPI: isGuest ? WEB_API.feedsPublicDocument : WEB_API.feedsDocument,
        bundleAPI: isGuest ? WEB_API.feedsPublicBundle : WEB_API.feedsBundle,
        catalogAPI: isGuest ? WEB_API.feedsPublicCatalog : WEB_API.feedsCatalog,
      };
    case ResourceScope.mytenant:
      return {
        categoryBundlesAPI: WEB_API.myTenantCategoryBundles,
        categoryDocumentsAPI: WEB_API.myTenantCategoryDocuments,
        bundleDocumentsAPI: WEB_API.myTenantBundleDocuments,
        documentAPI: WEB_API.myTenantDocument,
        bundleAPI: WEB_API.myTenantBundle,
        catalogAPI: WEB_API.myTenantCatalog,
      };
  }
}

/**
 * requestGraph 查询函数
 * @param api 调用API
 * @param config 配置
 */
export function requestWeb<TAPI extends WEB_API>(
  api: TAPI,
  config?: RequestConfig<
    WebParamTypeOfKey<TAPI>,
    WebVarTypeOfKey<TAPI>,
    WebRequestBodyTypeOfKey<TAPI>
  >
) {
  return _webRequest.request<WebResponseOfKey<TAPI>>({ url: api, ...config });
}

export interface Dimension {
  id: string;
  name: string;
  weight: number;
  locale: string;
  tags: Tag[];
}

export interface Tag {
  id: string;
  name: string;
  weight: number;
  locale: string;
}
