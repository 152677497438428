import { ICustomizations, ITheme, IRawStyle } from "@fluentui/react";
import { addVariants } from "@fluentui/scheme-utilities";
import { getTeamStyles } from "./teamsStyles";
import {
  FluentUITheme_TeamsContrast,
  FluentUITheme_TeamsDark,
  FluentUITheme_TeamsDefault,
  ThemeType,
} from "./teamsTheme";

export function getCustomizations(themeName: string, isMobile: boolean): ICustomizations {
  const fluentUITheme = getFluentUITheme(themeName);
  addVariants(fluentUITheme);
  const customizations: ICustomizations = {
    settings: {
      theme: fluentUITheme,
    },
    scopedSettings: getTeamStyles(themeName, fluentUITheme, isMobile),
  };
  return customizations;
}

export function getTeamsTheme(themeName: string, isMobile: boolean): ITheme {
  const fluentUITheme = getFluentUITheme(themeName);
  addVariants(fluentUITheme);
  fluentUITheme.components = getTeamStyles(themeName, fluentUITheme, isMobile) as any;
  return fluentUITheme;
}

export function getFluentUITheme(teamsThemeName: string) {
  const fontFamily: IRawStyle = {
    fontFamily: '"Segoe UI",system-ui,"Apple Color Emoji","Segoe UI Emoji",sans-serif',
  };
  switch (teamsThemeName) {
    case ThemeType.default:
      return FluentUITheme_TeamsDefault(fontFamily);
    case ThemeType.defaultV2:
      return FluentUITheme_TeamsDefault(fontFamily);
    case ThemeType.dark:
      return FluentUITheme_TeamsDark(fontFamily);
    case ThemeType.darkV2:
      return FluentUITheme_TeamsDark(fontFamily);
    case ThemeType.contrast:
      return FluentUITheme_TeamsContrast(fontFamily);
    case ThemeType.contrastV2:
      return FluentUITheme_TeamsContrast(fontFamily);
    default:
      return FluentUITheme_TeamsDefault(fontFamily);
  }
}
