import { PrincipalType, requestWeb, RoleType, WEB_API } from "../request";

export function checkUserRoles(roles: RoleType[]) {
  return requestWeb(WEB_API.checkRole, { method: "POST", data: roles });
}

export function deleteAssignment(assignmentId: string) {
  return requestWeb(WEB_API.assignment, {
    vars: {
      assignmentId,
    },
    method: "DELETE",
  });
}

export function addAssignment(
  assignments: {
    roleType: RoleType;
    principalId: string;
    principalType: PrincipalType;
  }[]
) {
  return requestWeb(WEB_API.assignments, {
    method: "POST",
    data: assignments,
  });
}
