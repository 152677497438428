/*
 *  Copyright © Microsoft Corporation. All rights reserved.
 */

import { IPersonaCoinStyles } from "@fluentui/react/lib/Persona";
import { FontWeights, ITheme } from "@fluentui/react";
const CoreColors = {
  appWhite: "#fff",
};
export function getPersonaCoinStyles(
  _themeName: string,
  _theme: ITheme
): Partial<IPersonaCoinStyles> {
  const INITIALS_FONT_COLOR = CoreColors.appWhite;
  const styles: Partial<IPersonaCoinStyles> = {
    initials: {
      color: `${INITIALS_FONT_COLOR} !important`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: FontWeights.regular,
    },
  };

  return styles;
}
