import { makeStyles, Shimmer } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useFileToken } from "../../hooks/useFileToken";

const useShimmerCss = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
  dataWrapper: {
    height: "100%",
    width: "100%",
  },
  shimmerWrapper: {
    height: "100%",
  },
});
const useVideoCss = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    display: "block",
    backgroundColor: theme.palette.neutralLighter,
  },
}));

export interface ShimmerVideoProps {
  src?: string;
  className?: string;
}
export default function ShimmerVideo({ src, ...props }: ShimmerVideoProps) {
  const shimmerCss = useShimmerCss();
  const videoCss = useVideoCss();
  const [isVideoLoaded, { setTrue }] = useBoolean(false);
  const { token } = useFileToken();

  return (
    <Shimmer className={props.className} styles={shimmerCss} isDataLoaded={isVideoLoaded}>
      <video
        onLoadedData={setTrue}
        className={videoCss.root}
        disablePictureInPicture
        controlsList="nodownload"
        controls
        autoPlay
        src={token && src ? `${src}?${token}` : ""}
      />
    </Shimmer>
  );
}
